/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import withFixedColumns from 'react-table-hoc-fixed-columns';

import '../App.css';

import '../index.css';

import { convertPricesToSelectedFiat, getDisplayedColumns } from './APIhandler';

import { Item } from '../data-types/Item';
import { Column } from '../types/Column';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

// Sort method that throw the null values at the bottom.
function customSort(a: string, b: string, desc: boolean): number {
  const infinity = desc ? '-1' : '999999999999999999';
  // force null and undefined to the bottom
  const cleaneda: string =
    a === null || a === undefined || Number.isNaN(parseFloat(a)) ? infinity : a;
  const cleanedb: string =
    b === null || b === undefined || Number.isNaN(parseFloat(b)) ? infinity : b;
  if (parseFloat(cleaneda) > parseFloat(cleanedb)) {
    return 1;
  }
  if (parseFloat(cleaneda) < parseFloat(cleanedb)) {
    return -1;
  }
  // returning 0, undefined or any falsey value will use subsequent sorts or
  // the index as a tiebreaker
  return 0;
}

type ItemListingProps = {
  items: Array<Item>;
  className: string;
  updateItemInfo: (value: string) => void | Item;
  selectedFiat: string;
  usdrates: { [key: string]: number };
  columns: Array<Column>;
  spHandleClickOpen: (name: string) => void;
};

export const ItemListing = ({
  items,
  className,
  updateItemInfo,
  selectedFiat,
  usdrates,
  columns,
  spHandleClickOpen,
}: ItemListingProps): JSX.Element => {
  let convertedToSelectedFiatItems;

  // convert the prices into usdrates
  if (selectedFiat !== 'USD') {
    if (usdrates) {
      convertedToSelectedFiatItems = convertPricesToSelectedFiat(items);
    } else {
      // console.log('Bug: usd rates were not loaded!');
      convertedToSelectedFiatItems = items;
    }
  } else {
    convertedToSelectedFiatItems = items;
  }

  const filteredcolumns = columns.filter(column => column.enabled);

  const categories: {
    [key: string]: { id: string; slug: string; name: string };
  } = items.reduce(
    (
      cats: { [key: string]: { id: string; slug: string; name: string } },
      item
    ) => {
      // const itemcats = item.categories.reduce(
      //   (
      //     itemcategories: {
      //       [key: string]: { id: string; slug: string; name: string };
      //     },
      //     cat: { id: string; slug: string; name: string }
      //   ) => ({
      //     ...itemcategories,
      //     [cat.id]: { id: cat.id, slug: cat.slug, name: cat.name },
      //   }),
      //   {}
      // );
      return { ...cats };
    },
    {}
  );

  const dataforfilters = {
    categories: Object.keys(categories)
      .map((key: string) => categories[key])
      .sort((a, b) => {
        const nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      }),
  };

  const displayedColumns = getDisplayedColumns(
    filteredcolumns,
    selectedFiat,
    customSort,
    dataforfilters,
    spHandleClickOpen
  );

  // find first enabled columm (beside the name column)
  // const firstcolumn = columns.find(
  //   column => column.enabled && column.id !== 'id'
  // );
  // const firstcolumnname = firstcolumn !== undefined ? firstcolumn.name : '';
  // calculate column width and marginleft for the second column.
  // const columnsize = 100 / (columns.length + 1);
  // let marginLeft = `${columnsize}%`;
  // if (window.innerWidth / columns.length < 100) marginLeft = '100px';

  return (
    <div className={className}>
      <ReactTableFixedColumns
        getTheadThProps={(): { style: { cursor: string } } => ({
          style: {
            cursor: 'pointer',
          },
        })}
        getTheadFilterThProps={(): { style: { cursor: string } } => ({
          style: {
            cursor: 'pointer',
          },
        })}
        getTdProps={(
          _state: {},
          rowInfo: { original: { id: string } }
        ): {
          style: { cursor: string };
          onClick: (e: {}, handleOriginal: () => void) => void;
        } => ({
          style: {
            cursor: 'pointer',
          },
          onClick: (e: {}, handleOriginal: () => void): void => {
            if (rowInfo) {
              updateItemInfo(rowInfo.original.id);

              // IMPORTANT! React-Table uses onClick internally to trigger
              // events like expanding SubComponents and pivots.
              // By default a custom 'onClick' handler will override this functionality.
              // If you want to fire the original onClick handler, call the
              // 'handleOriginal' function.
              if (handleOriginal) {
                handleOriginal();
              }
            }
          },
        })}
        data={convertedToSelectedFiatItems}
        columns={[
          ...displayedColumns,
          // {
          //   Header: '',
          //   columns: [
          //     // {
          //     //   expander: false,
          //     //   Header: '',
          //     //   accessor: 'name',
          //     //   filterable: false,
          //     //   Cell: (row: { value: number }) => (
          //     //     <span
          //     //       style={{
          //     //         fontWeight: 'bold',
          //     //       }}
          //     //     >
          //     //       {row.value}
          //     //     </span>
          //     //   ),
          //     // },
          //     ...displayedColumns,
          //   ],
          // },
        ]}
        defaultPageSize={window.innerWidth > 600 ? 50 : 25}
        showPagination
        pageSizeOptions={[5, 10, 20, 25, 50, 100, 1000, 2000]}
        showPageJump={false}
        className="-striped -highlight"
        onPageChange={(): void => {
          document.getElementsByClassName('rt-table')[0].scrollTo(0, 0);
        }}
        defaultSorted={[
          {
            id: 'name',
            desc: false,
          }
        ]}
        filterable
        style={{
          height: '100vh', // This will force the table body to overflow and scroll, since there is not enough room
        }}
      />
    </div>
  );
};

// export default ItemListing;
