import { Item } from '../data-types/Item';

const FiltersData = {
  hotkeys: {
    filter1: { name: '"b" key ', key: 'b' },
    filter2: { name: '"l" key ', key: 'l' },
    filter3: { name: '"m" key ', key: 'm' },
  },
  supportedFiat: ['USD', 'AUD', 'GBP'],
};

export function filter2condition(
  filter2: boolean,
  filter2items: Array<string>,
  item: Item
): boolean {
  return filter2 ? filter2items.includes(item.name.toLowerCase()) : true;
}

export function filter1condition(
  filter1: boolean,
  filter1items: Array<string>,
  item: Item
): boolean {
  return filter1 ? filter1items.includes(item.name.toLowerCase()) : true;
}

export default FiltersData;
