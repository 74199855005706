import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation/index';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction/index';
import ListIcon from '@material-ui/icons/ListOutlined';
import LocationOnIcon from '@material-ui/icons/HelpOutline';
import SideBarItemInfo from '../data-components/SideBarItemInfo';
import SideBarSettings from '../data-components/SideBarSettings';
import SideBarHelp from '../data-components/SideBarHelp';
import {
  getSideBarInfoMenuTab,
  getSideBarCloseButton,
} from '../data-components/APIhandler';

import '../App.css';

import '../index.css';

import { Item } from '../data-types/Item';
import { Column } from '../types/Column';

type Props = {
  filter1Change: () => void;
  filter1: boolean;
  filter2Change: () => void;
  filter2: boolean;
  filter3: boolean;
  filter3Change: () => void;
  itemInfo: Item;
  columns: Array<Column>;
  onColumnChange: (value: string) => void;
  selectedFiat: string;
  changeFiat: (value: string) => void;
  usdrates: {};
  items: Array<Item>;
  searchterm: string;
  filterItems: (value: string) => void;
  closeSnackBar: () => void;
  updateItemInfo: (value: string) => void | Item;
  className: string;
  bottomnavigationitem: number;
  bottomnavigationchange: (value: {}, value2: number) => void;
  onChangeSettings: (
    somesettings: {},
    updateItems: boolean,
    listname: string
  ) => void;
  settings: { steamid64: string };
  closeDrawer: () => void;
  gameListId: string;
  loadingData: boolean;
  moodleLoadingData: boolean;
  windowInnerWidth: number;
  igdbloginurl: string;
  igdblists: Array<{ name: string }>;
  onChangeList: (filter: string) => boolean;
};

const SideBar = ({
  filter1,
  filter1Change,
  filter2,
  filter2Change,
  filter3,
  filter3Change,
  items,
  className,
  selectedFiat,
  usdrates,
  itemInfo,
  columns,
  onColumnChange,
  bottomnavigationitem,
  bottomnavigationchange,
  onChangeSettings,
  settings,
  closeDrawer,
  loadingData,
  moodleLoadingData,
  windowInnerWidth,
  igdbloginurl,
  igdblists,
  onChangeList,
  gameListId,
}: Props): JSX.Element => {
  let content = <div>empty content</div>;

  switch (bottomnavigationitem) {
    case 0:
      content = <SideBarItemInfo itemInfo={itemInfo} />;
      break;

    case 1:
      content = (
        <SideBarSettings
          columns={columns}
          onColumnChange={onColumnChange}
          binanceOnly={filter1}
          ledgerOnly={filter2}
          maxSupplyOnly={filter3}
          filter1Change={filter1Change}
          filter2Change={filter2Change}
          filter3Change={filter3Change}
          items={items}
          selectedFiat={selectedFiat}
          usdrates={usdrates}
          onChangeSettings={(
            somesettings: {},
            updateItems: boolean,
            listname: string
          ): void => {
            onChangeSettings(somesettings, updateItems, listname);
          }}
          settings={settings}
          closeDrawer={closeDrawer}
          loadingData={loadingData}
          moodleLoadingData={moodleLoadingData}
          igdbloginurl={igdbloginurl}
          igdblists={igdblists}
          onChangeList={onChangeList}
          gameListId={gameListId}
        />
      );
      break;
    case 2:
      content = <SideBarHelp />;
      break;
    default:
  }

  const bottomnav =
    windowInnerWidth >= 1020 ? (
      <BottomNavigation
        value={bottomnavigationitem}
        onChange={bottomnavigationchange}
        showLabels
        className={items.length > 0 ? 'tabmenu' : 'onboarding tabmenu'}
      >
        {getSideBarInfoMenuTab()}
        <BottomNavigationAction label="Settings" icon={<ListIcon />} />
        <BottomNavigationAction label="About" icon={<LocationOnIcon />} />
      </BottomNavigation>
    ) : (
      ''
    );

  return (
    <div
      className={
        items.length > 0
          ? `coinInfoBar ${className}`
          : `onboarding coinInfoBar ${className}`
      }
    >
      {bottomnavigationitem === 0 ? getSideBarCloseButton(closeDrawer) : ''}
      {bottomnav}

      <div
        className={
          items.length > 0
            ? 'coinInfoBarContent'
            : 'onboarding coinInfoBarContent'
        }
      >
        {content}
      </div>
    </div>
  );
};

export default SideBar;
