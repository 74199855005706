import React from 'react';
import List from '@material-ui/core/List/index';
import ListItem from '@material-ui/core/ListItem/index';
import ListItemText from '@material-ui/core/ListItemText/index';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import Switch from '@material-ui/core/Switch/index';
import { Item } from '../data-types/Item';
import { Column } from '../types/Column';

import '../App.css';

import '../index.css';

type Props = {
  filter1Change: () => void;
  binanceOnly: boolean;
  filter2Change: () => void;
  ledgerOnly: boolean;
  maxSupplyOnly: boolean;
  filter3Change: () => void;
  columns: Array<Column>;
  onColumnChange: (id: string) => void;
  selectedFiat: string;
  usdrates: {};
  closeDrawer: () => void;
  gameListId: string;
  onChangeSettings: (
    somesettings: {},
    updateItems: boolean,
    listname: string
  ) => void;
  settings: {steamid64: string};
  loadingData: boolean;
  moodleLoadingData: boolean;
  items: Array<Item>;
  igdbloginurl: string;
  igdblists: Array<{name: string}>;
  onChangeList: (filter: string) => boolean;
};

const SideBarSettings = ({ 
  columns,
  onColumnChange,
  onChangeSettings,
  settings,
  loadingData,
  moodleLoadingData,
  items,
  igdbloginurl,
  igdblists,
  onChangeList,
  gameListId,
}: Props) => {
  const columninputs: Array<JSX.Element> = columns.map(
    (column: Column): JSX.Element => (
      <span key={column.id} className="switchinputspan">
        <Switch
          className="switchinput"
          checked={column.enabled}
          onChange={() => onColumnChange(column.id)}
          aria-label={column.id}
        />
        <ListItem className="switchinput">
          <ListItemText primary={column.name} />
        </ListItem>
      </span>
    ),
  );

  const loading = loadingData ? (
    <CircularProgress className="steamidprogress" />
  ) : (
    ''
  );

  const moodleLoading = moodleLoadingData ? (
    <CircularProgress className="switchprogress" />
  ) : (
    ''
  );

  const igdblistlisting = igdblists.map((igdblist) => <div className="igdblist">{igdblist.name}</div>);

  let columnshtml: JSX.Element | string = '';
  if (items.length > 0) {
    columnshtml = (
      <span>
        <ListItem>
          <ListItemText primary="Columns" />
        </ListItem>
        {columninputs}
      </span>
    );
  }

  return (
    <div className={items.length > 0 ? 'helper' : 'onboarding helper'}>
      <h1 className="sitetitle">Ploogins</h1>
      <h3 className="sitesubtitle">Premium plugins directory for your LMS</h3>
      <List className="helplisting">
        <div className="listbuttoncontainer">
          {' '}
          <ListItem>
            <Button
              color="primary"
              variant="contained"
              className="moodlepluginsbutton"
              onClick={(event) => onChangeList('moodle')}
            >
              Moodle plugins
            </Button>
            {' '} {moodleLoading}

            
          </ListItem>
        </div>
        
        <div
          className={
            items.length > 0 ? 'settingscolumns' : 'onboarding settingscolumns'
          }
        >
          {columnshtml}
        </div>
        <div
          className={
            items.length > 0
              ? 'settingsexperimental'
              : 'onboarding settingsexperimental'
          }
        >
          
        </div>
      </List>
      
    </div>
  );
};

export default SideBarSettings;
