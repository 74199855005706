const ColumnsData = [
  {
    id: 'name',
    name: 'Name',
    enabled: true,
    position: 1,
  },
  {
    id: 'type',
    name: 'Type',
    enabled: true,
    position: 2,
  },
  {
    id: 'firstRelease',
    name: 'First Release',
    enabled: true,
    position: 3,
  },
  {
    id: 'firstPaidRelease',
    name: 'First Paid Release',
    enabled: true,
    position: 4,
  },
];

export default ColumnsData;
