import React, { Component } from 'react';
// import { Item } from '../data-types/Item';
import CircularProgress from '@material-ui/core/CircularProgress';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Img from 'react-image';
import Button from '@material-ui/core/Button';

import '../App.css';

import '../index.css';
import { Item } from '../data-types/Item';

type Props = {
  itemInfo: Item;
};

type State = {
  id: string;
  displaymore: string;
};

class SideBarItemInfo extends Component<Props, State> {
  state = {
    id: '',
    displaymore: '',
  };

  // constructor(props) {
  //   super(props);
  //   this.ref = React.createRef();
  // }

  componentDidMount(): void {
    this.storeCurrentItem();
  }

  componentDidUpdate(): void {
    this.storeCurrentItem();
  }

  storeCurrentItem(): void {
    const { itemInfo } = this.props;
    const { id } = this.state;

    if (id !== itemInfo.id) {
      this.setState({
        id: itemInfo.id,
      });
      document.getElementsByClassName('coinInfoBar')[0].scrollBy(0, -10000);
    }
  }

  render(): JSX.Element {
    const { itemInfo } = this.props;
    const { displaymore } = this.state;

  const iframe = <div><p>{itemInfo.desc}</p></div>


    let itemSummary: JSX.Element | string = itemInfo.desc;
    if (itemSummary.length > 400 && displaymore !== itemInfo.id) {
      itemSummary = (
        <span>
          {itemSummary.substr(0, 400)}
          ...{' '}
          <Button
            color="primary"
            className="morebutton"
            onClick={(): void => this.setState({ displaymore: itemInfo.id })}
          >
            more
          </Button>
        </span>
      );
    }

    // // const { videos, screenshots, reviews } = this.state;
    // if (itemInfo.id === '') {
    //   return (
    //     <div className="selectacoin">
    //       <br />
    //       <br />
    //       <br />
    //       Select a game
    //     </div>
    //   );
    // }

    const htmlscreenshots = itemInfo.screenshots.slice(0, 10).map(screenshot => {
      const imgurl = `${screenshot.url}`;
      return (
        <Img
          key={`screenshot_${screenshot.url}`}
          src={imgurl}
          className="gameimg"
          width="100%"
          id={screenshot.url}
          loader={
            <div>
              <CircularProgress className="imageloader" />
            </div>
          }
        />
      );
    });

    let htmlvideos: Array<JSX.Element> = [];
    if (itemInfo.videos) {
      htmlvideos = itemInfo.videos.slice(0, 2).map(video => {
        const videourl = `${video.url}`;
        return (
          <div className="videoWrapper" key={`video_${video.url}`}>
            <iframe
              src={videourl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowFullScreen
              title={`video ${video.url}`}
            />
          </div>
        );
      });
    }

    // let htmlreviews: Array<JSX.Element> = [];
    // if (itemInfo.reviews) {
    //   htmlreviews = itemInfo.reviews.map(review => (
    //     <div className="reviewWrapper" key={`review_${review.user}`}>
    //       <div className="reviewtitle">
    //         <a href={review.url} target="_blank" rel="noopener noreferrer">
    //           {review.user_rating ? review.user_rating.rating : '-'} / 10
    //         </a>
    //       </div>
    //       <div className="reviewcontent">
    //         &quot;{review.content}&quot;
    //         <span className="reviewtitleuserinfo">- user {review.user} </span>
    //       </div>
    //     </div>
    //   ));
    // }

    return (
      <div className="gameinfo">
        <div
          className="gametitle"
          ref={
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            (r): void => (this.el = r)
          }
        >
          {itemInfo.name}
        </div>
        <div className="gamegenres">
          {itemInfo.type}
        </div>
        
        <div className="gameurl">
          <a href={itemInfo.url} target="_blank" rel="noopener noreferrer">
            {itemInfo.url}
          </a>
          <br/>
          <a href={itemInfo.demoUrl} target="_blank" rel="noopener noreferrer">
            {itemInfo.demoUrl}
          </a>
        </div>
        
        <div className="gamesummary">{itemSummary}</div>

        <div className="gameimages">{htmlscreenshots}</div>
        <div className="gamevideos">{htmlvideos}</div>

        {/* {itemInfo.reviews.length ? (
          <h2 className="gamereviewstitle">User reviews</h2>
        ) : (
          ''
        )} */}

        {/* <div className="gamereviews">{htmlreviews}</div> */}
      </div>
    );
  }
}

export default SideBarItemInfo;
