import React from 'react';
import Button from '@material-ui/core/Button/index';
import Input from '@material-ui/core/Input/index';
import FormControl from '@material-ui/core/FormControl/index';
import { getSearchPlaceholder } from '../data-components/APIhandler';

import '../App.css';

import '../index.css';

type Props = {
  searchterm: string;
  filterItems: (value: string) => void;
};

const SearchForm = ({ searchterm, filterItems }: Props): JSX.Element => (
  <FormControl key="form" className="searchsnackbarform">
    <Input
      autoFocus
      color="primary"
      id="custom-color-input"
      placeholder={getSearchPlaceholder()}
      value={searchterm}
      onChange={(event: { target: { value: string } }): void => {
        filterItems(event.target.value);
        window.scrollTo(0, 0);
      }}
    />
    <Button
      className="clearbutton"
      onClick={(): void => {
        filterItems('');
      }}
      // dense
    >
      clear
    </Button>
  </FormControl>
);

export default SearchForm;
