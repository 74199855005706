import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import localforage from 'localforage';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import theme from './theme';

const root = document.getElementById('root');

if (root !== null) {
  ReactDOM.render(
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <App />
      </ThemeProvider>
    </BrowserRouter>,
    root
  );
}

let swversion = 0;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
localforage.getItem('swversion').then((value: any) => {
  swversion = value;

  const currentswversion = 11;
  if (!swversion || swversion < currentswversion) {
    localforage.setItem('swversion', currentswversion);
    unregister();
    registerServiceWorker();
  } else {
    registerServiceWorker();
  }
});
