import React from 'react';
import Snackbar from '@material-ui/core/Snackbar/index';
import IconButton from '@material-ui/core/IconButton/index';
import CloseIcon from '@material-ui/icons/Close';
import SearchForm from './SearchForm';
import '../App.css';
import '../index.css';

type SearchSnackBarProps = {
  searchterm: string;
  filterItems: (value: string) => void;
  snackbaropen: boolean;
  closeSnackBar: () => void;
};

const SearchSnackBar = ({
  searchterm,
  filterItems,
  snackbaropen,
  closeSnackBar,
}: SearchSnackBarProps): JSX.Element => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
    key="searchsnackbar"
    open={snackbaropen}
    onClose={closeSnackBar}
    className="searchsnackbar"
    // SnackbarContentProps={{
    //   'aria-describedby': 'message-id',
    // }}
    message={<span id="message-id" />}
    action={[
      <SearchForm
        key={searchterm}
        searchterm={searchterm}
        filterItems={filterItems}
      />,
      <IconButton
        key="close"
        aria-label="Close"
        // color="inherit"
        onClick={closeSnackBar}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

export default SearchSnackBar;
