const DefaultItemData = {
  id: '0',
  name: '',
  url: '',
  demoUrl: '',
  type: '',
  desc: '',
  screenshots: [],
  videos: [],
  firstRelease: 0,
  firstPaidRelease: 0
};

export const autoSelectedItemId = 'none';

export default DefaultItemData;
