import React from 'react';
import ListItem from '@material-ui/core/ListItem/index';
import ListItemText from '@material-ui/core/ListItemText/index';

import '../App.css';

import '../index.css';

const SideBarHelp = (): JSX.Element => (
  <div className="helper">
    <ListItem className="helppartnersection">
      <ListItemText
        primary="Why Ploogins"
        secondary="Because it is not easy to find a place listing all existing quality premiums plugins for your LMS.- even though there are very few of them. We also would like to be able to sort/filter them in an easier way."
      />
    </ListItem>
    <ListItem>
      <ListItemText
        primary="Multiple Sorting"
        secondary="You can sort on multiple columns when holding Shift."
      />
    </ListItem>
    <ListItem>
      <ListItemText
        primary="This tool is a prototype!"
        secondary="It is running on a PWA framework that I am currently working on. It will change following (your) user requests.
        Even though we try to keep it up to date, it also means that the data is questionable, do your own research if you need to confirm the veracity of the information.
       "
      />
    </ListItem>
  </div>
);

export default SideBarHelp;
