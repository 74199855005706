import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import SwipeableDrawer from '@material-ui/core/Drawer/index';
import { HotKeys } from 'react-hotkeys';
import localforage from 'localforage';
import { ItemListing } from './data-components/ItemListing';
import ShortkeySnackBar from './components/ShortkeySnackBar';
import CustomizedSnackBar from './components/CustomizedSnackBar';
import SearchSnackBar from './components/SearchSnackBar';
import SideBar from './components/SideBar';
import IgdbLogin from './data-components/IgdbLogin';
import withRoot from './withRoot';
import { Item } from './data-types/Item';
import { ItemsExtra } from './data-types/ItemsExtra';
import { Column } from './types/Column';
import { SnackBarType } from './types/SnackBarType';
import './App.css';
import ColumnsData from './data-components/ColumnsData';
import Filters, {
  filter1condition,
  filter2condition,
} from './data-components/FiltersData';
import DefaultItem, {
  autoSelectedItemId,
} from './data-components/DefaultItemData';
import APIhandler, {
  getSearchCondition,
  getOpenDrawerDefault,
  getSideBarInfoMenuTab,
} from './data-components/APIhandler';
import gkreviews from './database/gkreviews';

import BottomNavigation from '@material-ui/core/BottomNavigation/index';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction/index';
import ListIcon from '@material-ui/icons/BuildOutlined';
import LocationOnIcon from '@material-ui/icons/HelpOutline';

import SoundtrackDialog from './data-components/SoundtrackDialog';

const hotkeyMap = {
  filter1: Filters.hotkeys.filter1.key,
  filter2: Filters.hotkeys.filter2.key,
  filter3: Filters.hotkeys.filter3.key,
  search: 's',
};

interface Props {
  classes: {
    root: {};
    fab: string;
    list: string;
  };
}

interface State {
  // SteamGK state
  steamid: number;
  loading: boolean;
  error: boolean;
  gkreviews: {
    note?: string;
    url: string;
    name: string;
  }[];
  // app features
  shortkeysnackbaropen: boolean;
  customizedsnackbaropen: boolean;
  snackbarmessage: string;
  snackbartype: SnackBarType;
  searchsnackbaropen: boolean;
  columns: Column[];
  bottomnavigationitem: number;
  opendrawer: boolean;
  searchterm: string;
  usdrates: { [key: string]: number };
  selectedFiat: string;
  // app data - currently selected row - app feature?
  itemInfo: Item;
  // app data - rows displayed in the table
  items: Item[];
  // app data - row extra data
  itemsExtraData: ItemsExtra;
  // app data - pre-filtered (whitelisted) rows
  filter2items: string[];
  filter1items: string[];
  // app data filters
  filter1: boolean;
  filter2: boolean;
  filter3: boolean;

  windowInnerWidth: number;
  settings: {};
  loadingData: boolean;
  moodleLoadingData: boolean;
  soundtrackName: string;
  gameListId: string;
  igdbloginurl: string;
  spOpen: boolean;
  igdblists: { name: string }[];
}

class App extends Component<Props, State> {
  state = {
    // steam GK
    steamid: 0,
    loading: false,
    error: false,
    gkreviews,
    // other
    items: [],
    filter1: false,
    filter2: false,
    filter3: false,
    usdrates: {},
    itemInfo: DefaultItem,
    shortkeysnackbaropen: false,
    customizedsnackbaropen: false,
    snackbarmessage: 'no exception error message',
    searchsnackbaropen: false,
    itemsExtraData: {},
    selectedFiat: 'USD',
    filter1items: [],
    filter2items: [],
    searchterm: '',
    opendrawer: getOpenDrawerDefault(),
    columns: ColumnsData,
    bottomnavigationitem: 1,
    snackbartype: SnackBarType.error,
    windowInnerWidth: window.innerWidth,
    settings: { steamid64: '' },
    igdbloginurl: '',
    igdblists: [],
    spOpen: false,
    moodleLoadingData: false,
    soundtrackName: '',
    gameListId: '',
    loadingData: false,
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async componentDidMount() {
    fetch('https://shrouded-bayou-67011.herokuapp.com/igdbloginurl')
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ igdbloginurl: responseJson.url });
      })
      .catch((error: { message: string }) => {
        this.displayErrorSnackBar(error);
      });

    // Load the innerWidth
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));

    // check we don't want to force a clear cache.
    let appversion = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await localforage.getItem('appversion').then((value: any) => {
      appversion = value;
    });

    let storedGameListId = 'false';
    let storedItems = '[]';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await localforage.getItem('gameListId').then((value: any) => {
      storedGameListId = value;
    });

    if (storedGameListId) {
      await localforage
        .getItem(`${storedGameListId}_items`)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((value: any) => {
          storedItems = value;
        });
    }

    const currentappversion = 11;
    if (!appversion || appversion < currentappversion) {
      if (storedItems) {
        this.displayCustomizedSnackBar(
          SnackBarType.info,
          'The app has been updated (cache cleared)'
        );
      }
      await localforage.clear();
      await localforage.setItem('appversion', currentappversion);
      return;
    }

    let storedfilter1 = false;
    await localforage.getItem('filter1').then(value => {
      storedfilter1 = value === 'true';
    });
    let storedfilter2 = false;
    await localforage.getItem('filter2').then(value => {
      storedfilter2 = value === 'true';
    });
    let storedfilter3 = false;
    await localforage.getItem('filter3').then(value => {
      storedfilter3 = value === 'true';
    });

    let storedcolumns = '[]';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await localforage.getItem('columns').then((value: any) => {
      storedcolumns = value;
    });
    if (storedcolumns) {
      const columns: Column[] = JSON.parse(storedcolumns);
      this.setState({ columns });
    }

    if (storedfilter1) {
      this.setState({ filter1: storedfilter1 });
    }

    if (storedfilter2) {
      this.setState({ filter2: storedfilter2 });
    }

    if (storedfilter3) {
      this.setState({ filter3: storedfilter3 });
    }

    if (storedItems && storedItems.length > 0) {
      await this.setState({
        items: JSON.parse(storedItems),
        gameListId: storedGameListId,
      });
      this.initialiseToFirstItem(autoSelectedItemId);
      this.toggleDrawer(false);
    }
  }

  /**
   * Remove event listener
   */
  componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  onChangeSettings = (
    somesettings: { [key: string]: string },
    updateItems: boolean,
    listname: string
  ): void => {
    const { filter1, filter1items, filter2, filter3, settings } = this.state;

    const newsettings = { ...settings, ...somesettings };

    if (updateItems) {
      this.setState({ loadingData: true });
      APIhandler.setItems(
        this.setState.bind(this),
        this.initialiseToFirstItem.bind(this),
        filter1,
        filter1items,
        filter2,
        filter3,
        this.displayErrorSnackBar.bind(this),
        newsettings,
        () => this.toggleDrawer(false),
        null,
        listname
      );
    }

    this.setState({ settings: newsettings });
  };

  notifyCurrentSettings = (): void => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async switchColumnState(columnid: string) {
    const { columns } = this.state;
    const updatedcolumns = columns.map(column => {
      if (column.id !== columnid) {
        return column;
      }
      return {
        id: columnid,
        name: column.name,
        enabled: !column.enabled,
        position: column.position,
      };
    });
    this.setState({ columns: updatedcolumns });
    await localforage.setItem('columns', JSON.stringify(updatedcolumns));
  }

  toggleDrawer = (open: boolean): void => {
    this.setState({
      opendrawer: open,
    });
  };

  handleClose = (): void => {};

  /**
   * Calculate & Update state of new dimensions
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async updateDimensions() {
    const windowInnerWidth = window.innerWidth;
    this.setState({ windowInnerWidth });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async filterItems(
    filter1: boolean,
    filter2: boolean,
    filter3: boolean,
    coinstr: string
  ) {
    const { filter1items, filter2items } = this.state;
    let jsonStoredItems = '[]';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await localforage.getItem('items').then((value: any) => {
      jsonStoredItems = value;
    });
    // convert null value into empty string (better sorted on the table)
    const storedItems = jsonStoredItems ? JSON.parse(jsonStoredItems) : [];
    const items = storedItems.filter(
      (item: Item) =>
        filter1condition(filter1, filter1items, item) &&
        filter2condition(filter2, filter2items, item) &&
        (coinstr && coinstr !== '' ? getSearchCondition(item, coinstr) : true)
    );
    this.setState({
      items,
      filter1,
      filter2,
      filter3,
      searchterm: coinstr,
    });

    // scroll table to top after a filter/a search is done.
    document.getElementsByClassName('rt-table')[0].scrollTo(0, 0);
  }

  initialiseToFirstItem(itemid: string): void {
    const { items, windowInnerWidth } = this.state;

    if (itemid !== 'none') {
      if (windowInnerWidth > 1020 && items.length > 0) {
        this.updateItemInfo(itemid);
      }
    }
  }

  updateItemInfo(itemId: string): void | Item {
    const { items } = this.state;

    const theItem: Item | null | undefined = items.find(
      (item: Item): boolean => item.id === itemId
    );

    // If the item does not exist return an error message and indicate we were trying to change an item.
    if (theItem === undefined || !theItem) {
      this.displayErrorSnackBar({
        message: `The item id (${itemId}) is not in the item listing.`,
      });

      return;
    }

    this.setState({
      itemInfo: theItem,
      bottomnavigationitem: 0,
    });

    this.toggleDrawer(true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async changeFiat(newSelectedFiat: string) {
    this.setState({
      selectedFiat: newSelectedFiat,
    });
    await localforage.setItem('fiat', newSelectedFiat);
  }

  displayErrorSnackBar(error: { message: string }): void {
    this.displayCustomizedSnackBar(SnackBarType.error, error.message);
    throw error;
  }

  spHandleClose = (): void => {
    this.setState({ spOpen: false });
  };

  spHandleClickOpen = (name: string): void => {
    this.setState({
      spOpen: true,
      soundtrackName: name,
    });
  };

  onChangeList = (filter: string): boolean => {
    localforage.setItem('gameListId', filter);
    this.setState({ gameListId: filter });
    let nodeserverurl = '';
    if (filter === 'switch') {
      this.setState({ moodleLoadingData: true });
      nodeserverurl =
        new URL(window.location.href).hostname === 'localhost'
          ? 'http://localhost:3030/switchgames'
          : 'https://shrouded-bayou-67011.herokuapp.com/switchgames';
    } else if (filter === 'steam') {
      // Do not trigger any loading yet. It is trigger by the steam input text.
      return true;
    }
    APIhandler.setItems(
      this.setState.bind(this),
      this.initialiseToFirstItem.bind(this),
      this.state.filter1,
      this.state.filter1items,
      this.state.filter2,
      this.state.filter3,
      this.displayErrorSnackBar.bind(this),
      {},
      () => this.toggleDrawer(false),
      nodeserverurl,
      filter
    );
    return true;
  };

  displayCustomizedSnackBar(
    snackbartype: SnackBarType,
    snackbarmessage: string
  ): void {
    this.setState({
      customizedsnackbaropen: true,
      snackbartype,
      snackbarmessage,
    });
  }

  render(): JSX.Element {
    const {
      filter1,
      filter2,
      filter3,
      opendrawer,
      columns,
      searchterm,
      itemInfo,
      items,
      selectedFiat,
      usdrates,
      bottomnavigationitem,
      shortkeysnackbaropen,
      searchsnackbaropen,
      snackbarmessage,
      snackbartype,
      customizedsnackbaropen,
      windowInnerWidth,
      settings,
      loadingData,
      moodleLoadingData,
      igdbloginurl,
      igdblists,
      soundtrackName,
      gameListId,
    } = this.state;

    const hotkeysHandlers = {
      filter1: (): void => {
        this.filterItems(!filter1, filter2, filter3, '');
        localforage.setItem('filter1', (!filter1).toString());
        this.setState({ shortkeysnackbaropen: true });
      },
      filter2: (): void => {
        this.filterItems(filter1, !filter2, filter3, '');
        localforage.setItem('filter2', (!filter2).toString());
        this.setState({ shortkeysnackbaropen: true });
      },
      filter3: (): void => {
        this.filterItems(filter1, filter2, !filter3, '');
        localforage.setItem('filter3', (!filter3).toString());
        this.setState({ shortkeysnackbaropen: true });
      },
      search: (event?: KeyboardEvent): void => {
        if (event) {
          event.preventDefault(); // do not propagate the s character in the search box.
        }
        this.setState({ searchsnackbaropen: true });
      },
    };

    const dialog =
      windowInnerWidth > 1020 ? (
        ''
      ) : (
        <SwipeableDrawer
          anchor="right"
          open={opendrawer}
          onClose={(): void => this.toggleDrawer(false)}
        >
          <div>
            <SideBar
              className=""
              columns={columns}
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              onColumnChange={columnid => this.switchColumnState(columnid)}
              searchterm={searchterm}
              filter1={filter1}
              filter2={filter2}
              filter3={filter3}
              filter1Change={(): void => {
                this.filterItems(!filter1, filter2, filter3, '');
              }}
              filter2Change={(): void => {
                this.filterItems(filter1, !filter2, filter3, '');
              }}
              filter3Change={(): void => {
                this.filterItems(filter1, filter2, !filter3, '');
              }}
              changeFiat={(newselectedFiat): void => {
                this.changeFiat(newselectedFiat);
              }}
              itemInfo={itemInfo}
              items={items}
              updateItemInfo={(itemId): void | Item =>
                this.updateItemInfo(itemId)
              }
              selectedFiat={selectedFiat}
              usdrates={usdrates}
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              filterItems={itemstr =>
                this.filterItems(filter1, filter2, filter3, itemstr)
              }
              closeSnackBar={(): void => {
                this.setState({ searchsnackbaropen: false });
              }}
              bottomnavigationchange={(event, value): void => {
                this.setState({ bottomnavigationitem: value });
              }}
              bottomnavigationitem={bottomnavigationitem}
              onChangeSettings={(somesettings, updateItems, listname): void => {
                this.onChangeSettings(somesettings, updateItems, listname);
              }}
              settings={settings}
              closeDrawer={(): void => this.toggleDrawer(false)}
              loadingData={loadingData}
              moodleLoadingData={moodleLoadingData}
              windowInnerWidth={windowInnerWidth}
              igdbloginurl={igdbloginurl}
              igdblists={igdblists}
              onChangeList={(filter): boolean => this.onChangeList(filter)}
              gameListId={gameListId}
            />
          </div>
        </SwipeableDrawer>
      );

    const bottomnav =
      windowInnerWidth < 1020 && opendrawer ? (
        <BottomNavigation
          value={bottomnavigationitem}
          onChange={(event, value): void => {
            this.setState({ bottomnavigationitem: value });
          }}
          showLabels
          className={items.length > 0 ? 'tabmenu' : 'onboarding tabmenu'}
        >
          {getSideBarInfoMenuTab()}
          <BottomNavigationAction label="Settings" icon={<ListIcon />} />
          <BottomNavigationAction label="About" icon={<LocationOnIcon />} />
        </BottomNavigation>
      ) : (
        ''
      );

    const sidebar =
      windowInnerWidth > 1020 ? (
        <SideBar
          closeDrawer={(): void => this.toggleDrawer(false)}
          columns={columns}
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          onColumnChange={column => this.switchColumnState(column)}
          className="wideSideBar"
          searchterm={searchterm}
          filter1={filter1}
          filter2={filter2}
          filter3={filter3}
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          filter1Change={() => {
            this.filterItems(!filter1, filter2, filter3, '');
          }}
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          filter2Change={() => {
            this.filterItems(filter1, !filter2, filter3, '');
          }}
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          filter3Change={() => {
            this.filterItems(filter1, filter2, !filter3, '');
          }}
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          changeFiat={newselectedFiat => {
            this.changeFiat(newselectedFiat);
          }}
          itemInfo={itemInfo}
          items={items}
          updateItemInfo={(itemId): void | Item => this.updateItemInfo(itemId)}
          selectedFiat={selectedFiat}
          usdrates={usdrates}
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          filterItems={itemstr =>
            this.filterItems(filter1, filter2, filter3, itemstr)
          }
          closeSnackBar={(): void => {
            this.setState({ searchsnackbaropen: false });
          }}
          bottomnavigationchange={(_event, value): void => {
            this.setState({ bottomnavigationitem: value });
          }}
          bottomnavigationitem={bottomnavigationitem}
          onChangeSettings={(somesettings, updateItems, listname): void => {
            this.onChangeSettings(somesettings, updateItems, listname);
          }}
          settings={settings}
          loadingData={loadingData}
          moodleLoadingData={moodleLoadingData}
          windowInnerWidth={windowInnerWidth}
          igdbloginurl={igdbloginurl}
          igdblists={igdblists}
          onChangeList={(filter): boolean => this.onChangeList(filter)}
          gameListId={gameListId}
        />
      ) : (
        ''
      );

    return (
      <div>
        <Route
          exact
          path="/"
          render={(): JSX.Element => (
            <HotKeys
              keyMap={hotkeyMap}
              handlers={hotkeysHandlers}
              focused
              attach={window}
            >
              {dialog}
              <ItemListing
                className={
                  items.length > 0 ? 'coinlisting' : 'onboarding coinlisting'
                }
                items={items}
                updateItemInfo={(itemId): Item | void =>
                  this.updateItemInfo(itemId)
                }
                selectedFiat={selectedFiat}
                usdrates={usdrates}
                columns={columns}
                spHandleClickOpen={(name): void => this.spHandleClickOpen(name)}
              />
              {bottomnav}
              {sidebar}
              <ShortkeySnackBar
                shortkeyStatus={{
                  filter1,
                  filter2,
                  filter3,
                }}
                snackbaropen={shortkeysnackbaropen}
                closeShortkeySnackBar={(): void => {
                  this.setState({ shortkeysnackbaropen: false });
                }}
              />
              <SearchSnackBar
                searchterm={searchterm}
                snackbaropen={searchsnackbaropen}
                closeSnackBar={(): void => {
                  this.setState({ searchsnackbaropen: false });
                }}
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                filterItems={(itemstr: string) =>
                  this.filterItems(filter1, filter2, filter3, itemstr)
                }
              />
              <CustomizedSnackBar
                message={snackbarmessage}
                type={snackbartype}
                snackbaropen={customizedsnackbaropen}
                closeCustomizedSnackBar={(): void => {
                  this.setState({ customizedsnackbaropen: false });
                }}
              />

              <SoundtrackDialog
                igdbid={itemInfo.id}
                name={soundtrackName}
                open={this.state.spOpen}
                onClose={this.spHandleClose}
              />
              <span className="hidetopleftcorner" />
            </HotKeys>
          )}
        />
        <Route
          path="/igdblogin"
          render={({ history }): JSX.Element => (
            <IgdbLogin
              history={history}
              setIgdbLists={(igdblists): void => {
                this.setState({ igdblists });
              }}
              displayErrorSnackBar={(error: { message: string }): void => {
                this.displayErrorSnackBar(error);
              }}
            />
          )}
        />
      </div>
    );
  }
}

export default withRoot(App);
