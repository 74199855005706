import React, { Component } from 'react';
import '../App.css';
import '../index.css';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Popper from '@material-ui/core/Popper';
import Draggable from 'react-draggable';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/ArrowLeft';
import SkipNextIcon from '@material-ui/icons/ArrowRight';

type DialogTitleProps = {
  children: string;
  onClose: () => void;
};

const DialogTitle = (props: DialogTitleProps): JSX.Element => {
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

interface Props {
  onClose: () => void;
  name: string;
  open: boolean;
  classes?: { [key: string]: string };
  selectedValue?: string;
  igdbid: string;
}

type AlbumIter = {
  [key: string]: number;
};

type Album = {
  id: string;
  uri: string;
  link: string;
};

type Albums = {
  [key: string]: Array<Album>;
};

type State = {
  spotifyAlbums: Array<Album>;
  spotifyAlbum: number;
  deezerAlbums: Array<Album>;
  deezerAlbum: number;
  searched: boolean;
  provider: string;
  changingAlbum: boolean;
  albums: Albums;
  album: AlbumIter;
  open: boolean;
  itemName: string;
};

class SoundtrackDialog extends Component<Props, State> {
  state = {
    spotifyAlbums: [],
    spotifyAlbum: 0,
    deezerAlbums: [],
    deezerAlbum: 0,
    album: { spotify: 0, deezer: 0 },
    albums: {
      spotify: [],
      deezer: [],
    },
    searched: false,
    open: false,
    provider: 'spotify',
    changingAlbum: false,
    itemName: '',
  };

  handleChange = (event: { target: { name: string; value: string } }): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //@ts-ignore do not want to have complicated typescript for the sake of checking this dynamic setState.
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = (): void => {
    this.setState({
      searched: false,
      albums: {
        spotify: [],
      },
      album: {
        spotify: 0,
      },
    });
    this.props.onClose();
  };

  loadSoundTrack(): void {
    const { changingAlbum, itemName } = this.state;
    const { open, name } = this.props;
    if (open && name !== itemName) {
      // retrieve spotify albums
      const nodeserverurl =
        new URL(window.location.href).hostname === 'localhost'
          ? `http://localhost:3030/soundtrack/?name=${name}`
          : `https://shrouded-bayou-67011.herokuapp.com/soundtrack/?name=${name}`;

      fetch(nodeserverurl)
        .then(response => response.json())
        .then((responseJson: { items: string; spotify: Array<Album> }) => {
          this.setState({
            spotifyAlbums: responseJson.spotify,
            spotifyAlbum: 0,
            albums: {
              spotify: responseJson.spotify,
            },
            album: {
              spotify: 0,
            },

            searched: true,
            itemName: name,
          });
        })
        .catch((error: { message: string }) => {
          throw error.message;
        });
    }

    if (changingAlbum) {
      this.setState({ changingAlbum: false });
    }
  }

  componentDidMount(): void {
    this.loadSoundTrack();
  }

  componentDidUpdate(): void {
    this.loadSoundTrack();
  }

  render(): JSX.Element {
    const {
      searched,
      provider,
      changingAlbum,
      albums,
      album,
    }: {
      searched: boolean;
      provider: string;
      changingAlbum: boolean;
      albums: Albums;
      album: AlbumIter;
    } = this.state;

    let player: string | JSX.Element = '';
    const albumIndex: number = album[provider];
    const providerAlbums: Array<Album> = albums[provider];
    if (provider === 'spotify') {
      player = albums[provider][albumIndex] ? (
        changingAlbum ? (
          ''
        ) : (
          <iframe
            title={`spotify_frame_${albums[provider][albumIndex].id}`}
            src={`https://open.spotify.com/embed/album/${albums[provider][albumIndex].id}`}
            width="300"
            height="380"
            frameBorder="0"
            allow="encrypted-media"
          />
        )
      ) : (
        <div className={searched ? 'noalbumfound' : 'noalbumfound invisible'}>
          No album found
        </div>
      );
    }

    const providerUri: string = providerAlbums[albumIndex]
      ? provider === 'spotify'
        ? albums[provider][albumIndex].uri
        : albums[provider][albumIndex].link
      : '';

    const prevnextcontroller = albums[provider].length ? (
      <span>
        <IconButton
          onClick={(): void => {
            let previousalbum = 0;
            if (albumIndex - 1 > 0) {
              previousalbum = albumIndex - 1;
            }
            this.setState({
              album: { ...album, [provider]: previousalbum },
              changingAlbum: true,
            });
          }}
          aria-label="Previous"
          className="soundtrackPreviousButton {
"
        >
          <SkipPreviousIcon />
        </IconButton>
        Album {albumIndex + 1} / {albums[provider].length}
        <IconButton
          onClick={(): void => {
            let nextalbum = 0;
            if (albums[provider].length !== albumIndex + 1) {
              nextalbum = albumIndex + 1;
            }

            this.setState({
              album: { ...album, [provider]: nextalbum },
              changingAlbum: true,
            });
          }}
          aria-label="Next"
        >
          <SkipNextIcon />
        </IconButton>
      </span>
    ) : (
      ''
    );

    const openspotify: string | JSX.Element = providerAlbums.length ? (
      <a href={providerUri} className="openinspotify">
        OPEN {provider}
      </a>
    ) : (
      ''
    );

    return (
      <Draggable>
        <Popper
          id="soundtrackpopup"
          open={this.props.open}
          aria-labelledby="simple-dialog-title"
          transition
        >
          <DialogTitle onClose={this.handleClose}> </DialogTitle>
          <span>
            <span>{openspotify}</span>
            <span>{prevnextcontroller}</span>
          </span>
          <div className="spotifyframe">{player}</div>
        </Popper>
      </Draggable>
    );
  }
}

export default SoundtrackDialog;
