import React, { Component } from 'react';
import '../App.css';
import '../index.css';

type Props = {
  history: { push: (value: string) => void };
  displayErrorSnackBar: (error: { message: string }) => void;
  // eslint-disable-next-line no-empty-pattern
  setIgdbLists: ([]) => void;
};

class IgdbLogin extends Component<Props> {
  state = {};

  getParams(location: { search: string }): { code: string | null } {
    const searchParams = new URLSearchParams(location.search);
    return {
      code: searchParams.get('code'),
    };
  }

  render(): JSX.Element {
    const { history, displayErrorSnackBar, setIgdbLists } = this.props;
    const { code } = this.getParams(window.location);

    if (code) {
      fetch(`https://shrouded-bayou-67011.herokuapp.com/user/?code=${code}`)
        .then(response => response.json())
        .then(responseJson => {
          setIgdbLists(responseJson);
          history.push('/');
        })
        .catch((error: { message: string }) => {
          displayErrorSnackBar(error);
        });
    }
    return <div>Login...</div>;
  }
}

export default IgdbLogin;
