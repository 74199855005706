import React from 'react';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton/index';
import Snackbar from '@material-ui/core/Snackbar/index';
import SnackbarContent from '@material-ui/core/SnackbarContent/index';
import WarningIcon from '@material-ui/icons/Warning';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { SnackBarType } from '../types/SnackBarType';

const variantIcon: {
  [key: string]: React.ComponentType<SvgIconProps>;
} = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

// Theme-dependent styles
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      success: {
        backgroundColor: green[600],
      },
      error: {
        backgroundColor: palette.error.dark,
      },
      info: {
        backgroundColor: palette.primary.dark,
      },
      warning: {
        backgroundColor: amber[700],
      },
      icon: {
        fontSize: 20,
      },
      iconVariant: {
        opacity: 0.9,
        marginRight: spacing(),
      },
      message: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  });

function MySnackbarContent(props: {
  classes: { [key: string]: string };
  className: string;
  message: string;
  onClose: () => void;
  variant: string;
}): JSX.Element {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon: React.ComponentType<SvgIconProps> = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

type Props = {
  snackbaropen: boolean;
  closeCustomizedSnackBar: () => void;
  message: string;
  type: SnackBarType;
};

const CustomizedSnackbar = ({
  snackbaropen,
  closeCustomizedSnackBar,
  message,
  type,
}: Props): JSX.Element => (
  <div>
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={snackbaropen}
      onClose={closeCustomizedSnackBar}
    >
      <MySnackbarContentWrapper
        onClose={closeCustomizedSnackBar}
        variant={type}
        message={message}
        className=""
      />
    </Snackbar>
  </div>
);

export default CustomizedSnackbar;
