const gkreviews1: Array<{ note?: string; url: string; name: string }> = [
  {
    note: '8',
    url: '/jeux/pillars-of-eternity-3050300593/test.html',
    name: 'pillars-of-eternity',
  },
  {
    note: '8',
    url: '/jeux/crypt-of-the-necrodancer-3050208706/test.html',
    name: 'crypt-of-the-necrodancer',
  },
  {
    note: '8',
    url: '/jeux/kerbal-space-program-3050284789/test.html',
    name: 'kerbal-space-program',
  },
  {
    note: '9',
    url: '/jeux/invisible-inc-3050466057/test.html',
    name: 'invisible--inc.',
  },
  {
    note: '8',
    url: '/jeux/nuclear-throne-3050172675/test.html',
    name: 'nuclear-throne',
  },
  {
    note: '8',
    url: '/jeux/darkest-dungeon-3050376113/test.html',
    name: 'darkest-dungeon',
  },
  {
    note: '8',
    url: '/jeux/grim-dawn-100726/test.html',
    name: 'grim-dawn',
  },
  {
    note: '8',
    url: '/jeux/salt-and-sanctuary-3050328781/test.html',
    name: 'salt-and-sanctuary',
  },
  {
    note: '9',
    url: '/jeux/dark-souls-3-3050477169/test.html',
    name: 'dark-souls-iii',
  },
  {
    note: '7',
    url: '/jeux/stellaris-3050660463/test.html',
    name: 'stellaris',
  },
  { note: '8', url: '/jeux/furi-3050561463/test.html', name: 'furi' },
  {
    note: '7',
    url: '/jeux/rungunjumpgun-3050609519/test.html',
    name: 'rungunjumpgun',
  },
  {
    note: '7',
    url: '/jeux/thumper-3050480161/test.html',
    name: 'thumper',
  },
  {
    note: '7',
    url: '/jeux/butcher-3050788473/test.html',
    name: 'butcher',
  },
  {
    note: '6',
    url: '/jeux/rain-world-3050569529/test.html',
    name: 'rain-world',
  },
  {
    note: '7',
    url: '/jeux/nex-machina-3050873167/test.html',
    name: 'nex-machina',
  },
  {
    note: '8',
    url: '/jeux/the-end-is-nigh-3050875981/test.html',
    name: 'the-end-is-nigh',
  },
  { note: '8', url: '/jeux/fez-3010006481/test.html', name: 'fez' },
  {
    note: '8',
    url: '/jeux/ratchet-clank-3050273939/test.html',
    name: 'ratchet-clank',
  },
  {
    note: '6',
    url: '/jeux/eagle-flight-3050595993/test.html',
    name: 'eagle-flight',
  },
  {
    note: '4',
    url: '/jeux/1-2-switch-3050838143/test.html',
    name: '1-2-switch',
  },
  { note: '7', url: '/jeux/gnog-3050479145/test.html', name: 'gnog' },
  {
    note: '6',
    url: '/jeux/crash-bandicoot-n-sane-trilogy-3050817603/test.html',
    name: 'crash-bandicoot-n-sane-trilogy',
  },
  { note: '7', url: '/jeux/ode-3050877277/test.html', name: 'ode' },
  {
    note: '6',
    url: '/jeux/nidhogg-3050255791/test.html',
    name: 'nidhogg',
  },
  {
    note: '9',
    url: '/jeux/towerfall-3050165694/test.html',
    name: 'towerfall',
  },
  {
    note: '7',
    url: '/jeux/rocket-league-3050454653/test.html',
    name: 'rocket-league',
  },
  {
    note: '7',
    url: '/jeux/helldivers-3050173652/test.html',
    name: 'helldivers',
  },
  {
    note: '7',
    url: '/jeux/overwatch-3050575573/test.html',
    name: 'overwatch',
  },
  {
    note: '8',
    url: '/jeux/killing-floor-2-3050375871/test-pc.html',
    name: 'killing-floor-2',
  },
  {
    note: '7',
    url: '/jeux/tom-clancy-s-ghost-recon-wildlands-3050484743/test.html',
    name: 'tom-clancy-s-ghost-recon-wildlands',
  },
  {
    note: '6',
    url: '/jeux/disc-jam-3050720473/test.html',
    name: 'disc-jam',
  },
  {
    note: '8',
    url: '/jeux/puyo-puyo-tetris-3050179555/test.html',
    name: 'puyo-puyo-tetris',
  },
  {
    note: '6',
    url: '/jeux/nba-playgrounds-3050875777/test-pc.html',
    name: 'nba-playgrounds',
  },
  {
    note: '7',
    url: '/jeux/tekken-7-3050301183/test.html',
    name: 'tekken-7',
  },
  {
    note: '8',
    url: '/jeux/final-fantasy-xiv-stormblood-3050788687/test.html',
    name: 'final-fantasy-xiv-stormblood',
  },
  {
    note: '8',
    url: '/jeux/playerunknown-s-battlegrounds-3050875615/test.html',
    name: 'playerunknown-s-battlegrounds',
  },
  {
    note: '9',
    url: '/jeux/grand-theft-auto-v-3010010088/test.html',
    name: 'grand-theft-auto-v',
  },
  {
    note: '8',
    url: '/jeux/elite-dangerous-3050426653/test.html',
    name: 'elite-dangerous',
  },
  {
    note: '7',
    url: '/jeux/cities-skylines-3050429383/test.html',
    name: 'cities-skylines',
  },
  {
    note: '9',
    url: '/jeux/the-witcher-3-traque-sauvage-3050117569/test.html',
    name: 'the-witcher-3-traque-sauvage',
  },
  { note: '9', url: '/jeux/xcom-2-3050476549/test.html', name: 'xcom-2' },
  {
    note: '8',
    url: '/jeux/hyper-light-drifter-3050182198/test.html',
    name: 'hyper-light-drifter',
  },
  { note: '7', url: '/jeux/doom-3050274973/test.html', name: 'doom' },
  {
    note: '9',
    url: '/jeux/battlefield-1-3050692319/test.html',
    name: 'battlefield-1',
  },
  {
    note: '8',
    url: '/jeux/civilization-vi-3050694643/test.html',
    name: 'sid-meier-s-civilization-vi',
  },
  {
    note: '9',
    url: '/jeux/planet-coaster-3050487417/test.html',
    name: 'planet-coaster',
  },
  {
    note: '8',
    url: '/jeux/shadow-tactics-blades-of-the-shogun-3050659051/test.html',
    name: 'shadow-tactics-blades-of-the-shogun',
  },
  {
    note: '9',
    url: '/jeux/prey-2017-3050713579/test.html',
    name: 'prey-2017',
  },
  {
    note: '9',
    url: '/jeux/into-the-breach-3050856559/test.html',
    name: 'into-the-breach',
  },
  {
    note: '6',
    url: '/jeux/octahedron-3050878265/test.html',
    name: 'octahedron',
  },
  {
    note: '5',
    url: '/jeux/far-cry-5-3050875843/test.html',
    name: 'far-cry-5',
  },
  {
    note: '6',
    url: '/jeux/sea-of-thieves-3050714203/test-pc.html',
    name: 'sea-of-thieves',
  },
  {
    note: '7',
    url: '/jeux/burnout-paradise-hd-remaster-3050877543/test.html',
    name: 'burnout-paradise-hd-remaster',
  },
  {
    note: '4',
    url: '/jeux/a-way-out-3050876005/test.html',
    name: 'a-way-out',
  },
  {
    note: '5',
    url: '/jeux/wolfenstein-ii-the-freedom-chronicles-3050878153/test.html',
    name: 'wolfenstein-ii-the-freedom-chronicles',
  },
  {
    note: '4',
    url: '/jeux/q-u-b-e-2-3050743295/test.html',
    name: 'q-u-b-e-2',
  },
  {
    note: '7',
    url: '/jeux/a-o-t-2-3050876477/test.html',
    name: 'a-o-t-2',
  },
  {
    note: '7',
    url: '/jeux/surviving-mars-3050875823/test.html',
    name: 'surviving-mars',
  },
  {
    note: '8',
    url: '/jeux/warhammer-vermintide-2-3050876601/test.html',
    name: 'warhammer-vermintide-2',
  },
  {
    note: '8',
    url: '/jeux/ghost-of-a-tale-3050318675/test.html',
    name: 'ghost-of-a-tale',
  },
  {
    note: '8',
    url: '/jeux/flinthook-3050714255/test.html',
    name: 'flinthook',
  },
  {
    note: '6',
    url: '/jeux/brawlhalla-3050575961/test.html',
    name: 'brawlhalla',
  },
  {
    note: '7',
    url: '/jeux/northgard-3050686097/test.html',
    name: 'northgard',
  },
  {
    note: '7',
    url: '/jeux/chuchel-3050807755/test.html',
    name: 'chuchel',
  },
  {
    note: '6',
    url: '/jeux/where-the-water-tastes-like-wine-3050595227/test.html',
    name: 'where-the-water-tastes-like-wine',
  },
  {
    note: '7',
    url: '/jeux/stellaris-apocalypse-3050877983/test.html',
    name: 'stellaris-apocalypse',
  },
  {
    note: '7',
    url: '/jeux/age-of-empires-definitive-edition-3050876049/test.html',
    name: 'age-of-empires-definitive-edition',
  },
  { note: '8', url: '/jeux/owlboy-3010007848/test.html', name: 'owlboy' },
  {
    note: '7',
    url: '/jeux/civilization-vi-rise-and-fall-3050877795/test.html',
    name: 'civilization-vi-rise-and-fall',
  },
  {
    note: '6',
    url: '/jeux/kingdom-come-deliverance-3050211841/test.html',
    name: 'kingdom-come-deliverance',
  },
  {
    note: '6',
    url: '/jeux/crossing-souls-3050485257/test.html',
    name: 'crossing-souls',
  },
  {
    note: '9',
    url: '/jeux/final-fantasy-xii-3050708543/test.html',
    name: 'final-fantasy-xii',
  },
  {
    note: '4',
    url: '/jeux/lost-sphear-3050875915/test.html',
    name: 'lost-sphear',
  },
  {
    note: '9',
    url: '/jeux/celeste-3050819035/test.html',
    name: 'celeste',
  },
  {
    note: '5',
    url: '/jeux/railway-empire-3050877263/test.html',
    name: 'railway-empire',
  },
  {
    note: '8',
    url: '/jeux/the-red-strings-club-3050877069/test.html',
    name: 'the-red-strings-club',
  },
  {
    note: '8',
    url:
      '/jeux/total-war-warhammer-ii-rise-of-the-tomb-kings-3050877673/test.html',
    name: 'total-war-warhammer-ii-rise-of-the-tomb-kings',
  },
  {
    note: '8',
    url: '/jeux/full-metal-furies-3050861337/test.html',
    name: 'full-metal-furies',
  },
  {
    note: '7',
    url: '/jeux/street-fighter-v-arcade-edition-3050876861/test.html',
    name: 'street-fighter-v-arcade-edition',
  },
  {
    note: '9',
    url: '/jeux/opus-magnum-3050876957/test.html',
    name: 'opus-magnum',
  },
  {
    note: '6',
    url: '/jeux/life-is-strange-before-the-storm-3050876029/test.html',
    name: 'life-is-strange-before-the-storm',
  },
  {
    note: '3',
    url: '/jeux/seven-the-days-long-gone-3050527871/test.html',
    name: 'seven-the-days-long-gone',
  },
  {
    note: '8',
    url: '/jeux/finding-paradise-3050617131/test.html',
    name: 'finding-paradise',
  },
  {
    note: '7',
    url: '/jeux/gorogoa-3050341073/test.html',
    name: 'gorogoa',
  },
  {
    note: '5',
    url: '/jeux/resident-evil-7-gold-edition-3050876671/test-pc.html',
    name: 'resident-evil-7-gold-edition',
  },
  {
    note: '6',
    url: '/jeux/spellforce-3-3050372369/test.html',
    name: 'spellforce-3',
  },
  {
    note: '6',
    url: '/jeux/battle-chef-brigade-3050341657/test.html',
    name: 'battle-chef-brigade',
  },
  {
    note: '5',
    url: '/jeux/steep-road-to-the-olympics-expansion-3050876061/test.html',
    name: 'steep-road-to-the-olympics-expansion',
  },
  {
    note: '7',
    url: '/jeux/the-surge-a-walk-in-the-park-3050877331/test.html',
    name: 'the-surge-a-walk-in-the-park',
  },
  {
    note: '4',
    url: '/jeux/black-mirror-3050876443/test.html',
    name: 'black-mirror',
  },
  {
    note: '4',
    url: '/jeux/star-wars-battlefront-ii-3050713301/test-pc.html',
    name: 'star-wars-battlefront-ii',
  },
  {
    note: '6',
    url: '/jeux/outcast-second-contact-3050703079/test.html',
    name: 'outcast-second-contact',
  },
  {
    note: '4',
    url: '/jeux/need-for-speed-2017-3050694167/test-pc.html',
    name: 'need-for-speed-2017',
  },
  {
    note: '8',
    url: '/jeux/hand-of-fate-2-3050530357/test-pc.html',
    name: 'hand-of-fate-2',
  },
  {
    note: '6',
    url: '/jeux/call-of-duty-wwii-3050875585/test-pc.html',
    name: 'call-of-duty-wwii',
  },
  {
    note: '7',
    url: '/jeux/wolfenstein-ii-the-new-colossus-3050876037/test.html',
    name: 'wolfenstein-ii-the-new-colossus',
  },
  {
    note: '4',
    url: '/jeux/aer-3050406525/test.html',
    name: 'aer-memories-of-old',
  },
  { note: '3', url: '/jeux/elex-3050495723/test.html', name: 'elex' },
  {
    note: '7',
    url: '/jeux/south-park-l-annale-du-destin-3050484555/test.html',
    name: 'south-park-l-annale-du-destin',
  },
  {
    note: '5',
    url: '/jeux/dungeons-3-3050861627/test.html',
    name: 'dungeons-3',
  },
  {
    note: '7',
    url: '/jeux/guild-wars-2-path-of-fire-3050876367/test.html',
    name: 'guild-wars-2-path-of-fire',
  },
  {
    note: '6',
    url: '/jeux/road-redemption-3050131473/test.html',
    name: 'road-redemption',
  },
  {
    note: '6',
    url: '/jeux/battle-chasers-3050653931/test.html',
    name: 'battle-chasers',
  },
  {
    note: '8',
    url: '/jeux/total-war-warhammer-2-3050865295/test.html',
    name: 'total-war-warhammer-2',
  },
  { note: '7', url: '/jeux/hob-3050595759/test.html', name: 'hob' },
  {
    note: '8',
    url: '/jeux/dishonored-la-mort-de-l-outsider-3050876039/test.html',
    name: 'dishonored-la-mort-de-l-outsider',
  },
  {
    note: '-',
    url: '/jeux/raiders-of-the-broken-planet-3050714283/test.html',
    name: 'raiders-of-the-broken-planet',
  },
  {
    note: '5',
    url: '/jeux/marvel-vs-capcom-infinite-3050814151/test.html',
    name: 'marvel-vs-capcom-infinite',
  },
  {
    note: '8',
    url: '/jeux/divinity-original-sin-ii-3050526257/test.html',
    name: 'divinity-original-sin-ii',
  },
  {
    note: '8',
    url: '/jeux/xcom-2-war-of-the-chosen-3050876043/test.html',
    name: 'xcom-2-war-of-the-chosen',
  },
  {
    note: '6',
    url: '/jeux/battlefield-1-in-the-name-of-the-tsar-3050876007/test.html',
    name: 'battlefield-1-in-the-name-of-the-tsar',
  },
  {
    note: '5',
    url: '/jeux/last-day-of-june-3050875921/test-pc.html',
    name: 'last-day-of-june',
  },
  {
    note: '8',
    url: '/jeux/observer-3050867647/test.html',
    name: 'observer',
  },
  {
    note: '4',
    url: '/jeux/ark-survival-evolved-3050465655/test.html',
    name: 'ark-survival-evolved',
  },
  {
    note: '8',
    url: '/jeux/absolver-3050702387/test.html',
    name: 'absolver',
  },
  {
    note: '7',
    url: '/jeux/starcraft-remastered-3050875569/test.html',
    name: 'starcraft-remastered',
  },
  {
    note: '9',
    url: '/jeux/undertale-3050588185/test.html',
    name: 'undertale',
  },
  {
    note: '6',
    url: '/jeux/lawbreakers-3050298383/test.html',
    name: 'lawbreakers',
  },
  {
    note: '5',
    url: '/jeux/redeemer-3050859771/test.html',
    name: 'redeemer',
  },
  {
    note: '4',
    url: '/jeux/fortnite-3010010206/test.html',
    name: 'fortnite',
  },
  { note: '6', url: '/jeux/tacoma-3050483939/test.html', name: 'tacoma' },
  {
    note: '7',
    url: '/jeux/gigantic-3050426691/test.html',
    name: 'gigantic',
  },
  {
    note: '7',
    url: '/jeux/serial-cleaner-3050876191/test.html',
    name: 'serial-cleaner',
  },
  {
    note: '7',
    url: '/jeux/steel-division-normandy-44-3050865585/test.html',
    name: 'steel-division-normandy-44',
  },
  {
    note: '7',
    url: '/jeux/star-trek-bridge-crew-vr-3050714567/test.html',
    name: 'star-trek-bridge-crew-vr',
  },
  {
    note: '5',
    url: '/jeux/get-even-3050274509/test.html',
    name: 'get-even',
  },
  {
    note: '6',
    url: '/jeux/the-elder-scrolls-online-morrowind-3050849223/test.html',
    name: 'the-elder-scrolls-online-morrowind',
  },
  {
    note: '7',
    url: '/jeux/rising-storm-2-vietnam-3050486623/test.html',
    name: 'rising-storm-2-vietnam',
  },
  {
    note: '7',
    url: '/jeux/vanquish-3010007446/test.html',
    name: 'vanquish',
  },
  {
    note: '7',
    url: '/jeux/everspace-3050566877/test.html',
    name: 'everspace',
  },
  {
    note: '8',
    url: '/jeux/endless-space-2-3050518485/test.html',
    name: 'endless-space-2',
  },
  {
    note: '7',
    url: '/jeux/the-surge-3050513677/test.html',
    name: 'the-surge',
  },
  {
    note: '6',
    url: '/jeux/seasons-after-fall-3010009575/test.html',
    name: 'seasons-after-fall',
  },
  { note: '5', url: '/jeux/strafe-3050650391/test.html', name: 'strafe' },
  {
    note: '3',
    url: '/jeux/syberia-3-3010006490/test.html',
    name: 'syberia-3',
  },
  {
    note: '4',
    url: '/jeux/sniper-ghost-warrior-3-3050380495/test.html',
    name: 'sniper-ghost-warrior-3',
  },
  {
    note: '7',
    url: '/jeux/warhammer-40-000-dawn-of-war-iii-3050496191/test.html',
    name: 'warhammer-40-000-dawn-of-war-iii',
  },
  {
    note: '6',
    url: '/jeux/cosmic-star-heroine-3050186463/test.html',
    name: 'cosmic-star-heroine',
  },
  {
    note: '5',
    url: '/jeux/skyforge-3050858859/test.html',
    name: 'skyforge',
  },
  {
    note: '7',
    url: '/jeux/full-throttle-remastered-3050595611/test-pc.html',
    name: 'full-throttle-remastered',
  },
  {
    note: '6',
    url: '/jeux/mr-shifty-3050865109/test.html',
    name: 'mr-shifty',
  },
  {
    note: '6',
    url: '/jeux/day-of-infamy-3050715333/test.html',
    name: 'day-of-infamy',
  },
  {
    note: '7',
    url: '/jeux/enter-the-gungeon-3050392829/test.html',
    name: 'enter-the-gungeon',
  },
  {
    note: '6',
    url: '/jeux/yooka-laylee-3050461523/test.html',
    name: 'yooka-laylee',
  },
  {
    note: '7',
    url: '/jeux/battlefield-1-they-shall-not-pass-3050875611/test.html',
    name: 'battlefield-1-they-shall-not-pass',
  },
  {
    note: '7',
    url: '/jeux/thimbleweed-park-3050520831/test.html',
    name: 'thimbleweed-park',
  },
  {
    note: '6',
    url: '/jeux/mass-effect-andromeda-3050198371/test.html',
    name: 'mass-effect-andromeda',
  },
  { note: '3', url: '/jeux/2dark-3050321743/test.html', name: '2dark' },
  {
    note: '7',
    url: '/jeux/styx-shards-of-darkness-3050561277/test.html',
    name: 'styx-shards-of-darkness',
  },
  {
    note: '8',
    url: '/jeux/stories-untold-3050855605/test.html',
    name: 'stories-untold',
  },
  {
    note: '8',
    url: '/jeux/hollow-knight-3050383353/test.html',
    name: 'hollow-knight',
  },
  {
    note: '6',
    url: '/jeux/torment-tides-of-numenera-3050117574/test.html',
    name: 'torment-tides-of-numenera',
  },
  {
    note: '8',
    url: '/jeux/for-honor-3050484469/test.html',
    name: 'for-honor',
  },
  {
    note: '7',
    url: '/jeux/sniper-elite-4-3050652807/test-pc.html',
    name: 'sniper-elite-4',
  },
  {
    note: '8',
    url: '/jeux/splasher-3050509269/test.html',
    name: 'splasher',
  },
  {
    note: '7',
    url: '/jeux/linelight-3050834141/test.html',
    name: 'linelight',
  },
  {
    note: '5',
    url: '/jeux/urban-empire-3050656059/test.html',
    name: 'urban-empire',
  },
  {
    note: '4',
    url: '/jeux/double-dragon-iv-3050830107/test-pc.html',
    name: 'double-dragon-iv',
  },
  {
    note: '4',
    url: '/jeux/batman-a-telltale-game-series-3050594371/test-pc.html',
    name: 'batman-a-telltale-game-series',
  },
  {
    note: '4',
    url: '/jeux/rise-shine-3050606809/test-pc.html',
    name: 'rise-shine',
  },
  {
    note: '8',
    url: '/jeux/superhot-vr-3050715597/test.html',
    name: 'superhot-vr',
  },
  {
    note: '8',
    url: '/jeux/shenzhen-i-o-3050769101/test.html',
    name: 'shenzhen-i-o',
  },
  {
    note: '5',
    url: '/jeux/shantae-half-genie-hero-3050179980/test.html',
    name: 'shantae-half-genie-hero',
  },
  {
    note: '4',
    url: '/jeux/space-hulk-deathwing-3050291439/test.html',
    name: 'space-hulk-deathwing',
  },
  { note: '7', url: '/jeux/orwell-3050821397/test.html', name: 'orwell' },
  {
    note: '5',
    url: '/jeux/the-little-acre-3050755425/test-pc.html',
    name: 'the-little-acre',
  },
  {
    note: '5',
    url: '/jeux/yesterday-origins-3050472015/test-pc.html',
    name: 'yesterday-origins',
  },
  {
    note: '5',
    url: '/jeux/silence-3050355895/test-pc.html',
    name: 'silence',
  },
  {
    note: '7',
    url: '/jeux/ittle-dew-2-3050531983/test.html',
    name: 'ittle-dew-2',
  },
  {
    note: '8',
    url: '/jeux/dishonored-2-3050482995/test.html',
    name: 'dishonored-2',
  },
  {
    note: '7',
    url: '/jeux/tyranny-3050660417/test-pc.html',
    name: 'tyranny',
  },
  {
    note: '7',
    url:
      '/jeux/the-elder-scrolls-v-skyrim-edition-legendaire-3050133532/test.html',
    name: 'the-elder-scrolls-v-skyrim-edition-legendaire',
  },
  {
    note: '7',
    url: '/jeux/dark-souls-iii-ashes-of-ariandel-3050794057/test-pc.html',
    name: 'dark-souls-iii-ashes-of-ariandel',
  },
  { note: '8', url: '/jeux/gonner-3050785425/test.html', name: 'gonner' },
  {
    note: '7',
    url: '/jeux/shadow-warrior-2-3050481819/test.html',
    name: 'shadow-warrior-2',
  },
  {
    note: '7',
    url: '/jeux/aragami-3050494109/test.html',
    name: 'aragami',
  },
  {
    note: '4',
    url: '/jeux/cossacks-3-3050468591/test-pc.html',
    name: 'cossacks-3',
  },
  {
    note: '8',
    url: '/jeux/forza-horizon-3-3050714055/test-pc.html',
    name: 'forza-horizon-3',
  },
  {
    note: '5',
    url: '/jeux/virginia-3050759283/test.html',
    name: 'virginia',
  },
  {
    note: '7',
    url: '/jeux/clustertruck-3050768225/test.html',
    name: 'clustertruck',
  },
  {
    note: '3',
    url: '/jeux/pankapu-le-gardien-des-reves-3050863611/test.html',
    name: 'pankapu-le-gardien-des-reves',
  },
  { note: '5', url: '/jeux/rive-3050342117/test.html', name: 'rive' },
  {
    note: '6',
    url: '/jeux/event-0-3050465725/test.html',
    name: 'event-0',
  },
  {
    note: '6',
    url: '/jeux/redout-3050715541/test-pc.html',
    name: 'redout',
  },
  {
    note: '7',
    url: '/jeux/starbound-3050172721/test.html',
    name: 'starbound',
  },
  {
    note: '7',
    url: '/jeux/neurovoider-3050543439/test.html',
    name: 'neurovoider',
  },
  {
    note: '8',
    url: '/jeux/world-of-warcraft-legion-3050523495/test.html',
    name: 'world-of-warcraft-legion',
  },
  {
    note: '5',
    url: '/jeux/mother-russia-bleeds-3050485245/test-pc.html',
    name: 'mother-russia-bleeds',
  },
  {
    note: '5',
    url: '/jeux/master-of-orion-3050480295/test.html',
    name: 'master-of-orion',
  },
  {
    note: '5',
    url: '/jeux/champions-of-anteria-3050683565/test.html',
    name: 'champions-of-anteria',
  },
  {
    note: '9',
    url: '/jeux/deus-ex-mankind-divided-3050446171/test.html',
    name: 'deus-ex-mankind-divided',
  },
  {
    note: '5',
    url: '/jeux/no-man-s-sky-3050314371/test-pc.html',
    name: 'no-man-s-sky',
  },
  {
    note: '6',
    url: '/jeux/quadrilateral-cowboy-3050293195/test.html',
    name: 'quadrilateral-cowboy',
  },
  {
    note: '6',
    url: '/jeux/headlander-3050595929/test-pc.html',
    name: 'headlander',
  },
  {
    note: '6',
    url: '/jeux/zombie-night-terror-3050627303/test.html',
    name: 'zombie-night-terror',
  },
  {
    note: '7',
    url: '/jeux/arma-iii-apex-3050738067/test.html',
    name: 'arma-iii-apex',
  },
  {
    note: '6',
    url: '/jeux/song-of-the-deep-3050627619/test-pc.html',
    name: 'song-of-the-deep',
  },
  {
    note: '7',
    url: '/jeux/fossil-echo-3050442029/test-pc.html',
    name: 'fossil-echo',
  },
  {
    note: '3',
    url: '/jeux/umbrella-corps-3050542801/test-pc.html',
    name: 'umbrella-corps',
  },
  {
    note: '6',
    url: '/jeux/sherlock-holmes-the-devil-s-daughter-3050463821/test.html',
    name: 'sherlock-holmes-the-devil-s-daughter',
  },
  {
    note: '7',
    url: '/jeux/hearts-of-iron-iv-3050709089/test.html',
    name: 'hearts-of-iron-iv',
  },
  {
    note: '5',
    url: '/jeux/mirror-s-edge-catalyst-3010006762/test.html',
    name: 'mirror-s-edge-catalyst',
  },
  {
    note: '4',
    url:
      '/jeux/teenage-mutant-ninja-turtles-des-mutants-a-manhattan-3050592039/test.html',
    name: 'teenage-mutant-ninja-turtles-des-mutants-a-manhattan',
  },
  {
    note: '8',
    url:
      '/jeux/the-witcher-3-traque-sauvage-blood-and-wine-3050598087/test.html',
    name: 'the-witcher-3-traque-sauvage-blood-and-wine',
  },
  {
    note: '6',
    url: '/jeux/fallout-4-far-harbor-3050701017/test.html',
    name: 'fallout-4-far-harbor',
  },
  {
    note: '7',
    url: '/jeux/total-war-warhammer-3050455153/test.html',
    name: 'total-war-warhammer',
  },
  {
    note: '5',
    url: '/jeux/lastfight-3050363161/test.html',
    name: 'lastfight',
  },
  {
    note: '4',
    url: '/jeux/homefront-the-revolution-3010009554/test.html',
    name: 'homefront-the-revolution',
  },
  {
    note: '7',
    url: '/jeux/offworld-trading-company-3050414423/test-pc.html',
    name: 'offworld-trading-company',
  },
  {
    note: '7',
    url: '/jeux/chronos-3050481913/test.html',
    name: 'chronos',
  },
  {
    note: '6',
    url: '/jeux/battlefleet-gothic-armada-3050394797/test.html',
    name: 'battlefleet-gothic-armada',
  },
  {
    note: '6',
    url: '/jeux/lucky-s-tale-3050609797/test.html',
    name: 'lucky-s-tale',
  },
  {
    note: '7',
    url: '/jeux/the-banner-saga-2-3050376585/test.html',
    name: 'the-banner-saga-2',
  },
  {
    note: '6',
    url: '/jeux/final-fantasy-ix-3010007885/test-pc.html',
    name: 'final-fantasy-ix',
  },
  {
    note: '5',
    url: '/jeux/quantum-break-3050636725/test-pc.html',
    name: 'quantum-break',
  },
  {
    note: '6',
    url: '/jeux/samorost3-3010009269/test-pc.html',
    name: 'samorost3',
  },
  {
    note: '9',
    url: '/jeux/day-of-the-tentacle-remastered-3010008330/test-pc.html',
    name: 'day-of-the-tentacle-remastered',
  },
  {
    note: '7',
    url: '/jeux/deadbolt-3050614189/test.html',
    name: 'deadbolt',
  },
  {
    note: '6',
    url: '/jeux/black-desert-online-3050063766/test.html',
    name: 'black-desert-online',
  },
  {
    note: '5',
    url: '/jeux/need-for-speed-2015-3050470701/test.html',
    name: 'need-for-speed-2015',
  },
  {
    note: '6',
    url: '/jeux/shardlight-3050473057/test.html',
    name: 'shardlight',
  },
  {
    note: '7',
    url: '/jeux/deponia-doomsday-3050646531/test.html',
    name: 'deponia-doomsday',
  },
  {
    note: '7',
    url: '/jeux/far-cry-primal-3050553747/test-pc.html',
    name: 'far-cry-primal',
  },
  {
    note: '7',
    url: '/jeux/superhot-3050318607/test.html',
    name: 'superhot',
  },
  {
    note: '6',
    url: '/jeux/the-flame-in-the-flood-3050425537/test.html',
    name: 'the-flame-in-the-flood',
  },
  {
    note: '7',
    url: '/jeux/disgaea-ds-3010002775/test-pc.html',
    name: 'disgaea-ds',
  },
  {
    note: '7',
    url: '/jeux/street-fighter-v-3050276925/test-pc.html',
    name: 'street-fighter-v',
  },
  {
    note: '5',
    url: '/jeux/tales-of-symphonia-3010001317/test-pc.html',
    name: 'tales-of-symphonia',
  },
  {
    note: '6',
    url: '/jeux/rise-of-the-tomb-raider-3050272781/test-pc.html',
    name: 'rise-of-the-tomb-raider',
  },
  {
    note: '7',
    url: '/jeux/homeworld-deserts-of-kharak-3050106404/test.html',
    name: 'homeworld-deserts-of-kharak',
  },
  {
    note: '7',
    url: '/jeux/a-boy-and-his-blob-3050611257/test-pc.html',
    name: 'a-boy-and-his-blob',
  },
  {
    note: '6',
    url: '/jeux/dragon-s-dogma-dark-arisen-3050068180/test-pc.html',
    name: 'dragon-s-dogma-dark-arisen',
  },
  {
    note: '7',
    url: '/jeux/lightning-returns-final-fantasy-xiii-3050061240/test-pc.html',
    name: 'lightning-returns-final-fantasy-xiii',
  },
  {
    note: '6',
    url: '/jeux/warhammer-end-times-vermintide-3050409361/test.html',
    name: 'warhammer-end-times-vermintide',
  },
  {
    note: '6',
    url: '/jeux/mordheim-city-of-the-damned-3050782867/test-pc.html',
    name: 'mordheim-city-of-the-damned',
  },
  {
    note: '8',
    url: '/jeux/dragon-quest-heroes-3050329999/test-pc.html',
    name: 'dragon-quest-heroes',
  },
  {
    note: '7',
    url: '/jeux/just-cause-3-3050254109/test.html',
    name: 'just-cause-3',
  },
  {
    note: '8',
    url: '/jeux/mini-metro-3050621313/test.html',
    name: 'mini-metro',
  },
  {
    note: '4',
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-3050208106/test-pc.html',
    name: 'game-of-thrones-a-telltale-games-series',
  },
  {
    note: '7',
    url: '/jeux/star-wars-battlefront-3050151047/test-pc.html',
    name: 'star-wars-battlefront',
  },
  {
    note: '6',
    url: '/jeux/call-of-duty-black-ops-3-3050448007/test.html',
    name: 'call-of-duty-black-ops-3',
  },
  {
    note: '8',
    url: '/jeux/football-manager-2016-3050537963/test-pc.html',
    name: 'football-manager-2016',
  },
  {
    note: '8',
    url: '/jeux/starcraft-ii-legacy-of-the-void-3010005983/test.html',
    name: 'starcraft-ii-legacy-of-the-void',
  },
  {
    note: '5',
    url: '/jeux/anno-2205-3050484553/test.html',
    name: 'anno-2205',
  },
  {
    note: '8',
    url: '/jeux/tales-from-the-borderlands-saison-1-3050569701/test.html',
    name: 'tales-from-the-borderlands-saison-1',
  },
  {
    note: '8',
    url: '/jeux/broforce-3050556017/test-pc.html',
    name: 'broforce',
  },
  {
    note: '-',
    url: '/jeux/minecraft-story-mode-3050381967/test-pc.html',
    name: 'minecraft-story-mode',
  },
  {
    note: '6',
    url:
      '/jeux/the-witcher-3-traque-sauvage-hearts-of-stone-3050562593/test.html',
    name: 'the-witcher-3-traque-sauvage-hearts-of-stone',
  },
  {
    note: '7',
    url: '/jeux/civilization-beyond-earth-rising-tide-3050468219/test.html',
    name: 'civilization-beyond-earth-rising-tide',
  },
  {
    note: '6',
    url: '/jeux/transformers-devastation-3050482745/test-pc.html',
    name: 'transformers-devastation',
  },
  {
    note: '6',
    url: '/jeux/might-magic-heroes-vii-3050319755/test.html',
    name: 'might-magic-heroes-vii',
  },
  {
    note: '7',
    url: '/jeux/blood-bowl-ii-3050310695/test-pc.html',
    name: 'blood-bowl-ii',
  },
  {
    note: '5',
    url: '/jeux/skyshine-s-bedlam-3050483623/test.html',
    name: 'skyshine-s-bedlam',
  },
  {
    note: '6',
    url: '/jeux/lovers-in-a-dangerous-spacetime-3050274721/test.html',
    name: 'lovers-in-a-dangerous-spacetime',
  },
  {
    note: '7',
    url: '/jeux/big-pharma-3050516173/test.html',
    name: 'big-pharma',
  },
  {
    note: '7',
    url: '/jeux/company-of-heroes-2-the-british-forces-3050505467/test.html',
    name: 'company-of-heroes-2-the-british-forces',
  },
  { note: '9', url: '/jeux/stasis-3050385829/test.html', name: 'stasis' },
  {
    note: '8',
    url: '/jeux/act-of-aggression-3050321191/test.html',
    name: 'act-of-aggression',
  },
  {
    note: '6',
    url:
      '/jeux/evoland-2-a-slight-case-of-spacetime-continuum-disorder-3050330573/test.html',
    name: 'evoland-2-a-slight-case-of-spacetime-continuum-disorder',
  },
  {
    note: '6',
    url: '/jeux/mad-max-3050119241/test.html',
    name: 'mad-max',
  },
  {
    note: '6',
    url: '/jeux/shadowrun-hong-kong-3050471279/test.html',
    name: 'shadowrun-hong-kong---extended-edition',
  },
  { note: '6', url: '/jeux/volume-3050172680/test.html', name: 'volume' },
  {
    note: '2',
    url: '/jeux/submerged-3050444297/test.html',
    name: 'submerged',
  },
  {
    note: '4',
    url: '/jeux/interstellaria-3050213854/test.html',
    name: 'interstellaria',
  },
  {
    note: '6',
    url: '/jeux/yatagarasu-attack-on-cataclysm-3050480181/test.html',
    name: 'yatagarasu-attack-on-cataclysm',
  },
  {
    note: '8',
    url: '/jeux/infinifactory-3050515937/test.html',
    name: 'infinifactory',
  },
  {
    note: '6',
    url: '/jeux/lethis-path-of-progress-3050394503/test.html',
    name: 'lethis-path-of-progress',
  },
  {
    note: '8',
    url: '/jeux/galactic-civilizations-iii-3050189938/test.html',
    name: 'galactic-civilizations-iii',
  },
  {
    note: '7',
    url: '/jeux/heroes-of-the-storm-3050198649/test-pc.html',
    name: 'heroes-of-the-storm',
  },
  { note: '4', url: '/jeux/hatred-3050353543/test.html', name: 'hatred' },
  {
    note: '8',
    url: '/jeux/technobabylon-3050387475/test-pc.html',
    name: 'technobabylon',
  },
  {
    note: '7',
    url: '/jeux/project-cars-3010010388/test-pc.html',
    name: 'project-cars',
  },
  {
    note: '7',
    url: '/jeux/not-a-hero-3050280311/test.html',
    name: 'not-a-hero',
  },
  {
    note: '6',
    url: '/jeux/dead-synchronicity-tomorrow-comes-today-3050697911/test.html',
    name: 'dead-synchronicity-tomorrow-comes-today',
  },
  {
    note: '7',
    url: '/jeux/chroma-squad-3050457903/test.html',
    name: 'chroma-squad',
  },
  {
    note: '5',
    url: '/jeux/dungeons-ii-3050419881/test.html',
    name: 'dungeons-ii',
  },
  {
    note: '8',
    url: '/jeux/verdun-3050460227/test-pc.html',
    name: 'verdun',
  },
  {
    note: '7',
    url: '/jeux/ys-vi-the-ark-of-napishtim-steam-3050440527/test.html',
    name: 'ys-vi-the-ark-of-napishtim-steam',
  },
  {
    note: '6',
    url: '/jeux/broken-age-3010010528/test-pc.html',
    name: 'broken-age',
  },
  {
    note: '6',
    url: '/jeux/titan-souls-3050276401/test.html',
    name: 'titan-souls',
  },
  {
    note: '-',
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-3-the-sword-in-the-darkness-3050374645/test.html',
    name:
      'game-of-thrones-a-telltale-games-series-episode-3-the-sword-in-the-darkness',
  },
  {
    note: '6',
    url: '/jeux/etherium-3050158928/test.html',
    name: 'etherium',
  },
  {
    note: '-',
    url:
      '/jeux/tales-from-the-borderlands-episode-2-atlas-mugged-3050376625/test.html',
    name: 'tales-from-the-borderlands-episode-2-atlas-mugged',
  },
  {
    note: '-',
    url: '/jeux/dreamfall-chapters-book-two-rebels-3050373121/test.html',
    name: 'dreamfall-chapters-book-two-rebels',
  },
  {
    note: '6',
    url: '/jeux/battlefield-hardline-3050266539/test.html',
    name: 'battlefield-hardline',
  },
  {
    note: '4',
    url: '/jeux/sid-meier-s-starships-3050393149/test-pc.html',
    name: 'sid-meier-s-starships',
  },
  {
    note: '8',
    url: '/jeux/hotline-miami-2-wrong-number-3050091382/test-pc.html',
    name: 'hotline-miami-2-wrong-number',
  },
  {
    note: '6',
    url: '/jeux/white-night-3050206972/test.html',
    name: 'white-night',
  },
  {
    note: '7',
    url: '/jeux/hand-of-fate-3050422125/test-pc.html',
    name: 'hand-of-fate',
  },
  {
    note: '8',
    url: '/jeux/homeworld-remastered-3050166002/test.html',
    name: 'homeworld-remastered',
  },
  {
    note: '7',
    url: '/jeux/total-war-attila-3050341837/test.html',
    name: 'total-war-attila',
  },
  {
    note: '-',
    url: '/jeux/resident-evil-revelations-2-3050323887/test-pc.html',
    name: 'resident-evil-revelations-2',
  },
  {
    note: '6',
    url: '/jeux/aaru-s-awakening-3050268893/test.html',
    name: 'aaru-s-awakening',
  },
  {
    note: '7',
    url: '/jeux/harold-3050107902/test-pc.html',
    name: 'harold',
  },
  { note: '6', url: '/jeux/evolve-3050214843/test.html', name: 'evolve' },
  {
    note: '6',
    url: '/jeux/sandstorm-3050412527/test.html',
    name: 'sandstorm',
  },
  {
    note: '6',
    url: '/jeux/grey-goo-3050233086/test.html',
    name: 'grey-goo',
  },
  {
    note: '6',
    url: '/jeux/cities-xxl-3050378253/test.html',
    name: 'cities-xxl',
  },
  {
    note: '-',
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-2-the-lost-lords-3050388959/test-pc.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-2-the-lost-lords',
  },
  {
    note: '2',
    url: '/jeux/raven-s-cry-3050406855/test.html',
    name: 'raven-s-cry',
  },
  {
    note: '4',
    url: '/jeux/dying-light-3050140146/test-pc.html',
    name: 'dying-light',
  },
  {
    note: '-',
    url: '/jeux/life-is-strange-3050317725/test.html',
    name: 'life-is-strange',
  },
  {
    note: '8',
    url: '/jeux/grim-fandango-remastered-3050273887/test-pc.html',
    name: 'grim-fandango-remastered',
  },
  {
    note: '8',
    url: '/jeux/the-talos-principle-3050275567/test.html',
    name: 'the-talos-principle',
  },
  {
    note: '5',
    url: '/jeux/metal-gear-solid-v-ground-zeroes-3050195323/test-pc.html',
    name: 'metal-gear-solid-v-ground-zeroes',
  },
  {
    note: '7',
    url: '/jeux/final-fantasy-xiii-2-3010009039/test-pc.html',
    name: 'final-fantasy-xiii-2',
  },
  {
    note: '5',
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-1-iron-from-ice-3050388957/test.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-1-iron-from-ice',
  },
  {
    note: '6',
    url: '/jeux/geometry-wars3-dimensions-3050322377/test.html',
    name: 'geometry-wars3-dimensions',
  },
  {
    note: '8',
    url:
      '/jeux/tales-from-the-borderlands-episode-1-zer0-sum-3050256583/test.html',
    name: 'tales-from-the-borderlands-episode-1-zer0-sum',
  },
  {
    note: '5',
    url: '/jeux/never-alone-3050321799/test-pc.html',
    name: 'never-alone-(kisima-ingitchuna)',
  },
  {
    note: '7',
    url: '/jeux/this-war-of-mine-3050385707/test.html',
    name: 'this-war-of-mine',
  },
  {
    note: '7',
    url: '/jeux/dragon-age-inquisition-3010009450/test-pc.html',
    name: 'dragon-age-inquisition',
  },
  {
    note: '7',
    url: '/jeux/company-of-heroes-2-ardennes-assault-3050320517/test.html',
    name: 'company-of-heroes-2-ardennes-assault',
  },
  {
    note: '7',
    url: '/jeux/world-of-warcraft-warlords-of-draenor-3050198628/test.html',
    name: 'world-of-warcraft-warlords-of-draenor',
  },
  {
    note: '7',
    url: '/jeux/valkyria-chronicles-3050359633/test-pc.html',
    name: 'valkyria-chronicles',
  },
  {
    note: '8',
    url: '/jeux/the-binding-of-isaac-rebirth-3050092305/test.html',
    name: 'the-binding-of-isaac-rebirth',
  },
  {
    note: '9',
    url: '/jeux/football-manager-2015-3050316145/test.html',
    name: 'football-manager-2015',
  },
  {
    note: '5',
    url: '/jeux/call-of-duty-advanced-warfare-3050255637/test-pc.html',
    name: 'call-of-duty-advanced-warfare',
  },
  {
    note: '7',
    url: '/jeux/deadcore-3050252047/test.html',
    name: 'deadcore',
  },
  {
    note: '8',
    url: '/jeux/dungeon-of-the-endless-3050318651/test.html',
    name: 'dungeon-of-the-endless',
  },
  {
    note: '6',
    url: '/jeux/dreamfall-chapters-3050167892/test.html',
    name: 'dreamfall-chapters',
  },
  {
    note: '7',
    url: '/jeux/civilization-beyond-earth-3050247795/test.html',
    name: 'civilization-beyond-earth',
  },
  {
    note: '5',
    url:
      '/jeux/gabriel-knight-sins-of-the-fathers-20th-anniversary-edition-3050188022/test-pc.html',
    name: 'gabriel-knight-sins-of-the-fathers-20th-anniversary-edition',
  },
  {
    note: '7',
    url: '/jeux/legend-of-grimrock-2-3050117789/test.html',
    name: 'legend-of-grimrock-2',
  },
  {
    note: '6',
    url: '/jeux/borderlands-the-pre-sequel-3050245905/test-pc.html',
    name: 'borderlands-the-pre-sequel',
  },
  {
    note: '7',
    url: '/jeux/final-fantasy-xiii-3010005645/test-pc.html',
    name: 'final-fantasy-xiii',
  },
  {
    note: '7',
    url: '/jeux/a-golden-wake-3050291287/test.html',
    name: 'a-golden-wake',
  },
  {
    note: '7',
    url: '/jeux/styx-master-of-shadows-3050291659/test-pc.html',
    name: 'styx-master-of-shadows',
  },
  {
    note: '5',
    url: '/jeux/gauntlet-2014-3050501937/test-pc.html',
    name: 'gauntlet-2014',
  },
  {
    note: '8',
    url: '/jeux/endless-legend-3050173444/test-pc.html',
    name: 'endless-legend',
  },
  {
    note: '8',
    url: '/jeux/sherlock-holmes-crimes-and-punishments-3050112119/test-pc.html',
    name: 'sherlock-holmes-crimes-and-punishments',
  },
  {
    note: '4',
    url: '/jeux/ancient-space-3050324887/test.html',
    name: 'ancient-space',
  },
  {
    note: '7',
    url: '/jeux/heavy-bullets-3050338579/test.html',
    name: 'heavy-bullets',
  },
  {
    note: '7',
    url: '/jeux/the-vanishing-of-ethan-carter-3050319215/test-pc.html',
    name: 'the-vanishing-of-ethan-carter-redux',
  },
  {
    note: '8',
    url: '/jeux/wasteland-2-3050228071/test-pc.html',
    name: 'wasteland-2',
  },
  {
    note: '7',
    url: '/jeux/planetary-annihilation-3050328993/test-pc.html',
    name: 'planetary-annihilation',
  },
  {
    note: '4',
    url: '/jeux/les-sims-4-3050135650/test.html',
    name: 'les-sims-4',
  },
  {
    note: '5',
    url: '/jeux/dead-rising-3-3010010200/test-pc.html',
    name: 'dead-rising-3',
  },
  {
    note: '7',
    url:
      '/jeux/the-walking-dead-saison-2-episode-5-no-going-back-3050212967/test.html',
    name: 'the-walking-dead-saison-2-episode-5-no-going-back',
  },
  {
    note: '5',
    url: '/jeux/risen-3-titan-lords-3050227400/test-pc.html',
    name: 'risen-3-titan-lords',
  },
  {
    note: '8',
    url: '/jeux/road-not-taken-3050176474/test-pc.html',
    name: 'road-not-taken',
  },
  {
    note: '7',
    url: '/jeux/the-swapper-3050228765/test-pc.html',
    name: 'the-swapper',
  },
  {
    note: '5',
    url: '/jeux/pixel-piracy-3050314865/test-pc.html',
    name: 'pixel-piracy',
  },
  {
    note: '5',
    url: '/jeux/sacred-3-3050060915/test-pc.html',
    name: 'sacred-3',
  },
  {
    note: '8',
    url: '/jeux/gods-will-be-watching-3050164361/test-pc.html',
    name: 'gods-will-be-watching',
  },
  {
    note: '7',
    url:
      '/jeux/the-walking-dead-saison-2-episode-4-amid-the-ruins-3050212931/test.html',
    name: 'the-walking-dead-saison-2-episode-4-amid-the-ruins',
  },
  {
    note: '5',
    url: '/jeux/quest-for-infamy-3050300533/test.html',
    name: 'quest-for-infamy',
  },
  {
    note: '6',
    url: '/jeux/always-sometimes-monsters-3050682075/test.html',
    name: 'always-sometimes-monsters',
  },
  {
    note: '7',
    url: '/jeux/divinity-original-sin-3050027046/test-pc.html',
    name: 'divinity-original-sin',
  },
  {
    note: '3',
    url: '/jeux/death-cargo-3050293819/test.html',
    name: 'death-cargo',
  },
  {
    note: '8',
    url: '/jeux/shovel-knight-3050124119/test.html',
    name: 'shovel-knight',
  },
  {
    note: '7',
    url: '/jeux/qvadriga-3050288855/test.html',
    name: 'qvadriga',
  },
  {
    note: '6',
    url: '/jeux/where-is-my-heart-3050209995/test-pc.html',
    name: 'where-is-my-heart',
  },
  {
    note: '7',
    url: '/jeux/munin-3050288827/test-pc.html',
    name: 'munin',
  },
  { note: '7', url: '/jeux/wildstar-111810/test.html', name: 'wildstar' },
  {
    note: '3',
    url: '/jeux/warhammer-40-000-kill-team-3010009500/test-pc.html',
    name: 'warhammer-40-000-kill-team',
  },
  {
    note: '8',
    url: '/jeux/distant-worlds-universe-3050269059/test.html',
    name: 'distant-worlds-universe',
  },
  {
    note: '7',
    url: '/jeux/watch-dogs-3050029051/test-pc.html',
    name: 'watch-dogs',
  },
  {
    note: '7',
    url: '/jeux/the-last-tinker-city-of-colors-3050268511/test-pc.html',
    name: 'the-last-tinker-city-of-colors',
  },
  {
    note: '6',
    url: '/jeux/tropico-5-3050171764/test-pc.html',
    name: 'tropico-5',
  },
  {
    note: '5',
    url: '/jeux/wolfenstein-the-new-order-3050135792/test-pc.html',
    name: 'wolfenstein-the-new-order',
  },
  {
    note: '7',
    url:
      '/jeux/the-walking-dead-saison-2-episode-3-in-harm-s-way-3050212902/test.html',
    name: 'the-walking-dead-saison-2-episode-3-in-harm-s-way',
  },
  {
    note: '4',
    url: '/jeux/bound-by-flame-3050072248/test-pc.html',
    name: 'bound-by-flame',
  },
  {
    note: '7',
    url: '/jeux/wargame-red-dragon-3050174437/test.html',
    name: 'wargame-red-dragon',
  },
  {
    note: '8',
    url: '/jeux/dark-souls-ii-3050095434/test-pc.html',
    name: 'dark-souls-ii',
  },
  {
    note: '5',
    url:
      '/jeux/les-chevaliers-de-baphomet-la-malediction-du-serpent-episode-2-3050195772/test-pc.html',
    name: 'les-chevaliers-de-baphomet-la-malediction-du-serpent-episode-2',
  },
  {
    note: '7',
    url: '/jeux/the-elder-scrolls-online-3050019900/test-pc.html',
    name: 'the-elder-scrolls-online',
  },
  {
    note: '6',
    url: '/jeux/half-minute-hero-second-3050236695/test-pc.html',
    name: 'half-minute-hero-second',
  },
  {
    note: '6',
    url: '/jeux/age-of-wonders-iii-3050114587/test-pc.html',
    name: 'age-of-wonders-iii',
  },
  {
    note: '6',
    url: '/jeux/bioshock-infinite-burial-at-sea-episode-2-3050167777/test.html',
    name: 'bioshock-infinite-burial-at-sea-episode-2',
  },
  {
    note: '8',
    url: '/jeux/diablo-iii-reaper-of-souls-3050173003/test.html',
    name: 'diablo-iii-reaper-of-souls',
  },
  {
    note: '6',
    url: '/jeux/luftrausers-3050125154/test-pc.html',
    name: 'luftrausers',
  },
  {
    note: '8',
    url: '/jeux/hearthstone-heroes-of-warcraft-3050125482/test.html',
    name: 'hearthstone-heroes-of-warcraft',
  },
  {
    note: '6',
    url: '/jeux/shadowrun-dragonfall-3050168940/test-pc.html',
    name: 'shadowrun-dragonfall',
  },
  {
    note: '5',
    url:
      '/jeux/the-walking-dead-saison-2-episode-2-a-house-divided-3050212424/test.html',
    name: 'the-walking-dead-saison-2-episode-2-a-house-divided',
  },
  {
    note: '8',
    url: '/jeux/south-park-le-baton-de-la-verite-3010010176/test-pc.html',
    name: 'south-park-le-baton-de-la-verite',
  },
  {
    note: '2',
    url: '/jeux/rambo-the-video-game-3010009809/test-pc.html',
    name: 'rambo-the-video-game',
  },
  {
    note: '7',
    url: '/jeux/banished-3050178599/test.html',
    name: 'banished',
  },
  {
    note: '8',
    url: '/jeux/ikaruga-3010000842/test-pc.html',
    name: 'ikaruga',
  },
  {
    note: '7',
    url: '/jeux/jazzpunk-3050223154/test.html',
    name: 'jazzpunk',
  },
  {
    note: '8',
    url:
      '/jeux/the-wolf-among-us-episode-2-smoke-and-mirrors-3050189407/test-pc.html',
    name: 'the-wolf-among-us-episode-2-smoke-and-mirrors',
  },
  {
    note: '6',
    url: '/jeux/the-floor-is-jelly-3050219337/test-pc.html',
    name: 'the-floor-is-jelly',
  },
  {
    note: '7',
    url: '/jeux/rymdkapsel-3050168064/test-pc.html',
    name: 'rymdkapsel',
  },
  {
    note: '7',
    url: '/jeux/strike-vector-3050436275/test-pc.html',
    name: 'strike-vector',
  },
  {
    note: '7',
    url: '/jeux/octodad-dadliest-catch-3050151472/test.html',
    name: 'octodad-dadliest-catch',
  },
  {
    note: '6',
    url: '/jeux/insurgency-2014-3050218142/test.html',
    name: 'insurgency-2014',
  },
  {
    note: '6',
    url: '/jeux/might-magic-x-legacy-3050233705/test.html',
    name: 'might-magic-x-legacy',
  },
  {
    note: '5',
    url: '/jeux/blackguards-3050219064/test.html',
    name: 'blackguards',
  },
  {
    note: '7',
    url: '/jeux/the-banner-saga-3050047467/test.html',
    name: 'the-banner-saga',
  },
  {
    note: '7',
    url: '/jeux/metal-gear-rising-revengeance-3010006705/test-pc.html',
    name: 'metal-gear-rising-revengeance',
  },
  {
    note: '7',
    url:
      '/jeux/the-walking-dead-saison-2-episode-1-all-that-remains-3050212316/test.html',
    name: 'the-walking-dead-saison-2-episode-1-all-that-remains',
  },
  {
    note: '6',
    url: '/jeux/samurai-gunn-3050172805/test-pc.html',
    name: 'samurai-gunn',
  },
  {
    note: '5',
    url: '/jeux/final-fantasy-viii-2013-3050209418/test.html',
    name: 'final-fantasy-viii-2013',
  },
  {
    note: '3',
    url: '/jeux/redshirt-3050210983/test.html',
    name: 'redshirt',
  },
  {
    note: '7',
    url: '/jeux/teslagrad-3050142932/test.html',
    name: 'teslagrad',
  },
  {
    note: '5',
    url: '/jeux/battlefield-4-china-rising-3050193928/test-pc.html',
    name: 'battlefield-4-china-rising',
  },
  {
    note: '6',
    url:
      '/jeux/les-chevaliers-de-baphomet-la-malediction-du-serpent-episode-1-3050057798/test.html',
    name: 'les-chevaliers-de-baphomet-la-malediction-du-serpent-episode-1',
  },
  {
    note: '6',
    url: '/jeux/baldur-s-gate-2-enhanced-edition-3050008076/test.html',
    name: 'baldur-s-gate-2-enhanced-edition',
  },
  {
    note: '6',
    url: '/jeux/elder-sign-omens-3050206310/test-pc.html',
    name: 'elder-sign-omens',
  },
  {
    note: '4',
    url: '/jeux/x-rebirth-109962/test.html',
    name: 'x-rebirth',
  },
  {
    note: '5',
    url: '/jeux/world-of-warplanes-111906/test.html',
    name: 'world-of-warplanes',
  },
  {
    note: '6',
    url: '/jeux/need-for-speed-rivals-3050140159/test-pc.html',
    name: 'need-for-speed-rivals',
  },
  {
    note: '5',
    url: '/jeux/simcity-villes-de-demain-3050182695/test.html',
    name: 'simcity-villes-de-demain',
  },
  {
    note: '7',
    url: '/jeux/risk-of-rain-3050223990/test-pc.html',
    name: 'risk-of-rain',
  },
  {
    note: '5',
    url: '/jeux/contrast-3050140418/test-pc.html',
    name: 'contrast',
  },
  {
    note: '3',
    url: '/jeux/sniper-elite-nazi-zombie-army-2-3050178358/test.html',
    name: 'sniper-elite-nazi-zombie-army-2',
  },
  {
    note: '3',
    url: '/jeux/deadfall-adventures-3050119675/test-pc.html',
    name: 'deadfall-adventures',
  },
  {
    note: '6',
    url: '/jeux/bioshock-infinite-burial-at-sea-episode-1-3050167768/test.html',
    name: 'bioshock-infinite-burial-at-sea-episode-1',
  },
  {
    note: '8',
    url: '/jeux/xcom-enemy-within-3050172872/test-pc.html',
    name: 'xcom-enemy-within',
  },
  {
    note: '6',
    url: '/jeux/poof-vs-the-cursed-kitty-3050142631/test-pc.html',
    name: 'poof-vs-the-cursed-kitty',
  },
  {
    note: '7',
    url: '/jeux/final-exam-3050113102/test-pc.html',
    name: 'final-exam',
  },
  {
    note: '8',
    url: '/jeux/battlefield-4-3050043448/test-pc.html',
    name: 'battlefield-4',
  },
  {
    note: '8',
    url: '/jeux/football-manager-2014-3050171582/test-pc.html',
    name: 'football-manager-2014',
  },
  {
    note: '5',
    url: '/jeux/the-sims-3-into-the-future-3050166412/test.html',
    name: 'the-sims-3-into-the-future',
  },
  {
    note: '7',
    url: '/jeux/path-of-exile-3050841783/test.html',
    name: 'path-of-exile',
  },
  {
    note: '6',
    url:
      '/jeux/naruto-shippuden-ultimate-ninja-storm-3-full-burst-3050160862/test-pc.html',
    name: 'naruto-shippuden-ultimate-ninja-storm-3-full-burst',
  },
  {
    note: '4',
    url: '/jeux/how-to-survive-3050141948/test-pc.html',
    name: 'how-to-survive',
  },
  {
    note: '6',
    url: '/jeux/ethan-meteor-hunter-3050136320/test.html',
    name: 'ethan-meteor-hunter',
  },
  {
    note: '8',
    url: '/jeux/the-stanley-parable-3050228490/test.html',
    name: 'the-stanley-parable',
  },
  {
    note: '6',
    url: '/jeux/card-hunter-3010009736/test-pc.html',
    name: 'card-hunter',
  },
  {
    note: '7',
    url: '/jeux/goodbye-deponia-3050095855/test.html',
    name: 'goodbye-deponia',
  },
  {
    note: '8',
    url: '/jeux/tetrobot-co-3050074372/test-pc.html',
    name: 'tetrobot-co',
  },
  {
    note: '8',
    url: '/jeux/the-wolf-among-us-episode-1-faith-3050189282/test.html',
    name: 'the-wolf-among-us',
  },
  {
    note: '5',
    url: '/jeux/knock-knock-3050532805/test.html',
    name: 'knock-knock',
  },
  {
    note: '7',
    url: '/jeux/shadow-warrior-3050135947/test.html',
    name: 'shadow-warrior',
  },
  {
    note: '3',
    url: '/jeux/takedown-red-sabre-3050175482/test-pc.html',
    name: 'takedown-red-sabre',
  },
  {
    note: '7',
    url: '/jeux/valdis-story-abyssal-city-3050184897/test.html',
    name: 'valdis-story-abyssal-city',
  },
  {
    note: '6',
    url: '/jeux/ironclad-tactics-3050392587/test-pc.html',
    name: 'ironclad-tactics',
  },
  {
    note: '7',
    url: '/jeux/volgarr-the-viking-3050281257/test.html',
    name: 'volgarr-the-viking',
  },
  { note: '6', url: '/jeux/arma-iii-110309/test.html', name: 'arma-iii' },
  {
    note: '6',
    url: '/jeux/aarklash-legacy-3050107919/test.html',
    name: 'aarklash-legacy',
  },
  {
    note: '6',
    url: '/jeux/outlast-3050151487/test.html',
    name: 'outlast',
  },
  {
    note: '7',
    url: '/jeux/amnesia-a-machine-for-pigs-114648/test.html',
    name: 'amnesia-a-machine-for-pigs',
  },
  { note: '8', url: '/jeux/dota-2-3010008700/test.html', name: 'dota-2-test' },
  {
    note: '7',
    url: '/jeux/total-war-rome-ii-3050037422/test.html',
    name: 'total-war-rome-ii',
  },
  {
    note: '6',
    url: '/jeux/memoria-3050124384/test.html',
    name: 'memoria',
  },
  {
    note: '6',
    url: '/jeux/the-bureau-xcom-declassified-3010007945/test-pc.html',
    name: 'the-bureau-xcom-declassified',
  },
  {
    note: '5',
    url: '/jeux/lost-planet-3-3050012604/test.html',
    name: 'lost-planet-3',
  },
  {
    note: '8',
    url: '/jeux/papers-please-3050170257/test.html',
    name: 'papers-please',
  },
  {
    note: '5',
    url: '/jeux/mark-of-the-ninja-special-edition-3050124742/test.html',
    name: 'mark-of-the-ninja-special-edition',
  },
  {
    note: '5',
    url: '/jeux/hammerwatch-3050171098/test-pc.html',
    name: 'hammerwatch',
  },
  {
    note: '7',
    url: '/jeux/payday-2-3050122412/test.html',
    name: 'payday-2',
  },
  {
    note: '7',
    url: '/jeux/dishonored-les-sorcieres-de-brigmore-3050122638/test-pc.html',
    name: 'dishonored-les-sorcieres-de-brigmore',
  },
  {
    note: '6',
    url: '/jeux/saints-row-4-3050023568/test.html',
    name: 'saints-row-4',
  },
  {
    note: '2',
    url: '/jeux/citadels-3050167426/test.html',
    name: 'citadels',
  },
  {
    note: '7',
    url: '/jeux/cloudberry-kingdom-3050129451/test-pc.html',
    name: 'cloudberry-kingdom',
  },
  {
    note: '7',
    url: '/jeux/beatbuddy-tale-of-the-guardians-3050159314/test-pc.html',
    name: 'beatbuddy-tale-of-the-guardians',
  },
  {
    note: '5',
    url: '/jeux/rise-of-the-triad-3050049190/test.html',
    name: 'rise-of-the-triad',
  },
  {
    note: '6',
    url: '/jeux/shadowrun-returns-3050112245/test-pc.html',
    name: 'shadowrun-returns',
  },
  {
    note: '7',
    url:
      '/jeux/borderlands-2-tiny-tina-s-assault-on-dragon-keep-3050135292/test.html',
    name: 'borderlands-2-tiny-tina-s-assault-on-dragon-keep',
  },
  {
    note: '5',
    url: '/jeux/dusty-revenge-3050050079/test.html',
    name: 'dusty-revenge',
  },
  {
    note: '8',
    url: '/jeux/civilization-v-brave-new-world-3050123531/test-pc.html',
    name: 'civilization-v-brave-new-world',
  },
  {
    note: '6',
    url: '/jeux/les-sims-3-ile-de-reve-3050164319/test.html',
    name: 'les-sims-3-ile-de-reve',
  },
  {
    note: '6',
    url: '/jeux/ittle-dew-3050162000/test-pc.html',
    name: 'ittle-dew',
  },
  {
    note: '6',
    url: '/jeux/neverwinter-3050312553/test-pc.html',
    name: 'neverwinter',
  },
  {
    note: '4',
    url: '/jeux/marvel-heroes-3010006455/test.html',
    name: 'marvel-heroes',
  },
  { note: '3', url: '/jeux/dark-3050107840/test-pc.html', name: 'dark' },
  {
    note: '5',
    url: '/jeux/storm-3010009603/test-pc.html',
    name: 'storm',
  },
  {
    note: '7',
    url: '/jeux/rogue-legacy-3050172657/test-pc.html',
    name: 'rogue-legacy',
  },
  {
    note: '6',
    url: '/jeux/hexodius-3050010071/test.html',
    name: 'hexodius',
  },
  {
    note: '7',
    url: '/jeux/company-of-heroes-2-3050665349/test.html',
    name: 'company-of-heroes-2',
  },
  {
    note: '4',
    url: '/jeux/magrunner-dark-pulse-3050096454/test.html',
    name: 'magrunner-dark-pulse',
  },
  {
    note: '6',
    url: '/jeux/anomaly-2-3050119608/test-pc.html',
    name: 'anomaly-2',
  },
  {
    note: '3',
    url: '/jeux/survivor-squad-3050128568/test.html',
    name: 'survivor-squad',
  },
  { note: '6', url: '/jeux/reus-3050131661/test.html', name: 'reus' },
  { note: '7', url: '/jeux/gunpoint-113208/test.html', name: 'gunpoint' },
  {
    note: '7',
    url: '/jeux/wargame-airland-battle-3050051879/test.html',
    name: 'wargame-airland-battle',
  },
  {
    note: '6',
    url: '/jeux/the-night-of-the-rabbit-3050159816/test.html',
    name: 'the-night-of-the-rabbit',
  },
  {
    note: '6',
    url: '/jeux/the-incredible-adventures-of-van-helsing-3050020674/test.html',
    name: 'the-incredible-adventures-of-van-helsing',
  },
  {
    note: '6',
    url: '/jeux/leviathan-warships-3050131385/test-pc.html',
    name: 'leviathan-warships',
  },
  {
    note: '6',
    url: '/jeux/metro-last-light-3010008259/test-pc.html',
    name: 'metro-last-light',
  },
  {
    note: '7',
    url: '/jeux/monaco-3010007844/test-pc.html',
    name: 'monaco',
  },
  {
    note: '6',
    url: '/jeux/stardrive-3050128393/test.html',
    name: 'stardrive',
  },
  {
    note: '5',
    url: '/jeux/mars-war-logs-3050088159/test-pc.html',
    name: 'mars-war-logs',
  },
  {
    note: '7',
    url: '/jeux/papo-yo-3050126466/test-pc.html',
    name: 'papo-yo',
  },
  {
    note: '7',
    url: '/jeux/don-t-starve-3050134406/test.html',
    name: 'don-t-starve',
  },
  {
    note: '4',
    url: '/jeux/eador-masters-of-the-broken-world-3050105842/test.html',
    name: 'eador-masters-of-the-broken-world',
  },
  {
    note: '5',
    url: '/jeux/dead-island-riptide-3050030173/test-pc.html',
    name: 'dead-island-riptide',
  },
  {
    note: '6',
    url: '/jeux/starseed-pilgrim-3050127247/test-pc.html',
    name: 'starseed-pilgrim',
  },
  {
    note: '3',
    url: '/jeux/lords-of-football-3050075312/test-pc.html',
    name: 'lords-of-football',
  },
  {
    note: '5',
    url: '/jeux/slender-the-arrival-3050107741/test-pc.html',
    name: 'slender-the-arrival',
  },
  {
    note: '4',
    url: '/jeux/defiance-3010009854/test-pc.html',
    name: 'defiance',
  },
  {
    note: '7',
    url: '/jeux/shootmania-storm-94965/test.html',
    name: 'shootmania-storm',
  },
  {
    note: '6',
    url: '/jeux/cities-in-motion-2-3050053182/test.html',
    name: 'cities-in-motion-2',
  },
  {
    note: '5',
    url: '/jeux/evoland-3050077909/test-pc.html',
    name: 'evoland',
  },
  {
    note: '8',
    url: '/jeux/trials-evolution-3050062688/test-pc.html',
    name: 'trials-evolution',
  },
  {
    note: '5',
    url: '/jeux/sword-of-the-stars-the-pit-3050078150/test.html',
    name: 'sword-of-the-stars-the-pit',
  },
  {
    note: '8',
    url: '/jeux/bioshock-infinite-3010008443/test-pc.html',
    name: 'bioshock-infinite',
  },
  {
    note: '5',
    url: '/jeux/resident-evil-6-3010009941/test.html',
    name: 'resident-evil-6',
  },
  {
    note: '4',
    url: '/jeux/sniper-ghost-warrior-2-3010009028/test-pc.html',
    name: 'sniper-ghost-warrior-2',
  },
  {
    note: '8',
    url: '/jeux/runner-2-future-legend-of-rhythm-alien-3010010034/test-pc.html',
    name: 'runner-2-future-legend-of-rhythm-alien',
  },
  {
    note: '5',
    url: '/jeux/battlefield-3-end-game-3050042735/test-pc.html',
    name: 'battlefield-3-end-game',
  },
  {
    note: '8',
    url: '/jeux/starcraft-ii-heart-of-the-swarm-3010005982/test-pc.html',
    name: 'starcraft-ii-heart-of-the-swarm',
  },
  {
    note: '4',
    url: '/jeux/sniper-elite-nazi-zombie-army-3050114924/test.html',
    name: 'sniper-elite-nazi-zombie-army',
  },
  {
    note: '5',
    url: '/jeux/les-sims-3-university-3050104590/test.html',
    name: 'les-sims-3-university',
  },
  {
    note: '6',
    url: '/jeux/the-showdown-effect-3050002122/test.html',
    name: 'the-showdown-effect',
  },
  {
    note: '6',
    url: '/jeux/super-house-of-dead-ninjas-3050119984/test.html',
    name: 'super-house-of-dead-ninjas',
  },
  {
    note: '8',
    url: '/jeux/tomb-raider-3010008889/test.html',
    name: 'tomb-raider',
  },
  {
    note: '6',
    url: '/jeux/simcity-3050053159/test.html',
    name: 'simcity',
  },
  {
    note: '3',
    url: '/jeux/a-valley-without-wind-2-3050107299/test.html',
    name: 'a-valley-without-wind-2',
  },
  { note: '5', url: '/jeux/impire-3050047764/test.html', name: 'impire' },
  {
    note: '5',
    url: '/jeux/crysis-3-3050013458/test-pc.html',
    name: 'crysis-3',
  },
  {
    note: '6',
    url: '/jeux/dungeonland-3050002097/test.html',
    name: 'dungeonland',
  },
  {
    note: '8',
    url: '/jeux/antichamber-3050000971/test-pc.html',
    name: 'antichamber',
  },
  {
    note: '7',
    url: '/jeux/sonic-all-stars-racing-transformed-3010010494/test.html',
    name: 'sonic-all-stars-racing-transformed',
  },
  {
    note: '6',
    url: '/jeux/strike-suit-zero-3010009817/test.html',
    name: 'strike-suit-zero',
  },
  {
    note: '4',
    url: '/jeux/omerta-city-of-gangsters-3050057454/test.html',
    name: 'omerta-city-of-gangsters',
  },
  {
    note: '5',
    url: '/jeux/no-time-to-explain-3050318687/test.html',
    name: 'no-time-to-explain',
  },
  {
    note: '5',
    url: '/jeux/baldur-s-gate-enhanced-edition-3050008074/test-pc.html',
    name: 'baldur-s-gate-enhanced-edition',
  },
  {
    note: '7',
    url: '/jeux/teleglitch-die-more-edition-3050110031/test.html',
    name: 'teleglitch-die-more-edition',
  },
  {
    note: '7',
    url: '/jeux/dmc-devil-may-cry-3010008554/test-pc.html',
    name: 'dmc-devil-may-cry',
  },
  {
    note: '6',
    url: '/jeux/perspective-3050108975/test.html',
    name: 'perspective',
  },
  {
    note: '5',
    url:
      '/jeux/borderlands-2-sir-hammerlock-s-big-game-hunt-3050098120/test-pc.html',
    name: 'borderlands-2-sir-hammerlock-s-big-game-hunt',
  },
  {
    note: '5',
    url: '/jeux/kentucky-route-zero-3050128116/test.html',
    name: 'kentucky-route-zero',
  },
  {
    note: '6',
    url: '/jeux/cart-life-3050105863/test.html',
    name: 'cart-life',
  },
  {
    note: '6',
    url: '/jeux/primordia-3050057321/test.html',
    name: 'primordia',
  },
  {
    note: '8',
    url: '/jeux/stealth-inc-a-clone-in-the-dark-3050133745/test-pc.html',
    name: 'stealth-inc-a-clone-in-the-dark',
  },
  {
    note: '2',
    url: '/jeux/infestation-survivor-stories-3050045382/test.html',
    name: 'infestation-survivor-stories',
  },
  {
    note: '4',
    url: '/jeux/alt-minds-3050025963/test.html',
    name: 'alt-minds',
  },
  {
    note: '5',
    url:
      '/jeux/the-book-of-unwritten-tales-the-critter-chronicles-3050088494/test.html',
    name: 'the-book-of-unwritten-tales-the-critter-chronicles',
  },
  {
    note: '6',
    url: '/jeux/battlefield-3-aftermath-3050042731/test-pc.html',
    name: 'battlefield-3-aftermath',
  },
  {
    note: '5',
    url: '/jeux/miasmata-3050079108/test.html',
    name: 'miasmata',
  },
  {
    note: '7',
    url: '/jeux/rift-storm-legion-3050027403/test.html',
    name: 'rift-storm-legion',
  },
  {
    note: '6',
    url: '/jeux/dishonored-dunwall-city-trials-3050097485/test-pc.html',
    name: 'dishonored-dunwall-city-trials',
  },
  {
    note: '7',
    url: '/jeux/planetside-2-3050144027/test.html',
    name: 'planetside-2',
  },
  {
    note: '7',
    url:
      '/jeux/borderlands-2-mr-torgue-s-campaign-of-carnage-3050087686/test.html',
    name: 'borderlands-2-mr-torgue-s-campaign-of-carnage',
  },
  {
    note: '8',
    url: '/jeux/assassin-s-creed-iii-3010010020/test-pc.html',
    name: 'assassin-s-creed-iii',
  },
  {
    note: '9',
    url: '/jeux/the-walking-dead-3010009127/test-pc.html',
    name: 'the-walking-dead',
  },
  {
    note: '8',
    url: '/jeux/far-cry-3-3010007256/test-pc.html',
    name: 'far-cry-3',
  },
  {
    note: '6',
    url: '/jeux/les-sims-3-saisons-3050049275/test.html',
    name: 'les-sims-3-saisons',
  },
  {
    note: '8',
    url: '/jeux/natural-selection-2-72584/test.html',
    name: 'natural-selection-2',
  },
  {
    note: '7',
    url: '/jeux/hitman-absolution-3010006821/test-pc.html',
    name: 'hitman-absolution',
  },
  {
    note: '5',
    url: '/jeux/magical-drop-v-3010009489/test.html',
    name: 'magical-drop-v',
  },
  { note: '7', url: '/jeux/fly-n-3050020038/test.html', name: 'fly-n' },
  {
    note: '4',
    url: '/jeux/painkiller-hell-and-damnation-3050073641/test.html',
    name: 'painkiller-hell-and-damnation',
  },
  { note: '8', url: '/jeux/pid-3010010177/test-pc.html', name: 'pid' },
  {
    note: '7',
    url: '/jeux/need-for-speed-most-wanted-2012-3050016115/test-pc.html',
    name: 'need-for-speed-most-wanted-2012',
  },
  {
    note: '8',
    url: '/jeux/football-manager-2013-3050063254/test-pc.html',
    name: 'football-manager-2013',
  },
  {
    note: '8',
    url: '/jeux/chaos-on-deponia-3050059017/test.html',
    name: 'chaos-on-deponia',
  },
  {
    note: '6',
    url: '/jeux/elemental-fallen-enchantress-108405/test.html',
    name: 'elemental-fallen-enchantress',
  },
  {
    note: '6',
    url: '/jeux/cargo-commander-3050080951/test.html',
    name: 'cargo-commander',
  },
  { note: '4', url: '/jeux/lucius-111894/test.html', name: 'lucius' },
  {
    note: '6',
    url: '/jeux/giana-sisters-twisted-dreams-3050058721/test.html',
    name: 'giana-sisters-twisted-dreams',
  },
  {
    note: '6',
    url: '/jeux/king-s-bounty-warriors-of-the-north-112643/test.html',
    name: 'king-s-bounty-warriors-of-the-north',
  },
  {
    note: '3',
    url: '/jeux/farming-simulator-2013-3050071100/test.html',
    name: 'farming-simulator-2013',
  },
  {
    note: '4',
    url: '/jeux/a-game-of-dwarves-3010010364/test.html',
    name: 'a-game-of-dwarves',
  },
  {
    note: '7',
    url: '/jeux/hotline-miami-3050044978/test-pc.html',
    name: 'hotline-miami',
  },
  {
    note: '6',
    url:
      '/jeux/borderlands-2-captain-scarlett-and-her-pirate-s-booty-3050075556/test.html',
    name: 'borderlands-2-captain-scarlett-and-her-pirate-s-booty',
  },
  {
    note: '5',
    url: '/jeux/doom-3-bfg-edition-3050027265/test.html',
    name: 'doom-3-bfg-edition',
  },
  {
    note: '7',
    url: '/jeux/chivalry-medieval-warfare-3050336103/test-pc.html',
    name: 'chivalry-medieval-warfare',
  },
  { note: '7', url: '/jeux/ravaged-114470/test.html', name: 'ravaged' },
  {
    note: '7',
    url: '/jeux/edna-harvey-harvey-s-new-eyes-111881/test.html',
    name: 'edna-harvey-harvey-s-new-eyes',
  },
  {
    note: '6',
    url: '/jeux/retro-city-rampage-3010008968/test.html',
    name: 'retro-city-rampage',
  },
  {
    note: '6',
    url: '/jeux/fable-the-journey-110544/test.html',
    name: 'fable-the-journey',
  },
  {
    note: '5',
    url: '/jeux/war-of-the-roses-111828/test.html',
    name: 'war-of-the-roses',
  },
  {
    note: '8',
    url: '/jeux/xcom-enemy-unknown-3010010315/test-pc.html',
    name: 'xcom-enemy-unknown',
  },
  {
    note: '8',
    url: '/jeux/dishonored-3010009628/test-pc.html',
    name: 'dishonored',
  },
  {
    note: '6',
    url: '/jeux/orcs-must-die-2-family-ties-3050072254/test.html',
    name: 'orcs-must-die-2-family-ties',
  },
  {
    note: '6',
    url: '/jeux/world-of-warcraft-mists-of-pandaria-3010010083/test-pc.html',
    name: 'world-of-warcraft-mists-of-pandaria',
  },
  {
    note: '4',
    url: '/jeux/carrier-command-gaea-mission-3010005827/test-pc.html',
    name: 'carrier-command-gaea-mission',
  },
  {
    note: '6',
    url: '/jeux/colour-bind-3050061348/test.html',
    name: 'colour-bind',
  },
  {
    note: '5',
    url: '/jeux/secret-files-3-110984/test.html',
    name: 'secret-files-3',
  },
  {
    note: '8',
    url: '/jeux/borderlands-2-3010009801/test-pc.html',
    name: 'borderlands-2',
  },
  {
    note: '6',
    url: '/jeux/torchlight-ii-105556/test.html',
    name: 'torchlight-ii',
  },
  {
    note: '4',
    url: '/jeux/tony-hawk-s-pro-skater-hd-3010010205/test-pc.html',
    name: 'tony-hawk-s-pro-skater-hd',
  },
  {
    note: '6',
    url: '/jeux/transcripted-3050036022/test.html',
    name: 'transcripted',
  },
  { note: '3', url: '/jeux/tryst-3050050010/test.html', name: 'tryst' },
  {
    note: '7',
    url: '/jeux/intrusion-2-3050064783/test.html',
    name: 'intrusion-2',
  },
  {
    note: '7',
    url: '/jeux/black-mesa-3050064715/test.html',
    name: 'black-mesa',
  },
  {
    note: '4',
    url: '/jeux/starvoid-3050002120/test.html',
    name: 'starvoid',
  },
  {
    note: '7',
    url: '/jeux/ftl-faster-than-light-3050184362/test.html',
    name: 'ftl-faster-than-light',
  },
  {
    note: '6',
    url: '/jeux/battlefield-3-armored-kill-3050042727/test.html',
    name: 'battlefield-3-armored-kill',
  },
  {
    note: '6',
    url: '/jeux/les-sims-3-super-pouvoirs-3050032765/test.html',
    name: 'les-sims-3-super-pouvoirs',
  },
  { note: '4', url: '/jeux/shad-o-3050051791/test.html', name: 'shad-o' },
  {
    note: '7',
    url: '/jeux/they-bleed-pixels-3050050844/test.html',
    name: 'they-bleed-pixels',
  },
  {
    note: '4',
    url: '/jeux/tower-wars-3050049608/test.html',
    name: 'tower-wars',
  },
  {
    note: '8',
    url: '/jeux/guild-wars-2-3010010527/test.html',
    name: 'guild-wars-2',
  },
  {
    note: '5',
    url: '/jeux/l-oeil-noir-les-chaines-de-satinav-110844/test.html',
    name: 'l-oeil-noir-les-chaines-de-satinav',
  },
  {
    note: '7',
    url: '/jeux/counter-strike-global-offensive-3010009826/test.html',
    name: 'counter-strike-global-offensive',
  },
  {
    note: '7',
    url: '/jeux/dark-souls-prepare-to-die-edition-3050007543/test.html',
    name: 'dark-souls-prepare-to-die-edition',
  },
  {
    note: '7',
    url: '/jeux/sleeping-dogs-3010007325/test-pc.html',
    name: 'sleeping-dogs',
  },
  {
    note: '7',
    url: '/jeux/resonance-3050035112/test.html',
    name: 'resonance',
  },
  {
    note: '8',
    url: '/jeux/darksiders-ii-3010006506/test-pc.html',
    name: 'darksiders-ii',
  },
  {
    note: '3',
    url: '/jeux/legends-of-pegasus-3050046075/test.html',
    name: 'legends-of-pegasus',
  },
  {
    note: '6',
    url: '/jeux/tales-from-space-mutant-blobs-attack-3050001974/test-pc.html',
    name: 'tales-from-space-mutant-blobs-attack',
  },
  {
    note: '7',
    url: '/jeux/deponia-3050789523/test.html',
    name: 'deponia',
  },
  {
    note: '6',
    url: '/jeux/unmechanical-3050134848/test-pc.html',
    name: 'unmechanical',
  },
  {
    note: '5',
    url: '/jeux/death-rally-3050037493/test.html',
    name: 'death-rally',
  },
  {
    note: '7',
    url: '/jeux/awesomenauts-3010009446/test-pc.html',
    name: 'awesomenauts',
  },
  {
    note: '6',
    url: '/jeux/dungeonbowl-3050012823/test.html',
    name: 'dungeonbowl',
  },
  {
    note: '6',
    url: '/jeux/prototype-2-3010008912/test-pc.html',
    name: 'prototype-2',
  },
  {
    note: '8',
    url: '/jeux/orcs-must-die-2-3050010322/test.html',
    name: 'orcs-must-die-2',
  },
  {
    note: '5',
    url: '/jeux/beware-planet-earth-3050118630/test-pc.html',
    name: 'beware-planet-earth',
  },
  {
    note: '3',
    url: '/jeux/battlefield-3-close-quarters-3050042721/test.html',
    name: 'battlefield-3-close-quarters',
  },
  {
    note: '8',
    url: '/jeux/endless-space-3050020715/test.html',
    name: 'endless-space',
  },
  {
    note: '-',
    url: '/jeux/the-secret-world-3010003974/test-pc.html',
    name: 'the-secret-world',
  },
  {
    note: '5',
    url: '/jeux/mcpixel-3050066470/test.html',
    name: 'mcpixel',
  },
  {
    note: '7',
    url: '/jeux/pro-cycling-manager-tour-de-france-2012-3050034233/test.html',
    name: 'pro-cycling-manager-tour-de-france-2012',
  },
  {
    note: '6',
    url:
      '/jeux/magic-the-gathering-duels-of-the-planeswalkers-2013-3050026879/test-pc.html',
    name: 'magic-the-gathering-duels-of-the-planeswalkers-2013',
  },
  {
    note: '6',
    url: '/jeux/quantum-conundrum-3010009880/test.html',
    name: 'quantum-conundrum',
  },
  {
    note: '6',
    url: '/jeux/civilization-v-gods-kings-3010010555/test.html',
    name: 'civilization-v-gods-kings',
  },
  {
    note: '4',
    url: '/jeux/game-of-thrones-3010009647/test.html',
    name: 'game-of-thrones',
  },
  {
    note: '8',
    url: '/jeux/max-payne-3-3010006470/test.html',
    name: 'max-payne-3',
  },
  {
    note: '8',
    url: '/jeux/diablo-iii-3010005574/test.html',
    name: 'diablo-iii',
  },
  {
    note: '6',
    url: '/jeux/warlock-master-of-the-arcane-112011/test.html',
    name: 'warlock-master-of-the-arcane',
  },
  {
    note: '8',
    url: '/jeux/botanicula-3050011618/test.html',
    name: 'botanicula',
  },
  { note: '-', url: '/jeux/tera-3050697943/test.html', name: 'tera' },
  {
    note: '7',
    url: '/jeux/risen-2-dark-waters-3010009140/test.html',
    name: 'risen-2-dark-waters',
  },
  {
    note: '7',
    url: '/jeux/legend-of-grimrock-3050106701/test-pc.html',
    name: 'legend-of-grimrock',
  },
  {
    note: '-',
    url: '/jeux/tribes-ascend-3010009201/test-pc.html',
    name: 'tribes-ascend',
  },
  {
    note: '5',
    url: '/jeux/confrontation-104696/test.html',
    name: 'confrontation',
  },
  {
    note: '8',
    url: '/jeux/rayman-origins-3010008196/test-pc.html',
    name: 'rayman-origins',
  },
  { note: '-', url: '/jeux/wakfu-3010008504/test.html', name: 'wakfu' },
  {
    note: '7',
    url: '/jeux/yesterday-112943/test.html',
    name: 'yesterday',
  },
  { note: '7', url: '/jeux/vessel-3010009890/test.html', name: 'vessel' },
  {
    note: '9',
    url: '/jeux/total-war-shogun-2-la-fin-des-samourais-113187/test.html',
    name: 'total-war-shogun-2-la-fin-des-samourais',
  },
  {
    note: '8',
    url: '/jeux/wargame-european-escalation-3050106355/test-pc.html',
    name: 'wargame-european-escalation',
  },
  {
    note: '5',
    url: '/jeux/les-sims-3-showtime-3010010191/test.html',
    name: 'les-sims-3-showtime',
  },
  {
    note: '1',
    url: '/jeux/elections-2012-en-route-pour-l-elysee-114589/test.html',
    name: 'elections-2012-en-route-pour-l-elysee',
  },
  {
    note: '8',
    url: '/jeux/mass-effect-3-3010008181/test-pc.html',
    name: 'mass-effect-3',
  },
  {
    note: '5',
    url: '/jeux/captain-morgane-la-tortue-d-or-3010009761/test.html',
    name: 'captain-morgane-la-tortue-d-or',
  },
  {
    note: '4',
    url: '/jeux/gotham-city-impostors-3010009437/test-pc.html',
    name: 'gotham-city-impostors',
  },
  {
    note: '7',
    url: '/jeux/alan-wake-3010001899/test.html',
    name: 'alan-wake',
  },
  {
    note: '8',
    url: '/jeux/minecraft-3010009138/test.html',
    name: 'minecraft',
  },
  {
    note: '7',
    url: '/jeux/les-royaumes-d-amalur-reckoning-3010007805/test-pc.html',
    name: 'les-royaumes-d-amalur-reckoning',
  },
  {
    note: '7',
    url: '/jeux/dustforce-3010009937/test.html',
    name: 'dustforce',
  },
  {
    note: '8',
    url: '/jeux/trine-2-3010008178/test-pc.html',
    name: 'trine-2',
  },
  {
    note: '-',
    url: '/jeux/star-wars-the-old-republic-91678/test.html',
    name: 'star-wars-the-old-republic',
  },
  {
    note: '5',
    url: '/jeux/serious-sam-3-bfe-3010009168/test-pc.html',
    name: 'serious-sam-3-bfe',
  },
  {
    note: '7',
    url: '/jeux/anno-2070-109584/test.html',
    name: 'anno-2070',
  },
  {
    note: '5',
    url:
      '/jeux/le-seigneur-des-anneaux-la-guerre-du-nord-3010007240/test-pc.html',
    name: 'le-seigneur-des-anneaux-la-guerre-du-nord',
  },
  {
    note: '7',
    url: '/jeux/l-a-noire-l-edition-integrale-3010009972/test-pc.html',
    name: 'l-a-noire-l-edition-integrale',
  },
  {
    note: '8',
    url: '/jeux/the-elder-scrolls-v-skyrim-3010008913/test-pc.html',
    name: 'the-elder-scrolls-v-skyrim',
  },
  {
    note: '7',
    url: '/jeux/call-of-duty-modern-warfare-3-3010007926/test-pc.html',
    name: 'call-of-duty-modern-warfare-3',
  },
  {
    note: '6',
    url: '/jeux/payday-the-heist-3010009721/test.html',
    name: 'payday-the-heist',
  },
  {
    note: '8',
    url: '/jeux/sonic-generations-3010009313/test-pc.html',
    name: 'sonic-generations',
  },
  {
    note: '8',
    url: '/jeux/battlefield-3-3010006753/test-pc.html',
    name: 'battlefield-3',
  },
  {
    note: '7',
    url: '/jeux/dungeon-defenders-3010008459/test.html',
    name: 'dungeon-defenders',
  },
  {
    note: '7',
    url: '/jeux/les-sims-3-animaux-cie-3010009501/test.html',
    name: 'les-sims-3-animaux-cie',
  },
  {
    note: '6',
    url:
      '/jeux/deus-ex-human-revolution-le-chainon-manquant-3010009909/test.html',
    name: 'deus-ex-human-revolution-le-chainon-manquant',
  },
  {
    note: '7',
    url: '/jeux/orcs-must-die-3010009160/test-pc.html',
    name: 'orcs-must-die',
  },
  {
    note: '8',
    url: '/jeux/football-manager-2012-3010009827/test-pc.html',
    name: 'football-manager-2012',
  },
  {
    note: '7',
    url: '/jeux/might-magic-heroes-vi-105728/test.html',
    name: 'might-magic-heroes-vi',
  },
  {
    note: '8',
    url: '/jeux/nba-2k12-3010009498/test-pc.html',
    name: 'nba-2k12',
  },
  {
    note: '8',
    url: '/jeux/fifa-12-3010009326/test-pc.html',
    name: 'fifa-12',
  },
  {
    note: '8',
    url: '/jeux/blocks-that-matter-3010009454/test-pc.html',
    name: 'blocks-that-matter',
  },
  {
    note: '7',
    url: '/jeux/trackmania2-canyon-99029/test.html',
    name: 'trackmania2-canyon',
  },
  {
    note: '4',
    url: '/jeux/fallout-new-vegas-lonesome-road-3010009402/test.html',
    name: 'fallout-new-vegas-lonesome-road',
  },
  {
    note: '8',
    url: '/jeux/f1-2011-3010008444/test-pc.html',
    name: 'f1-2011',
  },
  {
    note: '6',
    url: '/jeux/red-orchestra-2-heroes-of-stalingrad-96366/test.html',
    name: 'red-orchestra-2-heroes-of-stalingrad',
  },
  {
    note: '6',
    url: '/jeux/warhammer-40-000-space-marine-3010005870/test.html',
    name: 'warhammer-40-000-space-marine',
  },
  {
    note: '6',
    url: '/jeux/the-baconing-3010009465/test.html',
    name: 'the-baconing',
  },
  {
    note: '4',
    url: '/jeux/les-sims-medieval-nobles-et-pirates-3010009632/test.html',
    name: 'les-sims-medieval-nobles-et-pirates',
  },
  {
    note: '7',
    url: '/jeux/tropico-4-3010009067/test.html',
    name: 'tropico-4',
  },
  {
    note: '3',
    url: '/jeux/les-sims-3-hidden-springs-3010009881/test-pc.html',
    name: 'les-sims-3-hidden-springs',
  },
  {
    note: '3',
    url: '/jeux/age-of-empires-online-defense-de-la-crete-111934/test.html',
    name: 'age-of-empires-online-defense-de-la-crete',
  },
  {
    note: '7',
    url:
      '/jeux/hector-episode-2-senseless-acts-of-justice-3010009892/test-pc.html',
    name: 'hector-episode-2-senseless-acts-of-justice',
  },
  {
    note: '8',
    url: '/jeux/deus-ex-human-revolution-3010004683/test-pc.html',
    name: 'deus-ex-human-revolution',
  },
  { note: '6', url: '/jeux/trauma-3050139061/test.html', name: 'trauma' },
  {
    note: '6',
    url: '/jeux/age-of-empires-online-105716/test.html',
    name: 'age-of-empires-online',
  },
  { note: '6', url: '/jeux/edge-3010008881/test-pc.html', name: 'edge' },
  {
    note: '5',
    url: '/jeux/supreme-ruler-cold-war-109177/test.html',
    name: 'supreme-ruler-cold-war',
  },
  {
    note: '7',
    url: '/jeux/e-y-e-divine-cybermancy-93737/test.html',
    name: 'e-y-e-divine-cybermancy',
  },
  { note: '7', url: '/jeux/proun-111203/test.html', name: 'proun' },
  {
    note: '6',
    url: '/jeux/fallout-new-vegas-old-world-blues-3010009401/test.html',
    name: 'fallout-new-vegas-old-world-blues',
  },
  {
    note: '5',
    url: '/jeux/the-clockwork-man-111715/test.html',
    name: 'the-clockwork-man',
  },
  {
    note: '5',
    url: '/jeux/cargo-the-quest-for-gravity-110004/test.html',
    name: 'cargo-the-quest-for-gravity',
  },
  {
    note: '3',
    url: '/jeux/trapped-dead-108567/test.html',
    name: 'trapped-dead',
  },
  {
    note: '7',
    url: '/jeux/critical-mass-110931/test.html',
    name: 'critical-mass',
  },
  {
    note: '7',
    url: '/jeux/anomaly-warzone-earth-3010009316/test.html',
    name: 'anomaly-warzone-earth',
  },
  {
    note: '7',
    url: '/jeux/world-of-tanks-3050417673/test.html',
    name: 'world-of-tanks',
  },
  {
    note: '5',
    url: '/jeux/solar-2-3010009886/test.html',
    name: 'solar-2',
  },
  {
    note: '3',
    url: '/jeux/the-asskickers-3010008276/test.html',
    name: 'the-asskickers',
  },
  {
    note: '6',
    url: '/jeux/hoard-3010009206/test-pc.html',
    name: 'hoard',
  },
  {
    note: '7',
    url: '/jeux/vertex-dispenser-3010009565/test.html',
    name: 'vertex-dispenser',
  },
  {
    note: '5',
    url: '/jeux/puzzle-agent-2-3010009132/test.html',
    name: 'puzzle-agent-2',
  },
  {
    note: '3',
    url: '/jeux/dungeons-dragons-daggerdale-3010008942/test.html',
    name: 'dungeons-dragons-daggerdale',
  },
  {
    note: '4',
    url: '/jeux/back-to-the-future-episode-5-outatime-3010008946/test.html',
    name: 'back-to-the-future-episode-5-outatime',
  },
  {
    note: '8',
    url: '/jeux/pro-cycling-manager-saison-2011-3010009283/test.html',
    name: 'pro-cycling-manager-saison-2011',
  },
  {
    note: '8',
    url: '/jeux/jamestown-3050255855/test.html',
    name: 'jamestown',
  },
  {
    note: '7',
    url:
      '/jeux/magic-the-gathering-duels-of-the-planeswalkers-2012-3010009195/test-pc.html',
    name: 'magic-the-gathering-duels-of-the-planeswalkers-2012',
  },
  {
    note: '5',
    url: '/jeux/duke-nukem-forever-3010001581/test-pc.html',
    name: 'duke-nukem-forever',
  },
  {
    note: '4',
    url: '/jeux/les-sims-3-generations-3010009294/test.html',
    name: 'les-sims-3-generations',
  },
  {
    note: '7',
    url: '/jeux/frozen-synapse-3010009471/test-pc.html',
    name: 'frozen-synapse',
  },
  {
    note: '7',
    url: '/jeux/terraria-3050064325/test-pc.html',
    name: 'terraria',
  },
  {
    note: '4',
    url:
      '/jeux/back-to-the-future-episode-4-double-visions-3010008945/test.html',
    name: 'back-to-the-future-episode-4-double-visions',
  },
  {
    note: '4',
    url:
      '/jeux/back-to-the-future-episode-3-citizen-brown-3010008944/test.html',
    name: 'back-to-the-future-episode-3-citizen-brown',
  },
  {
    note: '8',
    url: '/jeux/the-witcher-2-assassins-of-kings-3010010497/test.html',
    name: 'the-witcher-2-assassins-of-kings',
  },
  {
    note: '4',
    url: '/jeux/fortix-2-3010008205/test.html',
    name: 'fortix-2',
  },
  {
    note: '4',
    url: '/jeux/fallout-new-vegas-honest-hearts-3010009321/test-pc.html',
    name: 'fallout-new-vegas-honest-hearts',
  },
  {
    note: '7',
    url: '/jeux/fable-iii-3010007832/test-pc.html',
    name: 'fable-iii',
  },
  {
    note: '7',
    url: '/jeux/brink-3010006684/test-pc.html',
    name: 'brink',
  },
  {
    note: '3',
    url: '/jeux/yars-revenge-3010009077/test.html',
    name: 'yars-revenge',
  },
  {
    note: '6',
    url:
      '/jeux/hector-episode-1-we-negociate-with-terrorists-3010009134/test-pc.html',
    name: 'hector-episode-1-we-negociate-with-terrorists',
  },
  {
    note: '5',
    url: '/jeux/darkspore-105026/test.html',
    name: 'darkspore',
  },
  {
    note: '5',
    url: '/jeux/black-mirror-iii-104293/test.html',
    name: 'black-mirror-iii',
  },
  {
    note: '7',
    url: '/jeux/portal-2-3010007798/test-pc.html',
    name: 'portal-2',
  },
  {
    note: '7',
    url: '/jeux/the-next-big-thing-3010009034/test-pc.html',
    name: 'the-next-big-thing',
  },
  { note: '-', url: '/jeux/rift-96424/test.html', name: 'rift' },
  {
    note: '7',
    url: '/jeux/crysis-2-3010006688/test-pc.html',
    name: 'crysis-2',
  },
  {
    note: '7',
    url: '/jeux/les-sims-medieval-3010008423/test-pc.html',
    name: 'les-sims-medieval',
  },
  {
    note: '8',
    url:
      '/jeux/assassin-s-creed-brotherhood-edition-da-vinci-3010009169/test-pc.html',
    name: 'assassin-s-creed-brotherhood-edition-da-vinci',
  },
  {
    note: '2',
    url: '/jeux/battle-los-angeles-3010009147/test-pc.html',
    name: 'battle-los-angeles',
  },
  {
    note: '4',
    url: '/jeux/homefront-3010006672/test.html',
    name: 'homefront',
  },
  {
    note: '5',
    url: '/jeux/back-to-the-future-episode-2-get-tannen-3010008943/test.html',
    name: 'back-to-the-future-episode-2-get-tannen',
  },
  {
    note: '9',
    url: '/jeux/total-war-shogun-2-103958/test.html',
    name: 'total-war-shogun-2',
  },
  {
    note: '5',
    url: '/jeux/dragon-age-ii-3010007500/test-pc.html',
    name: 'dragon-age-ii',
  },
  {
    note: '6',
    url: '/jeux/bulletstorm-3010007924/test.html',
    name: 'bulletstorm',
  },
  {
    note: '7',
    url: '/jeux/men-of-war-assault-squad-103847/test.html',
    name: 'men-of-war-assault-squad',
  },
  {
    note: '3',
    url: '/jeux/farming-simulator-2011-3010008829/test.html',
    name: 'farming-simulator-2011',
  },
  {
    note: '7',
    url: '/jeux/warhammer-40-000-dawn-of-war-ii-retribution-105730/test.html',
    name: 'warhammer-40-000-dawn-of-war-ii-retribution',
  },
  {
    note: '7',
    url: '/jeux/cities-in-motion-3010009455/test-pc.html',
    name: 'cities-in-motion',
  },
  {
    note: '5',
    url: '/jeux/fallout-new-vegas-dead-money-3010009071/test-pc.html',
    name: 'fallout-new-vegas-dead-money',
  },
  {
    note: '5',
    url: '/jeux/test-drive-unlimited-2-3010003956/test.html',
    name: 'test-drive-unlimited-2',
  },
  { note: '5', url: '/jeux/dungeons-105687/test.html', name: 'dungeons' },
  {
    note: '7',
    url: '/jeux/magicka-3010007435/test-pc.html',
    name: 'magicka',
  },
  {
    note: '4',
    url: '/jeux/crasher-3010007432/test.html',
    name: 'crasher',
  },
  {
    note: '-',
    url: '/jeux/dc-universe-online-3010001994/test.html',
    name: 'dc-universe-online',
  },
  {
    note: '8',
    url: '/jeux/dead-space-2-3010007227/test-pc.html',
    name: 'dead-space-2',
  },
  { note: '4', url: '/jeux/nail-d-3010007980/test.html', name: 'nail-d' },
  {
    note: '4',
    url:
      '/jeux/back-to-the-future-episode-1-it-s-about-time-3010008163/test.html',
    name: 'back-to-the-future-episode-1-it-s-about-time',
  },
  {
    note: '-',
    url: '/jeux/world-of-warcraft-cataclysm-3010007004/test-pc.html',
    name: 'world-of-warcraft-cataclysm',
  },
  {
    note: '5',
    url: '/jeux/battlefield-bad-company-2-vietnam-3010008194/test.html',
    name: 'battlefield-bad-company-2-vietnam',
  },
  {
    note: '7',
    url: '/jeux/gray-matter-3010008001/test.html',
    name: 'gray-matter',
  },
  {
    note: '7',
    url: '/jeux/recettear-106098/test.html',
    name: 'recettear',
  },
  {
    note: '6',
    url: '/jeux/two-worlds-ii-3010004670/test-pc.html',
    name: 'two-worlds-ii',
  },
  {
    note: '4',
    url: '/jeux/the-polynomial-3010008736/test.html',
    name: 'the-polynomial',
  },
  {
    note: '5',
    url: '/jeux/alien-breed-3-descent-3010007303/test.html',
    name: 'alien-breed-3-descent',
  },
  {
    note: '5',
    url: '/jeux/alien-breed-2-assault-3010007302/test.html',
    name: 'alien-breed-2-assault',
  },
  {
    note: '3',
    url:
      '/jeux/harry-potter-et-les-reliques-de-la-mort-premiere-partie-3010007499/test-pc.html',
    name: 'harry-potter-et-les-reliques-de-la-mort-premiere-partie',
  },
  {
    note: '-',
    url: '/jeux/final-fantasy-xiv-3010006710/test.html',
    name: 'final-fantasy-xiv',
  },
  {
    note: '5',
    url: '/jeux/the-undergarden-3010008764/test.html',
    name: 'the-undergarden',
  },
  {
    note: '5',
    url: '/jeux/bloody-good-time-3010008113/test.html',
    name: 'bloody-good-time',
  },
  {
    note: '7',
    url: '/jeux/blood-bowl-edition-legendaire-103079/test.html',
    name: 'blood-bowl-edition-legendaire',
  },
  {
    note: '5',
    url: '/jeux/les-sims-3-acces-vip-3010008567/test.html',
    name: 'les-sims-3-acces-vip',
  },
  {
    note: '5',
    url: '/jeux/winter-voices-prologue-avalanche-3010008484/test-pc.html',
    name: 'winter-voices-prologue-avalanche',
  },
  {
    note: '7',
    url: '/jeux/fallout-new-vegas-3010006573/test-pc.html',
    name: 'fallout-new-vegas',
  },
  {
    note: '5',
    url: '/jeux/gothic-4-arcania-3010004005/test.html',
    name: 'gothic-4-arcania',
  },
  {
    note: '7',
    url: '/jeux/cities-xl-2011-104533/test.html',
    name: 'cities-xl-2011',
  },
  {
    note: '7',
    url: '/jeux/lost-planet2-3010006276/test.html',
    name: 'lost-planet2',
  },
  {
    note: '7',
    url: '/jeux/drakensang-the-river-of-time-95025/test.html',
    name: 'drakensang-the-river-of-time',
  },
  {
    note: '6',
    url: '/jeux/medal-of-honor-3010005763/test.html',
    name: 'medal-of-honor',
  },
  {
    note: '4',
    url: '/jeux/front-mission-evolved-3010006543/test-pc.html',
    name: 'front-mission-evolved',
  },
  {
    note: '7',
    url: '/jeux/fifa-11-3010007360/test-pc.html',
    name: 'fifa-11',
  },
  {
    note: '8',
    url: '/jeux/amnesia-the-dark-descent-3010008537/test-pc.html',
    name: 'amnesia-the-dark-descent',
  },
  {
    note: '7',
    url: '/jeux/dead-rising-2-3010006277/test.html',
    name: 'dead-rising-2',
  },
  {
    note: '7',
    url: '/jeux/pro-evolution-soccer-2011-3010008026/test.html',
    name: 'pro-evolution-soccer-2011',
  },
  {
    note: '3',
    url: '/jeux/les-sims-3-barnacle-bay-3010008595/test.html',
    name: 'les-sims-3-barnacle-bay',
  },
  {
    note: '8',
    url: '/jeux/darksiders-3010004206/test-pc.html',
    name: 'darksiders',
  },
  {
    note: '8',
    url: '/jeux/f1-2010-3010006592/test-pc.html',
    name: 'f1-2010',
  },
  {
    note: '7',
    url: '/jeux/civilization-v-3010008755/test.html',
    name: 'sid-meier-s-civilization-v',
  },
  {
    note: '7',
    url: '/jeux/r-u-s-e-3010006473/test.html',
    name: 'r-u-s-e',
  },
  {
    note: '4',
    url: '/jeux/disciples-iii-renaissance-69025/test.html',
    name: 'disciples-iii-renaissance',
  },
  {
    note: '3',
    url: '/jeux/dragon-age-origins-chasse-aux-sorcieres-3010008461/test.html',
    name: 'dragon-age-origins-chasse-aux-sorcieres',
  },
  {
    note: '-',
    url: '/jeux/city-of-heroes-going-rogue-3010008269/test.html',
    name: 'city-of-heroes-going-rogue',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-5-the-city-that-dares-not-sleep-3010007828/test.html',
    name: 'sam-max-season-3-episode-5-the-city-that-dares-not-sleep',
  },
  {
    note: '7',
    url: '/jeux/victoria-ii-3010008579/test-pc.html',
    name: 'victoria-ii',
  },
  {
    note: '6',
    url: '/jeux/mafia-ii-3010004361/test.html',
    name: 'mafia-ii',
  },
  {
    note: '7',
    url: '/jeux/lost-horizon-97573/test.html',
    name: 'lost-horizon',
  },
  {
    note: '3',
    url: '/jeux/elemental-war-of-magic-93507/test.html',
    name: 'elemental-war-of-magic',
  },
  {
    note: '8',
    url: '/jeux/blazblue-calamity-trigger-3010006413/test-pc.html',
    name: 'blazblue-calamity-trigger',
  },
  {
    note: '5',
    url: '/jeux/dragon-age-origins-le-chant-de-leliana-3010008285/test.html',
    name: 'dragon-age-origins-le-chant-de-leliana',
  },
  {
    note: '3',
    url: '/jeux/dragon-age-origins-les-golems-d-amgarrak-3010008389/test.html',
    name: 'dragon-age-origins-les-golems-d-amgarrak',
  },
  {
    note: '-',
    url: '/jeux/need-for-speed-world-94651/test.html',
    name: 'need-for-speed-world',
  },
  {
    note: '4',
    url: '/jeux/the-guild-2-renaissance-103605/test.html',
    name: 'the-guild-2-renaissance',
  },
  {
    note: '9',
    url: '/jeux/starcraft-ii-wings-of-liberty-3010005578/test-pc.html',
    name: 'starcraft-ii-wings-of-liberty',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls-3010007827/test.html',
    name: 'sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls',
  },
  {
    note: '8',
    url:
      '/jeux/monkey-island-2-lechuck-s-revenge-edition-speciale-3010007819/test.html',
    name: 'monkey-island-2-lechuck-s-revenge-edition-speciale',
  },
  {
    note: '6',
    url: '/jeux/singularity-3010005652/test.html',
    name: 'singularity',
  },
  {
    note: '-',
    url: '/jeux/apb-reloaded-3010003897/test.html',
    name: 'apb-reloaded',
  },
  {
    note: '6',
    url: '/jeux/lego-harry-potter-annees-1-a-4-3010006447/test-pc.html',
    name: 'lego-harry-potter-annees-1-a-4',
  },
  {
    note: '6',
    url: '/jeux/transformers-guerre-pour-cybertron-3010007339/test-pc.html',
    name: 'transformers-guerre-pour-cybertron',
  },
  {
    note: '5',
    url: '/jeux/art-of-murder-new-adventure-3010007093/test.html',
    name: 'art-of-murder-new-adventure',
  },
  {
    note: '3',
    url:
      '/jeux/les-dossiers-secrets-de-nancy-drew-n02-beaute-sous-tension-104408/test.html',
    name: 'les-dossiers-secrets-de-nancy-drew-n02-beaute-sous-tension',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-3-they-stole-max-s-brain-3010007826/test.html',
    name: 'sam-max-season-3-episode-3-they-stole-max-s-brain',
  },
  {
    note: '7',
    url:
      '/jeux/magic-the-gathering-duels-of-the-planeswalkers-3010005869/test-pc.html',
    name: 'magic-the-gathering-duels-of-the-planeswalkers',
  },
  {
    note: '4',
    url: '/jeux/alien-breed-impact-3010006867/test-pc.html',
    name: 'alien-breed-impact',
  },
  {
    note: '6',
    url: '/jeux/plain-sight-3010005874/test.html',
    name: 'plain-sight',
  },
  {
    note: '-',
    url: '/jeux/age-of-conan-rise-of-the-godslayer-92561/test.html',
    name: 'age-of-conan-rise-of-the-godslayer',
  },
  { note: '6', url: '/jeux/blur-3010006580/test-pc.html', name: 'blur' },
  {
    note: '6',
    url: '/jeux/les-sims-3-ambitions-3010007749/test.html',
    name: 'les-sims-3-ambitions',
  },
  {
    note: '-',
    url: '/jeux/global-agenda-83537/test.html',
    name: 'global-agenda',
  },
  {
    note: '8',
    url:
      '/jeux/sam-max-season-3-episode-2-the-tomb-of-sammun-mak-3010007825/test.html',
    name: 'sam-max-season-3-episode-2-the-tomb-of-sammun-mak',
  },
  {
    note: '5',
    url: '/jeux/black-mirror-ii-81485/test.html',
    name: 'black-mirror-ii',
  },
  {
    note: '4',
    url: '/jeux/dragon-age-origins-darkspawn-chronicles-3010008027/test.html',
    name: 'dragon-age-origins-darkspawn-chronicles',
  },
  {
    note: '7',
    url: '/jeux/heroes-of-newerth-97905/test.html',
    name: 'heroes-of-newerth',
  },
  {
    note: '6',
    url: '/jeux/beat-hazard-3010008032/test.html',
    name: 'beat-hazard',
  },
  {
    note: '6',
    url: '/jeux/serious-sam-hd-the-second-encounter-3010007279/test.html',
    name: 'serious-sam-hd-the-second-encounter',
  },
  {
    note: '7',
    url: '/jeux/splinter-cell-conviction-3010002930/test-pc.html',
    name: 'splinter-cell-conviction',
  },
  {
    note: '8',
    url: '/jeux/s-t-a-l-k-e-r-call-of-pripyat-96367/test.html',
    name: 's-t-a-l-k-e-r-call-of-pripyat',
  },
  {
    note: '6',
    url: '/jeux/lead-and-gold-gangs-of-the-wild-west-3010006964/test.html',
    name: 'lead-and-gold-gangs-of-the-wild-west',
  },
  {
    note: '6',
    url:
      '/jeux/les-chroniques-de-sadwick-the-whispered-world-3010005846/test.html',
    name: 'les-chroniques-de-sadwick-the-whispered-world',
  },
  {
    note: '-',
    url: '/jeux/left-4-dead-2-the-passing-3010007337/test-pc.html',
    name: 'left-4-dead-2-the-passing',
  },
  {
    note: '7',
    url: '/jeux/supreme-commander-2-3010006687/test.html',
    name: 'supreme-commander-2',
  },
  {
    note: '-',
    url: '/jeux/tiger-woods-pga-tour-online-96701/test.html',
    name: 'tiger-woods-pga-tour-online',
  },
  {
    note: '7',
    url: '/jeux/sam-max-season-3-episode-1-the-penal-zone-3010007824/test.html',
    name: 'sam-max-season-3-episode-1-the-penal-zone',
  },
  {
    note: '7',
    url: '/jeux/shatter-3010007779/test-pc.html',
    name: 'shatter',
  },
  {
    note: '7',
    url: '/jeux/metro-2033-3010007185/test.html',
    name: 'metro-2033',
  },
  {
    note: '7',
    url: '/jeux/just-cause-2-3010003075/test-pc.html',
    name: 'just-cause-2',
  },
  {
    note: '7',
    url:
      '/jeux/the-settlers-7-a-l-aube-d-un-nouveau-royaume-3010007925/test.html',
    name: 'the-settlers-7-a-l-aube-d-un-nouveau-royaume',
  },
  {
    note: '4',
    url: '/jeux/command-conquer-4-le-crepuscule-de-tiberium-97245/test.html',
    name: 'command-conquer-4-le-crepuscule-de-tiberium',
  },
  {
    note: '8',
    url: '/jeux/warhammer-40-000-dawn-of-war-ii-chaos-rising-99028/test.html',
    name: 'warhammer-40-000-dawn-of-war-ii-chaos-rising',
  },
  {
    note: '6',
    url: '/jeux/dragon-age-origins-awakening-3010007357/test.html',
    name: 'dragon-age-origins-awakening',
  },
  {
    note: '8',
    url: '/jeux/assassin-s-creed-ii-3010005539/test-pc.html',
    name: 'assassin-s-creed-ii',
  },
  {
    note: '8',
    url: '/jeux/napoleon-total-war-3050141889/test-pc.html',
    name: 'napoleon-total-war',
  },
  {
    note: '8',
    url: '/jeux/battlefield-bad-company-2-3010006328/test-pc.html',
    name: 'battlefield-bad-company-2',
  },
  {
    note: '5',
    url: '/jeux/anno-1404-venise-99872/test.html',
    name: 'anno-1404-venise',
  },
  {
    note: '5',
    url: '/jeux/aliens-vs-predator-3010006349/test.html',
    name: 'aliens-vs-predator',
  },
  {
    note: '7',
    url: '/jeux/bioshock-2-3010005082/test.html',
    name: 'bioshock-2-remastered',
  },
  {
    note: '-',
    url: '/jeux/star-trek-online-3010005807/test-pc.html',
    name: 'star-trek-online',
  },
  {
    note: '3',
    url: '/jeux/dragon-age-origins-retour-a-ostagar-3010007246/test-pc.html',
    name: 'dragon-age-origins-retour-a-ostagar',
  },
  {
    note: '7',
    url: '/jeux/mass-effect-2-3010005107/test-pc.html',
    name: 'mass-effect-2',
  },
  {
    note: '6',
    url: '/jeux/vancouver-2010-3010006439/test.html',
    name: 'vancouver-2010',
  },
  {
    note: '-',
    url:
      '/jeux/le-seigneur-des-anneaux-online-le-siege-de-la-foret-noire-98878/test.html',
    name: 'le-seigneur-des-anneaux-online-le-siege-de-la-foret-noire',
  },
  {
    note: '4',
    url: '/jeux/zombie-driver-99241/test.html',
    name: 'zombie-driver',
  },
  {
    note: '6',
    url:
      '/jeux/tales-of-monkey-island-chapter-5-rise-of-the-pirate-god-3010006698/test.html',
    name: 'tales-of-monkey-island-chapter-5-rise-of-the-pirate-god',
  },
  {
    note: '6',
    url: '/jeux/the-saboteur-3010003846/test-pc.html',
    name: 'the-saboteur',
  },
  {
    note: '6',
    url: '/jeux/shattered-horizon-97088/test.html',
    name: 'shattered-horizon',
  },
  {
    note: '5',
    url: '/jeux/serious-sam-hd-the-first-encounter-3010006818/test-pc.html',
    name: 'serious-sam-hd-the-first-encounter',
  },
  {
    note: '8',
    url: '/jeux/colin-mcrae-dirt-2-3010006038/test-pc.html',
    name: 'colin-mcrae-dirt-2',
  },
  {
    note: '8',
    url: '/jeux/runaway-a-twist-of-fate-3010005862/test-pc.html',
    name: 'runaway-a-twist-of-fate',
  },
  {
    note: '8',
    url: '/jeux/left-4-dead-2-3010006701/test-pc.html',
    name: 'left-4-dead-2',
  },
  {
    note: '6',
    url: '/jeux/les-sims-3-destination-aventure-3010006915/test.html',
    name: 'les-sims-3-destination-aventure',
  },
  {
    note: '7',
    url: '/jeux/for-the-glory-98879/test.html',
    name: 'for-the-glory',
  },
  {
    note: '8',
    url: '/jeux/call-of-duty-modern-warfare-2-3010005901/test-pc.html',
    name: 'call-of-duty-modern-warfare-2',
  },
  {
    note: '3',
    url:
      '/jeux/dragon-age-origins-la-forteresse-des-gardes-des-ombres-3010007152/test-pc.html',
    name: 'dragon-age-origins-la-forteresse-des-gardes-des-ombres',
  },
  {
    note: '4',
    url:
      '/jeux/dragon-age-origins-le-prisonnier-de-pierre-3010006997/test.html',
    name: 'dragon-age-origins-le-prisonnier-de-pierre',
  },
  {
    note: '3',
    url: '/jeux/painkiller-resurrection-95917/test.html',
    name: 'painkiller-resurrection',
  },
  {
    note: '8',
    url: '/jeux/dragon-age-origins-3010005997/test-pc.html',
    name: 'dragon-age-origins',
  },
  {
    note: '4',
    url: '/jeux/les-experts-premeditation-3010006887/test.html',
    name: 'les-experts-premeditation',
  },
  {
    note: '2',
    url: '/jeux/buccaneer-the-pursuit-of-infamy-98799/test.html',
    name: 'buccaneer-the-pursuit-of-infamy',
  },
  {
    note: '-',
    url: '/jeux/league-of-legends-3050109826/test.html',
    name: 'league-of-legends',
  },
  {
    note: '6',
    url:
      '/jeux/tales-of-monkey-island-chapter-4-the-trial-and-execution-of-guybrush-threepwood-3010006697/test.html',
    name:
      'tales-of-monkey-island-chapter-4-the-trial-and-execution-of-guybrush-threepwood',
  },
  {
    note: '6',
    url: '/jeux/tropico-3-3010006804/test.html',
    name: 'tropico-3',
  },
  {
    note: '7',
    url: '/jeux/torchlight-3010008055/test.html',
    name: 'torchlight',
  },
  {
    note: '6',
    url: '/jeux/pro-evolution-soccer-2010-3010006522/test-pc.html',
    name: 'pro-evolution-soccer-2010',
  },
  {
    note: '4',
    url:
      '/jeux/les-enquetes-de-nancy-drew-le-chateau-hante-de-malloy-93253/test.html',
    name: 'les-enquetes-de-nancy-drew-le-chateau-hante-de-malloy',
  },
  { note: '5', url: '/jeux/race-on-95465/test.html', name: 'race-on' },
  {
    note: '7',
    url: '/jeux/machinarium-3010007222/test.html',
    name: 'machinarium',
  },
  {
    note: '7',
    url: '/jeux/majesty-2-the-fantasy-kingdom-sim-3010006083/test.html',
    name: 'majesty-2-the-fantasy-kingdom-sim',
  },
  {
    note: '7',
    url: '/jeux/divinity-ii-ego-draconis-3010005769/test.html',
    name: 'divinity-ii-ego-draconis',
  },
  {
    note: '5',
    url: '/jeux/empire-total-war-the-warpath-campaign-99071/test.html',
    name: 'empire-total-war-the-warpath-campaign',
  },
  {
    note: '7',
    url: '/jeux/sacred-2-fallen-angel-ice-blood-3010006924/test.html',
    name: 'sacred-2-fallen-angel-ice-blood',
  },
  {
    note: '6',
    url: '/jeux/lucidity-3010007075/test-pc.html',
    name: 'lucidity',
  },
  {
    note: '7',
    url: '/jeux/cities-xl-89462/test.html',
    name: 'cities-xl',
  },
  {
    note: '4',
    url: '/jeux/fifa-10-3010006597/test-pc.html',
    name: 'fifa-10',
  },
  {
    note: '6',
    url: '/jeux/operation-flashpoint-dragon-rising-3010003868/test.html',
    name: 'operation-flashpoint-dragon-rising',
  },
  {
    note: '6',
    url:
      '/jeux/tales-of-monkey-island-chapter-3-lair-of-the-leviathan-3010006695/test-pc.html',
    name: 'tales-of-monkey-island-chapter-3-lair-of-the-leviathan',
  },
  {
    note: '3',
    url: '/jeux/winemaker-tycoon-99006/test.html',
    name: 'winemaker-tycoon',
  },
  {
    note: '8',
    url: '/jeux/risen-3010006089/test-pc.html',
    name: 'risen',
  },
  {
    note: '2',
    url: '/jeux/cinema-tycoon-2-99007/test.html',
    name: 'cinema-tycoon-2',
  },
  { note: '-', url: '/jeux/aion-77119/test.html', name: 'aion' },
  {
    note: '5',
    url: '/jeux/order-of-war-96007/test.html',
    name: 'order-of-war',
  },
  {
    note: '8',
    url: '/jeux/batman-arkham-asylum-3010005806/test-pc.html',
    name: 'batman-arkham-asylum',
  },
  {
    note: '5',
    url: '/jeux/mini-ninjas-3010006247/test-pc.html',
    name: 'mini-ninjas',
  },
  {
    note: '7',
    url: '/jeux/resident-evil-5-3010002023/test-pc.html',
    name: 'resident-evil-5',
  },
  {
    note: '-',
    url: '/jeux/champions-online-3010004956/test.html',
    name: 'champions-online',
  },
  {
    note: '7',
    url: '/jeux/hearts-of-iron-iii-3010007313/test.html',
    name: 'hearts-of-iron-iii',
  },
  {
    note: '7',
    url: '/jeux/osmos-3010008302/test-pc.html',
    name: 'osmos',
  },
  {
    note: '7',
    url:
      '/jeux/tales-of-monkey-island-chapter-2-the-siege-of-spinner-cay-3010006694/test.html',
    name: 'tales-of-monkey-island-chapter-2-the-siege-of-spinner-cay',
  },
  {
    note: '5',
    url: '/jeux/fallout-3-mothership-zeta-3010006658/test.html',
    name: 'fallout-3-mothership-zeta',
  },
  {
    note: '5',
    url: '/jeux/east-india-company-85823/test.html',
    name: 'east-india-company',
  },
  {
    note: '7',
    url:
      '/jeux/wallace-gromit-s-grand-adventures-episode-4-the-bogey-man-3010007150/test.html',
    name: 'wallace-gromit-s-grand-adventures-episode-4-the-bogey-man',
  },
  {
    note: '7',
    url: '/jeux/bionic-commando-3010004494/test.html',
    name: 'bionic-commando',
  },
  {
    note: '8',
    url:
      '/jeux/the-secret-of-monkey-island-special-edition-3010006693/test-pc.html',
    name: 'the-secret-of-monkey-island-special-edition',
  },
  { note: '7', url: '/jeux/ceville-90517/test.html', name: 'ceville' },
  {
    note: '7',
    url: '/jeux/trine-3010006079/test-pc.html',
    name: 'trine',
  },
  {
    note: '7',
    url:
      '/jeux/tales-of-monkey-island-chapter-1-launch-of-the-screaming-narwhal-3010006692/test.html',
    name: 'tales-of-monkey-island-chapter-1-launch-of-the-screaming-narwhal',
  },
  {
    note: '4',
    url: '/jeux/harry-potter-et-le-prince-de-sang-mele-3010005257/test-pc.html',
    name: 'harry-potter-et-le-prince-de-sang-mele',
  },
  {
    note: '7',
    url: '/jeux/blood-bowl-3010004625/test-pc.html',
    name: 'blood-bowl',
  },
  {
    note: '9',
    url: '/jeux/street-fighter-iv-3010004502/test-pc.html',
    name: 'street-fighter-iv',
  },
  {
    note: '4',
    url: '/jeux/spore-aventures-galactiques-3010006538/test.html',
    name: 'spore-aventures-galactiques',
  },
  {
    note: '6',
    url: '/jeux/overlord-ii-3010005823/test.html',
    name: 'overlord-ii',
  },
  {
    note: '6',
    url: '/jeux/arma-ii-3010004370/test-pc.html',
    name: 'arma-ii',
  },
  {
    note: '7',
    url: '/jeux/anno-1404-92519/test.html',
    name: 'anno-1404',
  },
  {
    note: '7',
    url: '/jeux/fallout-3-point-lookout-3010006659/test.html',
    name: 'fallout-3-point-lookout',
  },
  {
    note: '6',
    url: '/jeux/retour-sur-l-ile-mysterieuse-2-3010006668/test.html',
    name: 'retour-sur-l-ile-mysterieuse-2',
  },
  {
    note: '7',
    url:
      '/jeux/wallace-gromit-s-grand-adventures-episode-3-muzzled-3010007149/test.html',
    name: 'wallace-gromit-s-grand-adventures-episode-3-muzzled',
  },
  {
    note: '4',
    url: '/jeux/nancy-drew-le-fantome-de-venise-90677/test.html',
    name: 'nancy-drew-le-fantome-de-venise',
  },
  {
    note: '6',
    url: '/jeux/blueberry-garden-95711/test.html',
    name: 'blueberry-garden',
  },
  {
    note: '7',
    url: '/jeux/les-sims-3-3010006313/test-pc.html',
    name: 'les-sims-3',
  },
  {
    note: '6',
    url: '/jeux/darksector-3010001311/test.html',
    name: 'darksector',
  },
  {
    note: '6',
    url: '/jeux/killing-floor-3010008069/test.html',
    name: 'killing-floor',
  },
  {
    note: '8',
    url: '/jeux/plantes-contre-zombies-3010006902/test-pc.html',
    name: 'plantes-contre-zombies',
  },
  {
    note: '6',
    url: '/jeux/secret-files-2-puritas-cordis-3010005965/test.html',
    name: 'secret-files-2-puritas-cordis',
  },
  {
    note: '6',
    url: '/jeux/fallout-3-broken-steel-3010006578/test.html',
    name: 'fallout-3-broken-steel',
  },
  {
    note: '4',
    url: '/jeux/still-life-2-3010006869/test.html',
    name: 'still-life-2',
  },
  {
    note: '6',
    url: '/jeux/sherlock-holmes-contre-jack-l-eventreur-3010006469/test.html',
    name: 'sherlock-holmes-contre-jack-l-eventreur',
  },
  {
    note: '6',
    url:
      '/jeux/wallace-gromit-s-grand-adventures-episode-2-the-last-resort-3010006591/test-pc.html',
    name: 'wallace-gromit-s-grand-adventures-episode-2-the-last-resort',
  },
  {
    note: '5',
    url: '/jeux/company-of-heroes-tales-of-valor-93496/test.html',
    name: 'company-of-heroes-tales-of-valor',
  },
  { note: '7', url: '/jeux/demigod-87647/test.html', name: 'demigod' },
  {
    note: '6',
    url: '/jeux/zeno-clash-3010006739/test.html',
    name: 'zeno-clash',
  },
  {
    note: '5',
    url: '/jeux/flock-3010005643/test-pc.html',
    name: 'flock',
  },
  {
    note: '7',
    url: '/jeux/drakensang-l-oeil-noir-81357/test.html',
    name: 'drakensang-l-oeil-noir',
  },
  {
    note: '3',
    url: '/jeux/restaurant-empire-ii-3050025467/test.html',
    name: 'restaurant-empire-ii',
  },
  {
    note: '4',
    url: '/jeux/le-parrain-2-3010005513/test.html',
    name: 'le-parrain-2',
  },
  {
    note: '7',
    url: '/jeux/battleforge-89815/test.html',
    name: 'battleforge',
  },
  {
    note: '5',
    url: '/jeux/memento-mori-87093/test.html',
    name: 'memento-mori',
  },
  { note: '-', url: '/jeux/the-path-82695/test.html', name: 'the-path' },
  {
    note: '6',
    url: '/jeux/fallout-3-the-pitt-3010006412/test.html',
    name: 'fallout-3-the-pitt',
  },
  {
    note: '5',
    url: '/jeux/codename-panzers-cold-war-75685/test.html',
    name: 'codename-panzers-cold-war',
  },
  {
    note: '7',
    url:
      '/jeux/wallace-gromit-s-grand-adventures-episode-1-fright-of-the-bumblebees-3010005717/test.html',
    name:
      'wallace-gromit-s-grand-adventures-episode-1-fright-of-the-bumblebees',
  },
  {
    note: '5',
    url: '/jeux/command-conquer-alerte-rouge-3-la-revolte-94207/test.html',
    name: 'command-conquer-alerte-rouge-3-la-revolte',
  },
  {
    note: '3',
    url: '/jeux/watchmen-the-end-is-nigh-3010005698/test.html',
    name: 'watchmen-the-end-is-nigh',
  },
  {
    note: '5',
    url: '/jeux/world-in-conflict-soviet-assault-89094/test.html',
    name: 'world-in-conflict-soviet-assault',
  },
  {
    note: '5',
    url: '/jeux/tom-clancy-s-endwar-3010003883/test-pc.html',
    name: 'tom-clancy-s-endwar',
  },
  {
    note: '7',
    url: '/jeux/men-of-war-88140/test.html',
    name: 'men-of-war',
  },
  {
    note: '8',
    url: '/jeux/empire-total-war-85552/test.html',
    name: 'empire-total-war',
  },
  {
    note: '5',
    url: '/jeux/silent-hill-homecoming-3010005261/test-pc.html',
    name: 'silent-hill-homecoming',
  },
  {
    note: '5',
    url: '/jeux/necrovision-77964/test.html',
    name: 'necrovision',
  },
  {
    note: '9',
    url: '/jeux/warhammer-40-000-dawn-of-war-ii-84676/test.html',
    name: 'warhammer-40-000-dawn-of-war-ii',
  },
  {
    note: '7',
    url: '/jeux/f-e-a-r-2-project-origin-3010004192/test.html',
    name: 'f-e-a-r-2-project-origin',
  },
  {
    note: '7',
    url: '/jeux/cryostasis-sleep-of-reason-77470/test.html',
    name: 'cryostasis-sleep-of-reason',
  },
  {
    note: '7',
    url: '/jeux/burnout-paradise-the-ultimate-box-3010006023/test-pc.html',
    name: 'burnout-paradise-the-ultimate-box',
  },
  {
    note: '3',
    url: '/jeux/les-experts-manhattan-le-jeu-93563/test.html',
    name: 'les-experts-manhattan-le-jeu',
  },
  {
    note: '6',
    url: '/jeux/saints-row-2-3010003977/test-pc.html',
    name: 'saints-row-2',
  },
  {
    note: '5',
    url:
      '/jeux/le-seigneur-des-anneaux-l-age-des-conquetes-3010005337/test.html',
    name: 'le-seigneur-des-anneaux-l-age-des-conquetes',
  },
  {
    note: '7',
    url: '/jeux/crayon-physics-deluxe-3010007146/test-pc.html',
    name: 'crayon-physics-deluxe',
  },
  {
    note: '6',
    url: '/jeux/mirror-s-edge-3010004196/test-pc.html',
    name: 'mirror-s-edge',
  },
  {
    note: '6',
    url: '/jeux/professor-heinz-wolff-s-gravity-3010006069/test.html',
    name: 'professor-heinz-wolff-s-gravity',
  },
  {
    note: '3',
    url: '/jeux/everlight-le-pouvoir-des-elfes-93301/test.html',
    name: 'everlight-le-pouvoir-des-elfes',
  },
  {
    note: '-',
    url: '/jeux/les-chroniques-de-spellborn-75219/test.html',
    name: 'les-chroniques-de-spellborn',
  },
  {
    note: '3',
    url:
      '/jeux/30-millions-d-amis-mon-refuge-pour-animaux-3010006044/test-pc.html',
    name: '30-millions-d-amis-mon-refuge-pour-animaux',
  },
  {
    note: '6',
    url: '/jeux/a-vampyre-story-3010007836/test.html',
    name: 'a-vampyre-story',
  },
  {
    note: '8',
    url: '/jeux/grand-theft-auto-iv-3010002398/test-pc.html',
    name: 'grand-theft-auto-iv',
  },
  {
    note: '3',
    url:
      '/jeux/les-enquetes-de-nancy-drew-7-le-loup-blanc-d-icicle-creek-3010005990/test.html',
    name: 'les-enquetes-de-nancy-drew-7-le-loup-blanc-d-icicle-creek',
  },
  {
    note: '5',
    url: '/jeux/neverwinter-nights-2-storm-of-zehir-90675/test.html',
    name: 'neverwinter-nights-2-storm-of-zehir',
  },
  {
    note: '8',
    url: '/jeux/left-4-dead-3010004046/test-pc.html',
    name: 'left-4-dead',
  },
  {
    note: '-',
    url: '/jeux/world-of-warcraft-wrath-of-the-lich-king-3010004312/test.html',
    name: 'world-of-warcraft-wrath-of-the-lich-king',
  },
  {
    note: '-',
    url:
      '/jeux/le-seigneur-des-anneaux-online-les-mines-de-la-moria-88911/test.html',
    name: 'le-seigneur-des-anneaux-online-les-mines-de-la-moria',
  },
  {
    note: '3',
    url: '/jeux/hotel-giant-2-3010005699/test.html',
    name: 'hotel-giant-2',
  },
  {
    note: '8',
    url: '/jeux/world-of-goo-3010005236/test-pc.html',
    name: 'world-of-goo',
  },
  {
    note: '7',
    url: '/jeux/fallout-3-3010004047/test-pc.html',
    name: 'fallout-3',
  },
  {
    note: '7',
    url: '/jeux/command-conquer-alerte-rouge-3-3010004964/test.html',
    name: 'command-conquer-alerte-rouge-3',
  },
  {
    note: '7',
    url: '/jeux/bully-scholarship-edition-3010001906/test-pc.html',
    name: 'bully-scholarship-edition',
  },
  {
    note: '3',
    url: '/jeux/global-conflicts-latin-america-3010006003/test.html',
    name: 'global-conflicts-latin-america',
  },
  {
    note: '8',
    url: '/jeux/dead-space-3010004425/test-pc.html',
    name: 'dead-space',
  },
  {
    note: '5',
    url: '/jeux/diabolik-the-original-sin-3010005975/test.html',
    name: 'diabolik-the-original-sin',
  },
  {
    note: '4',
    url: '/jeux/mozart-le-dernier-secret-3010006369/test.html',
    name: 'mozart-le-dernier-secret',
  },
  {
    note: '7',
    url: '/jeux/x3-conflit-terrien-3010007216/test.html',
    name: 'x3-conflit-terrien',
  },
  {
    note: '7',
    url: '/jeux/far-cry-2-3010004807/test-pc.html',
    name: 'far-cry-2',
  },
  {
    note: '7',
    url: '/jeux/king-s-bounty-the-legend-3050070617/test.html',
    name: 'king-s-bounty-the-legend',
  },
  {
    note: '6',
    url: '/jeux/pro-evolution-soccer-2009-3010005525/test-pc.html',
    name: 'pro-evolution-soccer-2009',
  },
  {
    note: '6',
    url: '/jeux/brothers-in-arms-hell-s-highway-3010002265/test-pc.html',
    name: 'brothers-in-arms-hell-s-highway',
  },
  {
    note: '4',
    url: '/jeux/dark-horizon-90531/test.html',
    name: 'dark-horizon',
  },
  {
    note: '7',
    url: '/jeux/sacred-2-fallen-angel-3010003703/test-pc.html',
    name: 'sacred-2-fallen-angel',
  },
  {
    note: '8',
    url: '/jeux/bionic-commando-rearmed-3010004871/test-pc.html',
    name: 'bionic-commando-rearmed',
  },
  {
    note: '7',
    url: '/jeux/crysis-warhead-3010005451/test-pc.html',
    name: 'crysis-warhead',
  },
  {
    note: '7',
    url: '/jeux/multiwinia-survival-of-the-flattest-3010005138/test-pc.html',
    name: 'multiwinia-survival-of-the-flattest',
  },
  {
    note: '6',
    url: '/jeux/nikopol-la-foire-aux-immortels-84390/test.html',
    name: 'nikopol-la-foire-aux-immortels',
  },
  {
    note: '-',
    url: '/jeux/warhammer-online-age-of-reckoning-3010007122/test-pc.html',
    name: 'warhammer-online-age-of-reckoning',
  },
  {
    note: '6',
    url: '/jeux/gtr-evolution-89229/test.html',
    name: 'gtr-evolution',
  },
  {
    note: '6',
    url: '/jeux/race-07-gtr-evolution-89227/test.html',
    name: 'race-07-gtr-evolution',
  },
  {
    note: '5',
    url: '/jeux/war-leaders-clash-of-nations-74909/test.html',
    name: 'war-leaders-clash-of-nations',
  },
  {
    note: '6',
    url: '/jeux/spore-3010001975/test-pc.html',
    name: 'spore',
  },
  {
    note: '5',
    url: '/jeux/mercenaries-2-l-enfer-des-favelas-3010002359/test-pc.html',
    name: 'mercenaries-2-l-enfer-des-favelas',
  },
  {
    note: '7',
    url: '/jeux/s-t-a-l-k-e-r-clear-sky-84839/test.html',
    name: 's-t-a-l-k-e-r-clear-sky',
  },
  {
    note: '5',
    url: '/jeux/romance-of-the-three-kingdoms-xi-3010002463/test.html',
    name: 'romance-of-the-three-kingdoms-xi',
  },
  {
    note: '4',
    url: '/jeux/space-siege-3010004533/test.html',
    name: 'space-siege',
  },
  {
    note: '5',
    url: '/jeux/les-sims-2-la-vie-en-appartement-89445/test.html',
    name: 'les-sims-2-la-vie-en-appartement',
  },
  {
    note: '5',
    url: '/jeux/xiii-century-death-or-glory-68184/test.html',
    name: 'xiii-century-death-or-glory',
  },
  {
    note: '7',
    url: '/jeux/devil-may-cry-4-3010003763/test-pc.html',
    name: 'devil-may-cry-4',
  },
  {
    note: '7',
    url: '/jeux/perry-rhodan-le-mythe-des-illochim-84905/test.html',
    name: 'perry-rhodan-le-mythe-des-illochim',
  },
  {
    note: '3',
    url: '/jeux/so-blonde-83768/test.html',
    name: 'so-blonde',
  },
  {
    note: '6',
    url: '/jeux/europa-universalis-rome-3010005971/test.html',
    name: 'europa-universalis-rome',
  },
  {
    note: '6',
    url: '/jeux/penumbra-black-plague-3010008547/test-pc.html',
    name: 'penumbra-black-plague',
  },
  { note: '3', url: '/jeux/west-law-86376/test.html', name: 'west-law' },
  {
    note: '5',
    url: '/jeux/dracula-origin-83088/test.html',
    name: 'dracula-origin',
  },
  {
    note: '-',
    url: '/jeux/age-of-conan-hyborian-adventures-3010003300/test.html',
    name: 'age-of-conan-hyborian-adventures',
  },
  {
    note: '5',
    url:
      '/jeux/penny-arcade-adventures-on-the-rain-slick-precipice-of-darkness-episode-one-3010004375/test-pc.html',
    name:
      'penny-arcade-adventures-on-the-rain-slick-precipice-of-darkness-episode-one',
  },
  { note: '4', url: '/jeux/turok-3010002418/test.html', name: 'turok' },
  {
    note: '5',
    url: '/jeux/warhammer-mark-of-chaos-battle-march-3010004363/test-pc.html',
    name: 'warhammer-mark-of-chaos-battle-march',
  },
  {
    note: '4',
    url: '/jeux/darkness-within-la-poursuite-de-loath-nolder-84017/test.html',
    name: 'darkness-within-la-poursuite-de-loath-nolder',
  },
  {
    note: '6',
    url: '/jeux/nancy-drew-la-legende-du-crane-de-cristal-83100/test.html',
    name: 'nancy-drew-la-legende-du-crane-de-cristal',
  },
  { note: '-', url: '/jeux/exalight-84443/test.html', name: 'exalight' },
  {
    note: '6',
    url: '/jeux/dracula-3-la-voie-du-dragon-83435/test.html',
    name: 'dracula-3-la-voie-du-dragon',
  },
  {
    note: '6',
    url: '/jeux/tom-clancy-s-rainbow-six-vegas-2-3010004642/test-pc.html',
    name: 'tom-clancy-s-rainbow-six-vegas-2',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-2-episode-5-what-s-new-beelzebub-85942/test.html',
    name: 'sam-max-season-2-episode-5-what-s-new-beelzebub',
  },
  {
    note: '8',
    url: '/jeux/assassin-s-creed-3010002786/test-pc.html',
    name: 'assassin-s-creed',
  },
  {
    note: '5',
    url: '/jeux/avencast-rise-of-the-mage-82919/test.html',
    name: 'avencast-rise-of-the-mage',
  },
  {
    note: '6',
    url: '/jeux/command-conquer-3-la-fureur-de-kane-3010004333/test-pc.html',
    name: 'command-conquer-3-la-fureur-de-kane',
  },
  {
    note: '8',
    url: '/jeux/sins-of-a-solar-empire-79806/test.html',
    name: 'sins-of-a-solar-empire',
  },
  {
    note: '5',
    url: '/jeux/overclocked-therapie-de-choc-78926/test.html',
    name: 'overclocked-therapie-de-choc',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-2-episode-4-chariots-of-the-dogs-85941/test.html',
    name: 'sam-max-season-2-episode-4-chariots-of-the-dogs',
  },
  {
    note: '1',
    url: '/jeux/vera-jones-88682/test.html',
    name: 'vera-jones',
  },
  {
    note: '6',
    url: '/jeux/les-sims-2-quartier-libre-87200/test.html',
    name: 'les-sims-2-quartier-libre',
  },
  {
    note: '6',
    url: '/jeux/warhammer-40-000-dawn-of-war-soulstorm-85837/test.html',
    name: 'warhammer-40-000-dawn-of-war-soulstorm',
  },
  {
    note: '4',
    url: '/jeux/speedball-2-brutal-deluxe-3010002967/test-pc.html',
    name: 'speedball-2-brutal-deluxe',
  },
  {
    note: '-',
    url: '/jeux/pirates-of-the-burning-sea-62923/test.html',
    name: 'pirates-of-the-burning-sea',
  },
  {
    note: '3',
    url: '/jeux/hour-of-victory-3010004428/test-pc.html',
    name: 'hour-of-victory',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-2-episode-3-night-of-the-raving-dead-85939/test.html',
    name: 'sam-max-season-2-episode-3-night-of-the-raving-dead',
  },
  {
    note: '4',
    url: '/jeux/les-sims-histoires-de-naufrages-3010005117/test.html',
    name: 'les-sims-histoires-de-naufrages',
  },
  {
    note: '2',
    url: '/jeux/hard-to-be-a-god-72000/test.html',
    name: 'hard-to-be-a-god',
  },
  {
    note: '6',
    url: '/jeux/universe-at-war-earth-assault-3010003973/test.html',
    name: 'universe-at-war-earth-assault',
  },
  {
    note: '5',
    url: '/jeux/painkiller-overdose-84783/test.html',
    name: 'painkiller-overdose',
  },
  {
    note: '6',
    url:
      '/jeux/sam-max-season-2-episode-2-moai-better-blues-3010010531/test.html',
    name: 'sam-max-season-2-episode-2-moai-better-blues',
  },
  {
    note: '3',
    url: '/jeux/building-co-l-architecte-c-est-vous-83594/test.html',
    name: 'building-co-l-architecte-c-est-vous',
  },
  { note: '2', url: '/jeux/stranger-71946/test.html', name: 'stranger' },
  {
    note: '4',
    url: '/jeux/sudden-strike-3-arms-for-victory-71079/test.html',
    name: 'sudden-strike-3-arms-for-victory',
  },
  {
    note: '4',
    url: '/jeux/trolls-de-troy-la-cite-de-la-mort-rose-3010004688/test.html',
    name: 'trolls-de-troy-la-cite-de-la-mort-rose',
  },
  { note: '2', url: '/jeux/campus-84447/test.html', name: 'campus' },
  {
    note: '5',
    url: '/jeux/need-for-speed-prostreet-3010004031/test.html',
    name: 'need-for-speed-prostreet',
  },
  {
    note: '5',
    url: '/jeux/anno-1701-la-malediction-du-dragon-82024/test.html',
    name: 'anno-1701-la-malediction-du-dragon',
  },
  {
    note: '8',
    url: '/jeux/supreme-commander-forged-alliance-84564/test.html',
    name: 'supreme-commander-forged-alliance',
  },
  {
    note: '4',
    url: '/jeux/agatha-christie-meurtre-au-soleil-3010005774/test.html',
    name: 'agatha-christie-meurtre-au-soleil',
  },
  {
    note: '8',
    url: '/jeux/unreal-tournament-iii-3010002154/test-pc.html',
    name: 'unreal-tournament-iii',
  },
  {
    note: '8',
    url: '/jeux/call-of-duty-4-modern-warfare-3010003898/test-pc.html',
    name: 'call-of-duty-4-modern-warfare',
  },
  {
    note: '3',
    url: '/jeux/empire-earth-iii-82023/test.html',
    name: 'empire-earth-iii',
  },
  {
    note: '4',
    url: '/jeux/sim-city-societes-64547/test.html',
    name: 'sim-city-societes',
  },
  {
    note: '8',
    url: '/jeux/gears-of-war-3010001970/test-pc.html',
    name: 'gears-of-war',
  },
  {
    note: '8',
    url: '/jeux/crysis-3010003863/test-pc.html',
    name: 'crysis',
  },
  {
    note: '7',
    url: '/jeux/sam-max-season-beyond-time-and-space-ep-1-3010010406/test.html',
    name: 'sam-max-season-beyond-time-and-space-ep-1',
  },
  {
    note: '5',
    url: '/jeux/sherlock-holmes-contre-arsene-lupin-84157/test.html',
    name: 'sherlock-holmes-contre-arsene-lupin',
  },
  {
    note: '6',
    url: '/jeux/nostradamus-la-derniere-prophetie-3010006058/test.html',
    name: 'nostradamus-la-derniere-prophetie',
  },
  { note: '5', url: '/jeux/hellgate-74251/test.html', name: 'hellgate' },
  {
    note: '7',
    url: '/jeux/the-witcher-68443/test.html',
    name: 'the-witcher',
  },
  { note: '7', url: '/jeux/race-07-82129/test.html', name: 'race-07' },
  {
    note: '6',
    url: '/jeux/pro-evolution-soccer-2008-3010004094/test-pc.html',
    name: 'pro-evolution-soccer-2008',
  },
  {
    note: '6',
    url: '/jeux/escape-from-paradise-city-75733/test.html',
    name: 'escape-from-paradise-city',
  },
  {
    note: '7',
    url: '/jeux/age-of-empires-iii-the-asian-dynasties-3010005970/test.html',
    name: 'age-of-empires-iii-the-asian-dynasties',
  },
  {
    note: '6',
    url: '/jeux/jack-keane-3010005471/test.html',
    name: 'jack-keane',
  },
  {
    note: '4',
    url: '/jeux/l-ile-noyee-3010003042/test-pc.html',
    name: 'l-ile-noyee',
  },
  {
    note: '8',
    url: '/jeux/the-orange-box-3010002593/test-pc.html',
    name: 'the-orange-box',
  },
  {
    note: '7',
    url: '/jeux/half-life-2-episode-two-3010003657/test.html',
    name: 'half-life-2-episode-two',
  },
  {
    note: '7',
    url: '/jeux/heroes-of-might-and-magic-v-tribes-of-the-east-83137/test.html',
    name: 'heroes-of-might-and-magic-v-tribes-of-the-east',
  },
  {
    note: '5',
    url: '/jeux/portal-3010003658/test-pc.html',
    name: 'portal',
  },
  {
    note: '5',
    url: '/jeux/the-settlers-batisseurs-d-empire-79742/test.html',
    name: 'the-settlers-batisseurs-d-empire',
  },
  {
    note: '4',
    url: '/jeux/les-experts-morts-programmees-3010004229/test.html',
    name: 'les-experts-morts-programmees',
  },
  {
    note: '8',
    url: '/jeux/team-fortress-2-3010003655/test.html',
    name: 'team-fortress-2',
  },
  {
    note: '7',
    url: '/jeux/experience112-76530/test.html',
    name: 'experience112',
  },
  {
    note: '7',
    url: '/jeux/sam-max-saison-1-3010005190/test-pc.html',
    name: 'sam-max-saison-1',
  },
  {
    note: '7',
    url: '/jeux/company-of-heroes-opposing-fronts-82973/test.html',
    name: 'company-of-heroes-opposing-fronts',
  },
  {
    note: '4',
    url: '/jeux/star-family-85937/test.html',
    name: 'star-family',
  },
  {
    note: '6',
    url: '/jeux/enemy-territory-quake-wars-3010003568/test.html',
    name: 'enemy-territory-quake-wars',
  },
  {
    note: '6',
    url: '/jeux/fantasy-wars-81075/test.html',
    name: 'fantasy-wars',
  },
  {
    note: '5',
    url: '/jeux/death-to-spies-81078/test.html',
    name: 'death-to-spies',
  },
  {
    note: '7',
    url: '/jeux/world-in-conflict-77062/test.html',
    name: 'world-in-conflict',
  },
  {
    note: '5',
    url: '/jeux/nhl-08-3010004264/test-pc.html',
    name: 'nhl-08',
  },
  {
    note: '4',
    url: '/jeux/dawnspire-81547/test.html',
    name: 'dawnspire',
  },
  {
    note: '5',
    url: '/jeux/reprobates-80978/test.html',
    name: 'reprobates',
  },
  {
    note: '6',
    url: '/jeux/medal-of-honor-airborne-3010002176/test-pc.html',
    name: 'medal-of-honor-airborne',
  },
  {
    note: '4',
    url:
      '/jeux/les-enquetes-de-nancy-drew-la-creature-de-kapu-cave-79652/test.html',
    name: 'les-enquetes-de-nancy-drew-la-creature-de-kapu-cave',
  },
  {
    note: '6',
    url: '/jeux/les-sims-2-bon-voyage-3010005454/test-pc.html',
    name: 'les-sims-2-bon-voyage',
  },
  {
    note: '6',
    url: '/jeux/medieval-ii-total-war-kingdoms-82918/test.html',
    name: 'medieval-ii-total-war-kingdoms',
  },
  {
    note: '4',
    url: '/jeux/obscure-ii-3010001961/test.html',
    name: 'obscure-ii',
  },
  {
    note: '9',
    url: '/jeux/bioshock-3010002399/test.html',
    name: 'bioshock-remastered',
  },
  {
    note: '5',
    url: '/jeux/combat-mission-shock-force-81955/test.html',
    name: 'combat-mission-shock-force',
  },
  {
    note: '7',
    url:
      '/jeux/tom-clancy-s-ghost-recon-advanced-warfighter-2-3010003084/test.html',
    name: 'tom-clancy-s-ghost-recon-advanced-warfighter-2',
  },
  {
    note: '5',
    url: '/jeux/driver-parallel-lines-3010001945/test.html',
    name: 'driver-parallel-lines',
  },
  {
    note: '4',
    url: '/jeux/les-sims-histoires-d-animaux-3010005452/test.html',
    name: 'les-sims-histoires-d-animaux',
  },
  {
    note: '6',
    url: '/jeux/lost-planet-3010003855/test-pc.html',
    name: 'lost-planet',
  },
  {
    note: '5',
    url: '/jeux/cleopatre-le-destin-d-une-reine-3010006380/test.html',
    name: 'cleopatre-le-destin-d-une-reine',
  },
  {
    note: '7',
    url: '/jeux/overlord-3010002353/test.html',
    name: 'overlord',
  },
  {
    note: '2',
    url: '/jeux/fast-food-empire-84050/test.html',
    name: 'fast-food-empire',
  },
  {
    note: '7',
    url: '/jeux/colin-mcrae-dirt-3010002362/test-pc.html',
    name: 'colin-mcrae-dirt',
  },
  {
    note: '6',
    url: '/jeux/penumbra-overture-episode-one-3010008548/test-pc.html',
    name: 'penumbra-overture-episode-one',
  },
  { note: '5', url: '/jeux/loki-75563/test.html', name: 'loki' },
  {
    note: '4',
    url: '/jeux/halo-2-3010002224/test-pc.html',
    name: 'halo-2',
  },
  {
    note: '3',
    url: '/jeux/shadowrun-3010002384/test-pc.html',
    name: 'shadowrun',
  },
  {
    note: '5',
    url: '/jeux/the-guild-2-pirates-of-the-european-seas-81602/test.html',
    name: 'the-guild-2-pirates-of-the-european-seas',
  },
  {
    note: '6',
    url: '/jeux/hospital-tycoon-78923/test.html',
    name: 'hospital-tycoon',
  },
  {
    note: '8',
    url: '/jeux/tomb-raider-anniversary-3010002452/test-pc.html',
    name: 'tomb-raider-anniversary',
  },
  {
    note: '5',
    url: '/jeux/marvel-trading-card-game-3010002385/test-pc.html',
    name: 'marvel-trading-card-game',
  },
  { note: '3', url: '/jeux/tv-giant-81042/test.html', name: 'tv-giant' },
  {
    note: '5',
    url: '/jeux/space-empires-v-77087/test.html',
    name: 'space-empires-v',
  },
  {
    note: '6',
    url: '/jeux/two-worlds-3010003015/test.html',
    name: 'two-worlds',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-1-episode-6-bright-side-of-the-moon-79072/test.html',
    name: 'sam-max-season-1-episode-6-bright-side-of-the-moon',
  },
  {
    note: '1',
    url: '/jeux/eva-cash-projet-d-i-r-t-81953/test.html',
    name: 'eva-cash-projet-d-i-r-t',
  },
  {
    note: '4',
    url: '/jeux/attack-on-pearl-harbor-78648/test.html',
    name: 'attack-on-pearl-harbor',
  },
  {
    note: '5',
    url: '/jeux/la-malediction-de-judas-78927/test.html',
    name: 'la-malediction-de-judas',
  },
  {
    note: '3',
    url: '/jeux/genesis-rising-l-ere-de-la-genetique-77241/test.html',
    name: 'genesis-rising-l-ere-de-la-genetique',
  },
  {
    note: '7',
    url: '/jeux/sam-max-season-1-episode-5-reality-2-0-79070/test.html',
    name: 'sam-max-season-1-episode-5-reality-2-0',
  },
  {
    note: '3',
    url: '/jeux/sparta-ancient-wars-74311/test.html',
    name: 'sparta-ancient-wars',
  },
  {
    note: '7',
    url: '/jeux/32nd-america-s-cup-le-jeu-81930/test.html',
    name: '32nd-america-s-cup-le-jeu',
  },
  {
    note: '4',
    url: '/jeux/frontline-fields-of-thunder-79335/test.html',
    name: 'frontline-fields-of-thunder',
  },
  {
    note: '4',
    url: '/jeux/aura-2-les-anneaux-sacres-74901/test.html',
    name: 'aura-2-les-anneaux-sacres',
  },
  {
    note: '7',
    url: '/jeux/silent-hunter-4-wolves-of-the-pacific-73989/test.html',
    name: 'silent-hunter-4-wolves-of-the-pacific',
  },
  {
    note: '6',
    url: '/jeux/ufo-afterlight-76327/test.html',
    name: 'ufo-afterlight',
  },
  {
    note: '5',
    url: '/jeux/war-front-turning-point-74606/test.html',
    name: 'war-front-turning-point',
  },
  {
    note: '7',
    url: '/jeux/command-conquer-3-les-guerres-du-tiberium-3010003243/test.html',
    name: 'command-conquer-3-les-guerres-du-tiberium',
  },
  {
    note: '6',
    url: '/jeux/uefa-champions-league-2006-2007-3010003387/test-pc.html',
    name: 'uefa-champions-league-2006-2007',
  },
  {
    note: '6',
    url: '/jeux/spellforce-2-dragon-storm-78391/test.html',
    name: 'spellforce-2-dragon-storm',
  },
  {
    note: '7',
    url:
      '/jeux/the-elder-scrolls-iv-oblivion-the-shivering-isles-3010003398/test.html',
    name: 'the-elder-scrolls-iv-oblivion-the-shivering-isles',
  },
  {
    note: '6',
    url: '/jeux/test-drive-unlimited-3010002138/test-pc.html',
    name: 'test-drive-unlimited',
  },
  {
    note: '7',
    url: '/jeux/virtua-tennis-3-3010002338/test-pc.html',
    name: 'virtua-tennis-3',
  },
  {
    note: '2',
    url: '/jeux/mission-president-geopolitical-simulator-77491/test.html',
    name: 'mission-president-geopolitical-simulator',
  },
  {
    note: '6',
    url: '/jeux/nancy-drew-dernier-train-pour-bluemoon-canyon-79651/test.html',
    name: 'nancy-drew-dernier-train-pour-bluemoon-canyon',
  },
  {
    note: '7',
    url: '/jeux/galactic-civilizations-ii-dark-avatar-77889/test.html',
    name: 'galactic-civilizations-ii-dark-avatar',
  },
  {
    note: '8',
    url: '/jeux/s-t-a-l-k-e-r-shadow-of-chernobyl-14268/test.html',
    name: 's-t-a-l-k-e-r-shadow-of-chernobyl',
  },
  {
    note: '3',
    url: '/jeux/tortuga-two-treasures-72006/test.html',
    name: 'tortuga-two-treasures',
  },
  {
    note: '6',
    url: '/jeux/titan-quest-immortal-throne-80242/test.html',
    name: 'titan-quest-immortal-throne',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-1-episode-4-abe-lincoln-must-die-79069/test.html',
    name: 'sam-max-season-1-episode-4-abe-lincoln-must-die',
  },
  {
    note: '5',
    url: '/jeux/infernal-hell-s-vengeance-3010002404/test.html',
    name: 'infernal-hell-s-vengeance',
  },
  {
    note: '6',
    url: '/jeux/resident-evil-4-3010000180/test-pc.html',
    name: 'resident-evil-4',
  },
  {
    note: '7',
    url: '/jeux/les-sims-2-au-fil-des-saisons-3010004305/test-pc.html',
    name: 'les-sims-2-au-fil-des-saisons',
  },
  {
    note: '7',
    url: '/jeux/jade-empire-3010002375/test-pc.html',
    name: 'jade-empire',
  },
  {
    note: '4',
    url: '/jeux/freak-out-extreme-freeride-3010003239/test.html',
    name: 'freak-out-extreme-freeride',
  },
  {
    note: '5',
    url: '/jeux/les-sims-histoires-de-vie-3010005428/test.html',
    name: 'les-sims-histoires-de-vie',
  },
  {
    note: '6',
    url: '/jeux/arma-armed-assault-75033/test.html',
    name: 'arma-armed-assault',
  },
  {
    note: '8',
    url: '/jeux/supreme-commander-3010004532/test-pc.html',
    name: 'supreme-commander',
  },
  {
    note: '6',
    url:
      '/jeux/sam-max-season-1-episode-3-the-mole-the-mob-and-the-meatball-79068/test.html',
    name: 'sam-max-season-1-episode-3-the-mole-the-mob-and-the-meatball',
  },
  {
    note: '7',
    url: '/jeux/europa-universalis-iii-3010009378/test-pc.html',
    name: 'europa-universalis-iii',
  },
  {
    note: '4',
    url:
      '/jeux/berlin-1943-les-secrets-de-l-operation-wintersun-77018/test.html',
    name: 'berlin-1943-les-secrets-de-l-operation-wintersun',
  },
  {
    note: '3',
    url: '/jeux/maelstrom-76123/test.html',
    name: 'maelstrom',
  },
  {
    note: '6',
    url:
      '/jeux/another-world-edition-speciale-15eme-anniversaire-81035/test.html',
    name: 'another-world-edition-speciale-15eme-anniversaire',
  },
  {
    note: '6',
    url: '/jeux/sam-max-season-1-episode-2-situation-comedy-79067/test.html',
    name: 'sam-max-season-1-episode-2-situation-comedy',
  },
  {
    note: '1',
    url: '/jeux/beer-tycoon-79546/test.html',
    name: 'beer-tycoon',
  },
  {
    note: '7',
    url: '/jeux/tom-clancy-s-rainbow-six-vegas-3010002257/test-pc.html',
    name: 'tom-clancy-s-rainbow-six-vegas',
  },
  {
    note: '3',
    url: '/jeux/made-man-3010001884/test-pc.html',
    name: 'made-man',
  },
  {
    note: '5',
    url: '/jeux/agatha-christie-le-crime-de-l-orient-express-77240/test.html',
    name: 'agatha-christie-le-crime-de-l-orient-express',
  },
  {
    note: '6',
    url: '/jeux/sherlock-holmes-la-nuit-des-sacrifies-75934/test.html',
    name: 'sherlock-holmes-la-nuit-des-sacrifies',
  },
  {
    note: '6',
    url: '/jeux/the-elder-scrolls-iv-knights-of-the-nine-3010003052/test.html',
    name: 'the-elder-scrolls-iv-knights-of-the-nine',
  },
  {
    note: '6',
    url: '/jeux/race-the-official-wtcc-game-3010002295/test.html',
    name: 'race-the-official-wtcc-game',
  },
  {
    note: '6',
    url: '/jeux/warhammer-mark-of-chaos-75002/test.html',
    name: 'warhammer-mark-of-chaos',
  },
  {
    note: '5',
    url: '/jeux/cap-sur-l-ile-au-tresor-3010006378/test-pc.html',
    name: 'cap-sur-l-ile-au-tresor',
  },
  {
    note: '6',
    url: '/jeux/secret-files-tunguska-3010003900/test-pc.html',
    name: 'secret-files-tunguska',
  },
  {
    note: '6',
    url: '/jeux/arthur-et-les-minimoys-3010002151/test.html',
    name: 'arthur-et-les-minimoys',
  },
  {
    note: '6',
    url: '/jeux/silverfall-3010003871/test-pc.html',
    name: 'silverfall',
  },
  {
    note: '6',
    url: '/jeux/top-spin-2-3010001935/test-pc.html',
    name: 'top-spin-2',
  },
  {
    note: '6',
    url: '/jeux/ankh-2-le-coeur-d-osiris-77584/test.html',
    name: 'ankh-2-le-coeur-d-osiris',
  },
  {
    note: '7',
    url: '/jeux/heroes-of-might-and-magic-v-hammers-of-fate-78996/test.html',
    name: 'heroes-of-might-and-magic-v-hammers-of-fate',
  },
  {
    note: '7',
    url: '/jeux/anno-1701-3010003425/test.html',
    name: 'anno-1701',
  },
  {
    note: '6',
    url: '/jeux/trackmania-united-78183/test.html',
    name: 'trackmania-united',
  },
  {
    note: '7',
    url: '/jeux/runaway-the-dream-of-the-turtle-3010003872/test.html',
    name: 'runaway-the-dream-of-the-turtle',
  },
  {
    note: '8',
    url: '/jeux/medieval-ii-total-war-76591/test.html',
    name: 'medieval-ii-total-war',
  },
  {
    note: '5',
    url: '/jeux/horse-racing-manager-2-78248/test.html',
    name: 'horse-racing-manager-2',
  },
  {
    note: '3',
    url: '/jeux/camping-tycoon-79348/test.html',
    name: 'camping-tycoon',
  },
  {
    note: '5',
    url: '/jeux/the-secrets-of-atlantis-l-heritage-sacre-77888/test.html',
    name: 'the-secrets-of-atlantis-l-heritage-sacre',
  },
  {
    note: '4',
    url: '/jeux/tony-tough-2-detective-prive-74709/test.html',
    name: 'tony-tough-2-detective-prive',
  },
  {
    note: '7',
    url: '/jeux/need-for-speed-carbon-3010002466/test-pc.html',
    name: 'need-for-speed-carbon',
  },
  {
    note: '6',
    url: '/jeux/the-guild-2-65990/test.html',
    name: 'the-guild-2',
  },
  {
    note: '5',
    url: '/jeux/barrow-hill-72529/test.html',
    name: 'barrow-hill',
  },
  {
    note: '6',
    url: '/jeux/f-e-a-r-extraction-point-77557/test.html',
    name: 'f-e-a-r-extraction-point',
  },
  {
    note: '7',
    url: '/jeux/sam-max-season-1-episode-1-culture-shock-77750/test.html',
    name: 'sam-max-season-1-episode-1-culture-shock',
  },
  {
    note: '4',
    url: '/jeux/les-dents-de-la-mer-3010001480/test-pc.html',
    name: 'les-dents-de-la-mer',
  },
  {
    note: '7',
    url: '/jeux/neverwinter-nights-2-3010004592/test-pc.html',
    name: 'neverwinter-nights-2',
  },
  {
    note: '5',
    url:
      '/jeux/les-enquetes-de-nancy-drew-danger-au-coeur-de-la-mode-78968/test.html',
    name: 'les-enquetes-de-nancy-drew-danger-au-coeur-de-la-mode',
  },
  {
    note: '6',
    url: '/jeux/railroads-76984/test.html',
    name: 'railroads',
  },
  {
    note: '8',
    url: '/jeux/pro-evolution-soccer-6-3010002347/test-pc.html',
    name: 'pro-evolution-soccer-6',
  },
  {
    note: '7',
    url: '/jeux/football-manager-2007-3010002430/test-pc.html',
    name: 'football-manager-2007',
  },
  {
    note: '7',
    url: '/jeux/dark-messiah-of-might-magic-3010003524/test.html',
    name: 'dark-messiah-of-might-magic',
  },
  {
    note: '7',
    url: '/jeux/warhammer-40-000-dawn-of-war-dark-crusade-76712/test.html',
    name: 'warhammer-40-000-dawn-of-war-dark-crusade',
  },
  {
    note: '7',
    url: '/jeux/battlefield-2142-3010005155/test-pc.html',
    name: 'battlefield-2142',
  },
  {
    note: '6',
    url: '/jeux/caesar-iv-75689/test.html',
    name: 'caesar-iv',
  },
  {
    note: '7',
    url: '/jeux/age-of-empires-iii-the-warchiefs-3010005473/test.html',
    name: 'age-of-empires-iii-the-warchiefs',
  },
  {
    note: '5',
    url: '/jeux/les-sims-2-animaux-cie-3010002645/test.html',
    name: 'les-sims-2-animaux-cie',
  },
  {
    note: '6',
    url: '/jeux/pacific-storm-74571/test.html',
    name: 'pacific-storm',
  },
  {
    note: '6',
    url: '/jeux/tiger-woods-pga-tour-07-3010002590/test-pc.html',
    name: 'tiger-woods-pga-tour-07',
  },
  {
    note: '4',
    url: '/jeux/stronghold-legends-76828/test.html',
    name: 'stronghold-legends',
  },
  { note: '7', url: '/jeux/gothic-3-72293/test.html', name: 'gothic-3' },
  {
    note: '4',
    url: '/jeux/nba-live-07-3010002429/test-pc.html',
    name: 'nba-live-07',
  },
  { note: '6', url: '/jeux/nhl-07-3010002464/test.html', name: 'nhl-07' },
  {
    note: '7',
    url: '/jeux/defcon-3010006565/test-pc.html',
    name: 'defcon',
  },
  {
    note: '5',
    url: '/jeux/take-command-second-manassas-77775/test.html',
    name: 'take-command-second-manassas',
  },
  {
    note: '5',
    url: '/jeux/the-settlers-ii-10th-anniversary-77184/test.html',
    name: 'the-settlers-ii-10th-anniversary',
  },
  {
    note: '5',
    url: '/jeux/heroes-of-annihilated-empires-70197/test.html',
    name: 'heroes-of-annihilated-empires',
  },
  {
    note: '6',
    url: '/jeux/strategic-command-2-76356/test.html',
    name: 'strategic-command-2',
  },
  {
    note: '5',
    url: '/jeux/f-c-manager-2007-3010002408/test.html',
    name: 'f-c-manager-2007',
  },
  {
    note: '7',
    url: '/jeux/fifa-07-3010002498/test-pc.html',
    name: 'fifa-07',
  },
  { note: '8', url: '/jeux/gtr-2-76039/test.html', name: 'gtr-2' },
  {
    note: '6',
    url: '/jeux/sword-of-the-stars-77580/test.html',
    name: 'sword-of-the-stars',
  },
  {
    note: '6',
    url: '/jeux/joint-task-force-74554/test.html',
    name: 'joint-task-force',
  },
  {
    note: '3',
    url: '/jeux/bad-day-l-a-3010001889/test.html',
    name: 'bad-day-l-a',
  },
  {
    note: '5',
    url: '/jeux/el-matador-68333/test.html',
    name: 'el-matador',
  },
  {
    note: '7',
    url: '/jeux/in-memoriam-le-dernier-rituel-77811/test.html',
    name: 'in-memoriam-le-dernier-rituel',
  },
  {
    note: '8',
    url: '/jeux/company-of-heroes-3010010567/test-pc.html',
    name: 'company-of-heroes',
  },
  {
    note: '2',
    url: '/jeux/victi-blood-bitterness-77406/test.html',
    name: 'victi-blood-bitterness',
  },
  {
    note: '5',
    url: '/jeux/faces-of-war-76055/test.html',
    name: 'faces-of-war',
  },
  {
    note: '4',
    url: '/jeux/new-york-section-criminelle-76065/test.html',
    name: 'new-york-section-criminelle',
  },
  {
    note: '7',
    url: '/jeux/paraworld-74623/test.html',
    name: 'paraworld',
  },
  {
    note: '6',
    url:
      '/jeux/les-chevaliers-de-baphomet-les-gardiens-du-temple-de-salomon-75672/test.html',
    name: 'les-chevaliers-de-baphomet-les-gardiens-du-temple-de-salomon',
  },
  {
    note: '6',
    url: '/jeux/call-of-juarez-3010001848/test-pc.html',
    name: 'call-of-juarez',
  },
  {
    note: '7',
    url: '/jeux/darwinia-3010009753/test-pc.html',
    name: 'darwinia',
  },
  {
    note: '3',
    url: '/jeux/rag-doll-kung-fu-3010005651/test.html',
    name: 'rag-doll-kung-fu',
  },
  {
    note: '5',
    url: '/jeux/dungeon-siege-ii-broken-world-76381/test.html',
    name: 'dungeon-siege-ii-broken-world',
  },
  {
    note: '6',
    url: '/jeux/darkstar-one-3010008077/test.html',
    name: 'darkstar-one',
  },
  {
    note: '6',
    url: '/jeux/civilization-iv-warlords-3010006121/test.html',
    name: 'civilization-iv-warlords',
  },
  {
    note: '5',
    url: '/jeux/civcity-rome-76982/test.html',
    name: 'civcity-rome',
  },
  {
    note: '5',
    url: '/jeux/the-ship-murder-party-77358/test.html',
    name: 'the-ship-murder-party',
  },
  { note: '7', url: '/jeux/prey-3010001949/test.html', name: 'prey' },
  {
    note: '6',
    url: '/jeux/flatout-2-3010002020/test-pc.html',
    name: 'flatout-2',
  },
  {
    note: '6',
    url: '/jeux/scratches-dans-l-antre-de-la-peur-75235/test.html',
    name: 'scratches-dans-l-antre-de-la-peur',
  },
  {
    note: '5',
    url: '/jeux/cossacks-ii-battle-for-europe-76359/test.html',
    name: 'cossacks-ii-battle-for-europe',
  },
  {
    note: '5',
    url: '/jeux/glory-of-the-roman-empire-76651/test.html',
    name: 'glory-of-the-roman-empire',
  },
  {
    note: '7',
    url: '/jeux/pro-cycling-manager-saison-2006-77801/test.html',
    name: 'pro-cycling-manager-saison-2006',
  },
  {
    note: '7',
    url: '/jeux/titan-quest-3050669905/test.html',
    name: 'titan-quest',
  },
  {
    note: '7',
    url: '/jeux/red-orchestra-ostfront-41-45-76224/test.html',
    name: 'red-orchestra-ostfront-41-45',
  },
  {
    note: '5',
    url: '/jeux/rise-fall-civilizations-at-war-73568/test.html',
    name: 'rise-fall-civilizations-at-war',
  },
  {
    note: '6',
    url: '/jeux/full-spectrum-warrior-ten-hammers-3010001898/test-pc.html',
    name: 'full-spectrum-warrior-ten-hammers',
  },
  {
    note: '6',
    url: '/jeux/safari-photo-en-afrique-3010004730/test.html',
    name: 'safari-photo-en-afrique',
  },
  {
    note: '5',
    url: '/jeux/les-experts-las-vegas-crimes-en-serie-3010003420/test.html',
    name: 'les-experts-las-vegas-crimes-en-serie',
  },
  {
    note: '4',
    url: '/jeux/sensible-soccer-2006-3010002104/test-pc.html',
    name: 'sensible-soccer-2006',
  },
  {
    note: '2',
    url: '/jeux/will-of-steel-70255/test.html',
    name: 'will-of-steel',
  },
  {
    note: '4',
    url: '/jeux/namco-museum-50th-anniversary-3010001895/test-pc.html',
    name: 'namco-museum-50th-anniversary',
  },
  {
    note: '5',
    url: '/jeux/rush-for-berlin-75027/test.html',
    name: 'rush-for-berlin',
  },
  {
    note: '7',
    url: '/jeux/half-life-2-episode-one-3010003656/test.html',
    name: 'half-life-2-episode-one',
  },
  {
    note: '4',
    url: '/jeux/coupe-du-monde-de-la-fifa-2006-3010002222/test.html',
    name: 'coupe-du-monde-de-la-fifa-2006',
  },
  {
    note: '7',
    url: '/jeux/bone-the-great-cow-race-76968/test.html',
    name: 'bone-the-great-cow-race',
  },
  {
    note: '6',
    url: '/jeux/western-commandos-la-revanche-de-cooper-57094/test.html',
    name: 'western-commandos-la-revanche-de-cooper',
  },
  {
    note: '6',
    url:
      '/jeux/the-secrets-of-da-vinci-le-manuscrit-interdit-3010007217/test-pc.html',
    name: 'the-secrets-of-da-vinci-le-manuscrit-interdit',
  },
  {
    note: '8',
    url: '/jeux/hitman-blood-money-3010001314/test-pc.html',
    name: 'hitman-blood-money',
  },
  {
    note: '7',
    url: '/jeux/rise-of-legends-74768/test.html',
    name: 'rise-of-legends',
  },
  {
    note: '6',
    url:
      '/jeux/tom-clancy-s-ghost-recon-advanced-warfighter-3010001845/test.html',
    name: 'tom-clancy-s-ghost-recon-advanced-warfighter',
  },
  {
    note: '8',
    url: '/jeux/heroes-of-might-and-magic-v-3010005156/test-pc.html',
    name: 'heroes-of-might-and-magic-v',
  },
  {
    note: '7',
    url: '/jeux/dreamfall-3010001420/test-pc.html',
    name: 'dreamfall',
  },
  {
    note: '4',
    url: '/jeux/sin-episodes-emergence-4431/test.html',
    name: 'sin-episodes-emergence',
  },
  {
    note: '5',
    url: '/jeux/paradise-3010001729/test.html',
    name: 'paradise',
  },
  {
    note: '5',
    url:
      '/jeux/les-enquetes-de-nancy-drew-le-mystere-de-l-horloge-76860/test.html',
    name: 'les-enquetes-de-nancy-drew-le-mystere-de-l-horloge',
  },
  {
    note: '3',
    url: '/jeux/panzer-elite-action-3010001880/test.html',
    name: 'panzer-elite-action',
  },
  {
    note: '6',
    url: '/jeux/rogue-trooper-3010001497/test-pc.html',
    name: 'rogue-trooper',
  },
  {
    note: '6',
    url: '/jeux/city-life-ds-3010004938/test.html',
    name: 'city-life-ds',
  },
  {
    note: '7',
    url: '/jeux/condemned-criminal-origins-3010001811/test-pc.html',
    name: 'condemned-criminal-origins',
  },
  {
    note: '7',
    url: '/jeux/spellforce-2-shadow-wars-65986/test.html',
    name: 'spellforce-2-shadow-wars',
  },
  {
    note: '4',
    url: '/jeux/fire-department-episode-3-76360/test.html',
    name: 'fire-department-episode-3',
  },
  {
    note: '7',
    url: '/jeux/les-sims-2-la-bonne-affaire-3010005458/test-pc.html',
    name: 'les-sims-2-la-bonne-affaire',
  },
  {
    note: '7',
    url: '/jeux/tomb-raider-legend-3010001860/test-pc.html',
    name: 'tomb-raider-legend',
  },
  {
    note: '4',
    url: '/jeux/war-world-3010003258/test.html',
    name: 'war-world',
  },
  {
    note: '4',
    url: '/jeux/gti-racing-75229/test.html',
    name: 'gti-racing',
  },
  {
    note: '3',
    url: '/jeux/l-entraineur-2006-3010002223/test-pc.html',
    name: 'l-entraineur-2006',
  },
  {
    note: '6',
    url: '/jeux/mx-vs-atv-unleashed-3010001801/test-pc.html',
    name: 'mx-vs-atv-unleashed',
  },
  {
    note: '6',
    url: '/jeux/billy-hatcher-and-the-giant-egg-3010002221/test-pc.html',
    name: 'billy-hatcher-and-the-giant-egg',
  },
  {
    note: '6',
    url: '/jeux/sonic-mega-collection-plus-3010001353/test-pc.html',
    name: 'sonic-mega-collection-plus',
  },
  {
    note: '4',
    url: '/jeux/le-parrain-3010001640/test.html',
    name: 'le-parrain',
  },
  {
    note: '9',
    url: '/jeux/the-elder-scrolls-iv-oblivion-3010001513/test-pc.html',
    name: 'the-elder-scrolls-iv-oblivion',
  },
  {
    note: '4',
    url: '/jeux/commandos-strike-force-3010001407/test-pc.html',
    name: 'commandos-strike-force',
  },
  {
    note: '5',
    url:
      '/jeux/call-of-cthulhu-dark-corners-of-the-earth-3010000888/test-pc.html',
    name: 'call-of-cthulhu-dark-corners-of-the-earth',
  },
  {
    note: '6',
    url: '/jeux/s-w-a-t-4-the-stetchkov-syndicate-75750/test.html',
    name: 's-w-a-t-4-the-stetchkov-syndicate',
  },
  {
    note: '8',
    url: '/jeux/galactic-civilizations-ii-76405/test.html',
    name: 'galactic-civilizations-ii',
  },
  {
    note: '6',
    url:
      '/jeux/le-seigneur-des-anneaux-la-bataille-pour-la-terre-du-milieu-ii-3010002172/test.html',
    name: 'le-seigneur-des-anneaux-la-bataille-pour-la-terre-du-milieu-ii',
  },
  {
    note: '4',
    url: '/jeux/crashday-3010001180/test.html',
    name: 'crashday',
  },
  {
    note: '7',
    url: '/jeux/rugby-06-3010002136/test-pc.html',
    name: 'rugby-06',
  },
  {
    note: '5',
    url: '/jeux/tycoon-city-new-york-73481/test.html',
    name: 'tycoon-city-new-york',
  },
  {
    note: '7',
    url: '/jeux/toca-race-driver-3-3010001840/test.html',
    name: 'toca-race-driver-3',
  },
  {
    note: '5',
    url: '/jeux/tom-clancy-s-rainbow-six-lockdown-3010001632/test-pc.html',
    name: 'tom-clancy-s-rainbow-six-lockdown',
  },
  {
    note: '5',
    url: '/jeux/star-wars-empire-at-war-71767/test.html',
    name: 'star-wars-empire-at-war',
  },
  {
    note: '3',
    url: '/jeux/keepsake-aventures-a-dragonvale-72530/test.html',
    name: 'keepsake-aventures-a-dragonvale',
  },
  {
    note: '7',
    url: '/jeux/x3-reunion-3010001992/test-pc.html',
    name: 'x3-reunion',
  },
  {
    note: '4',
    url: '/jeux/the-regiment-3010001482/test-pc.html',
    name: 'the-regiment',
  },
  {
    note: '5',
    url: '/jeux/mad-tracks-3010002913/test.html',
    name: 'mad-tracks',
  },
  {
    note: '5',
    url: '/jeux/advent-rising-3010001029/test.html',
    name: 'advent-rising',
  },
  {
    note: '3',
    url: '/jeux/winter-challenge-76433/test.html',
    name: 'winter-challenge',
  },
  {
    note: '5',
    url: '/jeux/shadowgrounds-3010003381/test.html',
    name: 'shadowgrounds',
  },
  {
    note: '3',
    url: '/jeux/torino-2006-3010002128/test.html',
    name: 'torino-2006',
  },
  {
    note: '7',
    url: '/jeux/psychonauts-3010001554/test-pc.html',
    name: 'psychonauts',
  },
  {
    note: '5',
    url: '/jeux/bloodrayne-2-3010001178/test.html',
    name: 'bloodrayne-2',
  },
  { note: '7', url: '/jeux/ankh-3010004509/test-pc.html', name: 'ankh' },
  { note: '2', url: '/jeux/alpha-76031/test.html', name: 'alpha' },
  {
    note: '5',
    url: '/jeux/astronoid-76379/test.html',
    name: 'astronoid',
  },
  {
    note: '4',
    url: '/jeux/knights-of-the-temple-ii-3010001905/test.html',
    name: 'knights-of-the-temple-ii',
  },
  {
    note: '2',
    url: '/jeux/gene-troopers-3010001972/test.html',
    name: 'gene-troopers',
  },
  {
    note: '1',
    url: '/jeux/crazy-frog-racer-3010002155/test-pc.html',
    name: 'crazy-frog-racer',
  },
  {
    note: '3',
    url:
      '/jeux/alexandra-ledermann-6-l-ecole-des-champions-3010004500/test.html',
    name: 'alexandra-ledermann-6-l-ecole-des-champions',
  },
  { note: '6', url: '/jeux/sudoku-76362/test.html', name: 'sudoku' },
  {
    note: '5',
    url: '/jeux/sudoku-infini-75985/test.html',
    name: 'sudoku-infini',
  },
  {
    note: '3',
    url: '/jeux/diplomacy-75558/test.html',
    name: 'diplomacy',
  },
  {
    note: '5',
    url: '/jeux/agatha-christie-dix-petits-negres-3010003762/test.html',
    name: 'agatha-christie-dix-petits-negres',
  },
  {
    note: '7',
    url: '/jeux/prince-of-persia-les-deux-royaumes-3010001842/test.html',
    name: 'prince-of-persia-les-deux-royaumes',
  },
  {
    note: '6',
    url: '/jeux/battlefield-2-forces-speciales-75461/test.html',
    name: 'battlefield-2-forces-speciales',
  },
  {
    note: '6',
    url: '/jeux/ufo-aftershock-68332/test.html',
    name: 'ufo-aftershock',
  },
  {
    note: '6',
    url: '/jeux/ski-racing-2006-3010001881/test.html',
    name: 'ski-racing-2006',
  },
  {
    note: '5',
    url:
      '/jeux/les-indestructibles-la-terrible-attaque-du-demolisseur-3010001792/test.html',
    name: 'les-indestructibles-la-terrible-attaque-du-demolisseur',
  },
  {
    note: '6',
    url: '/jeux/need-for-speed-most-wanted-3010001871/test.html',
    name: 'need-for-speed-most-wanted',
  },
  {
    note: '4',
    url: '/jeux/world-racing-2-3010001219/test.html',
    name: 'world-racing-2',
  },
  {
    note: '7',
    url: '/jeux/trackmania-sunrise-extreme-75136/test.html',
    name: 'trackmania-sunrise-extreme',
  },
  {
    note: '6',
    url: '/jeux/harry-potter-et-la-coupe-de-feu-3010001874/test.html',
    name: 'harry-potter-et-la-coupe-de-feu',
  },
  {
    note: '6',
    url: '/jeux/king-kong-3010001324/test-pc.html',
    name: 'king-kong',
  },
  {
    note: '7',
    url: '/jeux/virtual-skipper-4-75176/test.html',
    name: 'virtual-skipper-4',
  },
  {
    note: '7',
    url: '/jeux/the-movies-3010000968/test-pc.html',
    name: 'the-movies',
  },
  {
    note: '6',
    url:
      '/jeux/les-enquetes-de-nancy-drew-la-malediction-du-manoir-de-blackmoor-75216/test.html',
    name: 'les-enquetes-de-nancy-drew-la-malediction-du-manoir-de-blackmoor',
  },
  {
    note: '8',
    url: '/jeux/civilization-iv-3010006120/test-pc.html',
    name: 'civilization-iv',
  },
  {
    note: '7',
    url: '/jeux/dragonshard-69936/test.html',
    name: 'dragonshard',
  },
  {
    note: '2',
    url: '/jeux/starship-troopers-3010001303/test.html',
    name: 'starship-troopers',
  },
  {
    note: '7',
    url: '/jeux/call-of-duty-2-3010001925/test.html',
    name: 'call-of-duty-2',
  },
  { note: '4', url: '/jeux/80-jours-74604/test.html', name: '80-jours' },
  {
    note: '7',
    url: '/jeux/football-manager-2006-3010001908/test.html',
    name: 'football-manager-2006',
  },
  {
    note: '7',
    url: '/jeux/age-of-empires-iii-3010004304/test-pc.html',
    name: 'age-of-empires-iii',
  },
  {
    note: '5',
    url: '/jeux/shattered-union-3010001903/test.html',
    name: 'shattered-union',
  },
  {
    note: '7',
    url: '/jeux/vietcong-2-64821/test.html',
    name: 'vietcong-2',
  },
  {
    note: '6',
    url: '/jeux/quake-4-3010001870/test.html',
    name: 'quake-4',
  },
  {
    note: '4',
    url: '/jeux/cold-war-3010001803/test.html',
    name: 'cold-war',
  },
  {
    note: '8',
    url: '/jeux/pro-evolution-soccer-5-3010001567/test-pc.html',
    name: 'pro-evolution-soccer-5',
  },
  {
    note: '6',
    url: '/jeux/lfp-manager-06-75751/test.html',
    name: 'lfp-manager-06',
  },
  {
    note: '8',
    url: '/jeux/f-e-a-r-3010002311/test.html',
    name: 'f-e-a-r',
  },
  {
    note: '3',
    url: '/jeux/neuro-hunter-76060/test.html',
    name: 'neuro-hunter',
  },
  {
    note: '6',
    url: '/jeux/trackmania-original-70659/test.html',
    name: 'trackmania-original',
  },
  {
    note: '6',
    url: '/jeux/black-white-2-3010000490/test.html',
    name: 'black-white-2',
  },
  {
    note: '6',
    url: '/jeux/brothers-in-arms-earned-in-blood-3010001847/test-pc.html',
    name: 'brothers-in-arms-earned-in-blood',
  },
  {
    note: '8',
    url: '/jeux/gt-legends-74646/test.html',
    name: 'gt-legends',
  },
  {
    note: '5',
    url: '/jeux/voyage-au-coeur-de-la-lune-3010010129/test.html',
    name: 'voyage-au-coeur-de-la-lune',
  },
  {
    note: '6',
    url: '/jeux/serious-sam-ii-3010001156/test.html',
    name: 'serious-sam-ii',
  },
  {
    note: '5',
    url: '/jeux/incrazyball-74703/test.html',
    name: 'incrazyball',
  },
  { note: '3', url: '/jeux/urgences-70640/test.html', name: 'urgences' },
  {
    note: '6',
    url: '/jeux/rome-total-war-barbarian-invasion-73141/test.html',
    name: 'rome-total-war-barbarian-invasion',
  },
  {
    note: '3',
    url: '/jeux/powerdrome-3010001240/test-pc.html',
    name: 'powerdrome',
  },
  {
    note: '6',
    url: '/jeux/fifa-06-3010001942/test-pc.html',
    name: 'fifa-06',
  },
  {
    note: '7',
    url: '/jeux/madden-nfl-06-3010001878/test.html',
    name: 'madden-nfl-06',
  },
  {
    note: '7',
    url: '/jeux/warhammer-40-000-dawn-of-war-winter-assault-73528/test.html',
    name: 'warhammer-40-000-dawn-of-war-winter-assault',
  },
  {
    note: '5',
    url: '/jeux/total-overdose-3010001769/test.html',
    name: 'total-overdose',
  },
  {
    note: '7',
    url: '/jeux/fable-the-lost-chapters-3010001838/test.html',
    name: 'fable-the-lost-chapters',
  },
  {
    note: '7',
    url: '/jeux/nhl-06-3010001904/test-pc.html',
    name: 'nhl-06',
  },
  {
    note: '6',
    url: '/jeux/earth-2160-16207/test.html',
    name: 'earth-2160',
  },
  {
    note: '7',
    url: '/jeux/myst-v-end-of-ages-3010004313/test-pc.html',
    name: 'myst-v-end-of-ages',
  },
  {
    note: '7',
    url: '/jeux/heroes-of-the-pacific-3010001896/test.html',
    name: 'heroes-of-the-pacific',
  },
  {
    note: '6',
    url: '/jeux/les-sims-2-nuits-de-folie-3010005459/test.html',
    name: 'les-sims-2-nuits-de-folie',
  },
  {
    note: '7',
    url: '/jeux/motogp-ultimate-racing-technology-3-3010001409/test.html',
    name: 'motogp-ultimate-racing-technology-3',
  },
  {
    note: '5',
    url: '/jeux/bet-on-soldier-3010002041/test.html',
    name: 'bet-on-soldier',
  },
];

const gkreviews2: Array<{ note?: string; url: string; name: string }> = [
  {
    note: '6',
    url: '/jeux/codename-panzers-phase-two-64461/test.html',
    name: 'codename-panzers-phase-two',
  },
  {
    note: '6',
    url: '/jeux/worms-4-mayhem-3010001702/test.html',
    name: 'worms-4-mayhem',
  },
  {
    note: '7',
    url: '/jeux/fahrenheit-3010001105/test.html',
    name: 'fahrenheit',
  },
  {
    note: '6',
    url: '/jeux/dungeon-siege-ii-54487/test.html',
    name: 'dungeon-siege-ii',
  },
  {
    note: '4',
    url: '/jeux/pilot-down-behind-enemy-lines-3010000899/test-pc.html',
    name: 'pilot-down-behind-enemy-lines',
  },
  {
    note: '3',
    url: '/jeux/oil-tycoon-2-75435/test.html',
    name: 'oil-tycoon-2',
  },
  {
    note: '4',
    url:
      '/jeux/big-mutha-truckers-2-une-viree-en-enfer-3010001633/test-pc.html',
    name: 'big-mutha-truckers-2-une-viree-en-enfer',
  },
  {
    note: '2',
    url: '/jeux/hellforces-67884/test.html',
    name: 'hellforces',
  },
  {
    note: '4',
    url:
      '/jeux/the-settlers-l-heritage-des-rois-expansion-pack-73013/test.html',
    name: 'the-settlers-l-heritage-des-rois-expansion-pack',
  },
  {
    note: '7',
    url: '/jeux/pro-cycling-manager-74687/test.html',
    name: 'pro-cycling-manager',
  },
  {
    note: '4',
    url: '/jeux/aurora-watching-68243/test.html',
    name: 'aurora-watching',
  },
  {
    note: '5',
    url: '/jeux/rollercoaster-tycoon-3-delires-aquatiques-72961/test.html',
    name: 'rollercoaster-tycoon-3-delires-aquatiques',
  },
  {
    note: '8',
    url: '/jeux/battlefield-2-68057/test.html',
    name: 'battlefield-2',
  },
  {
    note: '6',
    url: '/jeux/chaos-league-mort-subite-70849/test.html',
    name: 'chaos-league-mort-subite',
  },
  {
    note: '4',
    url: '/jeux/rising-kingdoms-74752/test.html',
    name: 'rising-kingdoms',
  },
  {
    note: '6',
    url: '/jeux/freedom-force-vs-the-third-reich-19722/test.html',
    name: 'freedom-force-vs-the-third-reich',
  },
  { note: '6', url: '/jeux/juiced-3010001236/test.html', name: 'juiced' },
  {
    note: '5',
    url: '/jeux/chrome-specforce-71239/test.html',
    name: 'chrome-specforce',
  },
  {
    note: '4',
    url: '/jeux/project-freedom-61966/test.html',
    name: 'project-freedom',
  },
  {
    note: '4',
    url: '/jeux/7-sins-3010001707/test-pc.html',
    name: '7-sins',
  },
  {
    note: '9',
    url: '/jeux/grand-theft-auto-san-andreas-3010001510/test-pc.html',
    name: 'grand-theft-auto-san-andreas',
  },
  {
    note: '5',
    url: '/jeux/domination-68313/test.html',
    name: 'domination',
  },
  {
    note: '4',
    url: '/jeux/cross-racing-championship-2005-69031/test.html',
    name: 'cross-racing-championship-2005',
  },
  { note: '4', url: '/jeux/pariah-3010001288/test.html', name: 'pariah' },
  {
    note: '6',
    url: '/jeux/imperial-glory-3010004307/test-pc.html',
    name: 'imperial-glory',
  },
  {
    note: '3',
    url: '/jeux/dungeon-lords-67814/test.html',
    name: 'dungeon-lords',
  },
  {
    note: '4',
    url: '/jeux/boiling-point-road-to-hell-17515/test.html',
    name: 'boiling-point-road-to-hell',
  },
  {
    note: '5',
    url: '/jeux/project-snowblind-3010001504/test-pc.html',
    name: 'project-snowblind',
  },
  {
    note: '3',
    url: '/jeux/singles-2-trio-d-enfer-69004/test.html',
    name: 'singles-2-trio-d-enfer',
  },
  {
    note: '6',
    url: '/jeux/sacred-underworld-70174/test.html',
    name: 'sacred-underworld',
  },
  {
    note: '6',
    url: '/jeux/empire-earth-ii-68157/test.html',
    name: 'empire-earth-ii',
  },
  {
    note: '4',
    url: '/jeux/sudeki-3010001795/test-pc.html',
    name: 'sudeki',
  },
  {
    note: '5',
    url: '/jeux/bone-out-from-boneville-3010007545/test.html',
    name: 'bone-out-from-boneville',
  },
  {
    note: '3',
    url: '/jeux/l-entraineur-5-3010001458/test.html',
    name: 'l-entraineur-5',
  },
  {
    note: '6',
    url: '/jeux/cossacks-ii-napoleonic-wars-18597/test.html',
    name: 'cossacks-ii-napoleonic-wars',
  },
  {
    note: '3',
    url: '/jeux/gotcha-3010001854/test-pc.html',
    name: 'gotcha',
  },
  {
    note: '6',
    url: '/jeux/still-life-3010001644/test.html',
    name: 'still-life',
  },
  {
    note: '6',
    url: '/jeux/doom-3-resurrection-of-evil-3010002086/test.html',
    name: 'doom-3-resurrection-of-evil',
  },
  {
    note: '3',
    url: '/jeux/rpm-tuning-3010001683/test-pc.html',
    name: 'rpm-tuning',
  },
  {
    note: '7',
    url: '/jeux/s-w-a-t-4-68249/test.html',
    name: 's-w-a-t-4',
  },
  {
    note: '8',
    url: '/jeux/silent-hunter-iii-64512/test.html',
    name: 'silent-hunter-iii',
  },
  {
    note: '7',
    url: '/jeux/trackmania-sunrise-68996/test.html',
    name: 'trackmania-sunrise',
  },
  {
    note: '6',
    url: '/jeux/gothic-ii-17409/test.html',
    name: 'gothic-ii',
  },
  {
    note: '8',
    url: '/jeux/splinter-cell-chaos-theory-3010001443/test.html',
    name: 'splinter-cell-chaos-theory',
  },
  {
    note: '2',
    url: '/jeux/war-times-64732/test.html',
    name: 'war-times',
  },
  {
    note: '6',
    url: '/jeux/les-sims-2-academie-3010005453/test-pc.html',
    name: 'les-sims-2-academie',
  },
  {
    note: '3',
    url: '/jeux/driv3r-3010000794/test-pc.html',
    name: 'driv3r',
  },
  {
    note: '8',
    url: '/jeux/act-of-war-direct-action-70119/test.html',
    name: 'act-of-war-direct-action',
  },
  {
    note: '6',
    url: '/jeux/rugby-2005-3010001628/test.html',
    name: 'rugby-2005',
  },
  {
    note: '2',
    url: '/jeux/neverend-la-malediction-des-fees-3010001158/test.html',
    name: 'neverend-la-malediction-des-fees',
  },
  {
    note: '7',
    url: '/jeux/brothers-in-arms-road-to-hill-30-3010001325/test.html',
    name: 'brothers-in-arms-road-to-hill-30',
  },
  {
    note: '4',
    url: '/jeux/emergency-3-mission-life-73938/test.html',
    name: 'emergency-3-mission-life',
  },
  { note: '5', url: '/jeux/ni-bi-ru-69165/test.html', name: 'ni-bi-ru' },
  {
    note: '5',
    url: '/jeux/scrapland-3010001423/test.html',
    name: 'scrapland',
  },
  {
    note: '6',
    url: '/jeux/joint-operations-escalation-70697/test.html',
    name: 'joint-operations-escalation',
  },
  {
    note: '2',
    url: '/jeux/playboy-the-mansion-3010001182/test.html',
    name: 'playboy-the-mansion',
  },
  {
    note: '6',
    url: '/jeux/star-wars-republic-commando-3010001070/test-pc.html',
    name: 'star-wars-republic-commando',
  },
  { note: '9', url: '/jeux/gtr-68314/test.html', name: 'gtr' },
  {
    note: '3',
    url: '/jeux/cops-2170-the-power-of-law-66778/test.html',
    name: 'cops-2170-the-power-of-law',
  },
  {
    note: '5',
    url: '/jeux/camera-cafe-le-jeu-71218/test.html',
    name: 'camera-cafe-le-jeu',
  },
  {
    note: '4',
    url: '/jeux/gun-metal-war-transformed-60289/test.html',
    name: 'gun-metal-war-transformed',
  },
  {
    note: '6',
    url: '/jeux/pro-rugby-manager-2005-70350/test.html',
    name: 'pro-rugby-manager-2005',
  },
  {
    note: '3',
    url: '/jeux/the-house-of-the-dead-iii-3010000407/test-pc.html',
    name: 'the-house-of-the-dead-iii',
  },
  {
    note: '7',
    url:
      '/jeux/star-wars-knights-of-the-old-republic-ii-3010001295/test-pc.html',
    name: 'star-wars-knights-of-the-old-republic-ii',
  },
  {
    note: '8',
    url: '/jeux/world-of-warcraft-3010004308/test-pc.html',
    name: 'world-of-warcraft',
  },
  {
    note: '5',
    url: '/jeux/the-settlers-v-3010000756/test.html',
    name: 'the-settlers-v',
  },
  {
    note: '7',
    url: '/jeux/second-sight-3010001358/test-pc.html',
    name: 'second-sight',
  },
  {
    note: '7',
    url: '/jeux/the-chronicles-of-riddick-3010001424/test-pc.html',
    name: 'the-chronicles-of-riddick',
  },
  {
    note: '6',
    url: '/jeux/les-enfants-du-nil-immortal-cities-69621/test.html',
    name: 'les-enfants-du-nil-immortal-cities',
  },
  {
    note: '4',
    url: '/jeux/ford-racing-3-3010001304/test-pc.html',
    name: 'ford-racing-3',
  },
  {
    note: '7',
    url: '/jeux/armies-of-exigo-68709/test.html',
    name: 'armies-of-exigo',
  },
  {
    note: '4',
    url: '/jeux/men-of-valor-3010000928/test.html',
    name: 'men-of-valor',
  },
  {
    note: '5',
    url: '/jeux/spellforce-shadow-of-the-phoenix-69444/test.html',
    name: 'spellforce-shadow-of-the-phoenix',
  },
  {
    note: '6',
    url: '/jeux/medal-of-honor-batailles-du-pacifique-34718/test.html',
    name: 'medal-of-honor-batailles-du-pacifique',
  },
  {
    note: '6',
    url: '/jeux/retour-sur-l-ile-mysterieuse-3010007915/test-pc.html',
    name: 'retour-sur-l-ile-mysterieuse',
  },
  {
    note: '6',
    url: '/jeux/final-fantasy-xi-3010000409/test-pc.html',
    name: 'final-fantasy-xi',
  },
  {
    note: '6',
    url: '/jeux/rollercoaster-tycoon-3-3050526537/test.html',
    name: 'rollercoaster-tycoon-3',
  },
  {
    note: '2',
    url: '/jeux/the-fall-last-days-of-gaia-60967/test.html',
    name: 'the-fall-last-days-of-gaia',
  },
  {
    note: '7',
    url:
      '/jeux/le-seigneur-des-anneaux-la-bataille-pour-la-terre-du-milieu-60723/test.html',
    name: 'le-seigneur-des-anneaux-la-bataille-pour-la-terre-du-milieu',
  },
  {
    note: '5',
    url: '/jeux/sonic-heroes-3010001021/test-pc.html',
    name: 'sonic-heroes',
  },
  {
    note: '3',
    url: '/jeux/kult-heretic-kingdoms-3010004359/test.html',
    name: 'kult-heretic-kingdoms',
  },
  {
    note: '3',
    url: '/jeux/mortyr-ii-27779/test.html',
    name: 'mortyr-ii',
  },
  {
    note: '5',
    url: '/jeux/painkiller-battle-out-of-hell-69238/test.html',
    name: 'painkiller-battle-out-of-hell',
  },
  {
    note: '7',
    url: '/jeux/pirates-3010001475/test.html',
    name: 'pirates',
  },
  {
    note: '8',
    url: '/jeux/prince-of-persia-l-ame-du-guerrier-3010001281/test.html',
    name: 'prince-of-persia-l-ame-du-guerrier',
  },
  {
    note: '6',
    url: '/jeux/need-for-speed-underground-2-3010001333/test.html',
    name: 'need-for-speed-underground-2',
  },
  {
    note: '5',
    url: '/jeux/alexandre-70067/test.html',
    name: 'alexandre',
  },
  {
    note: '7',
    url: '/jeux/football-manager-2005-64817/test.html',
    name: 'football-manager-2005',
  },
  {
    note: '8',
    url: '/jeux/pro-evolution-soccer-4-3010001406/test-pc.html',
    name: 'pro-evolution-soccer-4',
  },
  {
    note: '6',
    url: '/jeux/in-memoriam-la-treizieme-victime-70856/test.html',
    name: 'in-memoriam-la-treizieme-victime',
  },
  {
    note: '6',
    url: '/jeux/fire-department-2-68013/test.html',
    name: 'fire-department-2',
  },
  {
    note: '6',
    url: '/jeux/vampire-the-masquerade-bloodlines-51437/test.html',
    name: 'vampire-the-masquerade-bloodlines',
  },
  { note: '4', url: '/jeux/savage-19447/test.html', name: 'savage' },
  {
    note: '9',
    url: '/jeux/half-life-2-3010001001/test.html',
    name: 'half-life-2',
  },
  {
    note: '3',
    url: '/jeux/the-moment-of-silence-70635/test.html',
    name: 'the-moment-of-silence',
  },
  {
    note: '6',
    url: '/jeux/flatout-3010001002/test.html',
    name: 'flatout',
  },
  {
    note: '4',
    url: '/jeux/worms-forts-etat-de-siege-3010001343/test.html',
    name: 'worms-forts-etat-de-siege',
  },
  {
    note: '6',
    url: '/jeux/s3-silent-storm-sentinels-63826/test.html',
    name: 's3-silent-storm-sentinels',
  },
  {
    note: '5',
    url: '/jeux/anarchy-online-alien-invasion-64819/test.html',
    name: 'anarchy-online-alien-invasion',
  },
  {
    note: '3',
    url: '/jeux/alexandra-ledermann-5-l-heritage-du-haras-71272/test.html',
    name: 'alexandra-ledermann-5-l-heritage-du-haras',
  },
  {
    note: '4',
    url: '/jeux/shade-the-wrath-of-angels-4708/test.html',
    name: 'shade-the-wrath-of-angels',
  },
  {
    note: '8',
    url: '/jeux/top-spin-3010001399/test-pc.html',
    name: 'top-spin',
  },
  {
    note: '6',
    url: '/jeux/nexus-the-jupiter-incident-11957/test.html',
    name: 'nexus-the-jupiter-incident',
  },
  {
    note: '5',
    url: '/jeux/knights-of-honor-53149/test.html',
    name: 'knights-of-honor',
  },
  {
    note: '3',
    url: '/jeux/leisure-suit-larry-magna-cum-laude-3010001177/test.html',
    name: 'leisure-suit-larry-magna-cum-laude',
  },
  {
    note: '8',
    url: '/jeux/tribes-vengeance-8998/test.html',
    name: 'tribes-vengeance',
  },
  {
    note: '5',
    url: '/jeux/club-football-2005-3010001350/test.html',
    name: 'club-football-2005',
  },
  {
    note: '6',
    url: '/jeux/lfp-manager-2005-3010001481/test-pc.html',
    name: 'lfp-manager-2005',
  },
  {
    note: '7',
    url: '/jeux/nhl-2005-3010001366/test-pc.html',
    name: 'nhl-2005',
  },
  {
    note: '5',
    url: '/jeux/arena-wars-69808/test.html',
    name: 'arena-wars',
  },
  {
    note: '8',
    url: '/jeux/madden-nfl-2005-3010001313/test.html',
    name: 'madden-nfl-2005',
  },
  {
    note: '7',
    url: '/jeux/fifa-2005-3010001364/test-pc.html',
    name: 'fifa-2005',
  },
  {
    note: '2',
    url: '/jeux/sabotain-bafouez-les-regles-16099/test.html',
    name: 'sabotain-bafouez-les-regles',
  },
  {
    note: '8',
    url: '/jeux/rome-total-war-25700/test.html',
    name: 'rome-total-war',
  },
  {
    note: '6',
    url: '/jeux/evil-genius-63149/test.html',
    name: 'evil-genius',
  },
  {
    note: '6',
    url: '/jeux/call-of-duty-la-grande-offensive-3010007127/test-pc.html',
    name: 'call-of-duty-la-grande-offensive',
  },
  {
    note: '4',
    url: '/jeux/shadow-ops-red-mercury-3010001052/test-pc.html',
    name: 'shadow-ops-red-mercury',
  },
  {
    note: '7',
    url: '/jeux/myst-iv-revelation-3010001768/test.html',
    name: 'myst-iv-revelation',
  },
  {
    note: '7',
    url: '/jeux/full-spectrum-warrior-3010001089/test-pc.html',
    name: 'full-spectrum-warrior',
  },
  {
    note: '6',
    url: '/jeux/kohan-ii-kings-of-war-68768/test.html',
    name: 'kohan-ii-kings-of-war',
  },
  {
    note: '6',
    url: '/jeux/port-royale-2-64161/test.html',
    name: 'port-royale-2',
  },
  {
    note: '5',
    url: '/jeux/hidden-dangerous-2-sabre-squadron-62255/test.html',
    name: 'hidden-dangerous-2-sabre-squadron',
  },
  {
    note: '4',
    url: '/jeux/star-wars-battlefront-3010001199/test.html',
    name: 'star-wars-battlefront',
  },
  {
    note: '6',
    url: '/jeux/colin-mcrae-rally-2005-3010001342/test-pc.html',
    name: 'colin-mcrae-rally-2005',
  },
  {
    note: '6',
    url: '/jeux/silent-hill-4-3010001220/test-pc.html',
    name: 'silent-hill-4',
  },
  {
    note: '4',
    url: '/jeux/weird-war-25710/test.html',
    name: 'weird-war',
  },
  {
    note: '7',
    url: '/jeux/les-sims-2-3010001885/test-pc.html',
    name: 'les-sims-2',
  },
  {
    note: '5',
    url: '/jeux/medieval-lords-68334/test.html',
    name: 'medieval-lords',
  },
  {
    note: '8',
    url: '/jeux/warhammer-40-000-dawn-of-war-64222/test.html',
    name: 'warhammer-40-000-dawn-of-war',
  },
  {
    note: '5',
    url: '/jeux/xpand-rally-68062/test.html',
    name: 'xpand-rally',
  },
  {
    note: '6',
    url: '/jeux/obscure-3010001005/test.html',
    name: 'obscure',
  },
  {
    note: '8',
    url: '/jeux/richard-burns-rally-3010001179/test-pc.html',
    name: 'richard-burns-rally',
  },
  {
    note: '2',
    url: '/jeux/catwoman-3010001260/test.html',
    name: 'catwoman',
  },
  {
    note: '7',
    url: '/jeux/codename-panzers-phase-one-24611/test.html',
    name: 'codename-panzers-phase-one',
  },
  { note: '4', url: '/jeux/d-day-68118/test.html', name: 'd-day' },
  {
    note: '7',
    url: '/jeux/joint-operations-typhoon-rising-55525/test.html',
    name: 'joint-operations-typhoon-rising',
  },
  { note: '5', url: '/jeux/mashed-3010001277/test.html', name: 'mashed' },
  {
    note: '8',
    url: '/jeux/doom-3-3010000245/test-pc.html',
    name: 'doom-3',
  },
  {
    note: '3',
    url: '/jeux/gorky-zero-beyond-honor-68242/test.html',
    name: 'gorky-zero-beyond-honor',
  },
  {
    note: '3',
    url: '/jeux/evany-la-cle-des-7-mondes-52040/test.html',
    name: 'evany-la-cle-des-7-mondes',
  },
  {
    note: '5',
    url: '/jeux/nhl-eastside-hockey-manager-64152/test.html',
    name: 'nhl-eastside-hockey-manager',
  },
  {
    note: '4',
    url: '/jeux/spider-man-2-3010001130/test-pc.html',
    name: 'spider-man-2',
  },
  {
    note: '3',
    url: '/jeux/dinosaur-us-la-derniere-traversee-69493/test.html',
    name: 'dinosaur-us-la-derniere-traversee',
  },
  {
    note: '6',
    url: '/jeux/spellforce-the-breath-of-winter-65988/test.html',
    name: 'spellforce-the-breath-of-winter',
  },
  {
    note: '5',
    url: '/jeux/soldiers-heroes-of-world-war-ii-64470/test.html',
    name: 'soldiers-heroes-of-world-war-ii',
  },
  {
    note: '2',
    url: '/jeux/yetisports-deluxe-69425/test.html',
    name: 'yetisports-deluxe',
  },
  { note: '3', url: '/jeux/kreed-18945/test.html', name: 'kreed' },
  {
    note: '7',
    url: '/jeux/chaos-league-3010002042/test.html',
    name: 'chaos-league',
  },
  {
    note: '7',
    url: '/jeux/ground-control-2-operation-exodus-34061/test.html',
    name: 'ground-control-2-operation-exodus',
  },
  {
    note: '7',
    url: '/jeux/cycling-manager-4-saison-2004-2005-68053/test.html',
    name: 'cycling-manager-4-saison-2004-2005',
  },
  {
    note: '6',
    url: '/jeux/shrek-2-the-game-3010001066/test-pc.html',
    name: 'shrek-2-the-game',
  },
  {
    note: '7',
    url: '/jeux/dark-project-deadly-shadows-3010000377/test.html',
    name: 'dark-project-deadly-shadows',
  },
  {
    note: '7',
    url:
      '/jeux/harry-potter-et-le-prisonnier-d-azkaban-3010001210/test-pc.html',
    name: 'harry-potter-et-le-prisonnier-d-azkaban',
  },
  {
    note: '7',
    url: '/jeux/trackmania-power-up-68312/test.html',
    name: 'trackmania-power-up',
  },
  {
    note: '3',
    url: '/jeux/space-rangers-64783/test.html',
    name: 'space-rangers',
  },
  {
    note: '3',
    url: '/jeux/angels-vs-devils-68404/test.html',
    name: 'angels-vs-devils',
  },
  {
    note: '6',
    url: '/jeux/pool-paradise-3010001282/test-pc.html',
    name: 'pool-paradise',
  },
  {
    note: '4',
    url: '/jeux/celtic-kings-2-the-punic-wars-60244/test.html',
    name: 'celtic-kings-2-the-punic-wars',
  },
  {
    note: '5',
    url: '/jeux/pacific-warriors-2-3010002252/test.html',
    name: 'pacific-warriors-2',
  },
  {
    note: '5',
    url: '/jeux/les-experts-meurtres-a-las-vegas-3010004230/test.html',
    name: 'les-experts-meurtres-a-las-vegas',
  },
  {
    note: '4',
    url: '/jeux/soeldner-secret-wars-19718/test.html',
    name: 'soeldner-secret-wars',
  },
  {
    note: '7',
    url: '/jeux/s2-silent-storm-18197/test.html',
    name: 's2-silent-storm',
  },
  {
    note: '5',
    url: '/jeux/true-crime-streets-of-la-3010000674/test-pc.html',
    name: 'true-crime-streets-of-la',
  },
  {
    note: '6',
    url: '/jeux/rise-of-nations-thrones-and-patriots-63318/test.html',
    name: 'rise-of-nations-thrones-and-patriots',
  },
  {
    note: '6',
    url: '/jeux/beyond-divinity-53473/test.html',
    name: 'beyond-divinity',
  },
  {
    note: '5',
    url: '/jeux/perimeter-64170/test.html',
    name: 'perimeter',
  },
  {
    note: '6',
    url: '/jeux/syberia-ii-3010001264/test.html',
    name: 'syberia-ii',
  },
  {
    note: '7',
    url: '/jeux/uefa-euro-2004-portugal-3010001254/test-pc.html',
    name: 'uefa-euro-2004-portugal',
  },
  {
    note: '5',
    url: '/jeux/dead-man-s-hand-3010001262/test-pc.html',
    name: 'dead-man-s-hand',
  },
  {
    note: '7',
    url: '/jeux/anarchy-online-shadowlands-19152/test.html',
    name: 'anarchy-online-shadowlands',
  },
  {
    note: '4',
    url: '/jeux/le-tresor-d-alkaim-aventures-en-arabie-64782/test.html',
    name: 'le-tresor-d-alkaim-aventures-en-arabie',
  },
  { note: '3', url: '/jeux/konung-2-64779/test.html', name: 'konung-2' },
  {
    note: '4',
    url: '/jeux/a-i-m-artificial-intelligence-machines-64780/test.html',
    name: 'a-i-m-artificial-intelligence-machines',
  },
  { note: '7', url: '/jeux/sacred-53164/test.html', name: 'sacred' },
  {
    note: '7',
    url: '/jeux/manhunt-3010001211/test-pc.html',
    name: 'manhunt',
  },
  { note: '4', url: '/jeux/breed-3010000183/test.html', name: 'breed' },
  {
    note: '8',
    url:
      '/jeux/toca-race-driver-2-the-ultimate-racing-simulator-3010001153/test-pc.html',
    name: 'toca-race-driver-2-the-ultimate-racing-simulator',
  },
  {
    note: '3',
    url: '/jeux/bad-boys-ii-3010001110/test-pc.html',
    name: 'bad-boys-ii',
  },
  {
    note: '3',
    url: '/jeux/spanking-runners-64781/test.html',
    name: 'spanking-runners',
  },
  {
    note: '4',
    url: '/jeux/jeanne-d-arc-3010001181/test.html',
    name: 'jeanne-d-arc',
  },
  {
    note: '4',
    url: '/jeux/egypte-iii-le-destin-de-ramses-52048/test.html',
    name: 'egypte-iii-le-destin-de-ramses',
  },
  {
    note: '4',
    url: '/jeux/lords-of-the-realm-iii-14838/test.html',
    name: 'lords-of-the-realm-iii',
  },
  {
    note: '5',
    url: '/jeux/knightshift-17564/test.html',
    name: 'knightshift',
  },
  {
    note: '7',
    url: '/jeux/painkiller-3010001292/test.html',
    name: 'painkiller',
  },
  {
    note: '4',
    url: '/jeux/battle-mages-25045/test.html',
    name: 'battle-mages',
  },
  {
    note: '7',
    url: '/jeux/x2-the-threat-14068/test.html',
    name: 'x2-the-threat',
  },
  {
    note: '6',
    url: '/jeux/schizm-2-chameleon-52063/test.html',
    name: 'schizm-2-chameleon',
  },
  { note: '4', url: '/jeux/singles-65833/test.html', name: 'singles' },
  {
    note: '8',
    url: '/jeux/colin-mcrae-rally-04-3010001030/test-pc.html',
    name: 'colin-mcrae-rally-04',
  },
  {
    note: '5',
    url: '/jeux/pro-rugby-manager-2004-61253/test.html',
    name: 'pro-rugby-manager-2004',
  },
  {
    note: '7',
    url: '/jeux/battlefield-vietnam-50760/test.html',
    name: 'battlefield-vietnam',
  },
  {
    note: '1',
    url: '/jeux/le-chat-chapeaute-3010001300/test-pc.html',
    name: 'le-chat-chapeaute',
  },
  {
    note: '8',
    url: '/jeux/splinter-cell-pandora-tomorrow-3010001036/test.html',
    name: 'splinter-cell-pandora-tomorrow',
  },
  {
    note: '4',
    url: '/jeux/un-voisin-d-enfer-2-en-vacances-59961/test.html',
    name: 'un-voisin-d-enfer-2-en-vacances',
  },
  {
    note: '6',
    url: '/jeux/gang-land-64314/test.html',
    name: 'gang-land',
  },
  {
    note: '7',
    url:
      '/jeux/neverwinter-nights-hordes-of-the-underdark-3010004357/test.html',
    name: 'neverwinter-nights-hordes-of-the-underdark',
  },
  {
    note: '6',
    url:
      '/jeux/tom-clancy-s-rainbow-six-3-raven-shield-athena-sword-3010006629/test.html',
    name: 'tom-clancy-s-rainbow-six-3-raven-shield-athena-sword',
  },
  { note: '8', url: '/jeux/far-cry-19734/test.html', name: 'far-cry' },
  {
    note: '8',
    url: '/jeux/unreal-tournament-2004-3010005818/test.html',
    name: 'unreal-tournament-2004',
  },
  {
    note: '6',
    url: '/jeux/castle-strike-63133/test.html',
    name: 'castle-strike',
  },
  {
    note: '6',
    url: '/jeux/deus-ex-invisible-war-3010000609/test.html',
    name: 'deus-ex-invisible-war',
  },
  {
    note: '5',
    url: '/jeux/istaria-chronicles-of-the-gifted-2744/test.html',
    name: 'istaria-chronicles-of-the-gifted',
  },
  {
    note: '4',
    url: '/jeux/robin-hood-defender-of-the-crown-3010000082/test.html',
    name: 'robin-hood-defender-of-the-crown',
  },
  {
    note: '6',
    url: '/jeux/the-westerner-3010008532/test.html',
    name: 'the-westerner',
  },
  {
    note: '6',
    url: '/jeux/afrika-korps-vs-deserts-rats-53905/test.html',
    name: 'afrika-korps-vs-deserts-rats',
  },
  {
    note: '3',
    url: '/jeux/crazy-taxi-3-high-roller-3010000582/test-pc.html',
    name: 'crazy-taxi-3-high-roller',
  },
  {
    note: '5',
    url: '/jeux/armed-and-dangerous-3010001035/test.html',
    name: 'armed-and-dangerous',
  },
  {
    note: '4',
    url: '/jeux/counter-strike-condition-zero-9841/test.html',
    name: 'counter-strike-condition-zero',
  },
  {
    note: '4',
    url: '/jeux/dark-fall-rencontres-avec-l-au-dela-64193/test.html',
    name: 'dark-fall-rencontres-avec-l-au-dela',
  },
  {
    note: '5',
    url: '/jeux/vietnam-med-evac-60860/test.html',
    name: 'vietnam-med-evac',
  },
  {
    note: '6',
    url: '/jeux/etherlords-ii-20187/test.html',
    name: 'etherlords-ii',
  },
  {
    note: '7',
    url: '/jeux/combat-mission-3-afrika-korps-49923/test.html',
    name: 'combat-mission-3-afrika-korps',
  },
  {
    note: '8',
    url: '/jeux/fs-repaint-64553/test.html',
    name: 'fs-repaint',
  },
  {
    note: '7',
    url: '/jeux/vietcong-fist-alpha-56190/test.html',
    name: 'vietcong-fist-alpha',
  },
  {
    note: '3',
    url: '/jeux/chicago-1930-25123/test.html',
    name: 'chicago-1930',
  },
  {
    note: '8',
    url: '/jeux/fs-design-studio-version-2-64446/test.html',
    name: 'fs-design-studio-version-2',
  },
  {
    note: '2',
    url: '/jeux/military-aircraft-collector-s-64447/test.html',
    name: 'military-aircraft-collector-s',
  },
  {
    note: '4',
    url: '/jeux/flight-deck-2004-64448/test.html',
    name: 'flight-deck-2004',
  },
  {
    note: '2',
    url: '/jeux/fair-strike-le-chasseur-de-terroristes-26680/test.html',
    name: 'fair-strike-le-chasseur-de-terroristes',
  },
  {
    note: '3',
    url: '/jeux/urban-freestyle-soccer-3010000925/test-pc.html',
    name: 'urban-freestyle-soccer',
  },
  {
    note: '5',
    url: '/jeux/le-seigneur-des-anneaux-la-guerre-de-l-anneau-23229/test.html',
    name: 'le-seigneur-des-anneaux-la-guerre-de-l-anneau',
  },
  {
    note: '5',
    url: '/jeux/pax-romana-21573/test.html',
    name: 'pax-romana',
  },
  {
    note: '5',
    url: '/jeux/massive-assault-24518/test.html',
    name: 'massive-assault',
  },
  {
    note: '6',
    url: '/jeux/le-monde-de-nemo-3010000754/test-pc.html',
    name: 'le-monde-de-nemo',
  },
  {
    note: '1',
    url: '/jeux/terminator-3-la-guerre-des-machines-20900/test.html',
    name: 'terminator-3-la-guerre-des-machines',
  },
  {
    note: '4',
    url: '/jeux/lords-of-everquest-21439/test.html',
    name: 'lords-of-everquest',
  },
  {
    note: '3',
    url: '/jeux/alexandra-ledermann-4-aventures-au-haras-62955/test.html',
    name: 'alexandra-ledermann-4-aventures-au-haras',
  },
  {
    note: '7',
    url: '/jeux/l-entraineur-saison-03-04-60864/test.html',
    name: 'l-entraineur-saison-03-04',
  },
  {
    note: '4',
    url: '/jeux/line-of-sight-vietnam-26434/test.html',
    name: 'line-of-sight-vietnam',
  },
  {
    note: '8',
    url: '/jeux/virtual-skipper-3-57443/test.html',
    name: 'virtual-skipper-3',
  },
  {
    note: '8',
    url: '/jeux/air-combat-simulation-lock-on-7788/test.html',
    name: 'air-combat-simulation-lock-on',
  },
  {
    note: '7',
    url: '/jeux/dungeon-siege-legends-of-aranna-55954/test.html',
    name: 'dungeon-siege-legends-of-aranna',
  },
  {
    note: '7',
    url: '/jeux/spellforce-the-order-of-dawn-25192/test.html',
    name: 'spellforce-the-order-of-dawn',
  },
  {
    note: '6',
    url: '/jeux/the-simpsons-hit-run-3010001127/test-pc.html',
    name: 'the-simpsons-hit-run',
  },
  {
    note: '7',
    url: '/jeux/need-for-speed-underground-3010001031/test-pc.html',
    name: 'need-for-speed-underground',
  },
  {
    note: '1',
    url: '/jeux/turok-evolution-3010000178/test-pc.html',
    name: 'turok-evolution',
  },
  {
    note: '6',
    url: '/jeux/trackmania-53443/test.html',
    name: 'trackmania',
  },
  {
    note: '8',
    url: '/jeux/pro-evolution-soccer-3-3010001139/test-pc.html',
    name: 'pro-evolution-soccer-3',
  },
  {
    note: '8',
    url: '/jeux/star-wars-knights-of-the-old-republic-3010000597/test-pc.html',
    name: 'star-wars-knights-of-the-old-republic',
  },
  {
    note: '7',
    url:
      '/jeux/le-seigneur-des-anneaux-le-retour-du-roi-3010000894/test-pc.html',
    name: 'le-seigneur-des-anneaux-le-retour-du-roi',
  },
  {
    note: '6',
    url: '/jeux/tortuga-pirates-et-flibustiers-60534/test.html',
    name: 'tortuga-pirates-et-flibustiers',
  },
  { note: '6', url: '/jeux/xiii-3010000254/test-pc.html', name: 'xiii' },
  {
    note: '7',
    url:
      '/jeux/les-chevaliers-de-baphomet-le-manuscrit-de-voynich-3010000309/test.html',
    name: 'les-chevaliers-de-baphomet-le-manuscrit-de-voynich',
  },
  {
    note: '8',
    url: '/jeux/star-wars-galaxies-3010000683/test-pc.html',
    name: 'star-wars-galaxies',
  },
  {
    note: '5',
    url: '/jeux/wallace-gromit-3010001085/test-pc.html',
    name: 'wallace-gromit',
  },
  {
    note: '7',
    url: '/jeux/le-temple-du-mal-elementaire-25879/test.html',
    name: 'le-temple-du-mal-elementaire',
  },
  {
    note: '7',
    url: '/jeux/secret-weapons-over-normandy-3010001011/test.html',
    name: 'secret-weapons-over-normandy',
  },
  {
    note: '5',
    url: '/jeux/dead-to-rights-3010000954/test-pc.html',
    name: 'dead-to-rights',
  },
  {
    note: '6',
    url: '/jeux/les-sims-abracadabra-3010008127/test.html',
    name: 'les-sims-abracadabra',
  },
  {
    note: '9',
    url: '/jeux/prince-of-persia-les-sables-du-temps-3010000965/test-pc.html',
    name: 'prince-of-persia-les-sables-du-temps',
  },
  {
    note: '5',
    url: '/jeux/voyage-au-centre-de-la-terre-56700/test.html',
    name: 'voyage-au-centre-de-la-terre',
  },
  {
    note: '3',
    url:
      '/jeux/paris-marseille-racing-edition-tour-du-monde-3010002594/test-pc.html',
    name: 'paris-marseille-racing-edition-tour-du-monde',
  },
  {
    note: '2',
    url: '/jeux/celebrity-deathmatch-3010001054/test-pc.html',
    name: 'celebrity-deathmatch',
  },
  {
    note: '8',
    url: '/jeux/railroad-tycoon-3-3010006264/test-pc.html',
    name: 'railroad-tycoon-3',
  },
  {
    note: '4',
    url: '/jeux/apocalyptica-3010000999/test.html',
    name: 'apocalyptica',
  },
  {
    note: '5',
    url: '/jeux/fighter-ace-3-6-54439/test.html',
    name: 'fighter-ace-3-6',
  },
  {
    note: '5',
    url: '/jeux/search-rescue-iv-60858/test.html',
    name: 'search-rescue-iv',
  },
  {
    note: '5',
    url: '/jeux/star-academy-53902/test.html',
    name: 'star-academy',
  },
  {
    note: '8',
    url: '/jeux/call-of-duty-3010007126/test-pc.html',
    name: 'call-of-duty',
  },
  {
    note: '4',
    url: '/jeux/ford-racing-2-3010001137/test-pc.html',
    name: 'ford-racing-2',
  },
  {
    note: '7',
    url: '/jeux/worms-3d-3010000789/test.html',
    name: 'worms-3d',
  },
  {
    note: '7',
    url: '/jeux/age-of-mythology-the-titans-50505/test.html',
    name: 'age-of-mythology-the-titans',
  },
  { note: '3', url: '/jeux/harbinger-1104/test.html', name: 'harbinger' },
  {
    note: '7',
    url: '/jeux/silent-hill-3-3010001028/test-pc.html',
    name: 'silent-hill-3',
  },
  {
    note: '6',
    url: '/jeux/no-man-s-land-20415/test.html',
    name: 'no-man-s-land',
  },
  {
    note: '5',
    url: '/jeux/vega-make-it-big-52145/test.html',
    name: 'vega-make-it-big',
  },
  {
    note: '4',
    url: '/jeux/warhammer-40-000-fire-warrior-3010000891/test.html',
    name: 'warhammer-40-000-fire-warrior',
  },
  {
    note: '6',
    url: '/jeux/empires-l-aube-d-un-monde-nouveau-3010001053/test.html',
    name: 'empires-l-aube-d-un-monde-nouveau',
  },
  {
    note: '7',
    url: '/jeux/hidden-dangerous-2-3010000130/test-pc.html',
    name: 'hidden-dangerous-2',
  },
  {
    note: '7',
    url: '/jeux/commandos-3-destination-berlin-18770/test.html',
    name: 'commandos-3-destination-berlin',
  },
  {
    note: '8',
    url: '/jeux/in-memoriam-3010008536/test-pc.html',
    name: 'in-memoriam',
  },
  {
    note: '8',
    url: '/jeux/max-payne-2-the-fall-of-max-payne-3010001049/test-pc.html',
    name: 'max-payne-2-the-fall-of-max-payne',
  },
  {
    note: '4',
    url: '/jeux/freedom-ridge-3010000083/test-pc.html',
    name: 'freedom-ridge',
  },
  {
    note: '7',
    url: '/jeux/halo-combat-evolved-3010000131/test-pc.html',
    name: 'halo-combat-evolved',
  },
  {
    note: '2',
    url: '/jeux/march-offworld-recon-17373/test.html',
    name: 'march-offworld-recon',
  },
  {
    note: '3',
    url: '/jeux/judge-dredd-vs-judge-death-3010000515/test-pc.html',
    name: 'judge-dredd-vs-judge-death',
  },
  { note: '5', url: '/jeux/chrome-15060/test.html', name: 'chrome' },
  {
    note: '6',
    url: '/jeux/medal-of-honor-debarquement-allie-l-offensive-50777/test.html',
    name: 'medal-of-honor-debarquement-allie-l-offensive',
  },
  {
    note: '8',
    url: '/jeux/tiger-woods-pga-tour-2004-3010000973/test-pc.html',
    name: 'tiger-woods-pga-tour-2004',
  },
  {
    note: '4',
    url: '/jeux/wildlife-park-14440/test.html',
    name: 'wildlife-park',
  },
  {
    note: '7',
    url: '/jeux/command-conquer-generals-heure-h-3010005814/test.html',
    name: 'command-conquer-generals-heure-h',
  },
  {
    note: '6',
    url: '/jeux/space-colony-51478/test.html',
    name: 'space-colony',
  },
  {
    note: '6',
    url: '/jeux/enclave-3010000389/test.html',
    name: 'enclave',
  },
  {
    note: '6',
    url: '/jeux/sim-city-4-rush-hour-3010005186/test.html',
    name: 'sim-city-4-rush-hour',
  },
  {
    note: '4',
    url: '/jeux/heaven-and-hell-3010001112/test.html',
    name: 'heaven-and-hell',
  },
  {
    note: '1',
    url: '/jeux/midnight-outlaw-illegal-street-drag-60867/test.html',
    name: 'midnight-outlaw-illegal-street-drag',
  },
  {
    note: '6',
    url: '/jeux/indycar-series-3010000914/test.html',
    name: 'indycar-series',
  },
  {
    note: '8',
    url: '/jeux/homeworld-2-3010006265/test-pc.html',
    name: 'homeworld-2',
  },
  {
    note: '7',
    url: '/jeux/freedom-fighters-3010000684/test-pc.html',
    name: 'freedom-fighters',
  },
  {
    note: '5',
    url: '/jeux/europa-universalis-les-guerres-du-nord-49778/test.html',
    name: 'europa-universalis-les-guerres-du-nord',
  },
  {
    note: '3',
    url: '/jeux/auryn-quest-60821/test.html',
    name: 'auryn-quest',
  },
  {
    note: '4',
    url: '/jeux/rugby-2004-3010001093/test.html',
    name: 'rugby-2004',
  },
  {
    note: '5',
    url: '/jeux/world-war-ii-frontline-command-16326/test.html',
    name: 'world-war-ii-frontline-command',
  },
  {
    note: '7',
    url: '/jeux/conflict-desert-storm-ii-3010000807/test-pc.html',
    name: 'conflict-desert-storm-ii',
  },
  {
    note: '6',
    url: '/jeux/europa-1400-les-marchands-du-moyen-age-19719/test.html',
    name: 'europa-1400-les-marchands-du-moyen-age',
  },
  {
    note: '6',
    url: '/jeux/star-wars-jedi-knight-jedi-academy-3010000997/test.html',
    name: 'star-wars-jedi-knight-jedi-academy',
  },
  {
    note: '6',
    url: '/jeux/the-black-mirror-58632/test.html',
    name: 'the-black-mirror',
  },
  {
    note: '7',
    url: '/jeux/age-of-wonders-shadow-magic-51141/test.html',
    name: 'age-of-wonders-shadow-magic',
  },
  {
    note: '6',
    url: '/jeux/aquanox-2-revelation-3010006606/test.html',
    name: 'aquanox-2-revelation',
  },
  {
    note: '7',
    url: '/jeux/battlefield-1942-arsenal-secret-49221/test.html',
    name: 'battlefield-1942-arsenal-secret',
  },
  {
    note: '3',
    url: '/jeux/beach-king-stunt-racer-3010001123/test-pc.html',
    name: 'beach-king-stunt-racer',
  },
  {
    note: '8',
    url: '/jeux/madden-nfl-2004-3010000972/test-pc.html',
    name: 'madden-nfl-2004',
  },
  {
    note: '7',
    url: '/jeux/lionheart-legacy-of-the-crusader-19696/test.html',
    name: 'lionheart-legacy-of-the-crusader',
  },
  {
    note: '8',
    url: '/jeux/flight-simulator-2004-un-siecle-d-aviation-27296/test.html',
    name: 'flight-simulator-2004-un-siecle-d-aviation',
  },
  {
    note: '4',
    url: '/jeux/nosferatu-the-wrath-of-malachi-60129/test.html',
    name: 'nosferatu-the-wrath-of-malachi',
  },
  {
    note: '6',
    url: '/jeux/fire-department-20163/test.html',
    name: 'fire-department',
  },
  {
    note: '7',
    url: '/jeux/republic-the-revolution-3010000019/test-pc.html',
    name: 'republic-the-revolution',
  },
  {
    note: '5',
    url: '/jeux/cold-zero-the-last-stand-17150/test.html',
    name: 'cold-zero-the-last-stand',
  },
  {
    note: '7',
    url: '/jeux/neverwinter-nights-shadows-of-undrentide-3010004358/test.html',
    name: 'neverwinter-nights-shadows-of-undrentide',
  },
  {
    note: '8',
    url: '/jeux/eve-online-3010006377/test-pc.html',
    name: 'eve-online',
  },
  {
    note: '6',
    url: '/jeux/battlecruiser-millenium-gold-edition-4150/test.html',
    name: 'battlecruiser-millenium-gold-edition',
  },
  {
    note: '4',
    url: '/jeux/world-racing-3010000631/test.html',
    name: 'world-racing',
  },
  {
    note: '6',
    url: '/jeux/tron-2-0-killer-app-3010001383/test.html',
    name: 'tron-2-0-killer-app',
  },
  {
    note: '3',
    url: '/jeux/wwii-desert-rats-18878/test.html',
    name: 'wwii-desert-rats',
  },
  {
    note: '7',
    url: '/jeux/pirates-des-caraibes-3010000892/test.html',
    name: 'pirates-des-caraibes',
  },
  {
    note: '2',
    url: '/jeux/pro-beach-soccer-3010000537/test.html',
    name: 'pro-beach-soccer',
  },
  {
    note: '5',
    url: '/jeux/dragon-s-lair-3d-3010000246/test.html',
    name: 'dragon-s-lair-3d',
  },
  {
    note: '5',
    url: '/jeux/tony-tough-and-the-night-of-roasted-moths-53166/test.html',
    name: 'tony-tough-and-the-night-of-roasted-moths',
  },
  {
    note: '8',
    url: '/jeux/live-for-speed-23901/test.html',
    name: 'live-for-speed',
  },
  {
    note: '6',
    url: '/jeux/new-york-police-judiciaire-la-mort-dans-l-arme-52062/test.html',
    name: 'new-york-police-judiciaire-la-mort-dans-l-arme',
  },
  {
    note: '6',
    url: '/jeux/american-conquest-fight-back-50179/test.html',
    name: 'american-conquest-fight-back',
  },
  { note: '3', url: '/jeux/mistmare-18666/test.html', name: 'mistmare' },
  {
    note: '4',
    url: '/jeux/aquatic-tycoon-54377/test.html',
    name: 'aquatic-tycoon',
  },
  {
    note: '4',
    url: '/jeux/kasparov-chessmate-54892/test.html',
    name: 'kasparov-chessmate',
  },
  {
    note: '5',
    url: '/jeux/hard-rock-casino-3010002316/test.html',
    name: 'hard-rock-casino',
  },
  { note: '5', url: '/jeux/chaser-12568/test.html', name: 'chaser' },
  {
    note: '4',
    url: '/jeux/tomb-raider-l-ange-des-tenebres-3010000046/test-pc.html',
    name: 'tomb-raider-l-ange-des-tenebres',
  },
  {
    note: '3',
    url: '/jeux/chariots-of-war-49169/test.html',
    name: 'chariots-of-war',
  },
  {
    note: '7',
    url: '/jeux/ghost-master-3010000726/test-pc.html',
    name: 'ghost-master',
  },
  {
    note: '8',
    url: '/jeux/warcraft-iii-the-frozen-throne-3010004311/test.html',
    name: 'warcraft-iii-the-frozen-throne',
  },
  {
    note: '6',
    url: '/jeux/star-trek-elite-force-ii-3010001058/test.html',
    name: 'star-trek-elite-force-ii',
  },
  {
    note: '7',
    url: '/jeux/motogp-ultimate-racing-technology-2-3010000938/test.html',
    name: 'motogp-ultimate-racing-technology-2',
  },
  {
    note: '6',
    url: '/jeux/enigma-rising-tide-20875/test.html',
    name: 'enigma-rising-tide',
  },
  { note: '3', url: '/jeux/torero-54469/test.html', name: 'torero' },
  {
    note: '2',
    url: '/jeux/superpower-17547/test.html',
    name: 'superpower',
  },
  {
    note: '3',
    url: '/jeux/empire-of-magic-19145/test.html',
    name: 'empire-of-magic',
  },
  {
    note: '4',
    url: '/jeux/starsky-hutch-3010000728/test.html',
    name: 'starsky-hutch',
  },
  {
    note: '6',
    url: '/jeux/cycling-manager-3-35082/test.html',
    name: 'cycling-manager-3',
  },
  {
    note: '4',
    url: '/jeux/un-voisin-d-enfer-3010001730/test.html',
    name: 'un-voisin-d-enfer',
  },
  {
    note: '5',
    url: '/jeux/planetside-1399/test.html',
    name: 'planetside',
  },
  {
    note: '4',
    url: '/jeux/the-hulk-3010000753/test-pc.html',
    name: 'the-hulk',
  },
  {
    note: '3',
    url: '/jeux/iss-3-3010000947/test-pc.html',
    name: 'iss-3',
  },
  { note: '8', url: '/jeux/neocron-8616/test.html', name: 'neocron' },
  {
    note: '6',
    url: '/jeux/colin-mcrae-rally-3-3010000463/test.html',
    name: 'colin-mcrae-rally-3',
  },
  {
    note: '5',
    url: '/jeux/will-rock-3010000744/test.html',
    name: 'will-rock',
  },
  {
    note: '4',
    url: '/jeux/pcbasket-2003-53093/test.html',
    name: 'pcbasket-2003',
  },
  {
    note: '5',
    url: '/jeux/g-i-combat-4975/test.html',
    name: 'g-i-combat',
  },
  {
    note: '5',
    url: '/jeux/roland-garros-2003-3010000896/test.html',
    name: 'roland-garros-2003',
  },
  {
    note: '5',
    url: '/jeux/les-experts-53074/test.html',
    name: 'les-experts',
  },
  {
    note: '4',
    url: '/jeux/red-faction-ii-3010000887/test-pc.html',
    name: 'red-faction-ii',
  },
  {
    note: '4',
    url: '/jeux/kaan-barbarian-s-blade-3010000758/test.html',
    name: 'kaan-barbarian-s-blade',
  },
  {
    note: '5',
    url: '/jeux/the-emperor-s-mahjong-55817/test.html',
    name: 'the-emperor-s-mahjong',
  },
  {
    note: '6',
    url: '/jeux/delta-force-black-hawk-down-3010000709/test.html',
    name: 'delta-force-black-hawk-down',
  },
  {
    note: '8',
    url: '/jeux/freelancer-3010006459/test-pc.html',
    name: 'freelancer',
  },
  {
    note: '6',
    url: '/jeux/les-sims-superstar-3010008133/test.html',
    name: 'les-sims-superstar',
  },
  {
    note: '9',
    url: '/jeux/rise-of-nations-17954/test.html',
    name: 'rise-of-nations',
  },
  {
    note: '4',
    url: '/jeux/enter-the-matrix-3010000563/test-pc.html',
    name: 'enter-the-matrix',
  },
  {
    note: '9',
    url: '/jeux/grand-theft-auto-vice-city-3010001218/test-pc.html',
    name: 'grand-theft-auto-vice-city',
  },
  {
    note: '6',
    url: '/jeux/tropico-2-la-baie-des-pirates-3010007857/test.html',
    name: 'tropico-2-la-baie-des-pirates',
  },
  {
    note: '5',
    url: '/jeux/devastation-9868/test.html',
    name: 'devastation',
  },
  {
    note: '7',
    url: '/jeux/l-entraineur-4-saison-2002-2003-19917/test.html',
    name: 'l-entraineur-4-saison-2002-2003',
  },
  {
    note: '3',
    url: '/jeux/disney-roller-coaster-simulator-51492/test.html',
    name: 'disney-roller-coaster-simulator',
  },
  {
    note: '5',
    url: '/jeux/bloodrayne-3010000519/test.html',
    name: 'bloodrayne',
  },
  {
    note: '5',
    url: '/jeux/downtown-run-3010000955/test-pc.html',
    name: 'downtown-run',
  },
  {
    note: '5',
    url: '/jeux/x-men-2-la-vengeance-de-wolverine-3010000696/test-pc.html',
    name: 'x-men-2-la-vengeance-de-wolverine',
  },
  {
    note: '6',
    url: '/jeux/warrior-kings-battles-22521/test.html',
    name: 'warrior-kings-battles',
  },
  {
    note: '5',
    url: '/jeux/indiana-jones-le-tombeau-de-l-empereur-3010000655/test.html',
    name: 'indiana-jones-le-tombeau-de-l-empereur',
  },
  {
    note: '7',
    url: '/jeux/blitzkrieg-14123/test.html',
    name: 'blitzkrieg',
  },
  { note: '4', url: '/jeux/salammbo-21107/test.html', name: 'salammbo' },
  { note: '7', url: '/jeux/vietcong-20658/test.html', name: 'vietcong' },
  {
    note: '7',
    url: '/jeux/casino-inc-24973/test.html',
    name: 'casino-inc',
  },
  {
    note: '8',
    url: '/jeux/anno-1503-le-nouveau-monde-1152/test.html',
    name: 'anno-1503-le-nouveau-monde',
  },
  {
    note: '3',
    url: '/jeux/heroes-of-might-and-magic-iv-winds-of-war-25092/test.html',
    name: 'heroes-of-might-and-magic-iv-winds-of-war',
  },
  {
    note: '5',
    url: '/jeux/highland-warriors-18273/test.html',
    name: 'highland-warriors',
  },
  {
    note: '5',
    url: '/jeux/jurassic-park-operation-genesis-3010000688/test.html',
    name: 'jurassic-park-operation-genesis',
  },
  {
    note: '8',
    url: '/jeux/metal-gear-solid-2-substance-3010000652/test.html',
    name: 'metal-gear-solid-2-substance',
  },
  {
    note: '8',
    url: '/jeux/il-2-sturmovik-the-forgotten-battles-19464/test.html',
    name: 'il-2-sturmovik-the-forgotten-battles',
  },
  {
    note: '8',
    url: '/jeux/rayman-3-hoodlum-havoc-3010000604/test.html',
    name: 'rayman-3-hoodlum-havoc',
  },
  {
    note: '8',
    url:
      '/jeux/tom-clancy-s-rainbow-six-3-raven-shield-3010006628/test-pc.html',
    name: 'tom-clancy-s-rainbow-six-3-raven-shield',
  },
  {
    note: '7',
    url: '/jeux/runaway-a-road-adventure-3010001442/test-pc.html',
    name: 'runaway-a-road-adventure',
  },
  {
    note: '9',
    url: '/jeux/toca-race-driver-3010000425/test.html',
    name: 'toca-race-driver',
  },
  { note: '7', url: '/jeux/o-r-b-8351/test.html', name: 'o-r-b' },
  {
    note: '6',
    url: '/jeux/horse-racing-manager-25148/test.html',
    name: 'horse-racing-manager',
  },
  {
    note: '7',
    url: '/jeux/praetorians-9853/test.html',
    name: 'praetorians',
  },
  {
    note: '9',
    url: '/jeux/splinter-cell-3010000633/test-pc.html',
    name: 'splinter-cell',
  },
  {
    note: '7',
    url: '/jeux/dark-age-of-camelot-shrouded-isles-19427/test.html',
    name: 'dark-age-of-camelot-shrouded-isles',
  },
  {
    note: '8',
    url: '/jeux/silent-hill-2-director-s-cut-3010000514/test-pc.html',
    name: 'silent-hill-2-director-s-cut',
  },
  {
    note: '5',
    url: '/jeux/shadow-of-memories-3010000591/test-pc.html',
    name: 'shadow-of-memories',
  },
  { note: '5', url: '/jeux/grom-21168/test.html', name: 'grom' },
  {
    note: '7',
    url: '/jeux/command-conquer-generals-3010005813/test.html',
    name: 'command-conquer-generals',
  },
  {
    note: '7',
    url: '/jeux/hearts-of-iron-18533/test.html',
    name: 'hearts-of-iron',
  },
  {
    note: '6',
    url: '/jeux/battlefield-1942-la-campagne-d-italie-24597/test.html',
    name: 'battlefield-1942-la-campagne-d-italie',
  },
  {
    note: '7',
    url: '/jeux/igi-2-covert-strike-4283/test.html',
    name: 'igi-2-covert-strike',
  },
  {
    note: '7',
    url: '/jeux/impossible-creatures-8396/test.html',
    name: 'impossible-creatures',
  },
  {
    note: '6',
    url: '/jeux/unreal-ii-the-awakening-3010000193/test.html',
    name: 'unreal-ii-the-awakening',
  },
  {
    note: '6',
    url: '/jeux/scrabble-edition-2003-3010003677/test-pc.html',
    name: 'scrabble-edition-2003',
  },
  {
    note: '1',
    url: '/jeux/taxi-3-3010000937/test-pc.html',
    name: 'taxi-3',
  },
  {
    note: '4',
    url: '/jeux/another-war-15708/test.html',
    name: 'another-war',
  },
  {
    note: '5',
    url: '/jeux/strike-fighters-project-1-21218/test.html',
    name: 'strike-fighters-project-1',
  },
  {
    note: '8',
    url: '/jeux/sim-city-4-3010005185/test.html',
    name: 'sim-city-4',
  },
  {
    note: '4',
    url: '/jeux/knights-of-the-cross-15844/test.html',
    name: 'knights-of-the-cross',
  },
  {
    note: '6',
    url: '/jeux/everquest-the-planes-of-power-20417/test.html',
    name: 'everquest-the-planes-of-power',
  },
  {
    note: '6',
    url: '/jeux/battle-realms-winter-of-the-wolf-20809/test.html',
    name: 'battle-realms-winter-of-the-wolf',
  },
  {
    note: '5',
    url:
      '/jeux/heroes-of-might-and-magic-iv-the-gathering-storm-18778/test.html',
    name: 'heroes-of-might-and-magic-iv-the-gathering-storm',
  },
  {
    note: '7',
    url: '/jeux/island-xtreme-stunts-3010000900/test.html',
    name: 'island-xtreme-stunts',
  },
  {
    note: '4',
    url: '/jeux/drome-racers-3010000672/test.html',
    name: 'drome-racers',
  },
  { note: '1', url: '/jeux/napoleon-25144/test.html', name: 'napoleon' },
  {
    note: '8',
    url: '/jeux/tiger-woods-pga-tour-2003-3010000666/test-pc.html',
    name: 'tiger-woods-pga-tour-2003',
  },
  {
    note: '1',
    url: '/jeux/knight-rider-the-game-3010000905/test.html',
    name: 'knight-rider-the-game',
  },
  { note: '2', url: '/jeux/archangel-8429/test.html', name: 'archangel' },
  {
    note: '7',
    url: '/jeux/chessmaster-9000-21192/test.html',
    name: 'chessmaster-9000',
  },
  {
    note: '6',
    url: '/jeux/soldiers-of-anarchy-21664/test.html',
    name: 'soldiers-of-anarchy',
  },
  {
    note: '6',
    url:
      '/jeux/pink-panther-a-la-poursuite-de-la-panthere-rose-3010000720/test.html',
    name: 'pink-panther-a-la-poursuite-de-la-panthere-rose',
  },
  {
    note: '7',
    url: '/jeux/the-elder-scrolls-iii-tribunal-22780/test.html',
    name: 'the-elder-scrolls-iii-tribunal',
  },
  {
    note: '5',
    url: '/jeux/james-bond-007-nightfire-3010000957/test.html',
    name: 'james-bond-007-nightfire',
  },
  {
    note: '7',
    url: '/jeux/nba-live-2003-3010000664/test-pc.html',
    name: 'nba-live-2003',
  },
  {
    note: '6',
    url: '/jeux/medal-of-honor-debarquement-allie-en-formation-21452/test.html',
    name: 'medal-of-honor-debarquement-allie-en-formation',
  },
  { note: '5', url: '/jeux/far-west-17503/test.html', name: 'far-west' },
  { note: '5', url: '/jeux/platoon-18836/test.html', name: 'platoon' },
  {
    note: '7',
    url:
      '/jeux/combat-flight-simulator-3-bataille-pour-l-europe-17981/test.html',
    name: 'combat-flight-simulator-3-bataille-pour-l-europe',
  },
  {
    note: '5',
    url: '/jeux/ski-park-manager-2003-24815/test.html',
    name: 'ski-park-manager-2003',
  },
  {
    note: '2',
    url: '/jeux/new-world-order-3010000589/test-pc.html',
    name: 'new-world-order',
  },
  {
    note: '8',
    url: '/jeux/haegemonia-legions-of-iron-14840/test.html',
    name: 'haegemonia-legions-of-iron',
  },
  {
    note: '8',
    url: '/jeux/american-conquest-10814/test.html',
    name: 'american-conquest',
  },
  {
    note: '8',
    url: '/jeux/rallisport-challenge-3010000707/test-pc.html',
    name: 'rallisport-challenge',
  },
  {
    note: '5',
    url: '/jeux/robin-hood-la-legende-de-sherwood-21697/test.html',
    name: 'robin-hood-la-legende-de-sherwood',
  },
  {
    note: '8',
    url: '/jeux/age-of-mythology-3010004336/test-pc.html',
    name: 'age-of-mythology',
  },
  {
    note: '6',
    url: '/jeux/war-and-peace-13908/test.html',
    name: 'war-and-peace',
  },
  {
    note: '6',
    url: '/jeux/harry-potter-et-la-chambre-des-secrets-3010000669/test-pc.html',
    name: 'harry-potter-et-la-chambre-des-secrets',
  },
  {
    note: '4',
    url: '/jeux/project-nomads-3010000730/test.html',
    name: 'project-nomads',
  },
  {
    note: '7',
    url: '/jeux/fifa-2003-3010000823/test.html',
    name: 'fifa-2003',
  },
  {
    note: '7',
    url: '/jeux/tennis-masters-series-2003-3010000796/test-pc.html',
    name: 'tennis-masters-series-2003',
  },
  {
    note: '8',
    url: '/jeux/need-for-speed-poursuite-infernale-2-3010000641/test-pc.html',
    name: 'need-for-speed-poursuite-infernale-2',
  },
  {
    note: '5',
    url:
      '/jeux/le-seigneur-des-anneaux-la-communaute-de-l-anneau-3010000350/test-pc.html',
    name: 'le-seigneur-des-anneaux-la-communaute-de-l-anneau',
  },
  {
    note: '7',
    url: '/jeux/total-immersion-racing-3010000686/test-pc.html',
    name: 'total-immersion-racing',
  },
  {
    note: '6',
    url: '/jeux/le-jeu-d-aventure-du-routard-24354/test.html',
    name: 'le-jeu-d-aventure-du-routard',
  },
  {
    note: '7',
    url: '/jeux/post-mortem-3010007914/test.html',
    name: 'post-mortem',
  },
  {
    note: '2',
    url: '/jeux/casino-empire-19945/test.html',
    name: 'casino-empire',
  },
  { note: '3', url: '/jeux/ring-ii-18665/test.html', name: 'ring-ii' },
  {
    note: '7',
    url: '/jeux/combat-mission-2-barbarossa-to-berlin-15554/test.html',
    name: 'combat-mission-2-barbarossa-to-berlin',
  },
  {
    note: '6',
    url: '/jeux/the-gladiators-17387/test.html',
    name: 'the-gladiators',
  },
  {
    note: '5',
    url: '/jeux/rollercoaster-tycoon-2-19100/test.html',
    name: 'rollercoaster-tycoon-2',
  },
  {
    note: '5',
    url: '/jeux/iron-storm-3010000458/test-pc.html',
    name: 'iron-storm',
  },
  {
    note: '7',
    url: '/jeux/x-plane-version-6-23265/test.html',
    name: 'x-plane-version-6',
  },
  {
    note: '4',
    url: '/jeux/thorgal-la-malediction-d-odin-3010000855/test.html',
    name: 'thorgal-la-malediction-d-odin',
  },
  {
    note: '9',
    url:
      '/jeux/no-one-lives-forever-2-le-c-r-i-m-e-est-eternel-3010000349/test-pc.html',
    name: 'no-one-lives-forever-2-le-c-r-i-m-e-est-eternel',
  },
  {
    note: '7',
    url: '/jeux/nhl-2003-3010000667/test-pc.html',
    name: 'nhl-2003',
  },
  {
    note: '5',
    url: '/jeux/frontline-attack-war-over-europe-21096/test.html',
    name: 'frontline-attack-war-over-europe',
  },
  {
    note: '3',
    url: '/jeux/beam-breakers-11007/test.html',
    name: 'beam-breakers',
  },
  {
    note: '5',
    url: '/jeux/port-royale-18632/test.html',
    name: 'port-royale',
  },
  {
    note: '8',
    url: '/jeux/virtual-skipper-2-20916/test.html',
    name: 'virtual-skipper-2',
  },
  {
    note: '8',
    url: '/jeux/hitman-2-3010000643/test-pc.html',
    name: 'hitman-2',
  },
  {
    note: '7',
    url: '/jeux/stronghold-crusader-19180/test.html',
    name: 'stronghold-crusader',
  },
  {
    note: '8',
    url: '/jeux/unreal-tournament-2003-3010005817/test.html',
    name: 'unreal-tournament-2003',
  },
  {
    note: '5',
    url: '/jeux/divine-divinity-3010000306/test.html',
    name: 'divine-divinity',
  },
  {
    note: '8',
    url: '/jeux/madden-nfl-2003-3010000662/test-pc.html',
    name: 'madden-nfl-2003',
  },
  {
    note: '8',
    url: '/jeux/medieval-total-war-13742/test.html',
    name: 'medieval-total-war',
  },
  {
    note: '5',
    url: '/jeux/face-factory-23422/test.html',
    name: 'face-factory',
  },
  {
    note: '5',
    url: '/jeux/batman-vengeance-3010000428/test-pc.html',
    name: 'batman-vengeance',
  },
  {
    note: '4',
    url: '/jeux/king-of-the-road-20923/test.html',
    name: 'king-of-the-road',
  },
  {
    note: '8',
    url: '/jeux/battlefield-1942-3010000194/test-pc.html',
    name: 'battlefield-1942',
  },
  {
    note: '9',
    url: '/jeux/arx-fatalis-3010000992/test-pc.html',
    name: 'arx-fatalis',
  },
  { note: '3', url: '/jeux/legion-13509/test.html', name: 'legion' },
  {
    note: '8',
    url: '/jeux/neverwinter-nights-3010004355/test-pc.html',
    name: 'neverwinter-nights',
  },
  {
    note: '3',
    url:
      '/jeux/largo-winch-aller-simple-pour-les-balkans-3010000496/test-pc.html',
    name: 'largo-winch-aller-simple-pour-les-balkans',
  },
  {
    note: '6',
    url: '/jeux/sudden-strike-2-17081/test.html',
    name: 'sudden-strike-2',
  },
  {
    note: '6',
    url: '/jeux/industry-giant-ii-15343/test.html',
    name: 'industry-giant-ii',
  },
  {
    note: '6',
    url: '/jeux/beach-life-14590/test.html',
    name: 'beach-life',
  },
  {
    note: '8',
    url: '/jeux/mafia-3010000208/test-pc.html',
    name: 'mafia',
  },
  {
    note: '3',
    url: '/jeux/panzer-elite-special-edition-21254/test.html',
    name: 'panzer-elite-special-edition',
  },
  {
    note: '7',
    url: '/jeux/conflict-desert-storm-3010000097/test-pc.html',
    name: 'conflict-desert-storm',
  },
  {
    note: '6',
    url: '/jeux/la-somme-de-toutes-les-peurs-3010000760/test.html',
    name: 'la-somme-de-toutes-les-peurs',
  },
  {
    note: '5',
    url: '/jeux/zoo-tycoon-dinosaur-digs-17869/test.html',
    name: 'zoo-tycoon-dinosaur-digs',
  },
  { note: '6', url: '/jeux/sim-golf-9735/test.html', name: 'sim-golf' },
  {
    note: '6',
    url: '/jeux/tactical-ops-assault-on-terror-17565/test.html',
    name: 'tactical-ops-assault-on-terror',
  },
  {
    note: '5',
    url: '/jeux/4x4-evo-2-3010000228/test.html',
    name: '4x4-evo-2',
  },
  {
    note: '6',
    url: '/jeux/kohan-battles-of-ahriman-19625/test.html',
    name: 'kohan-battles-of-ahriman',
  },
  {
    note: '5',
    url: '/jeux/football-mania-3010000766/test-pc.html',
    name: 'football-mania',
  },
  {
    note: '7',
    url: '/jeux/age-of-wonders-2-the-wizard-s-throne-4750/test.html',
    name: 'age-of-wonders-2-the-wizard-s-throne',
  },
  {
    note: '7',
    url: '/jeux/freedom-force-276/test.html',
    name: 'freedom-force',
  },
  {
    note: '8',
    url: '/jeux/operation-flashpoint-resistance-17329/test.html',
    name: 'operation-flashpoint-resistance',
  },
  {
    note: '4',
    url: '/jeux/cultures-2-les-portes-d-asgard-17376/test.html',
    name: 'cultures-2-les-portes-d-asgard',
  },
  {
    note: '8',
    url: '/jeux/grand-prix-4-3010000558/test.html',
    name: 'grand-prix-4',
  },
  {
    note: '9',
    url: '/jeux/warcraft-iii-reign-of-chaos-3010004310/test-pc.html',
    name: 'warcraft-iii-reign-of-chaos',
  },
  {
    note: '7',
    url: '/jeux/motogp-ultimate-racing-technology-3010000614/test.html',
    name: 'motogp-ultimate-racing-technology',
  },
  {
    note: '4',
    url: '/jeux/zanzarah-la-legende-des-deux-mondes-11861/test.html',
    name: 'zanzarah-la-legende-des-deux-mondes',
  },
  {
    note: '5',
    url: '/jeux/duke-nukem-manhattan-project-3010008143/test-pc.html',
    name: 'duke-nukem-manhattan-project',
  },
  { note: '6', url: '/jeux/gore-2948/test.html', name: 'gore' },
  {
    note: '4',
    url: '/jeux/cycling-manager-2-18916/test.html',
    name: 'cycling-manager-2',
  },
  {
    note: '6',
    url: '/jeux/mobile-forces-14213/test.html',
    name: 'mobile-forces',
  },
  {
    note: '4',
    url: '/jeux/hotel-giant-18256/test.html',
    name: 'hotel-giant',
  },
  {
    note: '8',
    url: '/jeux/f1-2002-3010000618/test-pc.html',
    name: 'f1-2002',
  },
  {
    note: '3',
    url: '/jeux/agassi-tennis-generation-3010000573/test.html',
    name: 'agassi-tennis-generation',
  },
  {
    note: '5',
    url: '/jeux/die-hard-piege-de-cristal-9623/test.html',
    name: 'die-hard-piege-de-cristal',
  },
  {
    note: '4',
    url: '/jeux/team-factor-4595/test.html',
    name: 'team-factor',
  },
  {
    note: '6',
    url: '/jeux/soldier-of-fortune-ii-double-helix-3010000952/test.html',
    name: 'soldier-of-fortune-ii-double-helix',
  },
  {
    note: '2',
    url: '/jeux/hot-wheels-williams-f1-team-driver-1194/test.html',
    name: 'hot-wheels-williams-f1-team-driver',
  },
  {
    note: '7',
    url: '/jeux/spider-man-3010000601/test-pc.html',
    name: 'spider-man',
  },
  {
    note: '5',
    url: '/jeux/syberia-3010000819/test.html',
    name: 'syberia',
  },
  {
    note: '7',
    url: '/jeux/dino-island-18834/test.html',
    name: 'dino-island',
  },
  {
    note: '4',
    url: '/jeux/roland-garros-2002-3010000534/test.html',
    name: 'roland-garros-2002',
  },
  {
    note: '6',
    url: '/jeux/ultima-online-lord-blackthorn-s-revenge-15341/test.html',
    name: 'ultima-online-lord-blackthorn-s-revenge',
  },
  {
    note: '9',
    url: '/jeux/grand-theft-auto-iii-3010000060/test-pc.html',
    name: 'grand-theft-auto-iii',
  },
  {
    note: '7',
    url: '/jeux/train-sim-the-activities-19819/test.html',
    name: 'train-sim-the-activities',
  },
  {
    note: '9',
    url: '/jeux/the-elder-scrolls-iii-morrowind-3010000351/test-pc.html',
    name: 'the-elder-scrolls-iii-morrowind',
  },
  {
    note: '1',
    url: '/jeux/incoming-forces-762/test.html',
    name: 'incoming-forces',
  },
  {
    note: '5',
    url: '/jeux/stealth-combat-16210/test.html',
    name: 'stealth-combat',
  },
  {
    note: '7',
    url: '/jeux/coupe-du-monde-fifa-2002-3010000594/test.html',
    name: 'coupe-du-monde-fifa-2002',
  },
  {
    note: '7',
    url: '/jeux/dungeon-siege-3010004345/test-pc.html',
    name: 'dungeon-siege',
  },
  {
    note: '6',
    url: '/jeux/global-operations-3668/test.html',
    name: 'global-operations',
  },
  {
    note: '6',
    url: '/jeux/blood-omen-2-3010000386/test-pc.html',
    name: 'blood-omen-2',
  },
  {
    note: '4',
    url: '/jeux/trains-and-trucks-tycoon-18681/test.html',
    name: 'trains-and-trucks-tycoon',
  },
  {
    note: '5',
    url: '/jeux/the-partners-17761/test.html',
    name: 'the-partners',
  },
  {
    note: '7',
    url: '/jeux/nascar-racing-season-2002-17192/test.html',
    name: 'nascar-racing-season-2002',
  },
  {
    note: '7',
    url: '/jeux/warlords-battlecry-ii-14694/test.html',
    name: 'warlords-battlecry-ii',
  },
  {
    note: '5',
    url: '/jeux/les-sims-en-vacances-3010008131/test-pc.html',
    name: 'les-sims-en-vacances',
  },
  {
    note: '7',
    url: '/jeux/rock-manager-17342/test.html',
    name: 'rock-manager',
  },
  {
    note: '6',
    url: '/jeux/destroyer-command-10733/test.html',
    name: 'destroyer-command',
  },
  {
    note: '6',
    url: '/jeux/might-and-magic-ix-11874/test.html',
    name: 'might-and-magic-ix',
  },
  {
    note: '8',
    url: '/jeux/virtua-tennis-3010000501/test-pc.html',
    name: 'virtua-tennis',
  },
  {
    note: '7',
    url: '/jeux/star-wars-jedi-knight-ii-jedi-outcast-3010000695/test.html',
    name: 'star-wars-jedi-knight-ii-jedi-outcast',
  },
  {
    note: '6',
    url: '/jeux/starmageddon-4797/test.html',
    name: 'starmageddon',
  },
  {
    note: '4',
    url: '/jeux/tom-clancy-s-ghost-recon-desert-siege-17112/test.html',
    name: 'tom-clancy-s-ghost-recon-desert-siege',
  },
  {
    note: '8',
    url: '/jeux/grandia-ii-3010000177/test-pc.html',
    name: 'grandia-ii',
  },
  {
    note: '6',
    url: '/jeux/le-secret-du-nautilus-17394/test.html',
    name: 'le-secret-du-nautilus',
  },
  {
    note: '9',
    url: '/jeux/heroes-of-might-and-magic-iv-4588/test.html',
    name: 'heroes-of-might-and-magic-iv',
  },
  {
    note: '5',
    url: '/jeux/worms-blast-3010000418/test.html',
    name: 'worms-blast',
  },
  {
    note: '6',
    url: '/jeux/le-maillon-faible-3010000431/test.html',
    name: 'le-maillon-faible',
  },
  {
    note: '6',
    url: '/jeux/clusterball-7713/test.html',
    name: 'clusterball',
  },
  { note: '6', url: '/jeux/trainz-430/test.html', name: 'trainz' },
  {
    note: '8',
    url: '/jeux/civilization-iii-3010004337/test.html',
    name: 'civilization-iii',
  },
  {
    note: '7',
    url: '/jeux/disciples-2-dark-prophecy-3010002057/test.html',
    name: 'disciples-2-dark-prophecy',
  },
  {
    note: '5',
    url: '/jeux/diggles-3010000557/test-pc.html',
    name: 'diggles',
  },
  {
    note: '5',
    url: '/jeux/command-conquer-renegade-1967/test.html',
    name: 'command-conquer-renegade',
  },
  {
    note: '7',
    url: '/jeux/warrior-kings-480/test.html',
    name: 'warrior-kings',
  },
  {
    note: '7',
    url: '/jeux/capitalism-2-10841/test.html',
    name: 'capitalism-2',
  },
  {
    note: '3',
    url: '/jeux/ski-park-manager-16959/test.html',
    name: 'ski-park-manager',
  },
  {
    note: '4',
    url: '/jeux/uefa-champions-league-season-2001-2002-3010000568/test-pc.html',
    name: 'uefa-champions-league-season-2001-2002',
  },
  {
    note: '8',
    url: '/jeux/dark-age-of-camelot-755/test.html',
    name: 'dark-age-of-camelot',
  },
  {
    note: '7',
    url: '/jeux/black-white-l-ile-aux-creatures-12242/test.html',
    name: 'black-white-l-ile-aux-creatures',
  },
  {
    note: '6',
    url: '/jeux/gorasul-l-heritage-du-dragon-12243/test.html',
    name: 'gorasul-l-heritage-du-dragon',
  },
  {
    note: '8',
    url: '/jeux/jimmy-white-s-cueball-world-10994/test.html',
    name: 'jimmy-white-s-cueball-world',
  },
  {
    note: '5',
    url: '/jeux/car-tycoon-11008/test.html',
    name: 'car-tycoon',
  },
  {
    note: '8',
    url: '/jeux/serious-sam-second-contact-14196/test.html',
    name: 'serious-sam-second-contact',
  },
  {
    note: '7',
    url: '/jeux/disciples-sacred-lands-gold-edition-17455/test.html',
    name: 'disciples-sacred-lands-gold-edition',
  },
  {
    note: '7',
    url: '/jeux/steel-beasts-1027/test.html',
    name: 'steel-beasts',
  },
  {
    note: '3',
    url: '/jeux/salt-lake-city-2002-3010000175/test-pc.html',
    name: 'salt-lake-city-2002',
  },
  {
    note: '9',
    url: '/jeux/medal-of-honor-debarquement-allie-4121/test.html',
    name: 'medal-of-honor-debarquement-allie',
  },
  {
    note: '7',
    url: '/jeux/tropico-paradise-island-15171/test.html',
    name: 'tropico-paradise-island',
  },
  {
    note: '3',
    url: '/jeux/casino-tycoon-12540/test.html',
    name: 'casino-tycoon',
  },
  {
    note: '8',
    url:
      '/jeux/operation-flashpoint-red-hammer-the-soviet-campaign-14021/test.html',
    name: 'operation-flashpoint-red-hammer-the-soviet-campaign',
  },
  {
    note: '8',
    url: '/jeux/comanche-4-11905/test.html',
    name: 'comanche-4',
  },
  {
    note: '6',
    url: '/jeux/waterloo-la-bataille-finale-de-napoleon-4417/test.html',
    name: 'waterloo-la-bataille-finale-de-napoleon',
  },
  {
    note: '6',
    url: '/jeux/everquest-shadows-of-luclin-9402/test.html',
    name: 'everquest-shadows-of-luclin',
  },
  {
    note: '5',
    url: '/jeux/the-nations-15342/test.html',
    name: 'the-nations',
  },
  {
    note: '6',
    url: '/jeux/trade-empires-8705/test.html',
    name: 'trade-empires',
  },
  {
    note: '3',
    url: '/jeux/supercar-street-challenge-3010000158/test-pc.html',
    name: 'supercar-street-challenge',
  },
  {
    note: '5',
    url: '/jeux/rayman-m-3010000335/test.html',
    name: 'rayman-m',
  },
  {
    note: '7',
    url: '/jeux/pool-of-radiance-ruins-of-myth-drannor-146/test.html',
    name: 'pool-of-radiance-ruins-of-myth-drannor',
  },
  {
    note: '6',
    url: '/jeux/cossacks-the-art-of-war-13953/test.html',
    name: 'cossacks-the-art-of-war',
  },
  {
    note: '8',
    url: '/jeux/silent-hunter-ii-722/test.html',
    name: 'silent-hunter-ii',
  },
  {
    note: '7',
    url: '/jeux/fighting-legends-3805/test.html',
    name: 'fighting-legends',
  },
  { note: '5', url: '/jeux/schizm-3010000529/test.html', name: 'schizm' },
  {
    note: '3',
    url: '/jeux/atlantide-l-empire-perdu-3010000399/test-pc.html',
    name: 'atlantide-l-empire-perdu',
  },
  {
    note: '7',
    url: '/jeux/master-rallye-3010000241/test.html',
    name: 'master-rallye',
  },
  {
    note: '7',
    url: '/jeux/europa-universalis-ii-3010001821/test.html',
    name: 'europa-universalis-ii',
  },
  {
    note: '5',
    url: '/jeux/s-w-i-n-e-11010/test.html',
    name: 's-w-i-n-e',
  },
  { note: '7', url: '/jeux/duelfield-3517/test.html', name: 'duelfield' },
  { note: '6', url: '/jeux/aquanox-3628/test.html', name: 'aquanox' },
  {
    note: '4',
    url: '/jeux/moto-racer-3-9939/test.html',
    name: 'moto-racer-3',
  },
  {
    note: '6',
    url: '/jeux/les-sims-et-plus-si-affinites-3010008130/test.html',
    name: 'les-sims-et-plus-si-affinites',
  },
  {
    note: '8',
    url: '/jeux/il-2-sturmovik-1072/test.html',
    name: 'il-2-sturmovik',
  },
  {
    note: '6',
    url: '/jeux/etherlords-9877/test.html',
    name: 'etherlords',
  },
  {
    note: '8',
    url: '/jeux/battle-realms-813/test.html',
    name: 'battle-realms',
  },
  {
    note: '8',
    url: '/jeux/return-to-castle-wolfenstein-3010000417/test-pc.html',
    name: 'return-to-castle-wolfenstein',
  },
  {
    note: '5',
    url: '/jeux/world-war-iii-black-gold-4028/test.html',
    name: 'world-war-iii-black-gold',
  },
  {
    note: '5',
    url: '/jeux/parkan-iron-strategy-13741/test.html',
    name: 'parkan-iron-strategy',
  },
  {
    note: '8',
    url: '/jeux/conquest-frontier-wars-101/test.html',
    name: 'conquest-frontier-wars',
  },
  {
    note: '3',
    url: '/jeux/loch-ness-16362/test.html',
    name: 'loch-ness',
  },
  {
    note: '6',
    url: '/jeux/rally-championship-2002-7953/test.html',
    name: 'rally-championship-2002',
  },
  {
    note: '8',
    url: '/jeux/tom-clancy-s-ghost-recon-3010000323/test.html',
    name: 'tom-clancy-s-ghost-recon',
  },
  {
    note: '9',
    url: '/jeux/flight-simulator-2002-8394/test.html',
    name: 'flight-simulator-2002',
  },
  { note: '2', url: '/jeux/real-war-3645/test.html', name: 'real-war' },
  {
    note: '5',
    url: '/jeux/ultimate-ride-12475/test.html',
    name: 'ultimate-ride',
  },
  {
    note: '6',
    url: '/jeux/star-wars-galactic-battlegrounds-8004/test.html',
    name: 'star-wars-galactic-battlegrounds',
  },
  {
    note: '8',
    url: '/jeux/empire-earth-152/test.html',
    name: 'empire-earth',
  },
  {
    note: '8',
    url: '/jeux/harry-potter-a-l-ecole-des-sorciers-3010000299/test-pc.html',
    name: 'harry-potter-a-l-ecole-des-sorciers',
  },
  {
    note: '5',
    url: '/jeux/mat-hoffman-s-pro-bmx-3010000154/test-pc.html',
    name: 'mat-hoffman-s-pro-bmx',
  },
  {
    note: '7',
    url: '/jeux/myth-iii-the-wolf-age-3010004354/test.html',
    name: 'myth-iii-the-wolf-age',
  },
  {
    note: '7',
    url: '/jeux/disney-dingo-extreme-skateboarding-15157/test.html',
    name: 'disney-dingo-extreme-skateboarding',
  },
  {
    note: '8',
    url: '/jeux/aliens-versus-predator-2-3010000438/test-pc.html',
    name: 'aliens-versus-predator-2',
  },
  {
    note: '2',
    url: '/jeux/l-ombre-de-zorro-3010000406/test.html',
    name: 'l-ombre-de-zorro',
  },
  {
    note: '6',
    url: '/jeux/nyr-new-york-race-3010000221/test.html',
    name: 'nyr-new-york-race',
  },
  {
    note: '7',
    url: '/jeux/woody-woodpecker-3010000511/test.html',
    name: 'woody-woodpecker',
  },
  {
    note: '5',
    url: '/jeux/megarace-3-3010000368/test.html',
    name: 'megarace-3',
  },
  {
    note: '7',
    url: '/jeux/ballistics-3671/test.html',
    name: 'ballistics',
  },
  {
    note: '7',
    url: '/jeux/rally-trophy-14848/test.html',
    name: 'rally-trophy',
  },
  {
    note: '8',
    url: '/jeux/zoo-tycoon-3010004332/test.html',
    name: 'zoo-tycoon',
  },
  {
    note: '6',
    url: '/jeux/frank-herbert-s-dune-3010000509/test.html',
    name: 'frank-herbert-s-dune',
  },
  { note: '8', url: '/jeux/colobot-15450/test.html', name: 'colobot' },
  {
    note: '6',
    url: '/jeux/zax-the-alien-hunter-13900/test.html',
    name: 'zax-the-alien-hunter',
  },
  {
    note: '4',
    url: '/jeux/rogue-spear-black-thorn-10839/test.html',
    name: 'rogue-spear-black-thorn',
  },
  {
    note: '8',
    url: '/jeux/fifa-football-2002-3010000439/test-pc.html',
    name: 'fifa-football-2002',
  },
  {
    note: '7',
    url: '/jeux/toy-trains-15376/test.html',
    name: 'toy-trains',
  },
  {
    note: '6',
    url: '/jeux/lnf-manager-2002-9457/test.html',
    name: 'lnf-manager-2002',
  },
  {
    note: '4',
    url: '/jeux/primitive-wars-14368/test.html',
    name: 'primitive-wars',
  },
  {
    note: '7',
    url: '/jeux/l-entraineur-saison-2001-2002-3010000723/test-pc.html',
    name: 'l-entraineur-saison-2001-2002',
  },
  {
    note: '4',
    url: '/jeux/m-m-s-les-formules-perdues-2524/test.html',
    name: 'm-m-s-les-formules-perdues',
  },
  {
    note: '6',
    url: '/jeux/project-eden-3010000114/test.html',
    name: 'project-eden',
  },
  {
    note: '5',
    url: '/jeux/monopoly-tycoon-10732/test.html',
    name: 'monopoly-tycoon',
  },
  {
    note: '9',
    url: '/jeux/stronghold-3010008134/test-pc.html',
    name: 'stronghold',
  },
  {
    note: '5',
    url: '/jeux/x-com-enforcer-4385/test.html',
    name: 'x-com-enforcer',
  },
  {
    note: '5',
    url: '/jeux/the-art-of-magic-4621/test.html',
    name: 'the-art-of-magic',
  },
  {
    note: '8',
    url: '/jeux/f1-2001-3010000480/test.html',
    name: 'f1-2001',
  },
  {
    note: '5',
    url: '/jeux/celtic-kings-rage-of-war-17408/test.html',
    name: 'celtic-kings-rage-of-war',
  },
  {
    note: '3',
    url: '/jeux/la-planete-des-singes-3010000460/test.html',
    name: 'la-planete-des-singes',
  },
  {
    note: '8',
    url: '/jeux/nhl-2002-3010000379/test-pc.html',
    name: 'nhl-2002',
  },
  {
    note: '7',
    url: '/jeux/tennis-masters-series-3010000392/test.html',
    name: 'tennis-masters-series',
  },
  { note: '2', url: '/jeux/druuna-3010000239/test.html', name: 'druuna' },
  {
    note: '6',
    url: '/jeux/evil-twin-cyprien-s-chronicles-3010000104/test.html',
    name: 'evil-twin-cyprien-s-chronicles',
  },
  {
    note: '7',
    url: '/jeux/sub-command-9373/test.html',
    name: 'sub-command',
  },
  {
    note: '5',
    url: '/jeux/anarchy-online-758/test.html',
    name: 'anarchy-online',
  },
  {
    note: '7',
    url: '/jeux/shogun-total-war-warlord-edition-3954/test.html',
    name: 'shogun-total-war-warlord-edition',
  },
  {
    note: '3',
    url: '/jeux/atlantis-iii-le-nouveau-monde-3010000446/test.html',
    name: 'atlantis-iii-le-nouveau-monde',
  },
  {
    note: '7',
    url: '/jeux/throne-of-darkness-166/test.html',
    name: 'throne-of-darkness',
  },
  {
    note: '2',
    url: '/jeux/paris-dakar-rally-3010000189/test-pc.html',
    name: 'paris-dakar-rally',
  },
  {
    note: '9',
    url: '/jeux/commandos-2-men-of-courage-3010000031/test-pc.html',
    name: 'commandos-2-men-of-courage',
  },
  {
    note: '6',
    url: '/jeux/codename-outbreak-1366/test.html',
    name: 'codename-outbreak',
  },
  {
    note: '6',
    url: '/jeux/crazy-factory-11041/test.html',
    name: 'crazy-factory',
  },
  {
    note: '6',
    url: '/jeux/spider-man-3010000034/test-pc.html',
    name: 'spider-man',
  },
  {
    note: '7',
    url: '/jeux/red-faction-3010000093/test.html',
    name: 'red-faction',
  },
  { note: '8', url: '/jeux/x-plane-2675/test.html', name: 'x-plane' },
  {
    note: '6',
    url: '/jeux/mechcommander-2-1475/test.html',
    name: 'mechcommander-2',
  },
  { note: '8', url: '/jeux/arcanum-110/test.html', name: 'arcanum' },
  {
    note: '5',
    url: '/jeux/shogun-total-war-l-invasion-mongole-4409/test.html',
    name: 'shogun-total-war-l-invasion-mongole',
  },
  {
    note: '8',
    url: '/jeux/myst-iii-exile-3010000232/test.html',
    name: 'myst-iii-exile',
  },
  {
    note: '3',
    url: '/jeux/eurotour-cycling-11794/test.html',
    name: 'eurotour-cycling',
  },
  {
    note: '8',
    url: '/jeux/train-simulator-4351/test.html',
    name: 'train-simulator',
  },
  {
    note: '8',
    url: '/jeux/une-faim-de-loup-3010000390/test-pc.html',
    name: 'une-faim-de-loup',
  },
  {
    note: '2',
    url: '/jeux/une-nuit-en-enfer-8227/test.html',
    name: 'une-nuit-en-enfer',
  },
  {
    note: '5',
    url: '/jeux/ski-doo-x-team-racing-9741/test.html',
    name: 'ski-doo-x-team-racing',
  },
  {
    note: '3',
    url: '/jeux/the-outforce-1209/test.html',
    name: 'the-outforce',
  },
  {
    note: '3',
    url: '/jeux/the-house-of-the-dead-2-3010000150/test-pc.html',
    name: 'the-house-of-the-dead-2',
  },
  {
    note: '6',
    url: '/jeux/sail-simulator-4-0-13958/test.html',
    name: 'sail-simulator-4-0',
  },
  { note: '6', url: '/jeux/echelon-815/test.html', name: 'echelon' },
  {
    note: '8',
    url: '/jeux/edge-of-chaos-independence-war-2-86/test.html',
    name: 'edge-of-chaos-independence-war-2',
  },
  {
    note: '9',
    url: '/jeux/max-payne-3010000250/test-pc.html',
    name: 'max-payne',
  },
  {
    note: '4',
    url: '/jeux/uefa-challenge-3010000247/test-pc.html',
    name: 'uefa-challenge',
  },
  {
    note: '3',
    url: '/jeux/cycling-manager-8938/test.html',
    name: 'cycling-manager',
  },
  {
    note: '8',
    url: '/jeux/baldur-s-gate-ii-throne-of-bhaal-3010006141/test.html',
    name: 'baldur-s-gate-ii-throne-of-bhaal',
  },
  { note: '6', url: '/jeux/anachronox-57/test.html', name: 'anachronox' },
  {
    note: '3',
    url: '/jeux/gangsters-2-vendetta-3335/test.html',
    name: 'gangsters-2-vendetta',
  },
  { note: '6', url: '/jeux/fly-ii-4539/test.html', name: 'fly-ii' },
  {
    note: '8',
    url: '/jeux/combat-mission-beyond-overlord-7899/test.html',
    name: 'combat-mission-beyond-overlord',
  },
  {
    note: '6',
    url: '/jeux/technomage-3010003098/test.html',
    name: 'technomage',
  },
  {
    note: '5',
    url: '/jeux/qui-veut-gagner-des-millions-3010000410/test-pc.html',
    name: 'qui-veut-gagner-des-millions',
  },
  {
    note: '3',
    url: '/jeux/legends-of-might-magic-112/test.html',
    name: 'legends-of-might-magic',
  },
  {
    note: '8',
    url: '/jeux/diablo-ii-lord-of-destruction-3010005576/test-pc.html',
    name: 'diablo-ii-lord-of-destruction',
  },
  {
    note: '4',
    url: '/jeux/original-war-1207/test.html',
    name: 'original-war',
  },
  {
    note: '3',
    url: '/jeux/persian-wars-7874/test.html',
    name: 'persian-wars',
  },
  {
    note: '8',
    url: '/jeux/startopia-space-city-165/test.html',
    name: 'startopia-space-city',
  },
  {
    note: '6',
    url: '/jeux/empereur-la-bataille-pour-dune-1147/test.html',
    name: 'empereur-la-bataille-pour-dune',
  },
  {
    note: '8',
    url: '/jeux/operation-flashpoint-3010000197/test-pc.html',
    name: 'operation-flashpoint',
  },
  {
    note: '3',
    url: '/jeux/europe-racing-3010001595/test-pc.html',
    name: 'europe-racing',
  },
  {
    note: '4',
    url: '/jeux/half-life-blue-shift-8799/test.html',
    name: 'half-life-blue-shift',
  },
  {
    note: '4',
    url:
      '/jeux/les-chevaliers-d-arthur-chapitre-2-le-secret-de-merlin-2752/test.html',
    name: 'les-chevaliers-d-arthur-chapitre-2-le-secret-de-merlin',
  },
  { note: '6', url: '/jeux/eracer-3010000248/test.html', name: 'eracer' },
  {
    note: '5',
    url: '/jeux/jekyll-hyde-cryo-3010001680/test.html',
    name: 'jekyll-hyde-cryo',
  },
  {
    note: '5',
    url: '/jeux/open-kart-3010000242/test.html',
    name: 'open-kart',
  },
  {
    note: '3',
    url: '/jeux/roland-garros-2001-3010000380/test-pc.html',
    name: 'roland-garros-2001',
  },
  {
    note: '7',
    url: '/jeux/z-steel-soldiers-842/test.html',
    name: 'z-steel-soldiers',
  },
  {
    note: '7',
    url: '/jeux/conflict-zone-3010000113/test-pc.html',
    name: 'conflict-zone',
  },
  {
    note: '5',
    url: '/jeux/eurofighter-typhoon-8296/test.html',
    name: 'eurofighter-typhoon',
  },
  {
    note: '6',
    url: '/jeux/kohan-immortal-sovereign-4568/test.html',
    name: 'kohan-immortal-sovereign',
  },
  {
    note: '8',
    url: '/jeux/tropico-3010000182/test.html',
    name: 'tropico',
  },
  {
    note: '7',
    url: '/jeux/desperados-1418/test.html',
    name: 'desperados',
  },
  {
    note: '6',
    url: '/jeux/delta-force-land-warrior-304/test.html',
    name: 'delta-force-land-warrior',
  },
  {
    note: '5',
    url: '/jeux/summoner-3010000041/test.html',
    name: 'summoner',
  },
  {
    note: '6',
    url: '/jeux/globetrotter-8820/test.html',
    name: 'globetrotter',
  },
  {
    note: '7',
    url: '/jeux/the-settlers-iv-1368/test.html',
    name: 'the-settlers-iv',
  },
  {
    note: '6',
    url: '/jeux/fallout-tactics-180/test.html',
    name: 'fallout-tactics',
  },
  {
    note: '7',
    url: '/jeux/cossacks-european-wars-1431/test.html',
    name: 'cossacks-european-wars',
  },
  {
    note: '5',
    url: '/jeux/kuzco-l-empereur-megalo-3010000315/test-pc.html',
    name: 'kuzco-l-empereur-megalo',
  },
  {
    note: '5',
    url: '/jeux/les-sims-surprise-partie-3010008129/test.html',
    name: 'les-sims-surprise-partie',
  },
  {
    note: '6',
    url: '/jeux/earth-2150-the-moon-project-63/test.html',
    name: 'earth-2150-the-moon-project',
  },
  {
    note: '5',
    url: '/jeux/adventure-pinball-forgotten-island-4774/test.html',
    name: 'adventure-pinball-forgotten-island',
  },
  {
    note: '5',
    url: '/jeux/star-trek-away-team-4464/test.html',
    name: 'star-trek-away-team',
  },
  {
    note: '8',
    url: '/jeux/tribes-aerial-assault-3010000329/test-pc.html',
    name: 'tribes-aerial-assault',
  },
  {
    note: '9',
    url: '/jeux/black-white-3010000026/test.html',
    name: 'black-white',
  },
  {
    note: '4',
    url: '/jeux/star-wars-episode-i-battle-for-naboo-3010000195/test-pc.html',
    name: 'star-wars-episode-i-battle-for-naboo',
  },
  {
    note: '6',
    url: '/jeux/the-typing-of-the-dead-3010000192/test-pc.html',
    name: 'the-typing-of-the-dead',
  },
  {
    note: '6',
    url: '/jeux/hostile-waters-4334/test.html',
    name: 'hostile-waters',
  },
  {
    note: '6',
    url: '/jeux/offroad-racing-4332/test.html',
    name: 'offroad-racing',
  },
  {
    note: '6',
    url: '/jeux/evil-islands-172/test.html',
    name: 'evil-islands',
  },
  {
    note: '8',
    url: '/jeux/serious-sam-premier-contact-1762/test.html',
    name: 'serious-sam-premier-contact',
  },
  {
    note: '5',
    url: '/jeux/pizza-connection-2-3833/test.html',
    name: 'pizza-connection-2',
  },
  {
    note: '5',
    url: '/jeux/starship-titanic-4838/test.html',
    name: 'starship-titanic',
  },
  {
    note: '5',
    url: '/jeux/icewind-dale-heart-of-winter-676/test.html',
    name: 'icewind-dale-heart-of-winter',
  },
  {
    note: '6',
    url: '/jeux/destin-du-dragon-les-trois-royaumes-628/test.html',
    name: 'destin-du-dragon-les-trois-royaumes',
  },
  {
    note: '5',
    url: '/jeux/europa-universalis-1059/test.html',
    name: 'europa-universalis',
  },
  {
    note: '9',
    url: '/jeux/f1-racing-championship-3010000108/test-pc.html',
    name: 'f1-racing-championship',
  },
  {
    note: '6',
    url: '/jeux/deep-space-nine-the-fallen-873/test.html',
    name: 'deep-space-nine-the-fallen',
  },
  {
    note: '7',
    url: '/jeux/worms-world-party-3010000253/test-pc.html',
    name: 'worms-world-party',
  },
  {
    note: '5',
    url: '/jeux/nascar-racing-4-4875/test.html',
    name: 'nascar-racing-4',
  },
  {
    note: '3',
    url: '/jeux/heroes-chronicles-clash-of-the-dragons-609/test.html',
    name: 'heroes-chronicles-clash-of-the-dragons',
  },
  {
    note: '7',
    url: '/jeux/clive-barker-s-undying-3010000387/test-pc.html',
    name: 'clive-barker-s-undying',
  },
  {
    note: '7',
    url: '/jeux/nba-live-2001-3010000112/test-pc.html',
    name: 'nba-live-2001',
  },
  { note: '2', url: '/jeux/timeline-1847/test.html', name: 'timeline' },
  {
    note: '7',
    url: '/jeux/world-championship-snooker-3010000411/test-pc.html',
    name: 'world-championship-snooker',
  },
  {
    note: '6',
    url: '/jeux/age-of-sail-2-852/test.html',
    name: 'age-of-sail-2',
  },
  {
    note: '7',
    url: '/jeux/ultimate-blade-of-darkness-3010000478/test-pc.html',
    name: 'ultimate-blade-of-darkness',
  },
  {
    note: '4',
    url: '/jeux/theme-park-inc-3511/test.html',
    name: 'theme-park-inc',
  },
  {
    note: '4',
    url: '/jeux/blair-witch-episode-3-le-conte-d-elly-kedward-105/test.html',
    name: 'blair-witch-episode-3-le-conte-d-elly-kedward',
  },
  { note: '4', url: '/jeux/heist-3337/test.html', name: 'heist' },
  { note: '2', url: '/jeux/the-ward-1626/test.html', name: 'the-ward' },
  {
    note: '5',
    url: '/jeux/heroes-chronicles-conquest-of-the-underworld-607/test.html',
    name: 'heroes-chronicles-conquest-of-the-underworld',
  },
  {
    note: '5',
    url: '/jeux/arabian-nights-183/test.html',
    name: 'arabian-nights',
  },
  {
    note: '5',
    url: '/jeux/heroes-chronicles-master-of-the-elements-608/test.html',
    name: 'heroes-chronicles-master-of-the-elements',
  },
  {
    note: '5',
    url: '/jeux/heroes-chronicles-warlords-of-the-wasteland-606/test.html',
    name: 'heroes-chronicles-warlords-of-the-wasteland',
  },
  {
    note: '7',
    url: '/jeux/battle-isle-the-andosia-war-106/test.html',
    name: 'battle-isle-the-andosia-war',
  },
  {
    note: '7',
    url: '/jeux/le-livre-de-la-jungle-groove-party-3010000096/test-pc.html',
    name: 'le-livre-de-la-jungle-groove-party',
  },
  {
    note: '2',
    url: '/jeux/times-of-conflict-3183/test.html',
    name: 'times-of-conflict',
  },
  {
    note: '6',
    url: '/jeux/les-102-dalmatiens-a-la-rescousse-3010000155/test.html',
    name: 'les-102-dalmatiens-a-la-rescousse',
  },
  {
    note: '8',
    url: '/jeux/donald-couatk-attatk-3010002543/test-pc.html',
    name: 'donald-couatk-attatk',
  },
  {
    note: '7',
    url: '/jeux/star-trek-starfleet-command-ii-empires-at-war-872/test.html',
    name: 'star-trek-starfleet-command-ii-empires-at-war',
  },
  {
    note: '3',
    url: '/jeux/ford-racing-2001-4237/test.html',
    name: 'ford-racing-2001',
  },
  {
    note: '8',
    url: '/jeux/rocket-arena-3-35/test.html',
    name: 'rocket-arena-3',
  },
  {
    note: '9',
    url: '/jeux/mechwarrior-4-vengeance-282/test.html',
    name: 'mechwarrior-4-vengeance',
  },
  {
    note: '7',
    url: '/jeux/championship-surfer-3010000040/test.html',
    name: 'championship-surfer',
  },
  { note: '7', url: '/jeux/oni-3010000076/test-pc.html', name: 'oni' },
  {
    note: '3',
    url: '/jeux/squad-leader-1360/test.html',
    name: 'squad-leader',
  },
  {
    note: '7',
    url: '/jeux/chessmaster-8000-4577/test.html',
    name: 'chessmaster-8000',
  },
  {
    note: '8',
    url: '/jeux/ground-control-dark-conspiracy-456/test.html',
    name: 'ground-control-dark-conspiracy',
  },
  {
    note: '8',
    url: '/jeux/giants-citizen-kabuto-3010000086/test-pc.html',
    name: 'giants-citizen-kabuto',
  },
  {
    note: '3',
    url: '/jeux/crime-cities-4020/test.html',
    name: 'crime-cities',
  },
  {
    note: '5',
    url: '/jeux/kingdom-under-fire-3010000321/test.html',
    name: 'kingdom-under-fire',
  },
  {
    note: '2',
    url: '/jeux/mtv-sports-skateboarding-3010000064/test-pc.html',
    name: 'mtv-sports-skateboarding',
  },
  { note: '2', url: '/jeux/tv-star-3362/test.html', name: 'tv-star' },
  { note: '8', url: '/jeux/sea-dogs-102/test.html', name: 'sea-dogs' },
  {
    note: '6',
    url: '/jeux/rogue-spear-mission-pack-urban-operations-1257/test.html',
    name: 'rogue-spear-mission-pack-urban-operations',
  },
  {
    note: '6',
    url: '/jeux/quake-iii-team-arena-3010006132/test.html',
    name: 'quake-iii-team-arena',
  },
  {
    note: '7',
    url: '/jeux/stupid-invaders-3010003675/test.html',
    name: 'stupid-invaders',
  },
  {
    note: '4',
    url: '/jeux/chicken-run-3010000089/test-pc.html',
    name: 'chicken-run',
  },
  {
    note: '5',
    url: '/jeux/madden-nfl-2001-3010000116/test-pc.html',
    name: 'madden-nfl-2001',
  },
  { note: '4', url: '/jeux/far-gate-1632/test.html', name: 'far-gate' },
  {
    note: '8',
    url: '/jeux/b17-flying-fortress-ii-the-mighty-8th-113/test.html',
    name: 'b17-flying-fortress-ii-the-mighty-8th',
  },
  {
    note: '6',
    url: '/jeux/nascar-heat-2002-3010000223/test.html',
    name: 'nascar-heat-2002',
  },
  {
    note: '7',
    url: '/jeux/warm-up-3010000122/test.html',
    name: 'warm-up',
  },
  {
    note: '5',
    url: '/jeux/resident-evil-3-nemesis-3010000042/test.html',
    name: 'resident-evil-3-nemesis',
  },
  {
    note: '6',
    url: '/jeux/pro-rally-2001-1367/test.html',
    name: 'pro-rally-2001',
  },
  {
    note: '5',
    url: '/jeux/screamer-4x4-739/test.html',
    name: 'screamer-4x4',
  },
  {
    note: '9',
    url: '/jeux/colin-mcrae-rally-2-0-3010000004/test-pc.html',
    name: 'colin-mcrae-rally-2-0',
  },
  {
    note: '7',
    url: '/jeux/call-to-power-ii-119/test.html',
    name: 'call-to-power-ii',
  },
  {
    note: '8',
    url: '/jeux/virtual-pool-3-3010008845/test-pc.html',
    name: 'virtual-pool-3',
  },
  {
    note: '1',
    url: '/jeux/hellboy-dogs-of-the-night-3010005567/test-pc.html',
    name: 'hellboy-dogs-of-the-night',
  },
];

const gkreviews3: Array<{ note?: string; url: string; name: string }> = [
  {
    note: '6',
    url: '/jeux/gunman-chronicles-1229/test.html',
    name: 'gunman-chronicles',
  },
  {
    note: '8',
    url: '/jeux/alice-3010000144/test-pc.html',
    name: 'alice',
  },
  {
    note: '6',
    url: '/jeux/airfix-dogfighter-1150/test.html',
    name: 'airfix-dogfighter',
  },
  {
    note: '6',
    url: '/jeux/kao-le-kangourou-3010000149/test-pc.html',
    name: 'kao-le-kangourou',
  },
  {
    note: '8',
    url: '/jeux/project-igi-i-m-going-in-121/test.html',
    name: 'project-igi-i-m-going-in',
  },
  {
    note: '8',
    url: '/jeux/wasteland-half-life-3437/test.html',
    name: 'wasteland-half-life',
  },
  {
    note: '5',
    url: '/jeux/tomb-raider-sur-les-traces-de-lara-croft-3010000092/test.html',
    name: 'tomb-raider-sur-les-traces-de-lara-croft',
  },
  {
    note: '4',
    url: '/jeux/necronomicon-l-aube-des-tenebres-3010000117/test-pc.html',
    name: 'necronomicon-l-aube-des-tenebres',
  },
  {
    note: '4',
    url: '/jeux/jetfighter-iv-fortress-america-1656/test.html',
    name: 'jetfighter-iv-fortress-america',
  },
  { note: '5', url: '/jeux/1nsane-1473/test.html', name: '1nsane' },
  {
    note: '7',
    url: '/jeux/hitman-tueur-a-gages-3010000036/test-pc.html',
    name: 'hitman-tueur-a-gages',
  },
  {
    note: '5',
    url: '/jeux/le-psy-c-est-vous-cas-n01-lucienne-a-1644/test.html',
    name: 'le-psy-c-est-vous-cas-n01-lucienne-a',
  },
  {
    note: '4',
    url: '/jeux/bugdom-3010007794/test-pc.html',
    name: 'bugdom',
  },
  {
    note: '8',
    url: '/jeux/no-one-lives-forever-3010000348/test-pc.html',
    name: 'no-one-lives-forever',
  },
  {
    note: '7',
    url: '/jeux/escape-from-monkey-island-3010000298/test-pc.html',
    name: 'escape-from-monkey-island',
  },
  {
    note: '2',
    url: '/jeux/motocross-mania-3010000436/test.html',
    name: 'motocross-mania',
  },
  {
    note: '3',
    url:
      '/jeux/les-chevaliers-d-arthur-chapitre-1-origines-d-excalibur-1643/test.html',
    name: 'les-chevaliers-d-arthur-chapitre-1-origines-d-excalibur',
  },
  {
    note: '6',
    url: '/jeux/richesses-du-monde-1381/test.html',
    name: 'richesses-du-monde',
  },
  {
    note: '5',
    url: '/jeux/de-sang-froid-3010000005/test.html',
    name: 'de-sang-froid',
  },
  { note: '5', url: '/jeux/gunlok-108/test.html', name: 'gunlok' },
  {
    note: '5',
    url: '/jeux/blair-witch-episode-2-la-legende-de-coffin-rock-104/test.html',
    name: 'blair-witch-episode-2-la-legende-de-coffin-rock',
  },
  {
    note: '7',
    url: '/jeux/s-w-a-t-3-elite-edition-816/test.html',
    name: 's-w-a-t-3-elite-edition',
  },
  {
    note: '5',
    url: '/jeux/submarine-titans-1208/test.html',
    name: 'submarine-titans',
  },
  {
    note: '7',
    url: '/jeux/links-2001-727/test.html',
    name: 'links-2001',
  },
  { note: '7', url: '/jeux/gift-3010000012/test.html', name: 'gift' },
  {
    note: '7',
    url: '/jeux/l-entraineur-2-saison-2000-2001-1101/test.html',
    name: 'l-entraineur-2-saison-2000-2001',
  },
  {
    note: '6',
    url: '/jeux/le-maitre-de-l-olympe-zeus-996/test.html',
    name: 'le-maitre-de-l-olympe-zeus',
  },
  {
    note: '5',
    url: '/jeux/cultures-a-la-decouverte-du-vinland-1346/test.html',
    name: 'cultures-a-la-decouverte-du-vinland',
  },
  {
    note: '9',
    url: '/jeux/counter-strike-3010001032/test-pc.html',
    name: 'counter-strike',
  },
  {
    note: '7',
    url: '/jeux/metal-gear-solid-integral-3010003565/test.html',
    name: 'metal-gear-solid-integral',
  },
  {
    note: '2',
    url: '/jeux/la-legende-du-prophete-et-de-l-assassin-1416/test.html',
    name: 'la-legende-du-prophete-et-de-l-assassin',
  },
  {
    note: '3',
    url: '/jeux/super-dominos-3d-1615/test.html',
    name: 'super-dominos-3d',
  },
  {
    note: '6',
    url: '/jeux/command-conquer-alerte-rouge-2-177/test.html',
    name: 'command-conquer-alerte-rouge-2',
  },
  {
    note: '3',
    url: '/jeux/super-casino-3d-1505/test.html',
    name: 'super-casino-3d',
  },
  {
    note: '7',
    url: '/jeux/sudden-strike-59/test.html',
    name: 'sudden-strike',
  },
  {
    note: '4',
    url: '/jeux/rainbow-six-covert-operations-1430/test.html',
    name: 'rainbow-six-covert-operations',
  },
  { note: '7', url: '/jeux/rune-3010000500/test-pc.html', name: 'rune' },
  {
    note: '4',
    url: '/jeux/microsoft-casino-1378/test.html',
    name: 'microsoft-casino',
  },
  {
    note: '6',
    url: '/jeux/wizards-warriors-721/test.html',
    name: 'wizards-warriors',
  },
  { note: '5', url: '/jeux/sheep-3010000020/test.html', name: 'sheep' },
  {
    note: '5',
    url: '/jeux/hogs-of-war-les-cochons-de-guerre-3010000824/test.html',
    name: 'hogs-of-war-les-cochons-de-guerre',
  },
  {
    note: '7',
    url: '/jeux/tony-hawk-s-pro-skater-2-3010000045/test-pc.html',
    name: 'tony-hawk-s-pro-skater-2',
  },
  {
    note: '3',
    url: '/jeux/wall-street-trader-2001-1302/test.html',
    name: 'wall-street-trader-2001',
  },
  {
    note: '5',
    url: '/jeux/4x4-evolution-3010000136/test.html',
    name: '4x4-evolution',
  },
  {
    note: '4',
    url: '/jeux/airline-tycoon-367/test.html',
    name: 'airline-tycoon',
  },
  {
    note: '4',
    url: '/jeux/reach-for-the-stars-986/test.html',
    name: 'reach-for-the-stars',
  },
  { note: '8', url: '/jeux/fly-2k-167/test.html', name: 'fly-2k' },
  {
    note: '8',
    url: '/jeux/combat-flight-simulator-2-guerre-du-pacifique-670/test.html',
    name: 'combat-flight-simulator-2-guerre-du-pacifique',
  },
  {
    note: '4',
    url: '/jeux/kiss-pinball-3010002648/test-pc.html',
    name: 'kiss-pinball',
  },
  {
    note: '4',
    url: '/jeux/dracula-2-le-dernier-sanctuaire-3010000517/test-pc.html',
    name: 'dracula-2-le-dernier-sanctuaire',
  },
  { note: '8', url: '/jeux/sacrifice-89/test.html', name: 'sacrifice' },
  {
    note: '6',
    url: '/jeux/mercedes-benz-truck-racing-1237/test.html',
    name: 'mercedes-benz-truck-racing',
  },
  {
    note: '5',
    url: '/jeux/blair-witch-episode-1-rustin-parr-103/test.html',
    name: 'blair-witch-episode-1-rustin-parr',
  },
  {
    note: '8',
    url: '/jeux/homeworld-cataclysm-96/test.html',
    name: 'homeworld-cataclysm',
  },
  {
    note: '7',
    url: '/jeux/ea-sports-rugby-2001-3010000408/test-pc.html',
    name: 'ea-sports-rugby-2001',
  },
  {
    note: '7',
    url: '/jeux/enemy-engaged-rah-66-comanche-vs-ka-52-hokum-49/test.html',
    name: 'enemy-engaged-rah-66-comanche-vs-ka-52-hokum',
  },
  {
    note: '5',
    url: '/jeux/guy-roux-manager-2000-3010000013/test-pc.html',
    name: 'guy-roux-manager-2000',
  },
  {
    note: '5',
    url: '/jeux/frogger-2-la-revanche-de-swampy-3010000199/test-pc.html',
    name: 'frogger-2-la-revanche-de-swampy',
  },
  {
    note: '5',
    url: '/jeux/rage-rally-1063/test.html',
    name: 'rage-rally',
  },
  {
    note: '7',
    url: '/jeux/midtown-madness-2-810/test.html',
    name: 'midtown-madness-2',
  },
  {
    note: '2',
    url: '/jeux/paris-marseille-racing-3010001601/test.html',
    name: 'paris-marseille-racing',
  },
  {
    note: '8',
    url: '/jeux/crimson-skies-78/test.html',
    name: 'crimson-skies',
  },
  {
    note: '4',
    url: '/jeux/les-sims-ca-vous-change-la-vie-3010008128/test.html',
    name: 'les-sims-ca-vous-change-la-vie',
  },
  {
    note: '9',
    url: '/jeux/baldur-s-gate-ii-shadows-of-amn-3010006140/test-pc.html',
    name: 'baldur-s-gate-ii-shadows-of-amn',
  },
  {
    note: '7',
    url: '/jeux/heavy-metal-f-a-k-k-2-3010000084/test-pc.html',
    name: 'heavy-metal-f-a-k-k-2',
  },
  {
    note: '4',
    url: '/jeux/dino-crisis-3010000007/test.html',
    name: 'dino-crisis',
  },
  {
    note: '3',
    url: '/jeux/touche-coule-879/test.html',
    name: 'touche-coule',
  },
  {
    note: '8',
    url: '/jeux/superbike-2001-862/test.html',
    name: 'superbike-2001',
  },
  {
    note: '6',
    url: '/jeux/lnf-football-manager-2001-868/test.html',
    name: 'lnf-football-manager-2001',
  },
  {
    note: '5',
    url: '/jeux/deep-fighter-3010000139/test.html',
    name: 'deep-fighter',
  },
  {
    note: '6',
    url: '/jeux/star-trek-new-worlds-622/test.html',
    name: 'star-trek-new-worlds',
  },
  {
    note: '6',
    url: '/jeux/close-combat-invasion-normandie-844/test.html',
    name: 'close-combat-invasion-normandie',
  },
  {
    note: '9',
    url: '/jeux/deus-ex-3010000365/test-pc.html',
    name: 'deus-ex',
  },
  {
    note: '8',
    url: '/jeux/grand-prix-3-32/test.html',
    name: 'grand-prix-3',
  },
  { note: '3', url: '/jeux/soldier-858/test.html', name: 'soldier' },
  {
    note: '8',
    url: '/jeux/star-trek-voyager-elite-force-3010000127/test-pc.html',
    name: 'star-trek-voyager-elite-force',
  },
  {
    note: '8',
    url: '/jeux/nhl-2001-3010000016/test-pc.html',
    name: 'nhl-2001',
  },
  {
    note: '6',
    url: '/jeux/breakout-3010000119/test-pc.html',
    name: 'breakout',
  },
  {
    note: '3',
    url: '/jeux/fort-boyard-millenium-3010000240/test-pc.html',
    name: 'fort-boyard-millenium',
  },
  {
    note: '6',
    url: '/jeux/ka-52-team-alligator-568/test.html',
    name: 'ka-52-team-alligator',
  },
  {
    note: '4',
    url: '/jeux/v-rally-2-expert-edition-3010000024/test-pc.html',
    name: 'v-rally-2-expert-edition',
  },
  {
    note: '8',
    url: '/jeux/age-of-empires-ii-the-conquerors-expansion-140/test.html',
    name: 'age-of-empires-ii-the-conquerors-expansion',
  },
  {
    note: '7',
    url: '/jeux/microsoft-golf-2001-651/test.html',
    name: 'microsoft-golf-2001',
  },
  {
    note: '5',
    url: '/jeux/egypte-ii-3010000075/test.html',
    name: 'egypte-ii',
  },
  {
    note: '8',
    url: '/jeux/falcon-4-0-3010007795/test-pc.html',
    name: 'falcon-4-0',
  },
  { note: '7', url: '/jeux/gunship-130/test.html', name: 'gunship' },
  {
    note: '7',
    url: '/jeux/adiboud-chou-a-la-mer-507/test.html',
    name: 'adiboud-chou-a-la-mer',
  },
  {
    note: '8',
    url: '/jeux/flanker-2-0-1506/test.html',
    name: 'flanker-2-0',
  },
  {
    note: '7',
    url: '/jeux/ultima-ix-ascension-190/test.html',
    name: 'ultima-ix-ascension',
  },
  {
    note: '6',
    url: '/jeux/the-devil-inside-3010000252/test-pc.html',
    name: 'the-devil-inside',
  },
  {
    note: '6',
    url: '/jeux/dark-reign-2-79/test.html',
    name: 'dark-reign-2',
  },
  {
    note: '7',
    url: '/jeux/tachyon-the-fringe-128/test.html',
    name: 'tachyon-the-fringe',
  },
  {
    note: '6',
    url: '/jeux/half-life-opposing-force-383/test.html',
    name: 'half-life-opposing-force',
  },
  {
    note: '9',
    url: '/jeux/half-life-3010000014/test.html',
    name: 'half-life',
  },
  { note: '8', url: '/jeux/driver-3010000162/test.html', name: 'driver' },
  {
    note: '8',
    url: '/jeux/ground-control-3010000094/test-pc.html',
    name: 'ground-control',
  },
  { note: '7', url: '/jeux/s-w-a-t-3-39/test.html', name: 's-w-a-t-3' },
  {
    note: '7',
    url: '/jeux/starlancer-3010000133/test.html',
    name: 'starlancer',
  },
  {
    note: '2',
    url: '/jeux/high-impact-paintball-369/test.html',
    name: 'high-impact-paintball',
  },
  {
    note: '7',
    url: '/jeux/the-nomad-soul-3010000301/test.html',
    name: 'the-nomad-soul',
  },
  {
    note: '8',
    url: '/jeux/asheron-s-call-185/test.html',
    name: 'asheron-s-call',
  },
  {
    note: '4',
    url: '/jeux/official-formula-one-racing-357/test.html',
    name: 'official-formula-one-racing',
  },
  {
    note: '6',
    url: '/jeux/carmageddon-tdr-2000-353/test.html',
    name: 'carmageddon-tdr-2000',
  },
  {
    note: '9',
    url: '/jeux/unreal-tournament-3010000023/test-pc.html',
    name: 'unreal-tournament',
  },
  {
    note: '8',
    url: '/jeux/virtual-pool-hall-350/test.html',
    name: 'virtual-pool-hall',
  },
  {
    note: '7',
    url: '/jeux/nba-live-2000-3010001805/test-pc.html',
    name: 'nba-live-2000',
  },
  {
    note: '6',
    url: '/jeux/flight-simulator-2000-344/test.html',
    name: 'flight-simulator-2000',
  },
  {
    note: '7',
    url: '/jeux/superbike-2000-3010000296/test-pc.html',
    name: 'superbike-2000',
  },
  {
    note: '9',
    url: '/jeux/quake-iii-arena-3010000903/test-pc.html',
    name: 'quake-iii-arena',
  },
  {
    note: '7',
    url: '/jeux/vampire-la-mascarade-redemption-42/test.html',
    name: 'vampire-la-mascarade-redemption',
  },
  {
    note: '4',
    url: '/jeux/infestation-3010002659/test.html',
    name: 'infestation',
  },
  {
    note: '7',
    url: '/jeux/planescape-torment-340/test.html',
    name: 'planescape-torment',
  },
  {
    note: '6',
    url: '/jeux/urban-chaos-3010000087/test.html',
    name: 'urban-chaos',
  },
  { note: '4', url: '/jeux/road-wars-337/test.html', name: 'road-wars' },
  {
    note: '6',
    url: '/jeux/icewind-dale-3010005815/test-pc.html',
    name: 'icewind-dale',
  },
  {
    note: '5',
    url:
      '/jeux/3d-ultra-radio-control-racers-deluxe-traxxas-edition-336/test.html',
    name: '3d-ultra-radio-control-racers-deluxe-traxxas-edition',
  },
  {
    note: '7',
    url: '/jeux/soldier-of-fortune-3010000951/test-pc.html',
    name: 'soldier-of-fortune',
  },
  {
    note: '8',
    url: '/jeux/age-of-empires-ii-the-age-of-kings-3010000126/test-pc.html',
    name: 'age-of-empires-ii-the-age-of-kings',
  },
  {
    note: '3',
    url: '/jeux/daikatana-3010000210/test-pc.html',
    name: 'daikatana',
  },
  { note: '6', url: '/jeux/earth-2150-30/test.html', name: 'earth-2150' },
  {
    note: '5',
    url: '/jeux/lemmings-revolution-156/test.html',
    name: 'lemmings-revolution',
  },
  {
    note: '4',
    url: '/jeux/sydney-2000-3010000051/test-pc.html',
    name: 'sydney-2000',
  },
  {
    note: '6',
    url: '/jeux/euro-2000-3010000226/test.html',
    name: 'euro-2000',
  },
  {
    note: '8',
    url: '/jeux/motocross-madness-2-25/test.html',
    name: 'motocross-madness-2',
  },
  {
    note: '5',
    url: '/jeux/sim-city-3000-edition-mondiale-27/test.html',
    name: 'sim-city-3000-edition-mondiale',
  },
  {
    note: '7',
    url: '/jeux/re-volt-3010000290/test-pc.html',
    name: 're-volt',
  },
  {
    note: '6',
    url: '/jeux/kiss-psycho-circus-the-nightmare-child-3010000231/test-pc.html',
    name: 'kiss-psycho-circus-the-nightmare-child',
  },
  {
    note: '3',
    url: '/jeux/sherif-fais-moi-peur-3010000219/test.html',
    name: 'sherif-fais-moi-peur',
  },
  {
    note: '6',
    url: '/jeux/total-soccer-92/test.html',
    name: 'total-soccer',
  },
  {
    note: '8',
    url: '/jeux/dark-project-ii-l-age-de-metal-60/test.html',
    name: 'dark-project-ii-l-age-de-metal',
  },
  {
    note: '7',
    url: '/jeux/diablo-ii-3010005575/test-pc.html',
    name: 'diablo-ii',
  },
  {
    note: '7',
    url: '/jeux/need-for-speed-porsche-2000-3010000053/test.html',
    name: 'need-for-speed-porsche-2000',
  },
  {
    url: '/jeux/eden-s-last-sunrise-3050877601.html',
    name: 'eden-s-last-sunrise',
  },
  { url: '/jeux/in-other-waters-3050877859.html', name: 'in-other-waters' },
  { url: '/jeux/unsung-story-3050216631.html', name: 'unsung-story' },
  { url: '/jeux/iron-harvest-3050798879.html', name: 'iron-harvest' },
  {
    url: '/jeux/beautiful-desolation-3050845947.html',
    name: 'beautiful-desolation',
  },
  { url: '/jeux/wasteland-3-3050778597.html', name: 'wasteland-3' },
  {
    url: '/jeux/blue-omen-operation-3050877271.html',
    name: 'blue-omen-operation',
  },
  {
    url: '/jeux/saint-george-the-tree-of-secrets-3050877017.html',
    name: 'saint-george-the-tree-of-secrets',
  },
  { url: '/jeux/the-untold-legacy-3050877287.html', name: 'the-untold-legacy' },
  { url: '/jeux/selling-sunlight-3050876237.html', name: 'selling-sunlight' },
  { url: '/jeux/blasphemous-3050875889.html', name: 'blasphemous' },
  { url: '/jeux/winterfall-3050878285.html', name: 'winterfall' },
  { url: '/jeux/netherworld-3050878179.html', name: 'netherworld' },
  {
    url: '/jeux/flynn-son-of-crimson-3050876829.html',
    name: 'flynn-son-of-crimson',
  },
  { url: '/jeux/wormhole-wars-3050878313.html', name: 'wormhole-wars' },
  { url: '/jeux/two-worlds-iii-3010009144.html', name: 'two-worlds-iii' },
  {
    url: '/jeux/little-devil-inside-3050472181.html',
    name: 'little-devil-inside',
  },
  { url: '/jeux/psychonauts-2-3050594423.html', name: 'psychonauts-2' },
  { url: '/jeux/way-to-the-woods-3050627359.html', name: 'way-to-the-woods' },
  { url: '/jeux/anthem-3050876009.html', name: 'anthem' },
  { url: '/jeux/harold-halibut-3050876229.html', name: 'harold-halibut' },
  { url: '/jeux/hazelnut-bastille-3050876333.html', name: 'hazelnut-bastille' },
  { url: '/jeux/the-good-life-3050876439.html', name: 'the-good-life' },
  { url: '/jeux/boyfriend-dungeon-3050876959.html', name: 'boyfriend-dungeon' },
  { url: '/jeux/white-lie-3050876981.html', name: 'white-lie' },
  { url: '/jeux/nightkeep-3050877337.html', name: 'nightkeep' },
  {
    url: '/jeux/in-the-valley-of-gods-3050877379.html',
    name: 'in-the-valley-of-gods',
  },
  { url: '/jeux/the-surge-2-3050877799.html', name: 'the-surge-2' },
  { url: '/jeux/above-3050877919.html', name: 'above' },
  { url: '/jeux/hellscreen-3050877997.html', name: 'hellscreen' },
  {
    url: '/jeux/maupiti-island-2018-3050878003.html',
    name: 'maupiti-island-2018',
  },
  { url: '/jeux/the-blackout-club-3050878013.html', name: 'the-blackout-club' },
  {
    url: '/jeux/stygian-reign-of-the-old-ones-3050878045.html',
    name: 'stygian-reign-of-the-old-ones',
  },
  {
    url: '/jeux/vasara-hd-collection-3050878053.html',
    name: 'vasara-hd-collection',
  },
  { url: '/jeux/narcos-3050878145.html', name: 'narcos' },
  { url: '/jeux/afterparty-3050878185.html', name: 'afterparty' },
  {
    url: '/jeux/mechwarrior-5-mercenaries-49235.html',
    name: 'mechwarrior-5-mercenaries',
  },
  {
    url: '/jeux/the-walking-dead-overkill-3050320685.html',
    name: 'the-walking-dead-overkill',
  },
  {
    url: '/jeux/underworld-ascendant-3050408423.html',
    name: 'underworld-ascendant',
  },
  { url: '/jeux/shenmue-iii-3050485005.html', name: 'shenmue-iii' },
  {
    url: '/jeux/coven-and-labyrinth-of-refrain-3050515535.html',
    name: 'coven-and-labyrinth-of-refrain',
  },
  { url: '/jeux/jenny-leclue-3050588033.html', name: 'jenny-leclue' },
  { url: '/jeux/wargroove-3050865125.html', name: 'wargroove' },
  { url: '/jeux/pine-3050875663.html', name: 'pine' },
  { url: '/jeux/phoenix-point-3050875731.html', name: 'phoenix-point' },
  { url: '/jeux/ashes-of-creation-3050875753.html', name: 'ashes-of-creation' },
  { url: '/jeux/blacksad-3050875993.html', name: 'blacksad' },
  { url: '/jeux/metro-exodus-3050876019.html', name: 'metro-exodus' },
  { url: '/jeux/skull-and-bones-3050876053.html', name: 'skull-and-bones' },
  {
    url: '/jeux/mineko-s-night-market-3050876375.html',
    name: 'mineko-s-night-market',
  },
  { url: '/jeux/anno-1800-3050876509.html', name: 'anno-1800' },
  {
    url: '/jeux/virgo-vs-the-zodiac-3050876683.html',
    name: 'virgo-vs-the-zodiac',
  },
  { url: '/jeux/mega-man-11-3050877323.html', name: 'mega-man-11' },
  { url: '/jeux/koruldia-heritage-3050877361.html', name: 'koruldia-heritage' },
  {
    url: '/jeux/grapple-force-rena-3050877595.html',
    name: 'grapple-force-rena',
  },
  {
    url: '/jeux/the-world-next-door-3050877797.html',
    name: 'the-world-next-door',
  },
  {
    url: '/jeux/farming-simulator-19-3050877885.html',
    name: 'farming-simulator-19',
  },
  {
    url: '/jeux/warhammer-40-000-mechanicus-3050877945.html',
    name: 'warhammer-40-000-mechanicus',
  },
  { url: '/jeux/decay-of-logos-3050878137.html', name: 'decay-of-logos' },
  { url: '/jeux/rico-3050878149.html', name: 'rico' },
  {
    url: '/jeux/9-monkeys-of-shaolin-3050878203.html',
    name: '9-monkeys-of-shaolin',
  },
  { url: '/jeux/dark-drive-3050878213.html', name: 'dark-drive' },
  { url: '/jeux/crying-suns-3050878249.html', name: 'crying-suns' },
  {
    url: '/jeux/the-jackbox-party-pack-5-3050878301.html',
    name: 'the-jackbox-party-pack-5',
  },
  { url: '/jeux/ambrosia-3050878273.html', name: 'ambrosia' },
  { url: '/jeux/jennifer-wilde-3050876961.html', name: 'jennifer-wilde' },
  {
    url: '/jeux/shadow-of-the-mask-3050877007.html',
    name: 'shadow-of-the-mask',
  },
  {
    url: '/jeux/super-mighty-power-man-3050877085.html',
    name: 'super-mighty-power-man',
  },
  { url: '/jeux/the-ballad-singer-3050877505.html', name: 'the-ballad-singer' },
  { url: '/jeux/starflint-3050876275.html', name: 'starflint' },
  { url: '/jeux/battlefield-v-3050878031.html', name: 'battlefield-v' },
  { url: '/jeux/phantom-halls-3050878211.html', name: 'phantom-halls' },
  { url: '/jeux/the-13th-doll-3050878291.html', name: 'the-13th-doll' },
  { url: '/jeux/eagle-island-3050875833.html', name: 'eagle-island' },
  {
    url: '/jeux/holdfast-nations-at-war-3050877317.html',
    name: 'holdfast-nations-at-war',
  },
  { url: '/jeux/baba-is-you-3050877493.html', name: 'baba-is-you' },
  {
    url: '/jeux/total-war-three-kingdoms-3050877575.html',
    name: 'total-war-three-kingdoms',
  },
  { url: '/jeux/shadows-awakening-3050878129.html', name: 'shadows-awakening' },
  { url: '/jeux/double-cross-3050878283.html', name: 'double-cross' },
  {
    url: '/jeux/the-endless-mission-3050878295.html',
    name: 'the-endless-mission',
  },
  {
    url: '/jeux/tomb-raider-2-suite-du-reboot-3050877545.html',
    name: 'tomb-raider-2-suite-du-reboot',
  },
  {
    note: '7',
    url: '/jeux/dragon-quest-xi-3050516623.html',
    name: 'dragon-quest-xi',
  },
  {
    url: '/jeux/zone-of-the-enders-anubis-mars-3050876759.html',
    name: 'zone-of-the-enders-anubis-mars',
  },
  { url: '/jeux/trueberbrook-3050877181.html', name: 'trueberbrook' },
  { url: '/jeux/v-rally-4-3050878121.html', name: 'v-rally-4' },
  {
    url: '/jeux/world-of-warcraft-battle-for-azeroth-3050877107.html',
    name: 'world-of-warcraft-battle-for-azeroth',
  },
  {
    url: '/jeux/project-rap-rabbit-3050875837.html',
    name: 'project-rap-rabbit',
  },
  {
    url: '/jeux/lona-realm-of-colors-3050876643.html',
    name: 'lona-realm-of-colors',
  },
  { url: '/jeux/demoniaca-3050876345.html', name: 'demoniaca' },
  {
    url: '/jeux/monster-boy-and-the-cursed-kingdom-3050405653.html',
    name: 'monster-boy-and-the-cursed-kingdom',
  },
  { url: '/jeux/crackdown-3-3050714299.html', name: 'crackdown-3' },
  {
    url: '/jeux/the-incredible-journey-of-you-and-i-3050760819.html',
    name: 'the-incredible-journey-of-you-and-i',
  },
  { url: '/jeux/sacred-fire-3050875657.html', name: 'sacred-fire' },
  {
    url: '/jeux/shape-of-the-world-3050876427.html',
    name: 'shape-of-the-world',
  },
  { url: '/jeux/crazy-justice-3050876919.html', name: 'crazy-justice' },
  { url: '/jeux/antigraviator-3050877351.html', name: 'antigraviator' },
  { url: '/jeux/sky-noon-3050877791.html', name: 'sky-noon' },
  { url: '/jeux/what-the-golf-3050877971.html', name: 'what-the-golf' },
  { url: '/jeux/golem-pc-3050878057.html', name: 'golem-pc' },
  { url: '/jeux/the-crew-2-3050875841.html', name: 'the-crew-2' },
  {
    url: '/jeux/lego-les-indestructibles-3050878235.html',
    name: 'lego-les-indestructibles',
  },
  {
    url: '/jeux/new-gundam-breaker-3050877623.html',
    name: 'new-gundam-breaker',
  },
  { url: '/jeux/deiland-3050876945.html', name: 'deiland' },
  {
    url: '/jeux/jurassic-world-evolution-3050876473.html',
    name: 'jurassic-world-evolution',
  },
  { url: '/jeux/motogp-18-3050878251.html', name: 'motogp-18' },
  {
    url: '/jeux/shaq-fu-a-legend-reborn-3050231221.html',
    name: 'shaq-fu-a-legend-reborn',
  },
  { url: '/jeux/vampyr-3050394023.html', name: 'vampyr' },
  { url: '/jeux/onrush-3050877061.html', name: 'onrush' },
  { url: '/jeux/fight-knight-3050876203.html', name: 'fight-knight' },
  { url: '/jeux/cultist-simulator-3050878023.html', name: 'cultist-simulator' },
  {
    url: '/jeux/street-fighter-30th-anniversary-collection-3050877395.html',
    name: 'street-fighter-30th-anniversary-collection',
  },
  {
    url: '/jeux/sega-mega-drive-classics-3050878125.html',
    name: 'sega-mega-drive-classics',
  },
  {
    url: '/jeux/dark-souls-remastered-3050877577.html',
    name: 'dark-souls-remastered',
  },
  {
    url: '/jeux/pixeljunk-monsters-2-3050878207.html',
    name: 'pixeljunk-monsters-2',
  },
  { url: '/jeux/state-of-decay-2-3050714167.html', name: 'state-of-decay-2' },
  { url: '/jeux/runner3-3050777607.html', name: 'runner3' },
  { url: '/jeux/ancestors-3050875783.html', name: 'ancestors' },
  { url: '/jeux/tennis-world-tour-3050875865.html', name: 'tennis-world-tour' },
  { url: '/jeux/pixel-ripped-1989-3050878151.html', name: 'pixel-ripped-1989' },
  {
    url: '/jeux/space-hulk-deathwing-enhanced-edition-3050878205.html',
    name: 'space-hulk-deathwing-enhanced-edition',
  },
  {
    url: '/jeux/the-elder-scrolls-online-summerset-3050878189.html',
    name: 'the-elder-scrolls-online-summerset',
  },
  { url: '/jeux/far-lone-sails-3050878167.html', name: 'far-lone-sails' },
  {
    url: '/jeux/hitman-definitive-edition-3050878293.html',
    name: 'hitman-definitive-edition',
  },
  {
    url: '/jeux/little-witch-academia-3050875907.html',
    name: 'little-witch-academia',
  },
  {
    url: '/jeux/pillars-of-eternity-ii-deadfire-3050697285.html',
    name: 'pillars-of-eternity-ii-deadfire',
  },
  { url: '/jeux/city-of-brass-3050876235.html', name: 'city-of-brass' },
  { url: '/jeux/juicy-realm-3050878229.html', name: 'juicy-realm' },
  {
    url: '/jeux/total-war-saga-thrones-of-britannia-3050877177.html',
    name: 'total-war-saga-thrones-of-britannia',
  },
  { url: '/jeux/beat-saber-3050876535.html', name: 'beat-saber' },
  { url: '/jeux/trailblazers-3050877955.html', name: 'trailblazers' },
  {
    url: '/jeux/lumines-remastered-3050878197.html',
    name: 'lumines-remastered',
  },
  { url: '/jeux/the-forest-3050376107.html', name: 'the-forest' },
  {
    url: '/jeux/murderous-pursuits-3050877873.html',
    name: 'murderous-pursuits',
  },
  {
    url: '/jeux/sinner-sacrifice-for-redemption-3050876349.html',
    name: 'sinner-sacrifice-for-redemption',
  },
  { url: '/jeux/battletech-2015-3050550255.html', name: 'battletech-2015' },
  { url: '/jeux/frostpunk-3050875683.html', name: 'frostpunk' },
  {
    url: '/jeux/the-swords-of-ditto-3050875957.html',
    name: 'the-swords-of-ditto',
  },
  { url: '/jeux/we-happy-few-3050520839.html', name: 'we-happy-few' },
  { url: '/jeux/spyparty-3050091108.html', name: 'spyparty' },
  { url: '/jeux/dead-in-vinland-3050876423.html', name: 'dead-in-vinland' },
  { url: '/jeux/vandals-3050878257.html', name: 'vandals' },
  {
    url: '/jeux/double-kick-heroes-3050666497.html',
    name: 'double-kick-heroes',
  },
  {
    url: '/jeux/project-nimbus-code-mirai-3050876783.html',
    name: 'project-nimbus-code-mirai',
  },
  { url: '/jeux/extinction-3050875939.html', name: 'extinction' },
  { url: '/jeux/masters-of-anima-3050877079.html', name: 'masters-of-anima' },
  {
    url: '/jeux/super-daryl-deluxe-3050877163.html',
    name: 'super-daryl-deluxe',
  },
  { note: '7', url: '/jeux/minit-3050845261.html', name: 'minit' },
  {
    url: '/jeux/the-adventure-pals-3050877313.html',
    name: 'the-adventure-pals',
  },
  {
    url: '/jeux/crisis-on-the-planet-of-the-apes-vr-3050878001.html',
    name: 'crisis-on-the-planet-of-the-apes-vr',
  },
  { url: '/jeux/next-3-3050878317.html', name: 'next-3' },
  {
    url: '/jeux/hearthstone-le-bois-maudit-3050878115.html',
    name: 'hearthstone-le-bois-maudit',
  },
  { url: '/jeux/light-fall-3050878201.html', name: 'light-fall' },
  { url: '/jeux/scum-3050754087.html', name: 'scum' },
  {
    url: '/jeux/away-journey-to-the-unexpected-3050868601.html',
    name: 'away-journey-to-the-unexpected',
  },
  {
    url: '/jeux/battle-princess-madelyn-3050875237.html',
    name: 'battle-princess-madelyn',
  },
  {
    url: '/jeux/atelier-liddy-and-soeur-3050876403.html',
    name: 'atelier-liddy-and-soeur',
  },
  { url: '/jeux/god-s-trigger-3050876513.html', name: 'god-s-trigger' },
  { url: '/jeux/sword-legacy-omen-3050876533.html', name: 'sword-legacy-omen' },
  {
    url: '/jeux/adventure-time-pirates-of-the-enchiridion-3050877471.html',
    name: 'adventure-time-pirates-of-the-enchiridion',
  },
  { url: '/jeux/make-sail-3050877531.html', name: 'make-sail' },
  { url: '/jeux/the-mercury-man-3050877551.html', name: 'the-mercury-man' },
  { url: '/jeux/megaquarium-3050877655.html', name: 'megaquarium' },
  {
    url: '/jeux/injustice-2-legendary-edition-3050878011.html',
    name: 'injustice-2-legendary-edition',
  },
  { url: '/jeux/long-gone-days-3050878083.html', name: 'long-gone-days' },
  { url: '/jeux/umiro-3050878095.html', name: 'umiro' },
  { url: '/jeux/tempest-4000-3050876415.html', name: 'tempest-4000' },
  {
    url: '/jeux/tt-isle-of-man-ride-on-the-edge-3050680145.html',
    name: 'tt-isle-of-man-ride-on-the-edge',
  },
  { url: '/jeux/mx-vs-atv-all-out-3050876743.html', name: 'mx-vs-atv-all-out' },
  { url: '/jeux/pixark-3050877703.html', name: 'pixark' },
  {
    note: '7',
    url: '/jeux/ni-no-kuni-ii-revenant-kingdom-3050846101.html',
    name: 'ni-no-kuni-ii-revenant-kingdom',
  },
  { url: '/jeux/ash-of-gods-3050876111.html', name: 'ash-of-gods' },
  { url: '/jeux/ark-park-3050825623.html', name: 'ark-park' },
  {
    url: '/jeux/assault-gunners-hd-edition-3050877947.html',
    name: 'assault-gunners-hd-edition',
  },
  {
    url: '/jeux/hakuoki-shinkai-hana-no-sho-3050722549.html',
    name: 'hakuoki-shinkai-hana-no-sho',
  },
  { note: '5', url: '/jeux/pato-box-3050877569.html', name: 'pato-box' },
  { url: '/jeux/synthetik-3050877915.html', name: 'synthetik' },
  { url: '/jeux/wargames-3050877367.html', name: 'wargames' },
  {
    url: '/jeux/the-25th-ward-the-silver-case-3050875879.html',
    name: 'the-25th-ward-the-silver-case',
  },
  { url: '/jeux/the-council-3050877487.html', name: 'the-council' },
  {
    url: '/jeux/azure-saga-pathfinder-3050878171.html',
    name: 'azure-saga-pathfinder',
  },
  { url: '/jeux/amid-evil-3050878051.html', name: 'amid-evil' },
  {
    url: '/jeux/the-darwin-project-3050876023.html',
    name: 'the-darwin-project',
  },
  { url: '/jeux/hatsune-miku-vr-3050877665.html', name: 'hatsune-miku-vr' },
  { url: '/jeux/domiverse-3050472679.html', name: 'domiverse' },
  {
    url: '/jeux/damascus-gear-saikyo-exodus-3050871015.html',
    name: 'damascus-gear-saikyo-exodus',
  },
  {
    note: '4',
    url: '/jeux/fear-effect-sedna-3050673691.html',
    name: 'fear-effect-sedna',
  },
  {
    url: '/jeux/way-of-the-passive-fist-3050814063.html',
    name: 'way-of-the-passive-fist',
  },
  { url: '/jeux/pit-people-3050469303.html', name: 'pit-people' },
  {
    url: '/jeux/turok-2-remastered-nom-provisoire-3050669195.html',
    name: 'turok-2-remastered-nom-provisoire',
  },
  {
    url: '/jeux/guns-gore-cannoli-2-3050848783.html',
    name: 'guns-gore-cannoli-2',
  },
  { url: '/jeux/mordhau-3050668645.html', name: 'mordhau' },
  { url: '/jeux/rise-of-insanity-3050878025.html', name: 'rise-of-insanity' },
  { url: '/jeux/dragon-fang-z-3050877365.html', name: 'dragon-fang-z' },
  { url: '/jeux/ion-maiden-3050878021.html', name: 'ion-maiden' },
  { url: '/jeux/gravel-3050866007.html', name: 'gravel' },
  { url: '/jeux/mulaka-3050876635.html', name: 'mulaka' },
  { url: '/jeux/past-cure-3050875863.html', name: 'past-cure' },
  { url: '/jeux/projekt-1514-3050876457.html', name: 'projekt-1514' },
  {
    url: '/jeux/yume-nikki-dream-diary-3050877693.html',
    name: 'yume-nikki-dream-diary',
  },
  {
    url: '/jeux/hunt-horrors-of-the-gilded-age-3050269463.html',
    name: 'hunt-horrors-of-the-gilded-age',
  },
  {
    note: '5',
    url: '/jeux/metal-gear-survive-3050753181.html',
    name: 'metal-gear-survive',
  },
  {
    url: '/jeux/orwell-ignorance-is-strength-3050876409.html',
    name: 'orwell-ignorance-is-strength',
  },
  { url: '/jeux/boo-greedy-kid-3050877887.html', name: 'boo-greedy-kid' },
  { url: '/jeux/apex-construct-3050877099.html', name: 'apex-construct' },
  { url: '/jeux/the-station-3050877557.html', name: 'the-station' },
  {
    url: '/jeux/joe-dever-s-lone-wolf-3050201002.html',
    name: 'joe-dever-s-lone-wolf',
  },
  { note: '6', url: '/jeux/fe-3050713287.html', name: 'fe' },
  {
    url: '/jeux/the-longest-5-minutes-3050742799.html',
    name: 'the-longest-5-minutes',
  },
  { url: '/jeux/algo-bot-3050216231.html', name: 'algo-bot' },
  { url: '/jeux/hacktag-3050754721.html', name: 'hacktag' },
  {
    url: '/jeux/the-fall-part-2-unbound-3050745791.html',
    name: 'the-fall-part-2-unbound',
  },
  {
    note: '3',
    url: '/jeux/dynasty-warriors-9-3050838187.html',
    name: 'dynasty-warriors-9',
  },
  { url: '/jeux/dead-maze-3050876431.html', name: 'dead-maze' },
  {
    url:
      '/jeux/monster-energy-supercross-the-official-videogame-3050876929.html',
    name: 'monster-energy-supercross-the-official-videogame',
  },
  { url: '/jeux/break-arts-2-3050877667.html', name: 'break-arts-2' },
  { url: '/jeux/aegis-defenders-3050331997.html', name: 'aegis-defenders' },
  { url: '/jeux/laser-league-3050875989.html', name: 'laser-league' },
  { url: '/jeux/sprint-vector-3050877073.html', name: 'sprint-vector' },
  { url: '/jeux/bleed-2-3050877755.html', name: 'bleed-2' },
  {
    url: '/jeux/draw-a-stickman-epic-2-3050877811.html',
    name: 'draw-a-stickman-epic-2',
  },
  {
    url: '/jeux/a-case-of-distrust-3050877821.html',
    name: 'a-case-of-distrust',
  },
  {
    url: '/jeux/opus-rocket-of-whispers-3050878117.html',
    name: 'opus-rocket-of-whispers',
  },
  {
    url: '/jeux/treasure-adventure-world-3050180340.html',
    name: 'treasure-adventure-world',
  },
  { note: '7', url: '/jeux/dandara-3050865101.html', name: 'dandara' },
  { url: '/jeux/closers-3050876549.html', name: 'closers' },
  {
    url: '/jeux/remnants-of-naezith-3050877953.html',
    name: 'remnants-of-naezith',
  },
  { url: '/jeux/treadnauts-3050877883.html', name: 'treadnauts' },
  { url: '/jeux/insidia-3050877781.html', name: 'insidia' },
  { url: '/jeux/next-2-3050878315.html', name: 'next-2' },
  { url: '/jeux/lvl99-axerage-3050876835.html', name: 'lvl99-axerage' },
  { url: '/jeux/trailmakers-3050877591.html', name: 'trailmakers' },
  { url: '/jeux/uayeb-3050878187.html', name: 'uayeb' },
  {
    note: '4',
    url: '/jeux/strikers-edge-3050750513.html',
    name: 'strikers-edge',
  },
  { url: '/jeux/pawarumi-3050826421.html', name: 'pawarumi' },
  { url: '/jeux/dragon-ball-2018-3050875997.html', name: 'dragon-ball-2018' },
  {
    url: '/jeux/monster-hunter-world-3050876001.html',
    name: 'monster-hunter-world',
  },
  {
    url: '/jeux/tesla-vs-lovecraft-3050878183.html',
    name: 'tesla-vs-lovecraft',
  },
  { url: '/jeux/earth-wars-3050503871.html', name: 'earth-wars' },
  { url: '/jeux/zwei-3010001389.html', name: 'zwei' },
  {
    url: '/jeux/legrand-legacy-tale-of-the-fatebounds-3050877467.html',
    name: 'legrand-legacy-tale-of-the-fatebounds',
  },
  { url: '/jeux/subnautica-3050225756.html', name: 'subnautica' },
  {
    note: '8',
    url: '/jeux/the-iconoclasts-3050517711.html',
    name: 'the-iconoclasts',
  },
  {
    url: '/jeux/ok-k-o-let-s-be-heroes-3050876133.html',
    name: 'ok-k-o-let-s-be-heroes',
  },
  { url: '/jeux/my-time-at-portia-3050876837.html', name: 'my-time-at-portia' },
  { url: '/jeux/sos-3050877589.html', name: 'sos' },
  {
    note: '6',
    url: '/jeux/assassin-s-creed-origins-the-hidden-ones-3050877731.html',
    name: 'assassin-s-creed-origins-the-hidden-ones',
  },
  { url: '/jeux/damsel-3050877749.html', name: 'damsel' },
  { url: '/jeux/vesta-3050878311.html', name: 'vesta' },
  { url: '/jeux/genital-jousting-3050807845.html', name: 'genital-jousting' },
  { url: '/jeux/forged-battalion-3050876931.html', name: 'forged-battalion' },
  { url: '/jeux/innerspace-3050877525.html', name: 'innerspace' },
  { url: '/jeux/rym-9000-3050877943.html', name: 'rym-9000' },
  { url: '/jeux/cosmonaut-3050877719.html', name: 'cosmonaut' },
  { url: '/jeux/dusk-3050770419.html', name: 'dusk' },
  { url: '/jeux/next-up-hero-3050876239.html', name: 'next-up-hero' },
  { url: '/jeux/energy-invasion-3050877613.html', name: 'energy-invasion' },
  { url: '/jeux/fallen-legion-3050877321.html', name: 'fallen-legion' },
  { url: '/jeux/asylum-104882.html', name: 'asylum' },
  { url: '/jeux/428-3010006830.html', name: '428' },
  { url: '/jeux/reset-3050018651.html', name: 'reset' },
  { url: '/jeux/muv-luv-3050036774.html', name: 'muv-luv' },
  { url: '/jeux/project-phoenix-3050171232.html', name: 'project-phoenix' },
  { url: '/jeux/miegakure-3050247691.html', name: 'miegakure' },
  {
    url: '/jeux/heart-forth-alicia-3050249737.html',
    name: 'heart-forth-alicia',
  },
  { url: '/jeux/chasm-3050255411.html', name: 'chasm' },
  { url: '/jeux/at-the-gates-3050284223.html', name: 'at-the-gates' },
  { url: '/jeux/hollowpoint-3050319539.html', name: 'hollowpoint' },
  {
    url: '/jeux/super-meat-boy-forever-3050330935.html',
    name: 'super-meat-boy-forever',
  },
  { url: '/jeux/children-of-morta-3050348003.html', name: 'children-of-morta' },
  {
    url: '/jeux/the-bard-s-tale-iv-3050401307.html',
    name: 'the-bard-s-tale-iv',
  },
  {
    url: '/jeux/rain-of-reflections-3050408265.html',
    name: 'rain-of-reflections',
  },
  { url: '/jeux/edge-of-eternity-3050415865.html', name: 'edge-of-eternity' },
  { url: '/jeux/song-of-memories-3050430807.html', name: 'song-of-memories' },
  {
    url: '/jeux/bloodstained-ritual-of-the-night-3050465737.html',
    name: 'bloodstained-ritual-of-the-night',
  },
  { url: '/jeux/the-sigma-theory-3050468959.html', name: 'the-sigma-theory' },
  { url: '/jeux/song-of-horror-3050477923.html', name: 'song-of-horror' },
  { url: '/jeux/raging-justice-3050483865.html', name: 'raging-justice' },
  { url: '/jeux/ashen-3050484361.html', name: 'ashen' },
  { url: '/jeux/indivisible-3050495487.html', name: 'indivisible' },
  {
    url: '/jeux/old-school-musical-3050513797.html',
    name: 'old-school-musical',
  },
  {
    url: '/jeux/lords-of-the-fallen-2-3050515173.html',
    name: 'lords-of-the-fallen-2',
  },
  { url: '/jeux/pixel-noir-3050545015.html', name: 'pixel-noir' },
  { url: '/jeux/freedom-planet-2-3050608527.html', name: 'freedom-planet-2' },
  { url: '/jeux/battalion-1944-3050631955.html', name: 'battalion-1944' },
  { url: '/jeux/knights-and-bikes-3050636817.html', name: 'knights-and-bikes' },
  {
    url: '/jeux/insurgency-sandstorm-3050644507.html',
    name: 'insurgency-sandstorm',
  },
  {
    url: '/jeux/jay-and-silent-bob-chronic-blunt-punch-3050644949.html',
    name: 'jay-and-silent-bob-chronic-blunt-punch',
  },
  { url: '/jeux/call-of-cthulhu-3050646651.html', name: 'call-of-cthulhu' },
  { url: '/jeux/the-sinking-city-3050652939.html', name: 'the-sinking-city' },
  {
    url: '/jeux/oddworld-soulstorm-3050698137.html',
    name: 'oddworld-soulstorm',
  },
  {
    url: '/jeux/gwent-the-witcher-card-game-3050714493.html',
    name: 'gwent-the-witcher-card-game',
  },
  { url: '/jeux/dual-universe-3050715719.html', name: 'dual-universe' },
  { url: '/jeux/scorn-3050726741.html', name: 'scorn' },
  {
    url: '/jeux/tasukete-tako-san-save-me-mr-tako-3050745157.html',
    name: 'tasukete-tako-san-save-me-mr-tako',
  },
  {
    url: '/jeux/oceanhorn-2-knights-of-the-lost-realm-3050756887.html',
    name: 'oceanhorn-2-knights-of-the-lost-realm',
  },
  { url: '/jeux/squadron-42-3050765547.html', name: 'squadron-42' },
  { url: '/jeux/night-call-3050798589.html', name: 'night-call' },
  { url: '/jeux/dead-static-drive-3050814093.html', name: 'dead-static-drive' },
  { url: '/jeux/dauntless-3050816969.html', name: 'dauntless' },
  { url: '/jeux/secret-legend-3050830899.html', name: 'secret-legend' },
  {
    url: '/jeux/the-legend-of-bum-bo-3050837499.html',
    name: 'the-legend-of-bum-bo',
  },
  { url: '/jeux/the-banner-saga-3-3050844611.html', name: 'the-banner-saga-3' },
  {
    url: '/jeux/ace-combat-7-skies-unknown-3050846091.html',
    name: 'ace-combat-7-skies-unknown',
  },
  { url: '/jeux/gtr3-3050852001.html', name: 'gtr3' },
  { url: '/jeux/mothergunship-3050859279.html', name: 'mothergunship' },
  { url: '/jeux/ooblets-3050862893.html', name: 'ooblets' },
  {
    url: '/jeux/yoku-s-island-express-3050863389.html',
    name: 'yoku-s-island-express',
  },
  { url: '/jeux/dungeon-of-zaar-3050863505.html', name: 'dungeon-of-zaar' },
  { url: '/jeux/re-legend-3050875625.html', name: 're-legend' },
  {
    url: '/jeux/naruto-to-boruto-shinobi-striker-3050875635.html',
    name: 'naruto-to-boruto-shinobi-striker',
  },
  { url: '/jeux/hellpoint-3050875651.html', name: 'hellpoint' },
  { url: '/jeux/moons-of-madness-3050875665.html', name: 'moons-of-madness' },
  { url: '/jeux/prepare-to-dine-3050875681.html', name: 'prepare-to-dine' },
  { url: '/jeux/winter-3050875687.html', name: 'winter' },
  { url: '/jeux/blazing-chrome-3050875691.html', name: 'blazing-chrome' },
  { url: '/jeux/abandon-ship-3050875709.html', name: 'abandon-ship' },
  { url: '/jeux/zanki-zero-3050875721.html', name: 'zanki-zero' },
  { url: '/jeux/darksiders-iii-3050875757.html', name: 'darksiders-iii' },
  { url: '/jeux/roeki-3050875759.html', name: 'roeki' },
  { url: '/jeux/moonlighter-3050875801.html', name: 'moonlighter' },
  { url: '/jeux/phoenix-springs-3050875817.html', name: 'phoenix-springs' },
  {
    url: '/jeux/pathfinder-kingmaker-3050875859.html',
    name: 'pathfinder-kingmaker',
  },
  { url: '/jeux/tropico-6-3050875943.html', name: 'tropico-6' },
  {
    url: '/jeux/sakuna-of-rice-and-ruin-3050876003.html',
    name: 'sakuna-of-rice-and-ruin',
  },
  {
    url: '/jeux/ori-and-the-will-of-the-wisps-3050876013.html',
    name: 'ori-and-the-will-of-the-wisps',
  },
  { url: '/jeux/milanoir-3050876015.html', name: 'milanoir' },
  { url: '/jeux/ghost-ship-3050876021.html', name: 'ghost-ship' },
  { url: '/jeux/griftlands-3050876045.html', name: 'griftlands' },
  { url: '/jeux/unruly-heroes-3050876107.html', name: 'unruly-heroes' },
  { url: '/jeux/unto-the-end-3050876165.html', name: 'unto-the-end' },
  { url: '/jeux/coma-3050876225.html', name: 'coma' },
  {
    url: '/jeux/marvel-powers-united-vr-3050876263.html',
    name: 'marvel-powers-united-vr',
  },
  {
    url: '/jeux/blazblue-cross-tag-battle-3050876269.html',
    name: 'blazblue-cross-tag-battle',
  },
  {
    url: '/jeux/the-walking-dead-the-final-season-3050876281.html',
    name: 'the-walking-dead-the-final-season',
  },
  {
    url: '/jeux/the-wolf-among-us-saison-2-3050876283.html',
    name: 'the-wolf-among-us-saison-2',
  },
  { url: '/jeux/noita-3050876325.html', name: 'noita' },
  {
    url: '/jeux/game-paradise-cruisinmix-3050876331.html',
    name: 'game-paradise-cruisinmix',
  },
  { url: '/jeux/necrobarista-3050876377.html', name: 'necrobarista' },
  { url: '/jeux/ufo-50-3050876407.html', name: 'ufo-50' },
  { url: '/jeux/artifact-3050876413.html', name: 'artifact' },
  { url: '/jeux/underflow-3050876417.html', name: 'underflow' },
  {
    url: '/jeux/the-gardens-between-3050876429.html',
    name: 'the-gardens-between',
  },
  { url: '/jeux/phantom-doctrine-3050876447.html', name: 'phantom-doctrine' },
  { url: '/jeux/close-to-the-sun-3050876479.html', name: 'close-to-the-sun' },
  {
    url: '/jeux/fear-effect-reinvented-3050876489.html',
    name: 'fear-effect-reinvented',
  },
  { url: '/jeux/biomutant-3050876499.html', name: 'biomutant' },
  {
    url: '/jeux/deep-sky-derelicts-3050876507.html',
    name: 'deep-sky-derelicts',
  },
  {
    url: '/jeux/immortal-unchained-3050876521.html',
    name: 'immortal-unchained',
  },
  { url: '/jeux/donut-county-3050876537.html', name: 'donut-county' },
  { url: '/jeux/felix-the-reaper-3050876543.html', name: 'felix-the-reaper' },
  {
    url: '/jeux/industries-of-titan-3050876545.html',
    name: 'industries-of-titan',
  },
  { url: '/jeux/pikuniku-3050876605.html', name: 'pikuniku' },
  { url: '/jeux/pathologic-2-3050876621.html', name: 'pathologic-2' },
  {
    url: '/jeux/shovel-knight-king-of-cards-3050876625.html',
    name: 'shovel-knight-king-of-cards',
  },
  { url: '/jeux/light-fingers-3050876641.html', name: 'light-fingers' },
  { url: '/jeux/steins-gate-elite-3050876663.html', name: 'steins-gate-elite' },
  { url: '/jeux/lifeless-moon-3050876685.html', name: 'lifeless-moon' },
  {
    url: '/jeux/lethal-league-blaze-3050876693.html',
    name: 'lethal-league-blaze',
  },
  { url: '/jeux/switchblade-3050876745.html', name: 'switchblade' },
  { url: '/jeux/left-alive-3050876757.html', name: 'left-alive' },
  { url: '/jeux/ironsight-3050876781.html', name: 'ironsight' },
  { url: '/jeux/path-to-mnemosyne-3050876803.html', name: 'path-to-mnemosyne' },
  {
    url: '/jeux/projection-first-light-3050876847.html',
    name: 'projection-first-light',
  },
  {
    url: '/jeux/untitled-goose-game-3050876853.html',
    name: 'untitled-goose-game',
  },
  { url: '/jeux/anamorphine-3050876863.html', name: 'anamorphine' },
  { url: '/jeux/dark-devotion-3050876895.html', name: 'dark-devotion' },
  { url: '/jeux/space-junkies-3050876897.html', name: 'space-junkies' },
  {
    url: '/jeux/relic-hunters-legend-3050876911.html',
    name: 'relic-hunters-legend',
  },
  {
    url: '/jeux/lonely-mountains-downhill-3050876951.html',
    name: 'lonely-mountains-downhill',
  },
  { url: '/jeux/steel-rats-3050876993.html', name: 'steel-rats' },
  {
    url: '/jeux/8-bit-adventures-2-3050876997.html',
    name: '8-bit-adventures-2',
  },
  {
    url: '/jeux/sense-a-cyberpunk-ghost-story-3050877029.html',
    name: 'sense-a-cyberpunk-ghost-story',
  },
  { url: '/jeux/hell-let-loose-3050877031.html', name: 'hell-let-loose' },
  { url: '/jeux/guacamelee-2-3050877033.html', name: 'guacamelee-2' },
  {
    url: '/jeux/the-hong-kong-massacre-3050877035.html',
    name: 'the-hong-kong-massacre',
  },
  { url: '/jeux/nykra-3050877105.html', name: 'nykra' },
  { url: '/jeux/pig-eat-ball-3050877127.html', name: 'pig-eat-ball' },
  { url: '/jeux/wandersong-3050877147.html', name: 'wandersong' },
  { url: '/jeux/stoneshard-3050877161.html', name: 'stoneshard' },
  {
    url: '/jeux/shining-resonance-refrain-3050877165.html',
    name: 'shining-resonance-refrain',
  },
  { url: '/jeux/sleep-tight-3050877193.html', name: 'sleep-tight' },
  { url: '/jeux/riverbond-3050877199.html', name: 'riverbond' },
  { url: '/jeux/iro-hero-3050877229.html', name: 'iro-hero' },
  { url: '/jeux/gunhead-3050877233.html', name: 'gunhead' },
  {
    url: '/jeux/neverwinter-nights-enhanced-edition-3050877237.html',
    name: 'neverwinter-nights-enhanced-edition',
  },
  { url: '/jeux/shattered-realms-3050877251.html', name: 'shattered-realms' },
  {
    url: '/jeux/warhammer-40-000-gladius-relics-of-war-3050877273.html',
    name: 'warhammer-40-000-gladius-relics-of-war',
  },
  { url: '/jeux/wizard-of-legend-3050877289.html', name: 'wizard-of-legend' },
  {
    url: '/jeux/my-hero-academia-one-s-justice-3050877291.html',
    name: 'my-hero-academia-one-s-justice',
  },
  { url: '/jeux/far-home-vr-3050877311.html', name: 'far-home-vr' },
  {
    url: '/jeux/black-clover-project-knights-3050877335.html',
    name: 'black-clover-project-knights',
  },
  {
    url: '/jeux/one-piece-world-seeker-3050877339.html',
    name: 'one-piece-world-seeker',
  },
  { url: '/jeux/unavowed-3050877363.html', name: 'unavowed' },
  {
    url: '/jeux/dying-light-bad-blood-3050877369.html',
    name: 'dying-light-bad-blood',
  },
  { url: '/jeux/soulcalibur-vi-3050877373.html', name: 'soulcalibur-vi' },
  { url: '/jeux/world-war-z-3050877383.html', name: 'world-war-z' },
  { url: '/jeux/gtfo-3050877385.html', name: 'gtfo' },
  { url: '/jeux/framed-collection-3050877413.html', name: 'framed-collection' },
  {
    url: '/jeux/vacation-simulator-3050877437.html',
    name: 'vacation-simulator',
  },
  {
    url: '/jeux/horizon-chase-turbo-3050877469.html',
    name: 'horizon-chase-turbo',
  },
  {
    url: '/jeux/the-unholy-society-3050877477.html',
    name: 'the-unholy-society',
  },
  { url: '/jeux/gladiabots-3050877485.html', name: 'gladiabots' },
  { url: '/jeux/arthurian-legends-3050877507.html', name: 'arthurian-legends' },
  { url: '/jeux/failed-state-3050877515.html', name: 'failed-state' },
  { url: '/jeux/overpass-3050877539.html', name: 'overpass' },
  { url: '/jeux/the-messenger-3050877541.html', name: 'the-messenger' },
  { url: '/jeux/omensight-3050877571.html', name: 'omensight' },
  { url: '/jeux/dakar-18-3050877593.html', name: 'dakar-18' },
  { url: '/jeux/nowhere-prophet-3050877611.html', name: 'nowhere-prophet' },
  {
    url: '/jeux/two-point-hospital-3050877627.html',
    name: 'two-point-hospital',
  },
  { url: '/jeux/sand-is-the-soul-3050877633.html', name: 'sand-is-the-soul' },
  { url: '/jeux/production-line-3050877647.html', name: 'production-line' },
  { url: '/jeux/memories-of-mars-3050877649.html', name: 'memories-of-mars' },
  { url: '/jeux/stay-3050877659.html', name: 'stay' },
  { url: '/jeux/kursk-3050877671.html', name: 'kursk' },
  {
    url: '/jeux/battlefleet-gothic-armada-2-3050877689.html',
    name: 'battlefleet-gothic-armada-2',
  },
  { url: '/jeux/7-billion-humans-3050877705.html', name: '7-billion-humans' },
  { url: '/jeux/those-who-remain-3050877713.html', name: 'those-who-remain' },
  {
    url: '/jeux/this-is-the-police-2-3050877753.html',
    name: 'this-is-the-police-2',
  },
  { url: '/jeux/seer-isle-3050877759.html', name: 'seer-isle' },
  {
    url: '/jeux/space-hulk-tactics-3050877771.html',
    name: 'space-hulk-tactics',
  },
  { url: '/jeux/the-peterson-case-3050877773.html', name: 'the-peterson-case' },
  { url: '/jeux/detective-gallo-3050877779.html', name: 'detective-gallo' },
  { url: '/jeux/fear-the-wolves-3050877801.html', name: 'fear-the-wolves' },
  {
    url: '/jeux/downward-spiral-horus-station-3050877803.html',
    name: 'downward-spiral-horus-station',
  },
  {
    url: '/jeux/smoke-and-sacrifice-3050877805.html',
    name: 'smoke-and-sacrifice',
  },
  {
    url: '/jeux/space-channel-5-vr-arakata-dancing-show-3050877819.html',
    name: 'space-channel-5-vr-arakata-dancing-show',
  },
  { url: '/jeux/egress-3050877823.html', name: 'egress' },
  { url: '/jeux/assault-spy-3050877851.html', name: 'assault-spy' },
  { url: '/jeux/unleashed-3050877871.html', name: 'unleashed' },
  {
    url: '/jeux/a-light-in-the-dark-3050877879.html',
    name: 'a-light-in-the-dark',
  },
  {
    url: '/jeux/assetto-corsa-competizione-3050877951.html',
    name: 'assetto-corsa-competizione',
  },
  { url: '/jeux/witch-it-3050877963.html', name: 'witch-it' },
  { url: '/jeux/hellbound-3050877969.html', name: 'hellbound' },
  { url: '/jeux/exo-one-3050877973.html', name: 'exo-one' },
  {
    url: '/jeux/star-control-origins-3050877975.html',
    name: 'star-control-origins',
  },
  { url: '/jeux/kids-3050877977.html', name: 'kids' },
  { url: '/jeux/foundation-3050877985.html', name: 'foundation' },
  { url: '/jeux/defiance-2050-3050878007.html', name: 'defiance-2050' },
  {
    url: '/jeux/mutant-year-zero-road-to-eden-3050878009.html',
    name: 'mutant-year-zero-road-to-eden',
  },
  {
    url: '/jeux/iratus-lord-of-the-dead-3050878015.html',
    name: 'iratus-lord-of-the-dead',
  },
  { url: '/jeux/jackhammer-3050878041.html', name: 'jackhammer' },
  { url: '/jeux/outpost-zero-3050878061.html', name: 'outpost-zero' },
  { url: '/jeux/torn-3050878087.html', name: 'torn' },
  { url: '/jeux/another-sight-3050878093.html', name: 'another-sight' },
  { url: '/jeux/disco-elysium-3050878097.html', name: 'disco-elysium' },
  { url: '/jeux/starmancer-3050878105.html', name: 'starmancer' },
  { url: '/jeux/planet-alpha-3050878109.html', name: 'planet-alpha' },
  { url: '/jeux/black-future-88-3050878131.html', name: 'black-future-88' },
  { url: '/jeux/outer-wilds-3050878143.html', name: 'outer-wilds' },
  {
    url: '/jeux/lode-runner-legacy-3050878147.html',
    name: 'lode-runner-legacy',
  },
  { url: '/jeux/warriors-orochi-4-3050878161.html', name: 'warriors-orochi-4' },
  { url: '/jeux/headsnatchers-3050878181.html', name: 'headsnatchers' },
  { url: '/jeux/rend-3050878195.html', name: 'rend' },
  {
    url: '/jeux/tales-of-the-neon-sea-3050878219.html',
    name: 'tales-of-the-neon-sea',
  },
  {
    url: '/jeux/mark-of-the-ninja-remastered-3050878231.html',
    name: 'mark-of-the-ninja-remastered',
  },
  { url: '/jeux/deathgarden-3050878259.html', name: 'deathgarden' },
  { url: '/jeux/not-tonight-3050878267.html', name: 'not-tonight' },
  { url: '/jeux/insomnia-the-ark-3050878271.html', name: 'insomnia-the-ark' },
  { url: '/jeux/semblance-3050878279.html', name: 'semblance' },
  {
    url: '/jeux/the-spectrum-retreat-3050878299.html',
    name: 'the-spectrum-retreat',
  },
  { url: '/jeux/blind-3050878321.html', name: 'blind' },
  { url: '/jeux/morphies-law-3050720549.html', name: 'morphies-law' },
  { url: '/jeux/outreach-3050747717.html', name: 'outreach' },
  {
    url: '/jeux/steambirds-alliance-3050839645.html',
    name: 'steambirds-alliance',
  },
  { url: '/jeux/sylvio-2-3050875705.html', name: 'sylvio-2' },
  { url: '/jeux/yaga-3050875831.html', name: 'yaga' },
  {
    url: '/jeux/mosh-pit-simulator-3050876117.html',
    name: 'mosh-pit-simulator',
  },
  { url: '/jeux/the-long-reach-3050876201.html', name: 'the-long-reach' },
  { url: '/jeux/we-the-revolution-3050876603.html', name: 'we-the-revolution' },
  {
    url: '/jeux/aquanox-deep-descent-3050876615.html',
    name: 'aquanox-deep-descent',
  },
  {
    url: '/jeux/sausage-sports-club-3050876639.html',
    name: 'sausage-sports-club',
  },
  { url: '/jeux/jalopy-3050876805.html', name: 'jalopy' },
  { url: '/jeux/worm-dungeon-3050877555.html', name: 'worm-dungeon' },
  { note: '3', url: '/jeux/tiny-metal-3050875853.html', name: 'tiny-metal' },
  {
    url: '/jeux/bridge-constructor-portal-3050877355.html',
    name: 'bridge-constructor-portal',
  },
  {
    url: '/jeux/wonder-boy-returns-3050775575.html',
    name: 'wonder-boy-returns',
  },
  { url: '/jeux/ultrawings-3050877039.html', name: 'ultrawings' },
  { note: '6', url: '/jeux/floor-kids-3050876629.html', name: 'floor-kids' },
  { url: '/jeux/the-flames-3050877553.html', name: 'the-flames' },
  { url: '/jeux/purrfect-date-3050876433.html', name: 'purrfect-date' },
  {
    url: '/jeux/bud-spencer-terence-hill-slaps-and-beans-3050877449.html',
    name: 'bud-spencer-terence-hill-slaps-and-beans',
  },
  { url: '/jeux/dodgeball-rising-3050877129.html', name: 'dodgeball-rising' },
  { url: '/jeux/fade-to-silence-3050877381.html', name: 'fade-to-silence' },
  {
    url: '/jeux/arcana-heart-3-love-max-six-stars-3050353145.html',
    name: 'arcana-heart-3-love-max-six-stars',
  },
  { url: '/jeux/violet-cycle-3050877517.html', name: 'violet-cycle' },
  {
    url: '/jeux/emily-wants-to-play-too-3050878139.html',
    name: 'emily-wants-to-play-too',
  },
  {
    url: '/jeux/read-only-memories-3050552129.html',
    name: 'read-only-memories',
  },
  { url: '/jeux/fallout-4-vr-3050876035.html', name: 'fallout-4-vr' },
  { url: '/jeux/they-are-billions-3050877629.html', name: 'they-are-billions' },
  {
    url: '/jeux/battlefield-1-turning-tides-3050877243.html',
    name: 'battlefield-1-turning-tides',
  },
  { url: '/jeux/ogon-musokyoku-3010009366.html', name: 'ogon-musokyoku' },
  { url: '/jeux/hello-neighbor-3050876113.html', name: 'hello-neighbor' },
  {
    url: '/jeux/hearthstone-kobolds-catacombes-3050877109.html',
    name: 'hearthstone-kobolds-catacombes',
  },
  { url: '/jeux/yodanji-3050877345.html', name: 'yodanji' },
  { url: '/jeux/dimension-drive-3050812099.html', name: 'dimension-drive' },
  {
    url: '/jeux/reigns-her-majesty-3050876551.html',
    name: 'reigns-her-majesty',
  },
  {
    url: '/jeux/superhot-mind-control-delete-3050877341.html',
    name: 'superhot-mind-control-delete',
  },
  { url: '/jeux/nine-parchments-3050751885.html', name: 'nine-parchments' },
  { url: '/jeux/dreadnought-3050817611.html', name: 'dreadnought' },
  { url: '/jeux/boiling-bolt-3050868781.html', name: 'boiling-bolt' },
  {
    url: '/jeux/ilios-betryal-of-gods-3050285183.html',
    name: 'ilios-betryal-of-gods',
  },
  { url: '/jeux/prey-for-the-gods-3050564377.html', name: 'prey-for-the-gods' },
  { url: '/jeux/aggelos-3050665599.html', name: 'aggelos' },
  { url: '/jeux/doom-vfr-3050876033.html', name: 'doom-vfr' },
  {
    url: '/jeux/l-a-noire-les-enquetes-vr-3050876689.html',
    name: 'l-a-noire-les-enquetes-vr',
  },
  { url: '/jeux/nocturnal-hunt-3050877489.html', name: 'nocturnal-hunt' },
  { url: '/jeux/black-jewel-3050877523.html', name: 'black-jewel' },
  {
    url: '/jeux/sky-force-reloaded-3050877239.html',
    name: 'sky-force-reloaded',
  },
  { url: '/jeux/elminage-original-3010005661.html', name: 'elminage-original' },
  {
    url: '/jeux/star-ocean-4-the-last-hope-4k-full-hd-remaster-3050876893.html',
    name: 'star-ocean-4-the-last-hope-4k-full-hd-remaster',
  },
  { url: '/jeux/contradiction-3050877513.html', name: 'contradiction' },
  { url: '/jeux/desert-bus-vr-3050877281.html', name: 'desert-bus-vr' },
  {
    url: '/jeux/stick-it-to-the-man-3050143514.html',
    name: 'stick-it-to-the-man',
  },
  {
    url: '/jeux/tokyo-tattoo-girls-3050860939.html',
    name: 'tokyo-tattoo-girls',
  },
  {
    url: '/jeux/the-elder-scrolls-skyrim-vr-3050876065.html',
    name: 'the-elder-scrolls-skyrim-vr',
  },
  { url: '/jeux/tower-57-3050760761.html', name: 'tower-57' },
  {
    url: '/jeux/uurnog-uurnlimited-3050877255.html',
    name: 'uurnog-uurnlimited',
  },
  {
    url: '/jeux/lego-marvel-super-heroes-3050104451.html',
    name: 'lego-marvel-super-heroes',
  },
  { url: '/jeux/hyakki-castle-3050876739.html', name: 'hyakki-castle' },
  { url: '/jeux/road-rage-3050647495.html', name: 'road-rage' },
  { url: '/jeux/far-from-noise-3050877111.html', name: 'far-from-noise' },
  {
    url: '/jeux/killing-floor-incursion-3050877401.html',
    name: 'killing-floor-incursion',
  },
  { url: '/jeux/prototype-mansion-3050877245.html', name: 'prototype-mansion' },
  { url: '/jeux/next-3050878237.html', name: 'next' },
  {
    url: '/jeux/football-manager-2018-3050876445.html',
    name: 'football-manager-2018',
  },
  {
    url: '/jeux/les-sims-4-chiens-et-chats-3050876497.html',
    name: 'les-sims-4-chiens-et-chats',
  },
  {
    url: '/jeux/football-manager-touch-2018-3050876823.html',
    name: 'football-manager-touch-2018',
  },
  { url: '/jeux/cat-quest-3050877133.html', name: 'cat-quest' },
  { url: '/jeux/ben-10-3050877151.html', name: 'ben-10' },
  { url: '/jeux/transroad-3050876515.html', name: 'transroad' },
  { url: '/jeux/battlerite-3050807209.html', name: 'battlerite' },
  { url: '/jeux/punch-planet-3050877325.html', name: 'punch-planet' },
  {
    note: '5',
    url: '/jeux/sonic-forces-3050739475.html',
    name: 'sonic-forces',
  },
  {
    note: '5',
    url: '/jeux/super-lucky-s-tale-3050876027.html',
    name: 'super-lucky-s-tale',
  },
  {
    url: '/jeux/hitman-game-of-the-year-edition-3050876989.html',
    name: 'hitman-game-of-the-year-edition',
  },
  { url: '/jeux/death-coming-3050877521.html', name: 'death-coming' },
  { url: '/jeux/ghoulboy-3050877319.html', name: 'ghoulboy' },
  {
    url: '/jeux/hack-g-u-last-recode-3050876099.html',
    name: 'hack-g-u-last-recode',
  },
  { url: '/jeux/enceladus-3050875737.html', name: 'enceladus' },
  { url: '/jeux/zwei-ii-plus-99607.html', name: 'zwei-ii-plus' },
  { url: '/jeux/creeping-terror-3050837827.html', name: 'creeping-terror' },
  {
    url: '/jeux/remothered-tormented-fathers-3050849745.html',
    name: 'remothered-tormented-fathers',
  },
  {
    note: '2',
    url: '/jeux/bubsy-the-woolies-strike-back-3050875987.html',
    name: 'bubsy-the-woolies-strike-back',
  },
  {
    url: '/jeux/spintires-mudrunner-3050876471.html',
    name: 'spintires-mudrunner',
  },
  { url: '/jeux/stifled-3050876927.html', name: 'stifled' },
  { url: '/jeux/midnight-ultra-3050877519.html', name: 'midnight-ultra' },
  { note: '3', url: '/jeux/oure-3050877047.html', name: 'oure' },
  { url: '/jeux/nights-of-azure-2-3050757479.html', name: 'nights-of-azure-2' },
  { url: '/jeux/shin-yomawari-3050875717.html', name: 'shin-yomawari' },
  {
    url: '/jeux/assassin-s-creed-origins-3050875839.html',
    name: 'assassin-s-creed-origins',
  },
  { url: '/jeux/rugby-18-3050875895.html', name: 'rugby-18' },
  {
    url: '/jeux/rogue-quest-the-vault-of-the-lost-tyrant-3050877077.html',
    name: 'rogue-quest-the-vault-of-the-lost-tyrant',
  },
  {
    url: '/jeux/the-mummy-demastered-3050876885.html',
    name: 'the-mummy-demastered',
  },
  { url: '/jeux/high-hell-3050876653.html', name: 'high-hell' },
  { url: '/jeux/the-cavern-3050876979.html', name: 'the-cavern' },
  { url: '/jeux/engare-3050876985.html', name: 'engare' },
  { url: '/jeux/spelunker-3050840745.html', name: 'spelunker' },
  { url: '/jeux/brass-tactics-3050865409.html', name: 'brass-tactics' },
  {
    url: '/jeux/cities-skylines-green-cities-3050876519.html',
    name: 'cities-skylines-green-cities',
  },
  {
    url: '/jeux/bafl-brakes-are-for-losers-3050876815.html',
    name: 'bafl-brakes-are-for-losers',
  },
  {
    url: '/jeux/a-mortician-s-tale-3050876935.html',
    name: 'a-mortician-s-tale',
  },
  {
    url: '/jeux/the-invisible-hours-3050875595.html',
    name: 'the-invisible-hours',
  },
  { url: '/jeux/wwe-2k18-3050876119.html', name: 'wwe-2k18' },
  { url: '/jeux/megaton-rainfall-3050876421.html', name: 'megaton-rainfall' },
  { url: '/jeux/overturn-3050876827.html', name: 'overturn' },
  {
    url: '/jeux/rosenkreuzstilette-freudenstachel-3050876975.html',
    name: 'rosenkreuzstilette-freudenstachel',
  },
  { url: '/jeux/chaos-child-3050440747.html', name: 'chaos-child' },
  {
    url: '/jeux/cyberdimension-neptunia-4-goddesses-online-3050871553.html',
    name: 'cyberdimension-neptunia-4-goddesses-online',
  },
  { note: '5', url: '/jeux/wulverblade-3050282365.html', name: 'wulverblade' },
  { url: '/jeux/sociable-soccer-3050579981.html', name: 'sociable-soccer' },
  {
    note: '7',
    url: '/jeux/the-evil-within-2-3050876011.html',
    name: 'the-evil-within-2',
  },
  {
    url: '/jeux/yono-and-the-celestial-elephants-3050876637.html',
    name: 'yono-and-the-celestial-elephants',
  },
  { url: '/jeux/the-well-3050876851.html', name: 'the-well' },
  { url: '/jeux/tiny-barbarian-dx-3050287813.html', name: 'tiny-barbarian-dx' },
  { url: '/jeux/arktika-1-3050783651.html', name: 'arktika-1' },
  {
    note: '6',
    url: '/jeux/la-terre-du-milieu-l-ombre-de-la-guerre-3050864239.html',
    name: 'la-terre-du-milieu-l-ombre-de-la-guerre',
  },
  { url: '/jeux/sine-mora-ex-3050867541.html', name: 'sine-mora-ex' },
  {
    url: '/jeux/shikhondo-soul-eater-3050876955.html',
    name: 'shikhondo-soul-eater',
  },
  {
    url: '/jeux/hanako-honor-blade-3050876121.html',
    name: 'hanako-honor-blade',
  },
  { url: '/jeux/infiniroom-3050877087.html', name: 'infiniroom' },
  { url: '/jeux/getting-over-it-3050876811.html', name: 'getting-over-it' },
  { url: '/jeux/mashinky-3050876887.html', name: 'mashinky' },
  { url: '/jeux/a-hat-in-time-3050144093.html', name: 'a-hat-in-time' },
  { url: '/jeux/after-death-3050876873.html', name: 'after-death' },
  { url: '/jeux/jydge-3050878027.html', name: 'jydge' },
  {
    note: '7',
    url: '/jeux/forza-motorsport-7-3050876017.html',
    name: 'forza-motorsport-7',
  },
  { url: '/jeux/the-norwood-suite-3050876817.html', name: 'the-norwood-suite' },
  { url: '/jeux/forsaken-castle-3050875913.html', name: 'forsaken-castle' },
  { url: '/jeux/ylands-3050876047.html', name: 'ylands' },
  { url: '/jeux/desert-child-3050876169.html', name: 'desert-child' },
  { url: '/jeux/mecho-tales-3050877621.html', name: 'mecho-tales' },
  { url: '/jeux/cuphead-3050273317.html', name: 'cuphead' },
  {
    note: '8',
    url: '/jeux/danganronpa-v3-killing-harmony-3050542625.html',
    name: 'danganronpa-v3-killing-harmony',
  },
  { url: '/jeux/blue-reflection-3050757491.html', name: 'blue-reflection' },
  { note: '7', url: '/jeux/fifa-18-3050875557.html', name: 'fifa-18' },
  { url: '/jeux/fall-of-light-3050876821.html', name: 'fall-of-light' },
  { url: '/jeux/vaporum-3050877293.html', name: 'vaporum' },
  { url: '/jeux/home-sweet-home-3050876925.html', name: 'home-sweet-home' },
  { url: '/jeux/fragments-of-him-3050507915.html', name: 'fragments-of-him' },
  { url: '/jeux/ruiner-3050863163.html', name: 'ruiner' },
  { url: '/jeux/raid-world-war-2-3050870559.html', name: 'raid-world-war-2' },
  { url: '/jeux/pinball-fx3-3050876189.html', name: 'pinball-fx3' },
  {
    url: '/jeux/fallout-4-game-of-the-year-edition-3050876425.html',
    name: 'fallout-4-game-of-the-year-edition',
  },
  {
    url: '/jeux/voltron-vr-chronicles-3050876591.html',
    name: 'voltron-vr-chronicles',
  },
  { url: '/jeux/the-guild-3-3050876813.html', name: 'the-guild-3' },
  { url: '/jeux/midnight-scenes-3050876849.html', name: 'midnight-scenes' },
  { url: '/jeux/ninja-shodown-3050876901.html', name: 'ninja-shodown' },
  {
    note: '7',
    url: '/jeux/project-cars-2-3050491245.html',
    name: 'project-cars-2',
  },
  {
    url: '/jeux/senran-kagura-peach-beach-splash-3050781469.html',
    name: 'senran-kagura-peach-beach-splash',
  },
  { url: '/jeux/figment-3050875857.html', name: 'figment' },
  { url: '/jeux/the-coma-recut-3050876525.html', name: 'the-coma-recut' },
  {
    url: '/jeux/doki-doki-literature-club-3050877529.html',
    name: 'doki-doki-literature-club',
  },
  {
    url: '/jeux/xing-the-land-beyond-3050589629.html',
    name: 'xing-the-land-beyond',
  },
  {
    note: '7',
    url: '/jeux/steamworld-dig-2-3050865027.html',
    name: 'steamworld-dig-2',
  },
  {
    url: '/jeux/another-lost-phone-laura-s-story-3050876391.html',
    name: 'another-lost-phone-laura-s-story',
  },
  { url: '/jeux/heat-signature-3050876793.html', name: 'heat-signature' },
  { url: '/jeux/super-hydorah-3050847961.html', name: 'super-hydorah' },
  { url: '/jeux/morphite-3050876773.html', name: 'morphite' },
  {
    url: '/jeux/muv-luv-alternative-3050036776.html',
    name: 'muv-luv-alternative',
  },
  { url: '/jeux/echo-3050702853.html', name: 'echo' },
  { url: '/jeux/hidden-dragon-3050876241.html', name: 'hidden-dragon' },
  { url: '/jeux/atomega-3050876711.html', name: 'atomega' },
  { url: '/jeux/fight-n-rage-3050876787.html', name: 'fight-n-rage' },
  {
    note: '8',
    url: '/jeux/ys-viii-lacrimosa-of-dana-3050330075.html',
    name: 'ys-viii-lacrimosa-of-dana',
  },
  { note: '7', url: '/jeux/nba-2k18-3050838545.html', name: 'nba-2k18' },
  { url: '/jeux/wrc-7-3050875851.html', name: 'wrc-7' },
  {
    url: '/jeux/radial-g-racing-revolved-3050876179.html',
    name: 'radial-g-racing-revolved',
  },
  { url: '/jeux/the-witch-s-isle-3050876807.html', name: 'the-witch-s-isle' },
  { url: '/jeux/freeways-3050877817.html', name: 'freeways' },
  {
    url: '/jeux/jettomero-hero-of-the-universe-3050877979.html',
    name: 'jettomero-hero-of-the-universe',
  },
  {
    note: '6',
    url: '/jeux/pro-evolution-soccer-2018-3050875849.html',
    name: 'pro-evolution-soccer-2018',
  },
  { url: '/jeux/fantasy-strike-3050876243.html', name: 'fantasy-strike' },
  { url: '/jeux/the-first-tree-3050876661.html', name: 'the-first-tree' },
  { url: '/jeux/samurai-riot-3050472629.html', name: 'samurai-riot' },
  { url: '/jeux/tooth-and-tail-3050526763.html', name: 'tooth-and-tail' },
  { url: '/jeux/bloody-zombies-3050876395.html', name: 'bloody-zombies' },
  { url: '/jeux/vulture-3050876701.html', name: 'vulture' },
  { url: '/jeux/hyperun-3050875773.html', name: 'hyperun' },
  { url: '/jeux/tokyo-dark-3050466415.html', name: 'tokyo-dark' },
  {
    url: '/jeux/tyranny-bastard-s-wound-3050876529.html',
    name: 'tyranny-bastard-s-wound',
  },
  { note: '6', url: '/jeux/destiny-2-3050778037.html', name: 'destiny-2' },
  {
    url: '/jeux/blood-bowl-ii-legendary-edition-3050855549.html',
    name: 'blood-bowl-ii-legendary-edition',
  },
  { url: '/jeux/don-t-knock-twice-3050876411.html', name: 'don-t-knock-twice' },
  { url: '/jeux/songbringer-3050590823.html', name: 'songbringer' },
  { url: '/jeux/rabi-ribi-3050720481.html', name: 'rabi-ribi' },
  { url: '/jeux/muso-stars-3050768549.html', name: 'muso-stars' },
  { url: '/jeux/backslash-3050870111.html', name: 'backslash' },
  { url: '/jeux/the-day-after-3050875847.html', name: 'the-day-after' },
  {
    url: '/jeux/card-city-nights-2-3050875973.html',
    name: 'card-city-nights-2',
  },
  {
    url: '/jeux/warhammer-40-000-inquisitor-martyr-3050509721.html',
    name: 'warhammer-40-000-inquisitor-martyr',
  },
  { url: '/jeux/tiny-echo-3050876681.html', name: 'tiny-echo' },
  { url: '/jeux/bannerman-3050876755.html', name: 'bannerman' },
  { url: '/jeux/surf-world-series-3050826253.html', name: 'surf-world-series' },
  { url: '/jeux/x-morph-defense-3050857919.html', name: 'x-morph-defense' },
  { url: '/jeux/sunless-skies-3050876363.html', name: 'sunless-skies' },
  { url: '/jeux/morph-girl-3050876405.html', name: 'morph-girl' },
  {
    url: '/jeux/rock-of-ages-ii-bigger-boulder-3050707033.html',
    name: 'rock-of-ages-ii-bigger-boulder',
  },
  { url: '/jeux/sparc-3050867675.html', name: 'sparc' },
  { url: '/jeux/dead-alliance-3050875903.html', name: 'dead-alliance' },
  { url: '/jeux/white-day-3050652979.html', name: 'white-day' },
  {
    url: '/jeux/naruto-ultimate-ninja-storm-trilogy-3050875633.html',
    name: 'naruto-ultimate-ninja-storm-trilogy',
  },
  {
    url: '/jeux/naruto-shippuden-ultimate-ninja-storm-legacy-3050875653.html',
    name: 'naruto-shippuden-ultimate-ninja-storm-legacy',
  },
  { url: '/jeux/f1-2017-3050875855.html', name: 'f1-2017' },
  { url: '/jeux/robocraft-3050878059.html', name: 'robocraft' },
  { url: '/jeux/rivals-of-aether-3050545897.html', name: 'rivals-of-aether' },
  { url: '/jeux/quake-champions-3050713537.html', name: 'quake-champions' },
  { url: '/jeux/the-escapists-2-3050780945.html', name: 'the-escapists-2' },
  {
    url: '/jeux/battlefield-1-revolution-3050876493.html',
    name: 'battlefield-1-revolution',
  },
  { url: '/jeux/agents-of-mayhem-3050708725.html', name: 'agents-of-mayhem' },
  { url: '/jeux/darkwood-3050166762.html', name: 'darkwood' },
  {
    url: '/jeux/super-blood-hockey-3050876585.html',
    name: 'super-blood-hockey',
  },
  {
    url: '/jeux/subsurface-circular-3050876983.html',
    name: 'subsurface-circular',
  },
  { url: '/jeux/the-trail-3050799385.html', name: 'the-trail' },
  { url: '/jeux/20xx-3050877431.html', name: '20xx' },
  {
    url: '/jeux/les-piliers-de-la-terre-3050373223.html',
    name: 'les-piliers-de-la-terre',
  },
  { note: '7', url: '/jeux/sonic-mania-3050739457.html', name: 'sonic-mania' },
  { url: '/jeux/nidhogg-2-3050799427.html', name: 'nidhogg-2' },
  {
    url: '/jeux/night-trap-25th-anniversary-edition-3050875727.html',
    name: 'night-trap-25th-anniversary-edition',
  },
  {
    url: '/jeux/minecraft-storymode-saison-2-3050875975.html',
    name: 'minecraft-storymode-saison-2',
  },
  { url: '/jeux/sudden-strike-4-3050746149.html', name: 'sudden-strike-4' },
  {
    note: '5',
    url: '/jeux/phantom-trigger-3050875867.html',
    name: 'phantom-trigger',
  },
  { url: '/jeux/west-of-loathing-3050876329.html', name: 'west-of-loathing' },
  { url: '/jeux/swim-out-3050876727.html', name: 'swim-out' },
  { url: '/jeux/gal-gun-vr-3050876763.html', name: 'gal-gun-vr' },
  { note: '6', url: '/jeux/hellblade-3050387569.html', name: 'hellblade' },
  { url: '/jeux/plague-road-3050875619.html', name: 'plague-road' },
  {
    url: '/jeux/mega-man-legacy-collection-2-3050875751.html',
    name: 'mega-man-legacy-collection-2',
  },
  {
    url: '/jeux/batman-the-enemy-within-3050876279.html',
    name: 'batman-the-enemy-within',
  },
  {
    url: '/jeux/all-walls-must-fall-3050876337.html',
    name: 'all-walls-must-fall',
  },
  {
    url: '/jeux/36-fragments-of-midnight-3050877433.html',
    name: '36-fragments-of-midnight',
  },
  { url: '/jeux/the-shrouded-isle-3050784197.html', name: 'the-shrouded-isle' },
  { url: '/jeux/use-your-words-3050876309.html', name: 'use-your-words' },
  { url: '/jeux/aztez-3050043806.html', name: 'aztez' },
  { url: '/jeux/slime-rancher-3050617219.html', name: 'slime-rancher' },
  { url: '/jeux/demetrios-3050702815.html', name: 'demetrios' },
  { url: '/jeux/archangel-vr-3050875947.html', name: 'archangel-vr' },
  {
    url: '/jeux/fidel-dungeon-rescue-3050876197.html',
    name: 'fidel-dungeon-rescue',
  },
  { url: '/jeux/sundered-3050777377.html', name: 'sundered' },
  {
    note: '7',
    url: '/jeux/the-darkside-detective-3050875933.html',
    name: 'the-darkside-detective',
  },
  {
    url: '/jeux/galaxy-of-pen-and-paper-3050875965.html',
    name: 'galaxy-of-pen-and-paper',
  },
  { url: '/jeux/theseus-3050875951.html', name: 'theseus' },
  {
    url: '/jeux/citadel-forged-with-fire-3050876259.html',
    name: 'citadel-forged-with-fire',
  },
  { url: '/jeux/vostok-inc-3050877349.html', name: 'vostok-inc' },
  { note: '8', url: '/jeux/pyre-3050681547.html', name: 'pyre' },
  { url: '/jeux/fable-fortune-3050704995.html', name: 'fable-fortune' },
  { url: '/jeux/aven-colony-3050759207.html', name: 'aven-colony' },
  { url: '/jeux/super-cloudbuilt-3050862669.html', name: 'super-cloudbuilt' },
  { url: '/jeux/infinite-minigolf-3050876305.html', name: 'infinite-minigolf' },
  { url: '/jeux/lone-echo-3050783657.html', name: 'lone-echo' },
  {
    url: '/jeux/dream-daddy-a-dad-dating-simulator-3050876749.html',
    name: 'dream-daddy-a-dad-dating-simulator',
  },
  {
    url: '/jeux/aporia-beyond-the-valley-3050875835.html',
    name: 'aporia-beyond-the-valley',
  },
  {
    url: '/jeux/orcs-must-die-unchained-3050376131.html',
    name: 'orcs-must-die-unchained',
  },
  {
    url: '/jeux/the-girl-and-the-robot-3050748011.html',
    name: 'the-girl-and-the-robot',
  },
  {
    url: '/jeux/yonder-the-cloud-catcher-chronicles-3050816419.html',
    name: 'yonder-the-cloud-catcher-chronicles',
  },
  {
    url: '/jeux/super-rocket-shootout-3050876245.html',
    name: 'super-rocket-shootout',
  },
  { url: '/jeux/yankai-s-peak-3050876159.html', name: 'yankai-s-peak' },
  { url: '/jeux/iron-crypticle-3050876233.html', name: 'iron-crypticle' },
  { url: '/jeux/black-the-fall-3050361989.html', name: 'black-the-fall' },
  {
    url: '/jeux/fire-pro-wrestling-world-3050867217.html',
    name: 'fire-pro-wrestling-world',
  },
  { url: '/jeux/antihero-3050876251.html', name: 'antihero' },
  { url: '/jeux/gorn-3050876255.html', name: 'gorn' },
  {
    url: '/jeux/accel-world-vs-sword-art-online-3050780793.html',
    name: 'accel-world-vs-sword-art-online',
  },
  { url: '/jeux/little-red-lie-3050877153.html', name: 'little-red-lie' },
  {
    url:
      '/jeux/baobabs-mausoleum-ep-1-ovnifagos-don-t-eat-flamingos-3050876293.html',
    name: 'baobabs-mausoleum-ep-1-ovnifagos-don-t-eat-flamingos',
  },
  {
    url: '/jeux/red-ash-the-kalkanon-incident-3050518005.html',
    name: 'red-ash-the-kalkanon-incident',
  },
  { url: '/jeux/graveyard-keeper-3050864337.html', name: 'graveyard-keeper' },
  {
    note: '6',
    url: '/jeux/tokyo-xanadu-3050728063.html',
    name: 'tokyo-xanadu',
  },
  {
    url: '/jeux/the-binding-of-isaac-afterbirth-3050823797.html',
    name: 'the-binding-of-isaac-afterbirth',
  },
  {
    note: '3',
    url: '/jeux/micro-machines-world-series-3050842071.html',
    name: 'micro-machines-world-series',
  },
  { url: '/jeux/ticket-to-earth-3050876969.html', name: 'ticket-to-earth' },
  {
    url: '/jeux/princess-maker-yumemiru-yosei-3010005205.html',
    name: 'princess-maker-yumemiru-yosei',
  },
  {
    url: '/jeux/secret-world-legends-3050875593.html',
    name: 'secret-world-legends',
  },
  { url: '/jeux/argo-3050875949.html', name: 'argo' },
  { url: '/jeux/lineway-3050877737.html', name: 'lineway' },
  { url: '/jeux/dragodino-3050876141.html', name: 'dragodino' },
  { url: '/jeux/the-mage-s-tale-3050876277.html', name: 'the-mage-s-tale' },
  { url: '/jeux/motogp-17-3050870649.html', name: 'motogp-17' },
  {
    url: '/jeux/pathfinder-adventures-3050876115.html',
    name: 'pathfinder-adventures',
  },
  {
    url: '/jeux/dark-rose-valkyrie-3050630735.html',
    name: 'dark-rose-valkyrie',
  },
  {
    url: '/jeux/cladun-returns-this-is-sengoku-3050806685.html',
    name: 'cladun-returns-this-is-sengoku',
  },
  { note: '7', url: '/jeux/dirt-4-3050845981.html', name: 'dirt-4' },
  { url: '/jeux/the-vagrant-3050876155.html', name: 'the-vagrant' },
  {
    url: '/jeux/lethis-daring-discoverers-3050656021.html',
    name: 'lethis-daring-discoverers',
  },
  { url: '/jeux/monolith-3050875713.html', name: 'monolith' },
  {
    url: '/jeux/little-nightmares-complete-edition-3050878089.html',
    name: 'little-nightmares-complete-edition',
  },
  {
    url: '/jeux/victor-vran-overkill-edition-3050875955.html',
    name: 'victor-vran-overkill-edition',
  },
  { url: '/jeux/conarium-3050875967.html', name: 'conarium' },
  { url: '/jeux/drifting-lands-3050642007.html', name: 'drifting-lands' },
  {
    url: '/jeux/tormentor-x-punisher-3050876383.html',
    name: 'tormentor-x-punisher',
  },
  { url: '/jeux/aerea-3050877845.html', name: 'aerea' },
  {
    url: '/jeux/operation-warcade-vr-3050878309.html',
    name: 'operation-warcade-vr',
  },
  { url: '/jeux/moira-3050672089.html', name: 'moira' },
  {
    url: '/jeux/astro-boy-edge-of-time-3050757101.html',
    name: 'astro-boy-edge-of-time',
  },
  { url: '/jeux/formula-fusion-3050877019.html', name: 'formula-fusion' },
  {
    url: '/jeux/hover-revolt-of-gamers-3050250095.html',
    name: 'hover-revolt-of-gamers',
  },
  { url: '/jeux/tokyo-42-3050764733.html', name: 'tokyo-42' },
  {
    url: '/jeux/the-long-journey-home-3050664713.html',
    name: 'the-long-journey-home',
  },
  {
    note: '7',
    url: '/jeux/lock-s-quest-3050835141.html',
    name: 'lock-s-quest',
  },
  { url: '/jeux/perception-3050847439.html', name: 'perception' },
  {
    url: '/jeux/mxgp3-the-official-motocross-videogame-3050847477.html',
    name: 'mxgp3-the-official-motocross-videogame',
  },
  { url: '/jeux/danger-zone-3050875735.html', name: 'danger-zone' },
  { url: '/jeux/balthazar-s-dream-3050875979.html', name: 'balthazar-s-dream' },
  {
    url: '/jeux/friday-the-13th-the-game-3050361755.html',
    name: 'friday-the-13th-the-game',
  },
  {
    url: '/jeux/samurai-warriors-sanadamaru-3050734633.html',
    name: 'samurai-warriors-sanadamaru',
  },
  { url: '/jeux/emily-is-away-too-3050789977.html', name: 'emily-is-away-too' },
  { note: '6', url: '/jeux/rime-3050833219.html', name: 'rime' },
  {
    url: '/jeux/guilty-gear-xrd-rev-2-3050839593.html',
    name: 'guilty-gear-xrd-rev-2',
  },
  { url: '/jeux/worlds-adrift-3050637899.html', name: 'worlds-adrift' },
  {
    url: '/jeux/mirage-arcane-warfare-3050649821.html',
    name: 'mirage-arcane-warfare',
  },
  { url: '/jeux/impact-winter-3050854691.html', name: 'impact-winter' },
  {
    url: '/jeux/trackmania2-lagoon-3050875789.html',
    name: 'trackmania2-lagoon',
  },
  {
    url: '/jeux/the-fidelio-incident-3050875893.html',
    name: 'the-fidelio-incident',
  },
  { url: '/jeux/spellspire-3050877139.html', name: 'spellspire' },
  { url: '/jeux/embers-of-mirrim-3050877353.html', name: 'embers-of-mirrim' },
  {
    url: '/jeux/hakuouki-kyoto-winds-3050533299.html',
    name: 'hakuouki-kyoto-winds',
  },
  {
    url: '/jeux/mayan-death-robots-3050569231.html',
    name: 'mayan-death-robots',
  },
  {
    url: '/jeux/skylar-plux-adventure-on-clover-island-3050662695.html',
    name: 'skylar-plux-adventure-on-clover-island',
  },
  {
    url: '/jeux/operation-babel-new-tokyo-legacy-3050808127.html',
    name: 'operation-babel-new-tokyo-legacy',
  },
  { url: '/jeux/nonguenz-3050875591.html', name: 'nonguenz' },
  { url: '/jeux/a-hole-new-world-3050875747.html', name: 'a-hole-new-world' },
  {
    url: '/jeux/the-infectious-madness-of-doctor-dekker-3050877315.html',
    name: 'the-infectious-madness-of-doctor-dekker',
  },
  { url: '/jeux/regalia-3050508261.html', name: 'regalia' },
  { note: '7', url: '/jeux/injustice-2-3050709583.html', name: 'injustice-2' },
  { url: '/jeux/old-man-s-journey-3050810393.html', name: 'old-man-s-journey' },
  { url: '/jeux/inner-chains-3050869465.html', name: 'inner-chains' },
  { url: '/jeux/flight-sim-world-3050875819.html', name: 'flight-sim-world' },
  { url: '/jeux/bokida-3050877119.html', name: 'bokida' },
  { url: '/jeux/phantom-dust-3050272801.html', name: 'phantom-dust' },
  { url: '/jeux/bounty-train-3050480087.html', name: 'bounty-train' },
  {
    url: '/jeux/call-of-duty-black-ops-iii-zombies-chronicles-3050875775.html',
    name: 'call-of-duty-black-ops-iii-zombies-chronicles',
  },
  {
    url: '/jeux/birthdays-the-beginning-3050770603.html',
    name: 'birthdays-the-beginning',
  },
  { url: '/jeux/dead-cells-3050326015.html', name: 'dead-cells' },
  { note: '7', url: '/jeux/polybius-3050785207.html', name: 'polybius' },
  {
    url: '/jeux/river-city-melee-battle-royal-special-3050805023.html',
    name: 'river-city-melee-battle-royal-special',
  },
  { url: '/jeux/rakuen-3050875769.html', name: 'rakuen' },
  { url: '/jeux/world-to-the-west-3050756953.html', name: 'world-to-the-west' },
  { url: '/jeux/spaceplan-3050875723.html', name: 'spaceplan' },
  { url: '/jeux/dawn-of-andromeda-3050875725.html', name: 'dawn-of-andromeda' },
  { url: '/jeux/crowd-smashers-3050875741.html', name: 'crowd-smashers' },
  { url: '/jeux/atomine-3050876465.html', name: 'atomine' },
  { url: '/jeux/post-human-w-a-r-3050376987.html', name: 'post-human-w-a-r' },
  { url: '/jeux/tumbleseed-3050863355.html', name: 'tumbleseed' },
  {
    url: '/jeux/neverwinter-shroud-of-souls-3050875641.html',
    name: 'neverwinter-shroud-of-souls',
  },
  {
    url: '/jeux/darksiders-fury-s-collection-war-and-death-3050875765.html',
    name: 'darksiders-fury-s-collection-war-and-death',
  },
  {
    url: '/jeux/extreme-gravity-rage-3050748225.html',
    name: 'extreme-gravity-rage',
  },
  {
    url: '/jeux/cities-skylines-mass-transit-3050864993.html',
    name: 'cities-skylines-mass-transit',
  },
  {
    note: '7',
    url: '/jeux/little-nightmares-3050422463.html',
    name: 'little-nightmares',
  },
  {
    note: '6',
    url: '/jeux/dragon-quest-heroes-ii-3050444075.html',
    name: 'dragon-quest-heroes-ii',
  },
  { url: '/jeux/constructor-3050861759.html', name: 'constructor' },
  { url: '/jeux/de-formers-3050706459.html', name: 'de-formers' },
  {
    url: '/jeux/expeditions-viking-3050855501.html',
    name: 'expeditions-viking',
  },
  { url: '/jeux/outlast-trinity-3050868491.html', name: 'outlast-trinity' },
  { url: '/jeux/slice-dice-rice-3050876953.html', name: 'slice-dice-rice' },
  { url: '/jeux/agatha-knife-3050877723.html', name: 'agatha-knife' },
  { url: '/jeux/scanner-sombre-3050875715.html', name: 'scanner-sombre' },
  { url: '/jeux/earthfall-3050878133.html', name: 'earthfall' },
  { url: '/jeux/outlast-2-3050359225.html', name: 'outlast-2' },
  { url: '/jeux/wilson-s-heart-3050714327.html', name: 'wilson-s-heart' },
  {
    note: '8',
    url: '/jeux/what-remains-of-edith-finch-3050816509.html',
    name: 'what-remains-of-edith-finch',
  },
  { url: '/jeux/immortal-redneck-3050875601.html', name: 'immortal-redneck' },
  { url: '/jeux/sailaway-3050875729.html', name: 'sailaway' },
  { url: '/jeux/pinstripe-3050877987.html', name: 'pinstripe' },
  {
    url:
      '/jeux/les-aventures-de-bertram-fiddle-episode-2-a-bleaker-predicklement-3050878241.html',
    name: 'les-aventures-de-bertram-fiddle-episode-2-a-bleaker-predicklement',
  },
  {
    url: '/jeux/dark-souls-iii-the-fire-fades-3050843769.html',
    name: 'dark-souls-iii-the-fire-fades',
  },
  { note: '5', url: '/jeux/brawlout-3050875701.html', name: 'brawlout' },
  { url: '/jeux/loud-on-planet-x-3050876335.html', name: 'loud-on-planet-x' },
  { note: '5', url: '/jeux/shiness-3050599777.html', name: 'shiness' },
  {
    url: '/jeux/voodoo-vince-remastered-3050783313.html',
    name: 'voodoo-vince-remastered',
  },
  {
    url:
      '/jeux/marvel-s-guardians-of-the-galaxy-the-telltale-series-3050816761.html',
    name: 'marvel-s-guardians-of-the-galaxy-the-telltale-series',
  },
  {
    note: '7',
    url: '/jeux/wonder-boy-the-dragon-s-trap-3050838401.html',
    name: 'wonder-boy-the-dragon-s-trap',
  },
  { url: '/jeux/late-shift-3050869743.html', name: 'late-shift' },
  {
    url: '/jeux/the-disney-afternoon-collection-3050873635.html',
    name: 'the-disney-afternoon-collection',
  },
  {
    url: '/jeux/stern-pinball-arcade-3050877537.html',
    name: 'stern-pinball-arcade',
  },
  {
    url: '/jeux/a-rose-in-the-twilight-3050807259.html',
    name: 'a-rose-in-the-twilight',
  },
  { url: '/jeux/the-sexy-brutale-3050752397.html', name: 'the-sexy-brutale' },
  { url: '/jeux/crawl-3050230510.html', name: 'crawl' },
  {
    url: '/jeux/planescape-torment-enhanced-edition-3050875587.html',
    name: 'planescape-torment-enhanced-edition',
  },
  {
    url: '/jeux/bulletstorm-full-clip-edition-3050779197.html',
    name: 'bulletstorm-full-clip-edition',
  },
  { url: '/jeux/slime-san-3050876347.html', name: 'slime-san' },
  { url: '/jeux/white-noise-2-3050876883.html', name: 'white-noise-2' },
  {
    url: '/jeux/graceful-explosion-machine-3050863375.html',
    name: 'graceful-explosion-machine',
  },
  {
    url: '/jeux/the-franz-kafka-videogame-3050870613.html',
    name: 'the-franz-kafka-videogame',
  },
  {
    url: '/jeux/spark-the-electric-jester-3050875603.html',
    name: 'spark-the-electric-jester',
  },
  {
    url: '/jeux/fantastic-contraption-3050643287.html',
    name: 'fantastic-contraption',
  },
  {
    url: '/jeux/blackwood-crossing-3050706243.html',
    name: 'blackwood-crossing',
  },
  { url: '/jeux/saucer-like-3050875643.html', name: 'saucer-like' },
  { url: '/jeux/has-been-heroes-3050838997.html', name: 'has-been-heroes' },
  { url: '/jeux/domina-3050875623.html', name: 'domina' },
  { url: '/jeux/dome-city-3050616723.html', name: 'dome-city' },
  {
    url: '/jeux/hearthstone-voyage-au-centre-d-un-goro-3050864671.html',
    name: 'hearthstone-voyage-au-centre-d-un-goro',
  },
  { url: '/jeux/8-bit-bayonetta-3050875621.html', name: '8-bit-bayonetta' },
  {
    url: '/jeux/guns-of-icarus-alliance-3050877435.html',
    name: 'guns-of-icarus-alliance',
  },
  { url: '/jeux/project-lux-3050877479.html', name: 'project-lux' },
  { url: '/jeux/x-plane-11-3050812847.html', name: 'x-plane-11' },
  { note: '6', url: '/jeux/snake-pass-3050811251.html', name: 'snake-pass' },
  {
    note: '8',
    url: '/jeux/dark-souls-iii-the-ringed-city-3050843751.html',
    name: 'dark-souls-iii-the-ringed-city',
  },
  {
    url: '/jeux/blossom-tales-the-sleeping-king-3050876657.html',
    name: 'blossom-tales-the-sleeping-king',
  },
  { note: '6', url: '/jeux/toukiden-2-3050542963.html', name: 'toukiden-2' },
  {
    url: '/jeux/vikings-wolves-of-midgard-3050745927.html',
    name: 'vikings-wolves-of-midgard',
  },
  {
    note: '8',
    url: '/jeux/zero-escape-the-nonary-games-3050797177.html',
    name: 'zero-escape-the-nonary-games',
  },
  { url: '/jeux/troll-and-i-3050825559.html', name: 'troll-and-i' },
  { url: '/jeux/everything-3050654197.html', name: 'everything' },
  { url: '/jeux/kona-3050847555.html', name: 'kona' },
  {
    url: '/jeux/train-sim-world-csx-heavy-haul-3050872949.html',
    name: 'train-sim-world-csx-heavy-haul',
  },
  { url: '/jeux/cosmic-express-3050875869.html', name: 'cosmic-express' },
  {
    url: '/jeux/chaos-code-new-sign-of-catastrophe-3050626777.html',
    name: 'chaos-code-new-sign-of-catastrophe',
  },
  { url: '/jeux/death-squared-3050876221.html', name: 'death-squared' },
  {
    note: '7',
    url: '/jeux/nier-automata-3050485947.html',
    name: 'nier-automata',
  },
  { url: '/jeux/atelier-firis-3050700987.html', name: 'atelier-firis' },
  { url: '/jeux/streets-of-rogue-3050875639.html', name: 'streets-of-rogue' },
  { url: '/jeux/faeria-3050871437.html', name: 'faeria' },
  { url: '/jeux/loot-rascals-3050877025.html', name: 'loot-rascals' },
  { url: '/jeux/just-dance-2017-3050719967.html', name: 'just-dance-2017' },
  {
    note: '4',
    url: '/jeux/super-bomberman-r-3050838197.html',
    name: 'super-bomberman-r',
  },
  {
    url: '/jeux/shovel-knight-specter-of-torment-3050867179.html',
    name: 'shovel-knight-specter-of-torment',
  },
  { url: '/jeux/desync-3050850073.html', name: 'desync' },
  { url: '/jeux/riftstar-raiders-3050855107.html', name: 'riftstar-raiders' },
  {
    url: '/jeux/river-city-ransom-underground-3050530585.html',
    name: 'river-city-ransom-underground',
  },
  { url: '/jeux/for-the-king-3050543215.html', name: 'for-the-king' },
  {
    note: '5',
    url: '/jeux/berserk-muso-3050713527.html',
    name: 'berserk-muso',
  },
  { url: '/jeux/blackwake-3050864711.html', name: 'blackwake' },
  { url: '/jeux/forma-8-3050229987.html', name: 'forma-8' },
  { url: '/jeux/dungeon-rushers-3050758791.html', name: 'dungeon-rushers' },
  { url: '/jeux/four-last-things-3050853947.html', name: 'four-last-things' },
  {
    url: '/jeux/serious-sam-vr-the-first-encounter-3050828345.html',
    name: 'serious-sam-vr-the-first-encounter',
  },
  {
    url: '/jeux/serious-sam-vr-the-last-hope-3050716583.html',
    name: 'serious-sam-vr-the-last-hope',
  },
  { url: '/jeux/great-sam-3050812167.html', name: 'great-sam' },
  {
    url: '/jeux/secret-files-sam-peters-3050173179.html',
    name: 'secret-files-sam-peters',
  },
  {
    url: '/jeux/serious-sam-collection-3050158909.html',
    name: 'serious-sam-collection',
  },
  {
    note: '5',
    url: '/jeux/serious-sam-3-bfe-3010009168.html',
    name: 'serious-sam-3-bfe',
  },
  {
    url: '/jeux/serious-sam-3-jewel-of-the-nile-3050074638.html',
    name: 'serious-sam-3-jewel-of-the-nile',
  },
  {
    url: '/jeux/serious-sam-the-random-encounter-109212.html',
    name: 'serious-sam-the-random-encounter',
  },
  {
    url: '/jeux/serious-sam-kamikaze-attack-3010010296.html',
    name: 'serious-sam-kamikaze-attack',
  },
  {
    url: '/jeux/serious-sam-double-d-3010009193.html',
    name: 'serious-sam-double-d',
  },
  { url: '/jeux/serious-sam-hd-109223.html', name: 'serious-sam-hd' },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-5-the-city-that-dares-not-sleep-3010007828.html',
    name: 'sam-max-season-3-episode-5-the-city-that-dares-not-sleep',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls-3010007827.html',
    name: 'sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-3-they-stole-max-s-brain-3010007826.html',
    name: 'sam-max-season-3-episode-3-they-stole-max-s-brain',
  },
  {
    note: '8',
    url:
      '/jeux/sam-max-season-3-episode-2-the-tomb-of-sammun-mak-3010007825.html',
    name: 'sam-max-season-3-episode-2-the-tomb-of-sammun-mak',
  },
  {
    url: '/jeux/blackwell-convergence-3050289207.html',
    name: 'blackwell-convergence',
  },
  { url: '/jeux/shutshimi-3050668759.html', name: 'shutshimi' },
  { url: '/jeux/loadout-3050272301.html', name: 'loadout' },
  { url: '/jeux/runestone-keeper-3050437415.html', name: 'runestone-keeper' },
  { url: '/jeux/grav-3050387717.html', name: 'grav' },
  { url: '/jeux/eyetoy-antigrav-68584.html', name: 'eyetoy-antigrav' },
  { url: '/jeux/blackwell-unbound-3050289203.html', name: 'blackwell-unbound' },
  { url: '/jeux/subterrain-3050859631.html', name: 'subterrain' },
  {
    url: '/jeux/prime-world-defenders-3050136218.html',
    name: 'prime-world-defenders',
  },
  { url: '/jeux/super-hexagon-3050127454.html', name: 'super-hexagon' },
  {
    url: '/jeux/hot-tin-roof-the-cat-that-wore-a-fedora-3050420361.html',
    name: 'hot-tin-roof-the-cat-that-wore-a-fedora',
  },
  { note: '4', url: '/jeux/aer-3050406525.html', name: 'aer' },
  { url: '/jeux/jones-on-fire-3050441709.html', name: 'jones-on-fire' },
  {
    note: '4',
    url: '/jeux/game-of-thrones-a-telltale-games-series-3050208106.html',
    name: 'game-of-thrones-a-telltale-games-series',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-6-the-ice-dragon-3050374749.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-6-the-ice-dragon',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-4-sons-of-winter-3050374669.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-4-sons-of-winter',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-4-sons-of-winter-3050374661.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-4-sons-of-winter',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-4-sons-of-winter-3050388953.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-4-sons-of-winter',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-4-sons-of-winter-3050388955.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-4-sons-of-winter',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-3-the-sword-in-the-darkness-3050374605.html',
    name:
      'game-of-thrones-a-telltale-games-series-episode-3-the-sword-in-the-darkness',
  },
  {
    note: '-',
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-3-the-sword-in-the-darkness-3050374645.html',
    name:
      'game-of-thrones-a-telltale-games-series-episode-3-the-sword-in-the-darkness',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-3-the-sword-in-the-darkness-3050388951.html',
    name:
      'game-of-thrones-a-telltale-games-series-episode-3-the-sword-in-the-darkness',
  },
  {
    note: '-',
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-2-the-lost-lords-3050388959.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-2-the-lost-lords',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-1-iron-from-ice-3050374617.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-1-iron-from-ice',
  },
  {
    note: '5',
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-1-iron-from-ice-3050388957.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-1-iron-from-ice',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-2-the-lost-lords-3050208113.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-2-the-lost-lords',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-episode-5-a-nest-of-vipers-3050374731.html',
    name: 'game-of-thrones-a-telltale-games-series-episode-5-a-nest-of-vipers',
  },
  {
    url:
      '/jeux/game-of-thrones-a-telltale-games-series-saison-2-3050586087.html',
    name: 'game-of-thrones-a-telltale-games-series-saison-2',
  },
  {
    url:
      '/jeux/tales-from-the-borderlands-episode-5-the-vault-of-the-traveler-3050439457.html',
    name: 'tales-from-the-borderlands-episode-5-the-vault-of-the-traveler',
  },
  {
    note: '8',
    url: '/jeux/tales-from-the-borderlands-saison-1-3050569701.html',
    name: 'tales-from-the-borderlands-saison-1',
  },
  {
    url:
      '/jeux/tales-from-the-borderlands-episode-4-escape-plan-bravo-3050376659.html',
    name: 'tales-from-the-borderlands-episode-4-escape-plan-bravo',
  },
  {
    note: '-',
    url:
      '/jeux/tales-from-the-borderlands-episode-2-atlas-mugged-3050376625.html',
    name: 'tales-from-the-borderlands-episode-2-atlas-mugged',
  },
  {
    note: '8',
    url: '/jeux/tales-from-the-borderlands-episode-1-zer0-sum-3050256583.html',
    name: 'tales-from-the-borderlands-episode-1-zer0-sum',
  },
  {
    url:
      '/jeux/tales-from-the-borderlands-episode-3-catch-a-ride-3050376641.html',
    name: 'tales-from-the-borderlands-episode-3-catch-a-ride',
  },
  {
    url: '/jeux/read-only-memories-3050552129.html',
    name: 'read-only-memories',
  },
  {
    note: '6',
    url: '/jeux/the-darkness-ii-3010005239.html',
    name: 'the-darkness-ii',
  },
  {
    url: '/jeux/white-knight-chronicles-ii-97869.html',
    name: 'white-knight-chronicles-ii',
  },
  { url: '/jeux/silva-saga-ii-86446.html', name: 'silva-saga-ii' },
  {
    url: '/jeux/guilty-gear-xx-accent-core-plus-r-3050470871.html',
    name: 'guilty-gear-xx-accent-core-plus-r',
  },
  {
    url: '/jeux/guilty-gear-xx-accent-core-plus-r-114488.html',
    name: 'guilty-gear-xx-accent-core-plus-r',
  },
  {
    url: '/jeux/guilty-gear-xx-accent-core-plus-r-3050064504.html',
    name: 'guilty-gear-xx-accent-core-plus-r',
  },
  { url: '/jeux/snakebird-3050462931.html', name: 'snakebird' },
  {
    url: '/jeux/blackwell-deception-3050289221.html',
    name: 'blackwell-deception',
  },
  {
    note: '6',
    url: '/jeux/alien-isolation-3050191825.html',
    name: 'alien-isolation',
  },
  {
    url: '/jeux/the-mean-greens-plastic-warfare-3050597389.html',
    name: 'the-mean-greens-plastic-warfare',
  },
  {
    url: '/jeux/age-of-empires-ii-hd-3050121498.html',
    name: 'age-of-empires-ii-hd-edition',
  },
  { url: '/jeux/jumpjet-rex-3050391327.html', name: 'jumpjet-rex' },
  { url: '/jeux/really-big-sky-111043.html', name: 'really-big-sky' },
  { url: '/jeux/x-com-interceptor-92704.html', name: 'x-com-interceptor' },
  {
    url: '/jeux/jurassic-park-the-game-3010009129.html',
    name: 'jurassic-park-the-game',
  },
  {
    url: '/jeux/jurassic-park-the-game-2-hd-113590.html',
    name: 'jurassic-park-the-game-2-hd',
  },
  {
    url: '/jeux/jurassic-park-the-game-1-hd-111914.html',
    name: 'jurassic-park-the-game-1-hd',
  },
  {
    note: '8',
    url: '/jeux/steamworld-heist-3050336425.html',
    name: 'steamworld-heist',
  },
  { url: '/jeux/poly-bridge-3050876631.html', name: 'poly-bridge' },
  { url: '/jeux/her-story-3050405083.html', name: 'her-story' },
  {
    url: '/jeux/arcana-heart-3-love-max-3050228332.html',
    name: 'arcana-heart-3-love-max',
  },
  {
    url: '/jeux/arcana-heart-3-love-max-six-stars-3050353145.html',
    name: 'arcana-heart-3-love-max-six-stars',
  },
  {
    url: '/jeux/trine-3-the-artifacts-of-power-3050597881.html',
    name: 'trine-3-the-artifacts-of-power',
  },
  { url: '/jeux/invisible-inc-3050466057.html', name: 'invisible-inc' },
  { url: '/jeux/invisible-inc-3050450349.html', name: 'invisible-inc' },
  { url: '/jeux/ninja-pizza-girl-3050552077.html', name: 'ninja-pizza-girl' },
  {
    url: '/jeux/dark-souls-iii-the-fire-fades-3050843769.html',
    name: 'dark-souls-iii-the-fire-fades',
  },
  {
    note: '8',
    url: '/jeux/dark-souls-iii-the-ringed-city-3050843751.html',
    name: 'dark-souls-iii-the-ringed-city',
  },
  {
    note: '7',
    url: '/jeux/dark-souls-iii-ashes-of-ariandel-3050794057.html',
    name: 'dark-souls-iii-ashes-of-ariandel',
  },
  { note: '8', url: '/jeux/limbo-3010008099.html', name: 'limbo' },
  { url: '/jeux/limbo-of-the-lost-89971.html', name: 'limbo-of-the-lost' },
  {
    url: '/jeux/touhou-shinhiroku-urban-legend-in-limbo-3050816061.html',
    name: 'touhou-shinhiroku-urban-legend-in-limbo',
  },
  {
    url: '/jeux/agatha-christie-the-abc-murders-3050588853.html',
    name: 'agatha-christie-the-abc-murders',
  },
  {
    url: '/jeux/agatha-christie-the-abc-murders-99124.html',
    name: 'agatha-christie-the-abc-murders',
  },
  { url: '/jeux/gone-home-3050171973.html', name: 'gone-home' },
  {
    url: '/jeux/human-resource-machine-3050543129.html',
    name: 'human-resource-machine',
  },
  { url: '/jeux/dirt-rally-3050593453.html', name: 'dirt-rally' },
  {
    note: '8',
    url: '/jeux/colin-mcrae-dirt-2-3010006038.html',
    name: 'colin-mcrae-dirt-2',
  },
  { url: '/jeux/dirt-102849.html', name: 'dirt' },
  { url: '/jeux/80-days-3050315983.html', name: '80-days' },
  { url: '/jeux/deathtrap-3050408785.html', name: 'deathtrap' },
  { url: '/jeux/deathtrap-3050483813.html', name: 'deathtrap' },
  {
    url: '/jeux/fighting-fantasy-deathtrap-dungeon-103159.html',
    name: 'fighting-fantasy-deathtrap-dungeon',
  },
  { url: '/jeux/deathtrap-dungeon-3010002968.html', name: 'deathtrap-dungeon' },
  { url: '/jeux/death-trap-104935.html', name: 'death-trap' },
  { note: '6', url: '/jeux/abzu-3050273883.html', name: 'abzû' },
  {
    url: '/jeux/time-gentlemen-please-97389.html',
    name: 'time-gentlemen-please',
  },
  { url: '/jeux/aviary-attorney-3050376525.html', name: 'aviary-attorney' },
  { url: '/jeux/ben-there-dan-that-97391.html', name: 'ben-there-dan-that' },
  {
    url: '/jeux/shadow-complex-remastered-3050594491.html',
    name: 'shadow-complex-remastered',
  },
  { url: '/jeux/warframe-3050143809.html', name: 'warframe' },
  {
    url: '/jeux/ori-and-the-blind-forest-definitive-edition-3050523521.html',
    name: 'ori-and-the-blind-forest-definitive-edition',
  },
  { url: '/jeux/penarium-3050546715.html', name: 'penarium' },
  {
    url: '/jeux/raceroom-racing-experience-3050297915.html',
    name: 'raceroom-racing-experience',
  },
  { url: '/jeux/a-virus-named-tom-3010009857.html', name: 'a-virus-named-tom' },
  { url: '/jeux/bulb-boy-3050554715.html', name: 'bulb-boy' },
  {
    note: '6',
    url: '/jeux/superbrothers-sword-sworcery-ep-3010008812.html',
    name: 'superbrothers-sword-sworcery-ep',
  },
  { url: '/jeux/punch-club-3050613637.html', name: 'punch-club' },
  { url: '/jeux/punch-club-2-3050861657.html', name: 'punch-club-2' },
  {
    url: '/jeux/one-piece-pirate-warriors-3-3050328891.html',
    name: 'one-piece-pirate-warriors-3',
  },
  {
    url: '/jeux/one-piece-pirate-warriors-3-3050328823.html',
    name: 'one-piece-pirate-warriors-3',
  },
  { url: '/jeux/else-heart-break-3050547249.html', name: 'else-heart-break' },
  { url: '/jeux/else-heart-break-3050104051.html', name: 'else-heart-break' },
  { url: '/jeux/q-u-b-e-3010010369.html', name: 'q-u-b-e' },
  { note: '4', url: '/jeux/q-u-b-e-2-3050743295.html', name: 'q-u-b-e-2' },
  {
    url: '/jeux/q-u-b-e-against-the-clock-3050135830.html',
    name: 'q-u-b-e-against-the-clock',
  },
  { url: '/jeux/adr1ft-3050244431.html', name: 'adr1ft' },
  { note: '7', url: '/jeux/skullgirls-3010009166.html', name: 'skullgirls' },
  {
    note: '8',
    url: '/jeux/battleblock-theater-3050230653.html',
    name: 'battleblock-theater',
  },
  { url: '/jeux/dropsy-the-clown-3050518355.html', name: 'dropsy' },
  {
    note: '7',
    url: '/jeux/s-t-a-l-k-e-r-clear-sky-84839.html',
    name: 's-t-a-l-k-e-r-clear-sky',
  },
  { note: '6', url: '/jeux/broken-age-3010010528.html', name: 'broken-age' },
  {
    note: '7',
    url: '/jeux/blazblue-continuum-shift-extend-3010008941.html',
    name: 'blazblue-continuum-shift-extend',
  },
  {
    url: '/jeux/blazblue-continuum-shift-ii-extend-112305.html',
    name: 'blazblue-continuum-shift-ii-extend',
  },
  { url: '/jeux/duet-3050521893.html', name: 'duet' },
  { url: '/jeux/angelique-duet-76156.html', name: 'angelique-duet' },
  {
    url: '/jeux/ai-war-fleet-command-3010008794.html',
    name: 'ai-war-fleet-command',
  },
  {
    url: '/jeux/rocketsrocketsrockets-3050462415.html',
    name: 'rocketsrocketsrockets',
  },
  {
    note: '7',
    url: '/jeux/street-fighter-x-tekken-3010008351.html',
    name: 'street-fighter-x-tekken',
  },
  {
    url: '/jeux/tekken-x-street-fighter-3010009832.html',
    name: 'tekken-x-street-fighter',
  },
  { url: '/jeux/soul-axiom-3050483737.html', name: 'soul-axiom' },
  {
    url: '/jeux/mount-your-friends-3050312365.html',
    name: 'mount-your-friends',
  },
  { url: '/jeux/montague-s-mount-3050189963.html', name: 'montague-s-mount' },
  {
    url: '/jeux/mount-blade-warband-napoleonic-wars-3050010289.html',
    name: 'mount-blade-warband-napoleonic-wars',
  },
  {
    url: '/jeux/mount-blade-with-fire-and-sword-108576.html',
    name: 'mount-blade-with-fire-and-sword',
  },
  {
    url: '/jeux/mount-blade-complete-108595.html',
    name: 'mount-blade-complete',
  },
  {
    url: '/jeux/mount-blade-warband-3050300683.html',
    name: 'mount-blade-warband',
  },
  { url: '/jeux/mount-blade-88004.html', name: 'mount-blade' },
  {
    url: '/jeux/mount-blade-ii-bannerlord-3050070608.html',
    name: 'mount-blade-ii-bannerlord',
  },
  {
    note: '6',
    url: '/jeux/spec-ops-the-line-3010007334.html',
    name: 'spec-ops-the-line',
  },
  { url: '/jeux/x-com-apocalypse-9391.html', name: 'x-com-apocalypse' },
  {
    note: '8',
    url: '/jeux/civilization-v-brave-new-world-3050123531.html',
    name: 'civilization-v-brave-new-world',
  },
  {
    url: '/jeux/civilization-v-edition-jeu-de-l-annee-3010009998.html',
    name: 'civilization-v-edition-jeu-de-l-annee',
  },
  {
    note: '7',
    url: '/jeux/civilization-v-3010008755.html',
    name: 'civilization-v',
  },
  {
    url: '/jeux/robot-roller-derby-disco-dodgeball-3050419149.html',
    name: 'robot-roller-derby-disco-dodgeball',
  },
  { url: '/jeux/receiver-3050118971.html', name: 'receiver' },
  { url: '/jeux/overgrowth-112086.html', name: 'overgrowth' },
  { url: '/jeux/tower-of-guns-3050442963.html', name: 'tower-of-guns' },
  {
    url: '/jeux/retro-city-rampage-dx-3050362151.html',
    name: 'retro-city-rampage-dx',
  },
  {
    note: '4',
    url: '/jeux/un-voisin-d-enfer-2-en-vacances-59961.html',
    name: 'un-voisin-d-enfer-2-en-vacances',
  },
  {
    note: '4',
    url: '/jeux/un-voisin-d-enfer-2-en-vacances-59961.html',
    name: 'un-voisin-d-enfer-2-en-vacances',
  },
  { url: '/jeux/girls-like-robots-3050080676.html', name: 'girls-like-robots' },
  {
    note: '8',
    url: '/jeux/ori-and-the-blind-forest-3050272745.html',
    name: 'ori-and-the-blind-forest',
  },
  {
    url: '/jeux/ori-and-the-blind-forest-definitive-edition-3050523521.html',
    name: 'ori-and-the-blind-forest-definitive-edition',
  },
  { url: '/jeux/dangerous-golf-3050625877.html', name: 'dangerous-golf' },
  { url: '/jeux/cibele-3050661279.html', name: 'cibele' },
  {
    url: '/jeux/aban-hawkins-the-1001-spikes-3050014648.html',
    name: 'aban-hawkins-the-1001-spikes',
  },
  {
    url: '/jeux/x-com-terror-from-the-deep-3010003090.html',
    name: 'x-com-terror-from-the-deep',
  },
  {
    url: '/jeux/system-shock-enhanced-edition-3050546379.html',
    name: 'system-shock-enhanced-edition',
  },
  {
    url: '/jeux/7-grand-steps-step-1-what-ancients-begat-3050155210.html',
    name: '7-grand-steps-step-1-what-ancients-begat',
  },
  {
    note: '7',
    url: '/jeux/stardew-valley-3050689921.html',
    name: 'stardew-valley',
  },
  {
    url: '/jeux/dangerous-high-school-girls-in-trouble-3010008180.html',
    name: 'dangerous-high-school-girls-in-trouble',
  },
  {
    note: '4',
    url: '/jeux/painkiller-hell-and-damnation-3050073641.html',
    name: 'painkiller-hell-and-damnation',
  },
  {
    url: '/jeux/painkiller-black-edition-74122.html',
    name: 'painkiller-black-edition',
  },
  {
    note: '5',
    url: '/jeux/painkiller-battle-out-of-hell-69238.html',
    name: 'painkiller-battle-out-of-hell',
  },
  { url: '/jeux/puzzle-agent-3010008292.html', name: 'puzzle-agent' },
  {
    note: '5',
    url: '/jeux/puzzle-agent-2-3010009132.html',
    name: 'puzzle-agent-2',
  },
  {
    url: '/jeux/spellforce-2-faith-in-destiny-96637.html',
    name: 'spellforce-2-faith-in-destiny',
  },
  { url: '/jeux/project-highrise-3050644903.html', name: 'project-highrise' },
  { note: '7', url: '/jeux/bastion-3010009197.html', name: 'bastion' },
  { url: '/jeux/devil-daggers-3050599365.html', name: 'devil-daggers' },
  {
    url: '/jeux/arcania-the-complete-tale-3050463847.html',
    name: 'arcania-the-complete-tale',
  },
  {
    url: '/jeux/gothic-4-arcania-fall-of-setarrif-3010009114.html',
    name: 'gothic-4-arcania-fall-of-setarrif',
  },
  {
    note: '5',
    url: '/jeux/gothic-4-arcania-3010004005.html',
    name: 'gothic-4-arcania',
  },
  {
    note: '6',
    url: '/jeux/red-faction-armageddon-3010006505.html',
    name: 'red-faction-armageddon',
  },
  {
    note: '7',
    url: '/jeux/guilty-gear-xrd-sign-3050178580.html',
    name: 'guilty-gear-xrd--sign-',
  },
  {
    note: '7',
    url: '/jeux/ultra-street-fighter-iv-3050163822.html',
    name: 'ultra-street-fighter-iv',
  },
  { url: '/jeux/spirits-3050089196.html', name: 'spirits' },
  {
    url: '/jeux/yo-kai-watch-2-ganso-3050247613.html',
    name: 'yo-kai-watch-2-ganso',
  },
  { url: '/jeux/adk-spirits-3050866699.html', name: 'adk-spirits' },
  { url: '/jeux/gt-spirits-3050540413.html', name: 'gt-spirits' },
  { url: '/jeux/spirits-of-xanadu-3050440311.html', name: 'spirits-of-xanadu' },
  {
    url: '/jeux/destiny-of-spirits-3050153209.html',
    name: 'destiny-of-spirits',
  },
  {
    url: '/jeux/g-g-series-dark-spirits-100328.html',
    name: 'g-g-series-dark-spirits',
  },
  {
    url: '/jeux/nyxquest-kindred-spirits-3010008605.html',
    name: 'nyxquest-kindred-spirits',
  },
  { url: '/jeux/equilibrio-3010006880.html', name: 'equilibrio' },
  { url: '/jeux/spooky-spirits-93349.html', name: 'spooky-spirits' },
  {
    note: '8',
    url: '/jeux/bangai-o-spirits-85777.html',
    name: 'bangai-o-spirits',
  },
  {
    note: '4',
    url: '/jeux/samurai-shodown-v-3010001285.html',
    name: 'samurai-shodown-v',
  },
  {
    note: '6',
    url: '/jeux/shaman-king-master-of-spirits-2-75014.html',
    name: 'shaman-king-master-of-spirits-2',
  },
  {
    url: '/jeux/shaman-king-master-of-spirits-64297.html',
    name: 'shaman-king-master-of-spirits',
  },
  {
    note: '6',
    url: '/jeux/mr-driller-drill-spirits-69863.html',
    name: 'mr-driller-drill-spirits',
  },
  { url: '/jeux/dino-d-day-109233.html', name: 'dino-d-day' },
  { url: '/jeux/defense-grid-2-3050170569.html', name: 'defense-grid-2' },
  { url: '/jeux/grid-autosport-3050249601.html', name: 'grid-autosport' },
  {
    url: '/jeux/grid-2-demolition-derby-3050177929.html',
    name: 'grid-2-demolition-derby',
  },
  {
    note: '6',
    url: '/jeux/race-driver-grid-2-3010008841.html',
    name: 'race-driver-grid-2',
  },
  {
    url: '/jeux/race-driver-grid-reloaded-3010007840.html',
    name: 'race-driver-grid-reloaded',
  },
  {
    url: '/jeux/defense-grid-the-awakening-3010006136.html',
    name: 'defense-grid-the-awakening',
  },
  { url: '/jeux/gridrunner-3010007098.html', name: 'gridrunner' },
  {
    note: '7',
    url: '/jeux/race-driver-grid-3010003867.html',
    name: 'race-driver-grid',
  },
  { url: '/jeux/gridrunner-3010005662.html', name: 'gridrunner' },
  { url: '/jeux/grid-iron-3490.html', name: 'grid-iron' },
  { url: '/jeux/grid-run-3010005497.html', name: 'grid-run' },
  { url: '/jeux/grid-seeker-87454.html', name: 'grid-seeker' },
  { url: '/jeux/gridrunner-3010005663.html', name: 'gridrunner' },
  { url: '/jeux/vid-grid-67444.html', name: 'vid-grid' },
  { url: '/jeux/the-witness-3050117143.html', name: 'the-witness' },
  { url: '/jeux/the-witness-3010009614.html', name: 'the-witness' },
  {
    url: '/jeux/jagged-alliance-back-in-action-108646.html',
    name: 'jagged-alliance-back-in-action',
  },
  { url: '/jeux/lost-castle-3050811053.html', name: 'lost-castle' },
  {
    url: '/jeux/guns-of-icarus-online-3050172704.html',
    name: 'guns-of-icarus-online',
  },
  {
    url: '/jeux/god-eater-2-rage-burst-3050330169.html',
    name: 'god-eater-2-rage-burst',
  },
  { note: '8', url: '/jeux/sine-mora-3010008449.html', name: 'sine-mora' },
  { url: '/jeux/sine-mora-ex-3050867541.html', name: 'sine-mora-ex' },
  {
    url: '/jeux/sega-mega-drive-classics-3050878125.html',
    name: 'sega-mega-drive-classics',
  },
  {
    url: '/jeux/sega-mega-drive-and-genesis-classics-3050688559.html',
    name: 'sega-mega-drive-and-genesis-classics',
  },
  {
    url: '/jeux/sega-mega-drive-classics-collection-volume-5-3050019979.html',
    name: 'sega-mega-drive-classics-collection-volume-5',
  },
  {
    url: '/jeux/sega-mega-drive-classics-collection-volume-4-105652.html',
    name: 'sega-mega-drive-classics-collection-volume-4',
  },
  {
    url: '/jeux/sega-mega-drive-classics-collection-volume-3-105651.html',
    name: 'sega-mega-drive-classics-collection-volume-3',
  },
  {
    url: '/jeux/sega-mega-drive-classics-collection-volume-2-105650.html',
    name: 'sega-mega-drive-classics-collection-volume-2',
  },
  {
    url: '/jeux/sega-mega-drive-classics-collection-volume-1-105649.html',
    name: 'sega-mega-drive-classics-collection-volume-1',
  },
  {
    note: '7',
    url: '/jeux/sega-megadrive-ultimate-collection-3010006027.html',
    name: 'sega-megadrive-ultimate-collection',
  },
  {
    note: '7',
    url: '/jeux/sega-megadrive-collection-3010002711.html',
    name: 'sega-megadrive-collection',
  },
  { url: '/jeux/skydrift-3010009448.html', name: 'skydrift' },
  {
    url: '/jeux/hell-yeah-pocket-inferno-3050117272.html',
    name: 'hell-yeah-pocket-inferno',
  },
  {
    note: '6',
    url: '/jeux/hell-yeah-la-fureur-du-lapin-mort-3010010102.html',
    name: 'hell-yeah-la-fureur-du-lapin-mort',
  },
  {
    note: '7',
    url: '/jeux/jet-set-radio-3010010573.html',
    name: 'jet-set-radio',
  },
  { url: '/jeux/jet-set-radio-3010002709.html', name: 'jet-set-radio' },
  {
    note: '8',
    url: '/jeux/jet-set-radio-future-8807.html',
    name: 'jet-set-radio-future',
  },
  { url: '/jeux/jet-set-radio-2-3498.html', name: 'jet-set-radio-2' },
  { url: '/jeux/de-la-jet-set-radio-78703.html', name: 'de-la-jet-set-radio' },
  {
    note: '6',
    url: '/jeux/binary-domain-3010008878.html',
    name: 'binary-domain',
  },
  {
    note: '6',
    url:
      '/jeux/strong-bad-s-cool-game-for-attractive-people-strong-badia-the-free-3010005792.html',
    name: 'strong-bad-s-cool-game-for-attractive-people-strong-badia-the-free',
  },
  {
    note: '7',
    url: '/jeux/renegade-ops-3010009276.html',
    name: 'renegade-ops',
  },
  {
    url: '/jeux/renegade-ops-coldstrike-campaign-3010010323.html',
    name: 'renegade-ops-coldstrike-campaign',
  },
  {
    note: '6',
    url: '/jeux/viking-battle-for-asgard-3010004346.html',
    name: 'viking-battle-for-asgard',
  },
  {
    note: '5',
    url:
      '/jeux/strong-bad-s-cool-game-for-attractive-people-baddest-of-the-bands-3010005793.html',
    name: 'strong-bad-s-cool-game-for-attractive-people-baddest-of-the-bands',
  },
  { url: '/jeux/waking-mars-3050004964.html', name: 'waking-mars' },
  {
    url: '/jeux/elegy-for-a-dead-world-3050344265.html',
    name: 'elegy-for-a-dead-world',
  },
  {
    note: '6',
    url:
      '/jeux/strong-bad-s-cool-game-for-attractive-people-dangeresque-3-3010005794.html',
    name: 'strong-bad-s-cool-game-for-attractive-people-dangeresque-3',
  },
  {
    note: '6',
    url:
      '/jeux/strong-bad-s-cool-game-for-attractive-people-8-bit-is-enough-3010005795.html',
    name: 'strong-bad-s-cool-game-for-attractive-people-8-bit-is-enough',
  },
  {
    url: '/jeux/thirty-flights-of-loving-3050087696.html',
    name: 'thirty-flights-of-loving',
  },
  { url: '/jeux/eets-munchies-3050233380.html', name: 'eets-munchies' },
  {
    url: '/jeux/the-wolf-among-us-saison-2-3050876283.html',
    name: 'the-wolf-among-us-saison-2',
  },
  {
    url: '/jeux/the-wolf-among-us-episode-5-cry-wolf-3050269479.html',
    name: 'the-wolf-among-us-episode-5-cry-wolf',
  },
  {
    url: '/jeux/the-wolf-among-us-episode-5-cry-wolf-3050269481.html',
    name: 'the-wolf-among-us-episode-5-cry-wolf',
  },
  {
    note: '6',
    url: '/jeux/the-wolf-among-us-episode-5-cry-wolf-3050189419.html',
    name: 'the-wolf-among-us-episode-5-cry-wolf',
  },
  {
    url: '/jeux/the-wolf-among-us-episode-5-cry-wolf-3050189406.html',
    name: 'the-wolf-among-us-episode-5-cry-wolf',
  },
  {
    url: '/jeux/the-wolf-among-us-episode-5-cry-wolf-3050189475.html',
    name: 'the-wolf-among-us-episode-5-cry-wolf',
  },
  {
    url:
      '/jeux/the-wolf-among-us-episode-4-in-sheep-s-clothing-3050269465.html',
    name: 'the-wolf-among-us-episode-4-in-sheep-s-clothing',
  },
  {
    url:
      '/jeux/the-wolf-among-us-episode-4-in-sheep-s-clothing-3050269467.html',
    name: 'the-wolf-among-us-episode-4-in-sheep-s-clothing',
  },
  {
    note: '6',
    url:
      '/jeux/the-wolf-among-us-episode-4-in-sheep-s-clothing-3050189415.html',
    name: 'the-wolf-among-us-episode-4-in-sheep-s-clothing',
  },
  {
    url:
      '/jeux/the-wolf-among-us-episode-4-in-sheep-s-clothing-3050189405.html',
    name: 'the-wolf-among-us-episode-4-in-sheep-s-clothing',
  },
  {
    url:
      '/jeux/the-wolf-among-us-episode-4-in-sheep-s-clothing-3050189459.html',
    name: 'the-wolf-among-us-episode-4-in-sheep-s-clothing',
  },
  {
    url: '/jeux/the-wolf-among-us-episode-3-a-crooked-mile-3050269459.html',
    name: 'the-wolf-among-us-episode-3-a-crooked-mile',
  },
  {
    url: '/jeux/the-wolf-among-us-episode-3-a-crooked-mile-3050269461.html',
    name: 'the-wolf-among-us-episode-3-a-crooked-mile',
  },
  {
    note: '7',
    url: '/jeux/the-wolf-among-us-episode-3-a-crooked-mile-3050189411.html',
    name: 'the-wolf-among-us-episode-3-a-crooked-mile',
  },
  {
    url: '/jeux/the-wolf-among-us-episode-3-a-crooked-mile-3050189437.html',
    name: 'the-wolf-among-us-episode-3-a-crooked-mile',
  },
  { url: '/jeux/forced-3050169516.html', name: 'forced' },
  {
    note: '6',
    url: '/jeux/rocketbirds-hardboiled-chicken-3050008057.html',
    name: 'rocketbirds-hardboiled-chicken',
  },
  { url: '/jeux/space-run-galaxy-3050373749.html', name: 'space-run-galaxy' },
  { url: '/jeux/super-meat-boy-3010007128.html', name: 'super-meat-boy' },
  {
    url: '/jeux/super-meat-boy-forever-3050330935.html',
    name: 'super-meat-boy-forever',
  },
  {
    url: '/jeux/super-meat-boy-ultra-edition-109984.html',
    name: 'super-meat-boy-ultra-edition',
  },
  {
    url: '/jeux/super-meat-boy-the-game-3050010305.html',
    name: 'super-meat-boy-the-game',
  },
  {
    url: '/jeux/poker-night-at-the-inventory-3010008859.html',
    name: 'poker-night-at-the-inventory',
  },
  { url: '/jeux/poker-night-2-3050132861.html', name: 'poker-night-2' },
  { url: '/jeux/door-kickers-2-3050613015.html', name: 'door-kickers-2' },
  {
    url: '/jeux/serious-sam-vr-the-first-encounter-3050828345.html',
    name: 'serious-sam-vr-the-first-encounter',
  },
  {
    url: '/jeux/serious-sam-vr-the-last-hope-3050716583.html',
    name: 'serious-sam-vr-the-last-hope',
  },
  { url: '/jeux/great-sam-3050812167.html', name: 'great-sam' },
  {
    url: '/jeux/secret-files-sam-peters-3050173179.html',
    name: 'secret-files-sam-peters',
  },
  {
    url: '/jeux/serious-sam-collection-3050158909.html',
    name: 'serious-sam-collection',
  },
  {
    note: '5',
    url: '/jeux/serious-sam-3-bfe-3010009168.html',
    name: 'serious-sam-3-bfe',
  },
  {
    url: '/jeux/serious-sam-3-jewel-of-the-nile-3050074638.html',
    name: 'serious-sam-3-jewel-of-the-nile',
  },
  {
    url: '/jeux/serious-sam-the-random-encounter-109212.html',
    name: 'serious-sam-the-random-encounter',
  },
  {
    url: '/jeux/serious-sam-kamikaze-attack-3010010296.html',
    name: 'serious-sam-kamikaze-attack',
  },
  {
    url: '/jeux/serious-sam-double-d-3010009193.html',
    name: 'serious-sam-double-d',
  },
  { url: '/jeux/serious-sam-hd-109223.html', name: 'serious-sam-hd' },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-5-the-city-that-dares-not-sleep-3010007828.html',
    name: 'sam-max-season-3-episode-5-the-city-that-dares-not-sleep',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls-3010007827.html',
    name: 'sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-3-they-stole-max-s-brain-3010007826.html',
    name: 'sam-max-season-3-episode-3-they-stole-max-s-brain',
  },
  {
    note: '8',
    url:
      '/jeux/sam-max-season-3-episode-2-the-tomb-of-sammun-mak-3010007825.html',
    name: 'sam-max-season-3-episode-2-the-tomb-of-sammun-mak',
  },
  {
    url: '/jeux/hitman-sniper-challenge-3050059169.html',
    name: 'hitman-sniper-challenge',
  },
  {
    note: '7',
    url: '/jeux/brothers-a-tale-of-two-sons-3050070367.html',
    name: 'brothers-a-tale-of-two-sons',
  },
  { url: '/jeux/gun-monkeys-3050136029.html', name: 'gun-monkeys' },
  { note: '5', url: '/jeux/mafia-iii-3050493767.html', name: 'mafia-iii' },
  { url: '/jeux/mushroom-11-3050551169.html', name: 'mushroom-11' },
  {
    url: '/jeux/serious-sam-vr-the-first-encounter-3050828345.html',
    name: 'serious-sam-vr-the-first-encounter',
  },
  {
    url: '/jeux/serious-sam-vr-the-last-hope-3050716583.html',
    name: 'serious-sam-vr-the-last-hope',
  },
  { url: '/jeux/great-sam-3050812167.html', name: 'great-sam' },
  {
    url: '/jeux/secret-files-sam-peters-3050173179.html',
    name: 'secret-files-sam-peters',
  },
  {
    url: '/jeux/serious-sam-collection-3050158909.html',
    name: 'serious-sam-collection',
  },
  {
    note: '5',
    url: '/jeux/serious-sam-3-bfe-3010009168.html',
    name: 'serious-sam-3-bfe',
  },
  {
    url: '/jeux/serious-sam-3-jewel-of-the-nile-3050074638.html',
    name: 'serious-sam-3-jewel-of-the-nile',
  },
  {
    url: '/jeux/serious-sam-the-random-encounter-109212.html',
    name: 'serious-sam-the-random-encounter',
  },
  {
    url: '/jeux/serious-sam-kamikaze-attack-3010010296.html',
    name: 'serious-sam-kamikaze-attack',
  },
  {
    url: '/jeux/serious-sam-double-d-3010009193.html',
    name: 'serious-sam-double-d',
  },
  { url: '/jeux/serious-sam-hd-109223.html', name: 'serious-sam-hd' },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-5-the-city-that-dares-not-sleep-3010007828.html',
    name: 'sam-max-season-3-episode-5-the-city-that-dares-not-sleep',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls-3010007827.html',
    name: 'sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-3-they-stole-max-s-brain-3010007826.html',
    name: 'sam-max-season-3-episode-3-they-stole-max-s-brain',
  },
  {
    note: '8',
    url:
      '/jeux/sam-max-season-3-episode-2-the-tomb-of-sammun-mak-3010007825.html',
    name: 'sam-max-season-3-episode-2-the-tomb-of-sammun-mak',
  },
  { url: '/jeux/cayne-3050845975.html', name: 'cayne' },
  {
    url: '/jeux/sir-you-are-being-hunted-3050267103.html',
    name: 'sir-you-are-being-hunted',
  },
  {
    url: '/jeux/maldita-castilla-ex-3050725541.html',
    name: 'maldita-castilla-ex',
  },
  {
    url: '/jeux/serious-sam-vr-the-first-encounter-3050828345.html',
    name: 'serious-sam-vr-the-first-encounter',
  },
  {
    url: '/jeux/serious-sam-vr-the-last-hope-3050716583.html',
    name: 'serious-sam-vr-the-last-hope',
  },
  { url: '/jeux/great-sam-3050812167.html', name: 'great-sam' },
  {
    url: '/jeux/secret-files-sam-peters-3050173179.html',
    name: 'secret-files-sam-peters',
  },
  {
    url: '/jeux/serious-sam-collection-3050158909.html',
    name: 'serious-sam-collection',
  },
  {
    note: '5',
    url: '/jeux/serious-sam-3-bfe-3010009168.html',
    name: 'serious-sam-3-bfe',
  },
  {
    url: '/jeux/serious-sam-3-jewel-of-the-nile-3050074638.html',
    name: 'serious-sam-3-jewel-of-the-nile',
  },
  {
    url: '/jeux/serious-sam-the-random-encounter-109212.html',
    name: 'serious-sam-the-random-encounter',
  },
  {
    url: '/jeux/serious-sam-kamikaze-attack-3010010296.html',
    name: 'serious-sam-kamikaze-attack',
  },
  {
    url: '/jeux/serious-sam-double-d-3010009193.html',
    name: 'serious-sam-double-d',
  },
  { url: '/jeux/serious-sam-hd-109223.html', name: 'serious-sam-hd' },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-5-the-city-that-dares-not-sleep-3010007828.html',
    name: 'sam-max-season-3-episode-5-the-city-that-dares-not-sleep',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls-3010007827.html',
    name: 'sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-3-they-stole-max-s-brain-3010007826.html',
    name: 'sam-max-season-3-episode-3-they-stole-max-s-brain',
  },
  {
    note: '8',
    url:
      '/jeux/sam-max-season-3-episode-2-the-tomb-of-sammun-mak-3010007825.html',
    name: 'sam-max-season-3-episode-2-the-tomb-of-sammun-mak',
  },
  {
    note: '8',
    url: '/jeux/grand-theft-auto-episodes-from-liberty-city-3010007445.html',
    name: 'grand-theft-auto-episodes-from-liberty-city',
  },
  {
    url: '/jeux/grand-theft-auto-iv-l-edition-integrale-3010008630.html',
    name: 'grand-theft-auto-iv-l-edition-integrale',
  },
  {
    note: '8',
    url: '/jeux/dust-an-elysian-tail-3050125777.html',
    name: 'dust-an-elysian-tail',
  },
  { note: '7', url: '/jeux/vvvvvv-3010008531.html', name: 'vvvvvv' },
  { url: '/jeux/system-shock-2-421.html', name: 'system-shock-2' },
  { url: '/jeux/poker-night-2-3050132861.html', name: 'poker-night-2' },
  {
    url: '/jeux/serious-sam-vr-the-first-encounter-3050828345.html',
    name: 'serious-sam-vr-the-first-encounter',
  },
  {
    url: '/jeux/serious-sam-vr-the-last-hope-3050716583.html',
    name: 'serious-sam-vr-the-last-hope',
  },
  { url: '/jeux/great-sam-3050812167.html', name: 'great-sam' },
  {
    url: '/jeux/secret-files-sam-peters-3050173179.html',
    name: 'secret-files-sam-peters',
  },
  {
    url: '/jeux/serious-sam-collection-3050158909.html',
    name: 'serious-sam-collection',
  },
  {
    note: '5',
    url: '/jeux/serious-sam-3-bfe-3010009168.html',
    name: 'serious-sam-3-bfe',
  },
  {
    url: '/jeux/serious-sam-3-jewel-of-the-nile-3050074638.html',
    name: 'serious-sam-3-jewel-of-the-nile',
  },
  {
    url: '/jeux/serious-sam-the-random-encounter-109212.html',
    name: 'serious-sam-the-random-encounter',
  },
  {
    url: '/jeux/serious-sam-kamikaze-attack-3010010296.html',
    name: 'serious-sam-kamikaze-attack',
  },
  {
    url: '/jeux/serious-sam-double-d-3010009193.html',
    name: 'serious-sam-double-d',
  },
  { url: '/jeux/serious-sam-hd-109223.html', name: 'serious-sam-hd' },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-5-the-city-that-dares-not-sleep-3010007828.html',
    name: 'sam-max-season-3-episode-5-the-city-that-dares-not-sleep',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls-3010007827.html',
    name: 'sam-max-season-3-episode-4-beyond-the-alley-of-the-dolls',
  },
  {
    note: '7',
    url:
      '/jeux/sam-max-season-3-episode-3-they-stole-max-s-brain-3010007826.html',
    name: 'sam-max-season-3-episode-3-they-stole-max-s-brain',
  },
  {
    note: '8',
    url:
      '/jeux/sam-max-season-3-episode-2-the-tomb-of-sammun-mak-3010007825.html',
    name: 'sam-max-season-3-episode-2-the-tomb-of-sammun-mak',
  },
  { url: '/jeux/monster-loves-you-3050138132.html', name: 'monster-loves-you' },
  { url: '/jeux/prison-architect-3050621759.html', name: 'prison-architect' },
  {
    note: '8',
    url: '/jeux/mark-of-the-ninja-3050065680.html',
    name: 'mark-of-the-ninja',
  },
  {
    url: '/jeux/mark-of-the-ninja-remastered-3050878231.html',
    name: 'mark-of-the-ninja-remastered',
  },
  {
    note: '5',
    url: '/jeux/mark-of-the-ninja-special-edition-3050124742.html',
    name: 'mark-of-the-ninja-special-edition',
  },
  { url: '/jeux/sunless-sea-3050410695.html', name: 'sunless-sea' },
  { url: '/jeux/xenonauts-3050105557.html', name: 'xenonauts' },
  { url: '/jeux/xenonauts-2-3050632717.html', name: 'xenonauts-2' },
  { url: '/jeux/to-the-moon-113378.html', name: 'to-the-moon' },
];

// we split the array in multiple part as typescript can not process such big array.
const gkreviews = [...gkreviews1, ...gkreviews2, ...gkreviews3];

export default gkreviews;
