import Snackbar from '@material-ui/core/Snackbar/index';
import IconButton from '@material-ui/core/IconButton/index';
import CloseIcon from '@material-ui/icons/Close';

import React from 'react';

import '../App.css';

import '../index.css';
import Filters from '../data-components/FiltersData';

const ShortkeySnackBar = ({
  snackbaropen,
  closeShortkeySnackBar,
  shortkeyStatus,
}: {
  closeShortkeySnackBar: () => void;
  snackbaropen: boolean;
  shortkeyStatus: { filter1: boolean; filter2: boolean; filter3: boolean };
}): JSX.Element => (
  <Snackbar
    open={snackbaropen}
    autoHideDuration={4000}
    onClose={closeShortkeySnackBar}
    // SnackbarContentProps={{
    //   'aria-describedby': 'message-id',
    // }}
    message={
      <span id="message-id" className="shortkeysnackbarmsg">
        {Filters.hotkeys.filter1.name}
        {shortkeyStatus.filter1 ? 'ON' : 'OFF'}
        &nbsp;&nbsp;
        {Filters.hotkeys.filter2.name}
        {shortkeyStatus.filter2 ? 'ON' : 'OFF'}
        &nbsp;&nbsp;
        {Filters.hotkeys.filter3.name}
        {shortkeyStatus.filter3 ? 'ON' : 'OFF'}
      </span>
    }
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={closeShortkeySnackBar}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

export default ShortkeySnackBar;
