/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/display-name */
import React from 'react';
import moment from 'moment';
import matchSorter from 'match-sorter';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction/index';
import RestoreIcon from '@material-ui/icons/BuildRounded';
import Button from '@material-ui/core/Button/index';
import localforage from 'localforage';
import { ItemAPI } from '../data-types/ItemAPI';
import DefaultItem, { autoSelectedItemId } from './DefaultItemData';
import { Item } from '../data-types/Item';
import { Column } from '../types/Column';
import gamekultreviews from '../database/gkreviews';
import SoundtrackLink from './SountrackLink';

export function getSearchPlaceholder(): string {
  return 'Plugin';
}

export function getOpenDrawerDefault(): boolean {
  return true;
}

export function getSearchCondition(item: Item, searchterm: string): boolean {
  return item.name.toLowerCase().indexOf(searchterm.toLowerCase()) !== -1;
}

export function getSideBarCloseButton(closeDrawer: () => void): JSX.Element {
  return (
    <Button className="backbutton" onClick={closeDrawer}>
      {' < BACK'}
    </Button>
  );
}

export function getSideBarInfoMenuTab(): JSX.Element {
  return <BottomNavigationAction label="Plugin" icon={<RestoreIcon />} />;
}

function formatTimePlayed(labelValue: string): string {
  let formatednumber;

  if (Math.abs(Number(labelValue) / 60) >= 1) {
    // Nine Zeroes for Billions
    formatednumber = `${(Math.abs(Number(labelValue)) / 60).toFixed(0)}h`;
  } else if (labelValue) {
    // less than an hour
    formatednumber = `${labelValue}mn`;
  } else {
    formatednumber = labelValue; // 0
  }

  return formatednumber;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getDisplayedColumns(
  filteredcolumns: Array<Column>,
  selectedFiat: string,
  customSort: (a: string, b: string, desc: boolean) => number,
  dataforfilters: { categories: Array<{ name: string }> },
  spHandleClickOpen: (value: string) => void
) {
  return filteredcolumns.map((column: Column) => {
    switch (column.id) {
      case 'id':
        return {
          Header: '',
          accessor: 'id',
          maxWidth: 50,
          fixed: 'left',
          filterable: false,
          Cell: (row: {
            value: number;
            row: { _original: { cover: { url: string } } };
          }): JSX.Element => {
            let imageurl = '';
            if (row.row._original.cover) {
              imageurl = row.row._original.cover.url;
            }

            return (
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                <img src={`${imageurl}`} width={45} height={55} alt="cover" />
              </span>
            );
          },
        };
      case 'name':
        return {
          Header: 'Name',
          id: 'name',
          className: 'namecell',
          filterable: true,
          filterMethod: (
            filter: { id: string; value: string },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rows: any
          ): boolean =>
            rows[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
          accessor: 'name',
          Cell: (row: { value: string }): JSX.Element => (
            <span>{row.value}</span>
          ),
        };
      case 'firstRelease':
        return {
          Header: 'First Release',
          id: 'firstRelease',
          className: 'namecell',
          accessor: 'firstRelease',
          Cell: (row: { value: number }): JSX.Element => (
            <span>{row.value}</span>
          ),
          filterable: true,
          filterAll: false,
          filterMethod: (
            filter: { id: number; value: string },
            row: Array<number>
          ): boolean => {
            if (filter.value === 'less1y') {
              return row[filter.id] != 0 && row[filter.id] >= 2019;
            }
            if (filter.value === 'over1y') {
              return row[filter.id] != 0 && row[filter.id] < 2019;
            }

            return true;
          },
          Filter: ({
            filter,
            onChange,
          }: {
            filter: { value: string };
            onChange: (value: string) => void;
          }): JSX.Element => (
            <select
              onChange={(event: { target: { value: string } }): void =>
                onChange(event.target.value)
              }
              style={{ width: '80%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">All</option>
              <option value="over1y"> Older than a year</option>
              <option value="less1y"> These last 12 months</option>
            </select>
          ),
        };

      case 'firstPaidRelease':
        return {
          Header: 'First Paid Release',
          id: 'firstPaidRelease',
          className: 'namecell',
          accessor: 'firstPaidRelease',
          Cell: (row: { value: number }): JSX.Element => (
            <span>{row.value}</span>
          ),
          filterable: true,
          filterAll: false,
          filterMethod: (
            filter: { id: number; value: string },
            row: Array<number>
          ): boolean => {
            if (filter.value === 'less1y') {
              return row[filter.id] != 0 && row[filter.id] >= 2019;
            }
            if (filter.value === 'over1y') {
              return row[filter.id] != 0 && row[filter.id] < 2019;
            }

            return true;
          },
          Filter: ({
            filter,
            onChange,
          }: {
            filter: { value: string };
            onChange: (value: string) => void;
          }): JSX.Element => (
            <select
              onChange={(event: { target: { value: string } }): void =>
                onChange(event.target.value)
              }
              style={{ width: '80%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">All</option>
              <option value="over1y"> Older than a year</option>
              <option value="less1y"> These last 12 months</option>
            </select>
          ),
        };

      case 'type':
        return {
          Header: 'Type',
          id: 'type',
          className: 'namecell',
          filterable: true,
          filterMethod: (
            filter: { id: string; value: string },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rows: any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ): any => {
            if (filter.value === 'all') {
              return rows;
            }
            return matchSorter(rows, filter.value, {
              keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'type' }],
            });
          },
          sortable: true,
          filterAll: true,
          Filter: ({
            filter,
            onChange,
          }: {
            filter: { id: string; value: string };
            onChange: (value: string) => void;
          }): JSX.Element => {
            return (
              <select
                onChange={(event: { target: { value: string } }): void =>
                  onChange(event.target.value)
                }
                style={{ width: '80%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">All</option>
                <option value="gamification">Gamification</option>
                <option value="Audio & Video">Audio & Video</option>
                <option value="plagiarism">Plagiarism</option>
                <option value="theme">Theme</option>
              </select>
            );
          },
          accessor: 'type',
          Cell: (row: { value: string }): JSX.Element => (
            <span>{row.value}</span>
          ),
        };

      default:
        return {};
    }
  });
}

export function convertPricesToSelectedFiat(items: Array<Item>): Array<Item> {
  return items;
}

function howlong(timetobeats: Array<{ normally: number }>): number | null {
  if (timetobeats.length === 0) return null;
  let numberoftimetobeat = 0;
  const timetobeat = timetobeats.reduce((accumulator, currentValue) => {
    if (currentValue.normally) {
      numberoftimetobeat += 1;
    }
    return currentValue.normally
      ? accumulator + currentValue.normally
      : accumulator;
  }, 0);
  return numberoftimetobeat ? timetobeat / numberoftimetobeat : null;
}

function updateItemsFromAPIdata(
  apiData: Array<ItemAPI>,
  filter1: boolean,
  filter1items: Array<string>,
  filter2: boolean,
  filter3: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setState: (state: any) => void,
  closeDrawer: () => void,
  gameListid: string
): void {
  console.log(apiData);
  const items: Array<Item> = apiData.map((itemAPI: ItemAPI) => {
    // Initialise the Item.
    const item: Item = { ...DefaultItem };

    // Create the Item from the API data.
    item.id = itemAPI.id;
    item.name = itemAPI.name;
    item.url = itemAPI.url;
    item.demoUrl = itemAPI.demoUrl;
    item.type = itemAPI.type;
    item.desc = itemAPI.desc;
    item.screenshots = itemAPI.screenshots;
    item.videos = itemAPI.videos;
    item.firstRelease = itemAPI.firstRelease;
    item.firstPaidRelease = itemAPI.firstPaidRelease;
    return item;
  });

  // cache the items so we do not call the api on every single refresh.
  if (!filter1 && !filter2 && !filter3) {
    // only save steam library under 1000 otherwise localforage explose
    // if (JSON.stringify(items).length < 3784251) {
    localforage.setItem(`${gameListid}_items`, JSON.stringify(items));
    localforage.setItem(`${gameListid}_updatetime`, moment().format('x'));
    // }
  }

  if (items.length > 0) {
    closeDrawer();
  }

  setState({ items, loadingData: false, moodleLoadingData: false });
}

const APIhandler = {
  setFilter1Items: (): void => {},

  setFilter2Items: (): void => {},

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setItems: async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setState: (state: any) => void,
    initialiseToFirstItem: (itemid: string) => void,
    filter1: boolean,
    filter1items: Array<string>,
    filter2: boolean,
    filter3: boolean,
    displayErrorSnackBar: ({ message }: { message: string }) => void,
    settings: { steamid64?: string },
    closeDrawer: () => void,
    gameListUrl: string | null,
    gameListid: string
  ) => {
    let nodeserverurl =
      new URL(window.location.href).hostname === 'localhost'
        ? `https://a4ce23ac-8214-4d3d-a6de-aae225468d06.mock.pstmn.io/plugins`
        : `https://a4ce23ac-8214-4d3d-a6de-aae225468d06.mock.pstmn.io/plugins`;

    if (gameListUrl) {
      nodeserverurl = gameListUrl;
    }

    await fetch(nodeserverurl)
      .then(response => response.json())
      .then((responseJson: Array<ItemAPI>) => {
        const itemsFromAPI = responseJson;
        updateItemsFromAPIdata(
          itemsFromAPI,
          filter1,
          filter1items,
          filter2,
          filter3,
          setState,
          closeDrawer,
          gameListid
        );
      })
      .catch((error: { message: string }) => {
        displayErrorSnackBar(error);
      });
    initialiseToFirstItem(autoSelectedItemId);
  },
};

export default APIhandler;
